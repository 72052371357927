.storefinder {
    &.content {
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        padding: 25px 0 25px 66px;
        align-items: flex-end;
        background-color: $gray-ultralight;

        @include breakpoint('tablet-portrait-max') {
            padding: 16px;
            margin-top: 8px;
        }

        >span {
            display: inline-block;

            &:first-child {
                @include font-size(20px);

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(18px);
                }
            }
        }
    }

    &__container {
        display: flex;
        padding: 0px 65px 40px;
        height: 850px;
        position: relative;

        @include breakpoint('tablet-portrait-max') {
            height: auto;
            flex-flow: column;
            padding: 0;
            min-height: 500px;
        }
    }

    &__search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0 24px 0;
        position: absolute;
        top: -1px;
        width: 500px;
        background: $white;
        @include z-index(one);

        @include breakpoint('tablet-landscape-max') {
            width: 450px;
        }

        @include breakpoint('tablet-portrait-max') {
            position: static;
            width: 100%;
            flex-flow: column;
            align-items: flex-end;
            padding: 24px 16px;
        }
    }

    &__input {
        display: flex;
        height: 45px;
        position: relative;
        width: calc(100% - 150px);

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            margin-bottom: 16px;
            max-width: none;
        }

        input {
            background-color: $white;
            border: solid 1px $gray-light;
            border-radius: 5px;
            padding: 0 60px 0 16px;
            flex: 1;
            height: 100%;
            text-overflow: ellipsis;
            @include font-size(16px);

            &:focus {
                border-color: $green;

                ~.storefinder__geoloc {
                    border-left: 1px solid $green;
                }
            }

            &::placeholder{
                 @include font-size(14px);

                 @include breakpoint('phone-max'){
                    @include font-size(13px);
                 }
            }
        }
    }

    &__geoloc {
        position: absolute;
        padding: 0 16px;
        right: 3px;
        top: 2px;
        bottom: 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: $white;
        @include z-index(three);

        @include breakpoint('tablet-portrait-max') {
            border-left: 1px solid $gray-light;
        }
    }

    &__filter--cta {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include font-size(14px);

        .iconfilter-mobile {
            color: transparent;
            padding-right: 8px;
        }
    } 

    &__cta {
        border-bottom: 1px solid $gray-light;
        padding: 16px;
        text-transform: uppercase;
        cursor: pointer;
        width: 50%;
        text-align: center;

        &.active {
            border-bottom: 3px solid $red;
        }

        &--container {
            display: flex;
            margin: 0 16px;
        }
    }

    &__list {
        overflow-y: auto;
        width: 500px;
        margin-top: 98px;
        @include overflow();
        
        &::-webkit-scrollbar {
            width: 8px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 0;
            background-color: #f2f2f2;
            box-shadow: none;
        } 

        @include breakpoint('tablet-landscape-max') {
            width: 450px;
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            margin-top: 0;
        }
    }

    &__filters {
        position: absolute;
        top: 0;
        bottom: 20px;
        left: 65px;
        background-color: $white;
        height: 812px;
        width: 500px;
        opacity: 0;
        visibility: hidden;
        @include z-index(two);
        @include overflow();

        &::-webkit-scrollbar {
            width: 8px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 0;
            background-color: #f2f2f2;
            box-shadow: none;
        } 


        @include breakpoint('tablet-landscape-max') {
            width: 450px;
        }

        @include breakpoint('tablet-portrait-max') {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            height: auto;
            padding: 0;
            @include z-index(filter-mobile);
        }
        
        &.open {
            transition: all .3s ease-in-out;
            @include open();
        }

        &--content {
            overflow-y: auto;
            height: 100%;
        }

        &--head {
            position: sticky;
            top: 0;
            background: $white;
            @include z-index(two);
        }

        &--title {
            display: flex;
            align-items: center;
            padding: 16px;
            justify-content: space-between;

            b {
                @include font-size(20px);
            }

            .btn {
                width: 150px;
                display: flex;
                justify-content: center;
            }
        }

        ul {
            background-color: rgb(0,0,0,0.04);

            @include breakpoint('tablet-portrait-max') {
                margin-bottom: 90px;
            }
        }

        &--item {
            margin: 0 16px;
            border-top: 1px solid $gray-light;
            padding: 16px 0;
            display: flex;
            align-items: center;

            span {
                display: flex;
                height: 50px;
                width: 50px;
                border-radius: 100%;
                margin-right: 35px;
                align-items: center;
                justify-content: center;
                background-color: $white;
                overflow: hidden;

                img {
                    width: 80%;
                    height: 80%;
                }
            }

            input[type=checkbox]+label {
                width: 100%;
                padding: 0 35px 0 0;
                font-weight: bold;
            }

            input[type=checkbox]+label:after,
            input[type=checkbox]:checked+label:before {
                top: 30%;
                right: 0;
                left: initial;
            }
        }

        &--cta {
            position: sticky;
            bottom: 0;
            padding: 24px 16px;
            box-shadow: 0 4px 16px 4px rgba(0,0,0,.2);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            @include z-index(one);

            @include breakpoint('tablet-portrait-max') {
                position: fixed;
                bottom: 0;
                width: 100%;
            }

            .btn-green {
                width: 270px;

                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                }
            }
        }
    }
}

 //Common StoreFinderSearchPage & storeFinderDetailsPage
 .store-page__c-slot {
    overflow: hidden;
    padding: 20px 65px;

    @include breakpoint('tablet-portrait-max') {
        padding: 12px 16px;
    }

    .paragraph {
        line-height: 1.5;
        padding: 20px 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 12px 0;
        }
    }

    .store-specific-banner {
        padding: 20px 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 12px 0;
        }

        .glide__bullets {
            position: absolute;
            bottom: 0;
        }
    }

    .store-finder-bottom-hero-banner,
    .store-details-bottom-hero-banner {
        .glide__slide {
            padding: 20px 0;

            @include breakpoint('tablet-portrait-max') {
                padding: 12px 0;
            }
        }

        .glide__bullets {
            position: absolute;
            bottom: 0;
        }
    }

    .twin-banner__item {
        @include breakpoint('tablet-portrait-min') {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}    

#allStores {
    position: fixed;
    left: -2500px;
    height: 500px;
    overflow-y: auto;
    @include z-index(negative);
}