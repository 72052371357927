.awards-carousel {
    background-color: $gray-ultralight;

    &__ctn {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-weight: bold;
        padding: 24px 16px 0;
        @include font-size(18px);
    }

    &__c-carousel {
        padding: 24px 0;
    }

    .glide__slide {
        height: auto;
        display: flex;

        .award-card {
            width: 100%;
        }

    }
}