.coupon-converter {

    .breadcrumbs {
        @include breakpoint('tablet-portrait-max') {
            margin-bottom: 32px;
        }
    }

    &__info {
        font-weight: bold;
        margin: 30px 0;
        @include font-size(24px);
        
        @include breakpoint('tablet-portrait-min') {
            @include font-size(30px);
        }
    }

    .account__banner {
        
        @include breakpoint('tablet-portrait-min') {
            margin: 10px 65px 30px;
        }
    }

    .awards-catalog__title {
        padding: 40px 0 16px;
        display: flex;
        flex-wrap: wrap;
        
        @include breakpoint('tablet-portrait-max') {
            text-align: center;
            padding: 32px 0 23px;
        }

    }

    .support__login, .frequently-asked-questions {
        margin: 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 36px 65px 4px;
        }
    }

    .tab-nav {
        margin-top: 16px;

        @include breakpoint('tablet-portrait-min') {
            margin-top: 40px;
        }
    }

    &__subtitle {
        font-size: 16px;
    }

    .tab-nav__item.active {
        font-size: 16px;
        font-weight: bold;
    }

    .tab-content {
        position: absolute;
        visibility: hidden;
        width: 100%;
        bottom: 0;
    
        &.show {
            position: inherit;
            visibility: visible;
        }
    }


    .support__login {
        .login-image {
            img {
                object-position: right;
                
                @media screen and (max-width: 600px) {
                    object-position: inherit;
                }
            }
        }
    }
}

.explanatory-box {
    @include breakpoint('tablet-portrait-min') {
        display: flex;
        flex-wrap: wrap;
    }

    .mirror-explanation {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        padding: 24px 0 0;

        &__box {
            flex-basis: 33%;
        }
    
        &__wrapper {
            width: 75%;
            max-width: 324px;
            margin: auto;

            @include breakpoint('tablet-portrait-min') {
                margin: 20px;
                width: auto;
                min-width: 247px;
            }
        }

        &__c-description {
            padding: 0;
        }

        &__title {
            text-align: center;
            padding: 10px 0 16px;

            @include breakpoint('tablet-portrait-max') {
                font-size: 18px;
            }
        }

        &__text {
            font-size: 16px;
            max-width: 600px;
        }
    }

    &__cta-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 32px 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 20px auto 36px;
        }
    }

    &__cta {
        border-radius: 8px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        padding: 16px 24px;

        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 180px;

            @include breakpoint('phone-small-min') {
                width: 250px;
            }
        }
    }
 
}

.coupon-converter-tab {
    margin: 0 16px 30px;
    position: relative;

    @include breakpoint('tablet-portrait-min') {
        margin: 0 65px 30px;
    }
}

.converter-box {
    margin: 40px auto 60px;

    @include breakpoint('phone-small-min') {
        width: max-content;
    }

    @include breakpoint('tablet-portrait-min') {
        width: auto;
        display: flex;
        padding: 40px 0;
        margin: 0;
    }

    &__info {
        @include breakpoint('tablet-portrait-min') {
            padding: 56px 0 0 52px;
        }
    }

    &__text {
        line-height: 2.5;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(24px);
        }
    }

    &__text.first {
        @include font-size(24px);
    }

    &__btn {
        width: 100%;
        max-width: 356px;
        height: 40px;
        margin-top: 32px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            width: 218px;
        }
    }
}

.points-balance {
    font-weight: bold;
    margin: 32px 0 26px;
    @include font-size(24px);

    @include breakpoint('tablet-portrait-min') {
        font-weight: normal;
        margin: 60px 0 0;
        @include font-size(40px);
    }
}

.points {
    color: $red;
    font-weight: 800;
    @include font-size(50px);

    @include breakpoint('tablet-portrait-min') {
        @include font-size(100px);
    }
}

.percent {
    position: relative;
    
    svg {
        position: relative;
        width: 290px;
        height: 290px;
        display: block;
        margin: auto;
        transform: rotate(-90deg);
    }
}

.central-image {
    position: absolute;
    left: 0; 
    right: 0; 
    top: 0; 
    bottom: 0; 
    margin: auto;
    max-width: 150px;
    max-height: 150px;
}