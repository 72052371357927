.bennet-card {
    $self: &;
    border-radius: 4px;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 16px;
    overflow: hidden;
    background-color: $white;
    height: min-content;

    &__c-img {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__info {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 200px;
    }

    &__title {
        @include font-size(16px);
    }

    &__text {
        flex: 1;
    }

    &--INIZIATIVA {
        min-height: 370px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);

        @include breakpoint('tablet-portrait-min') {
            min-height: 400px;
        }

        #{ $self }__c-img {
            height: 110px;

            @include breakpoint('tablet-portrait-min') {
                min-height: 210px;
            }
        }

        #{ $self }__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        #{ $self }__text {
            padding: 8px 0 16px;
            line-height: 1.5;
            @include font-size(14px);
        }

        #{ $self }__cta {
            width: 100%;

            a {
                display: flex;
                width: 100%;
                justify-content: center;
                height: 100%;
                align-items: center;
                padding: 0 16px;
            }

            @include breakpoint('tablet-portrait-min') {
                min-width: 175px;
                width: auto;
            }
        }
    }

    &--PRODOTTO {
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, .12);
        
        @include breakpoint('tablet-portrait-min') {
        }

        #{ $self }__c-img {
            min-height: 180px;
        }

        #{ $self }__img {
            height: 100%;
            width: auto;
        }

        #{ $self }__title {
            min-height: 46px;
        }

        #{ $self }__text {
            line-height: 1.5;
            @include font-size(16px);
        }

        #{ $self }__cta{
            margin: 12px auto;
            @include font-size(13px);
        }
    }

    &--CARATTERISTICA {
        min-height: 250px;

        @include breakpoint('tablet-portrait-min') {
            padding: 24px 16px;
            flex-direction: row;
            min-height: 190px;
        }

        #{ $self }__c-img {
            width: 120px;
            margin: 0 auto;

            @include breakpoint('tablet-portrait-min') {
                width: 140px;
                margin: 0;
            }
        }

        #{ $self }__text {
            line-height: normal;
            padding: 8px 0;
            @include font-size(14px);

            @include breakpoint('tablet-portrait-min') {
                padding: 4px 0 0 20px;
            }
        }
    }

    &--SERVIZIO {
        min-height: 180px;
        box-shadow: 0 1px 20px 0 rgba(110, 110, 110, 0.19);
        padding: 0;
        
        @include breakpoint('tablet-portrait-min') {
            min-height: 265px;
        }

        #{ $self }__wrapper {
            border-radius: 4px;
            display: flex;
            flex: 1;
            position: relative;
            flex-direction: column;
            overflow: hidden;

            &:hover #{ $self }__border {
                display: block;
            }
            
        }

        #{ $self }__border {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: inset 0 0 0 2px $green;
            border-radius: 4px;
            top: 0;
            left: 0;
        }

        #{ $self }__c-img {
            height: 120px;
            border-radius: 4px 4px 0 0;

            @include breakpoint('tablet-portrait-min') {
                min-height: 170px;
            }
        }

        #{ $self }__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        #{ $self }__info {
            padding: 12px 16px;
        }

        #{ $self }__title {
            padding-bottom: 8px;
            @include font-size(16px);

            @include breakpoint('tablet-portrait-min') {
                @include font-size(18px);
            }
        }

        #{ $self }__text {
            padding: 0;
            line-height: normal;

            @include breakpoint('tablet-portrait-min') {
               line-height: 1.5;
            }
        }

        #{ $self }__cta {
            width: 100%;

            @include breakpoint('tablet-portrait-min') {
                width: 175px;
            }
        }
    }

    &--EVENTO {
        border-radius: 0;
        box-shadow: 0 1px 20px 0 rgba(110, 110, 110, 0.19);
        padding: 0;

        #{ $self }__wrapper {
            display: flex;
            flex: 1;
            position: relative;
            flex-direction: column;
            overflow: hidden;
        }

        #{ $self }__c-img {
            height: 170px;
        }

        #{ $self }__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        #{ $self }__info {
            padding: 16px;
        }

        #{ $self }__title {
            line-height: normal;
            padding-bottom: 8px;
            @include font-size(18px);
        }

        #{ $self }__text {
            padding: 0;
            line-height: 1.5;
            @include font-size(14px);
        }
    }
}