$z-index: (
    cookie-banner: 19,
    loader: 18,
    global-message: 17,
    filter-mobile: 16,
    backdrop-up: 15,
    backdrop: 14,
    panel-mobile: 13,
    header: 12,
    backToTop: 11,
    footer: 10,
    three: 3,
    two: 2,
    one: 1,
    zero: 0,
    negative: -1,
);

@function z-index($key) {
    @return map-get($z-index, $key);
}

@mixin z-index($key) {
    & {
        z-index: z-index($key);
    }
}