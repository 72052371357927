.drive-panel {
    color: $black;
    cursor: initial;

    &__content {
        @include breakpoint('tablet-portrait-max') {
            padding-top: 8px;                  
        }
    }
}

.drive-selected {
    border-top: 1px solid $red;
    border-bottom: 1px solid $red;
    border-right: 16px solid $red;
    border-radius: 0 5px 5px 0;

    @include breakpoint('tablet-portrait-max') {
        border-right: 12px solid $red;                  
    }

    .drive-store__available-closer {
        background-color: $green;
    }

    .drive-store {
        border-bottom: none;

        &__wrapper {
            padding: 16px 0 16px 24px;

            @include breakpoint('tablet-portrait-max') {
                padding: 25px 0 25px 16px;                 
            }
        }
    }
}

.drive-search {
    padding: 30px 24px 25px;
    border-bottom: 1px solid $gray-light;

    @include breakpoint('tablet-portrait-max') {
        padding: 25px 16px 10px;
        line-height: 1.69;               
    }

    &__title {
        $color: $gray-dark;
        padding-bottom: 20px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-max') {
            padding-bottom: 15px;
            @include font-size(16px);                
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__input {
        display: flex;
        height: 45px;

        @include breakpoint('tablet-portrait-max') {
            height: 40px;         
        }

        input {
            border: solid 2px $green-rgb-4;
            background-color: $gray-ultralight-rgb-5;
            border-right: none;
            border-radius: 5px 0 0 5px;
            padding-left: 12px;
            flex: 1;
            height: 100%;
            @include font-size(16px);

            @include breakpoint('tablet-portrait-max') {
                @include font-size(14px);
            }

            &::placeholder {
                font-style: italic;
            }

            &:focus {
                border-color: $green;
            }
        }
    }

    &__button {
        width: 50px;
        border-radius: 0 4px 4px 0;
        background-color: $green;
        color: $white;
        cursor: pointer;
        border: 0;
        text-align: center;
        margin: 0;

        @include breakpoint('tablet-portrait-max') {
            width: 55px;
            
            .iconsearch {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.drive-listing .drive-store:hover {
    .drive-store__wrapper {
        background-color: #e8f4e7;
    }
    
    .drive-store__available-closer {
        background-color: $green;
    }
}
