    .initiative-section {
        margin: 0 15.84% 80px 11.6%;

        @include breakpoint('tablet-portrait-max') {
            margin: 0 4% 40px;
        }

        .glide__slide{
            display: flex;
            height: auto;

            .desktop-hide {
                width: 100%;
            }

        }

        .glide__arrows{
            @include breakpoint('tablet-portrait-max') {
                .glide__arrow {
                    top: auto;
                    bottom: 30%;

                    &--left {
                        left: -10px;
                    }

                    &--right {
                        right: -10px;
                    }
                }
            }
        }

        .text-container{
            display: flex;
            width: 68.9%;
            flex-flow: column;
            margin-right: 24px;
            justify-content: center;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }

            .title{
                line-height: 1.5;
                margin-bottom: 15px;
                font-weight: bold;
                padding-right: 25px;
                @include font-size(30px);

                @include breakpoint('tablet-portrait-max') {
                    padding-right: 0;
                    @include font-size(24px);
                }
            }

            .text{
                line-height: normal;
                margin-bottom: 16px;
                @include font-size(16px);
            }
        }

        .img-container{
            width: 28.7%;
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
                margin-bottom: 25px;
            }

            img{
                width: 100%;
                height: auto;
            }
        }

        .btn {
            min-width: 218px;
            margin-left: auto;

            a {
                display: flex;
                width: 100%;
                justify-content: center;
                height: 100%;
                align-items: center;
                color: inherit;
                padding: 0 16px;
            }

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }
        }

        .glide__arrow--right{
            right: -62px;
        }

        .glide__arrow--left{
            left: -62px;
        }
    }
