.rewards-common {

    .account__banner {
        @include breakpoint('tablet-portrait-max') {
            margin-bottom: 0px;
        }

        @include breakpoint('tablet-portrait-min') {
            margin: 0 65px 30px;
        }
    }

    &__hero-img {
        padding: 30px 16px 30px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 65px 32px;
        }
    }

    .support__login {
        margin-top: 0px;
        margin-bottom: 0px;

        @include breakpoint('tablet-portrait-min') {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .frequently-asked-questions {
        padding: 0 16px 60px;
    
        @include breakpoint('tablet-portrait-min') {
            padding: 0 64px 80px;
        }
    }

    .awards-carousel {

        &__c-carousel {
            @include breakpoint('tablet-portrait-min') {
                padding: 24px 125px;
            }
        }

        &__title {
            @include breakpoint('tablet-portrait-min') {
                padding-left: 65px;
            }
        }
    
        .glide {
            &__arrow--right {
                right: -60px;
            }
            
            &__arrow--left {
                left: -60px;
            }
        }
    }
}

.listing-rewards {

    .filter-hide {
        display: none;
    }

    .back-to-top {
        position: fixed;
        right: 3%;
        bottom: 100px;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.2);
        width: 45px;
        height: 45px;
        @include z-index('backToTop');

        .arrow__up {
            margin: 5px 7px 0 7px;
        }
    }

    .rewards-filter {
        padding: 32px 16px 32px;

        @include breakpoint('tablet-portrait-min') {
            padding: 32px 65px 32px;
        }

        &__header {
            padding-bottom: 25px;

            @include breakpoint('tablet-portrait-min') {
                display: flex;
                align-items: center;
            }
        }

        &__title {
            padding-bottom: 15px;
            @include font-size(18px);

            @include breakpoint('tablet-portrait-min') {
                padding: 0
            }
        }

        &__results {
            @include font-size(16px);

            @include breakpoint('tablet-portrait-min') {
                padding-left: 16px;
                @include font-size(14px);
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            max-width: none;

            @include breakpoint('tablet-portrait-min') {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__c-form {
            @include breakpoint('phone-min') {
                display: flex;
                justify-content: space-between;
                margin: 0 -12px;
            }

            @include breakpoint('tablet-portrait-min') {
                width: 75%;
            }
        }

        &__first-row {
            @include breakpoint('phone-min') {
                flex: 1;
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        &__second-row {
            display: flex;
            margin: 0 -12px;

            @include breakpoint('phone-min') {
                display: flex;
                justify-content: space-between;
                margin: 0;
                flex: 2;
            }

            .form-group {
                width: calc(50% - 12px);
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        .form-group {
            @include breakpoint('tablet-portrait-min') {
                margin-bottom: 0;
            }
        }

        input {
            &::-webkit-input-placeholder,
            &:-ms-input-placeholder,
            &::placeholder { 
                @include font-size(14px);
            }
        }

        input,
        .customselect__button {
            @include breakpoint('tablet-portrait-max') {
                font-weight: bold;
            }

            @include font-size(14px);
        }

        &__cta {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            @include font-size(13px);
    
            @include breakpoint('tablet-portrait-max') {
                @include btn-mobile('sm')
            }

            @include breakpoint('tablet-portrait-min') {
                width: 220px;
                margin: 0;
            }      
        }
    }

    &__c-items {
        background-color: $gray-ultralight;
        padding: 0 16px 34px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @include breakpoint('tablet-portrait-min') {
            padding-left: 65px;
            padding-right: 65px;
        }
    }

    &__items-title {
        padding: 28px 0 5px;
        @include font-size(18px);
    }

    &__items {
        display: flex;
        flex-flow: wrap;

        @include breakpoint('phone-min') {
            display: flex;
            flex-flow: wrap;
            margin: 0 -12px;
        }

        @include breakpoint('tablet-portrait-min') {
            margin: 0 -35px;
        }

        @include breakpoint('tablet-landscape-min') {
            margin: 0 -8px;
        }

        @include breakpoint('desktop-small-min') {
            margin: 0 -35px;
        }

        .award-card {
            margin: 12px 0;
            width: 100%;
    
            @include breakpoint('phone-min') {
                width: calc(50% - 24px);
                margin: 12px;
            }

            @include breakpoint('tablet-portrait-min') {
                width: calc(50% - 70px);
                margin: 12px 35px;
            }

            @include breakpoint('tablet-landscape-min') {
                width: calc(33.333% - 16px);
                margin: 8px;
                padding: 12px;
            }

            @include breakpoint('desktop-small-min') {
                width: calc(33.333% - 70px);
                margin: 12px 35px;
                padding: 16px;
            }
        }
    }

    &__view-all {
        width: 100%;
        max-width: 350px;
        margin: 12px auto 0;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            @include btn-mobile('sm')
        }
        
    }
}