/*! critical:start */
body {
    font-family: $muli-font;
    background-color: $white;
    color: $gray-dark;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include font-size(16px);

    @include breakpoint('tablet-portrait-max') {
        @include font-size (14px);
    }
}

.container {
    display: flex;
    flex-flow: column;
    max-width: 1680px;
    width: 100%;
    margin: 0 auto;

    @include breakpoint('tablet-portrait-max') {
        margin-top: 144px; //new topbar
    }
}
/*! critical:end */

.backdrop {
    width: calc(100% + 17px);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    background-color: $gray-light;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    @include z-index(backdrop);

    &__up {
        @include z-index(backdrop-up);
    }
    
    &.active {
        opacity: 0.8;
        max-height: 100vh;
        visibility: visible;
        transition: all 0.3s ease-in-out;
    }
}

.loader {
    width: calc(100% + 17px);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    visibility: hidden;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    @include z-index(loader);
    
    &.active {
        background-color: $gray-ultralight-rgb-8;
        max-height: 100vh;
        visibility: visible;
        transition: all 0.3s ease-in-out;

        img {
            width:300px;
            height:300px;
        }

        .message {
            background-color: rgb(255, 255, 255, 1);
            z-index: 20;
            border-radius: 6px;
            border: 2px solid $orange-secondary-first;
            padding: 24px;
            line-height: 1.6;
            width: 50%;
            display: flex;
            align-items: center;

            @include breakpoint('phone-max') {
                position: fixed;
                width: 100%;
                bottom: 0;
                left: 0;
            }

            .iconspeaker {
                margin-right: 16px;
                color: $orange-secondary-first;
                width: 30%;
            }
        }
    }
}

.right-panel {
    position: fixed;
    top: 0;
    right: 0;
    background-color: $white;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    overflow-x: hidden;
    width: 575px;
    border-radius: 0 0 0 5px;

    @include breakpoint('tablet-portrait-max') {
        width: 100%;
        left: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
}

.modal {
    position: fixed;
    top: 60px;
    right: 12%;
    left:12%;
    max-height: calc(100vh - 140px);
    background-color: $white;
    overflow-y: auto;
    overflow-x: hidden;
    width: 76%;
    @include overflow();

    @include breakpoint('tablet-portrait-max') {
        width: 100%;
        top: 0;
        left: 0;
        height: calc(var(--vh, 1vh) * 100 );
        max-height: calc(var(--vh, 1vh) * 100);
    }
}

.popup {
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
    background-color: $white;
    overflow-y: auto;
    overflow-x: hidden;
    width: 576px;
    border-radius: 0 0 8px 8px;

    @include breakpoint('tablet-portrait-max') {
        width: 100%;
        top: 0;
        left: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        transform: none;
        border-radius: initial;             
    }

    &__content {
        padding: 18px 60px;
        line-height: 1.5;

        @include breakpoint('tablet-portrait-max') {
            padding: 20px 16px;
            @include font-size(15px);
        }
    }

    &__c-go-back {
        color: $white;
        padding: 16px 24px;
        background-color: $red;
    }

    &__go-back {
        cursor: pointer;
        display: flex;
        align-items: center;
        @include font-size(18px);

        .arrow {
            border: solid white;
            border-width: 0 2px 2px 0;
            margin-right: 12px;
        }
    }


    &__footer {
        background-color: $gray-ultralight;
        display: flex;
        padding: 15px 55px;
        justify-content: space-between;
        align-items: center;

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            bottom: 0;
            padding: 15px;
            position: fixed;
            justify-content: center;
            background-color: $white;
            box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.2);
            @include z-index('two');
        }

        .btn {
            width: 175px;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }
        }
    }

    &__cancel {
        text-transform: uppercase;
        cursor: pointer;
    }

    &__split {
        width: 780px;

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
        }
        
        &__content {
            display: flex;

            @include breakpoint('tablet-portrait-max') {
                flex-direction: column;
            }

            p {
                flex: 1;
                padding: 16px 0;
                line-height: 1.6;

                @include breakpoint('tablet-portrait-max') {
                    padding: 0 0 16px 0;
                }

                b {
                    font-weight: 900;
                }
            }

            hr {
                margin: 16px 0;

                @include breakpoint('tablet-portrait-min') {
                    width: 1px;
                    height: auto;
                    margin: 0 16px;
                }
            }
        }

        &__footer .btn {
            width: 250px;
            line-height: 1.5;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }
        }
    }
}

.product-qnt {
    &__container {
        display: flex;
        //margin-bottom: 12px;
        height: 40px;
        width: 100%;
        border-radius: 5px;
        border: solid 1px $gray-ultralight;
        background-color: $gray-ultralight;
    }

    &__less,
    &__plus {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        min-width: 22px;
        font-weight: 600;
        color: $green;
        cursor: pointer;
        border: 1px solid $gray-ultralight;
        transition: border-color .15s ease-in-out;
        @include font-size(26px);

        &:hover {
            border: 1px solid $gray;
        }
    }

    &__less {
        border-radius: 5px 0 0 5px;
    }

    &__plus {
        border-radius: 0 5px 5px 0;
    }

    &__value {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        font-family: $lato-font;
        background-color: $white;

    }

    &--disabled {
        cursor: default;
        pointer-events: none;
        color: $gray;
        background-color: $gray-light;
    }
}

.timeslot__caption {
    padding-bottom: 12px;
    
    &--item {
        margin-right: 16px;
        display: inline-flex;
        align-items: center;
        @include font-size(14px);
    
        @include breakpoint('tablet-portrait-max') {
            margin-right: 10px;
            @include font-size(11px);
        }
    }
    
    .bullet {
        border-radius: 100%;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 8px;
    
        @include breakpoint('tablet-portrait-max') {
            margin-right: 4px;
        }
    }
    
    .empty {
        color: #4cbb50;
    
        .bullet {
            background-color: #4cbb50;
        }
        .description {
            border: 1px solid $green;
            padding: 1px 8px;
        }
    }
    
    .almost-full {
        color: #ff9f1c;
    
        .bullet {
            background-color: #ff9f1c;
        }
        .description {
            border-bottom: 1px dotted $orange-secondary-first;
            padding: 1px 6px;
        }
    }
    
    .full {
        color: #bdbdbd;
    
        .bullet {
            background-color: #bdbdbd;
        }

        .description {
            border: none;
        }
    }
}