
.product-details {
    display: flex;
    flex-direction: column;
    
    /* ProductDetailsPanelLeft */

    /* ProductDetailsGallery */

    /* ProductDetailsZoom */

    /* ProductDetailsRightPanel */

    /* ProductDetailsBottomPanel */

    .carousel-title {
        color: $red;
        padding-left: 65px;
        font-weight: 400;
    }

    .product-details__right {
        .promo-item {
            &__icon {
                height: 65px;
            }
            &__detail {
                @include font-size(12px);
            }
        }
    }

    .simple-banner {
        padding: 25px 0 0;

        @include breakpoint('tablet-portrait-min') {
            padding: 16px 0;
        }

        img{
            width: 100%;
        }
    }

    .content {
        padding: 25px 16px 0;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 65px;
        }
    }

    &__top {
        padding: 0 65px 30px 65px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 16px;
            flex-direction: column;
        }
    }
    &__left {
        flex: 2;
        max-width: 330px;
        display: flex;
        flex-flow: column;
        margin-top: 10px;
        order: 1;

        @include breakpoint('tablet-portrait-max') {
            order: 2;
            max-width: none;
            border-bottom: 1px solid $border-mobile;
            margin-top: 25px;
        }

        .product-name {
            margin-bottom: 5px;

            @include breakpoint('tablet-portrait-max') {
                margin-bottom: 10px;
            }

            &__title {
                line-height: 1.5;
                @include font-size(28px);

                @include breakpoint('tablet-portrait-max') {
                    line-height: normal;
                    @include font-size(20px);
                }
            }

            &__subtitle {
                line-height: 1.91;
                text-transform: uppercase;
                @include font-size(22px);               
            }
            
            img {
                max-width: 100px;
            }
        }

        .row-mobile { 
            @include breakpoint('tablet-portrait-max') {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
            }

            .weight-price-info {
                margin-bottom: 25px;
                @include font-size(14px);

                @include breakpoint('tablet-portrait-max') {
                    color: $gray;
                    margin-bottom: 20px;
                    @include font-size(13px);
                }
            }

            .label-product-box, .new-product-box {
                margin-bottom: 25px;
                border-radius: 5px;
                background-color: $new-product-box-background-color;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 4px 12px;

                @include breakpoint('tablet-portrait-max') {
                    margin-bottom: 16px;
                }

                &__text {
                    color: $white;
                }
            }
        }

        .documents {
            display: inherit;

            a {
                text-decoration: underline;
                margin-bottom: 10px;
                @include font-size(13px);
            }
        }

        .description-list {
            margin: 0 0 30px 15px;
            list-style: disc;

            @include breakpoint('tablet-portrait-max') {
                margin-bottom: 15px;
            }

            &__item {
                list-style: disc;
                line-height: 1.54;
                letter-spacing: 0.1px;
                @include font-size(13px);

                @include breakpoint('tablet-portrait-max') {
                    letter-spacing: normal;
                    color: $gray;
                }
            }
        }

        .product-image-info {
            display: flex;
            flex-wrap: wrap;
            

            @include breakpoint('tablet-portrait-max') {
                //@include hidden();
                flex-flow: row-reverse;
            }
            
            &__item {
                display: flex;
                align-items: center;
                margin: 0 15px 20px 0;

                @include breakpoint('tablet-portrait-max') {
                    margin: 0 0px 15px 0;
                }
            }

            &__icon {
                height: 35px;
                width: auto;
            }
        }
    }
    &__gallery {
        order: 2;
        flex: 1; 
        padding: 0 20px;
        margin-top: 10px;

        @include breakpoint('tablet-portrait-max') {
            order: 1;
            padding: 0;
            width: 100%;
            margin-top: 25px;
        }

        .categorybadge {
            position: absolute;
            top: 0;
            right: 0;
            img {
                width: 40px;
                height: auto;
            }
        }

        .img-responsive {
            height: auto;
            max-height: 100%;
            max-width: 100%;
            width: auto;
        }

        .c-gallery {
            display: flex;
            flex-flow: wrap;
            max-width: 350px;
            margin: 0 auto;

            @include breakpoint('tablet-portrait-max') {
                max-width: none;
            }

            &__full-img {
                max-width: 300px;
                cursor: zoom-in;
                max-height: 350px;
                width: auto;
                height: auto;

                @include breakpoint('tablet-portrait-max') {
                    max-width: 200px;
                    max-height: 250px;
                }
            }

            .glide {        
                &__slide {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 300px;

                    @include breakpoint('tablet-portrait-max') {
                        min-height: 200px;
                    }     
                }

                &__bullets {
                    display: flex;
                    flex-flow: wrap;
                    justify-content: center;
                    max-width: 325px;
                    margin: 20px auto 0;

                    @include breakpoint('tablet-portrait-max') {
                        max-width: none;
                        justify-content: center;
                        margin-top: 15px;
                    }
                }

                &__bullet {
                    opacity: 0.5;
                    box-shadow: none;
                    
                    &--active {
                        opacity: 1;
                        background-color: $gray-light;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    @include breakpoint('tablet-portrait-min') {
                        width: 62px;
                        height: 30px;
                        margin: 20px 5px;
                        transition: .2s ease-in-out;
                        background-color: $white;
                        border: none;
                    }
           
                    .image-container {
                        display: none;

                        @include breakpoint('tablet-portrait-min') {
                            width: 100%;
                            height: 62px;
                            padding: 5px;
                            background-color: $white;
                            border: 1px solid $gray;
                            border-radius: 3px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
    &__galleryzoom {
        .icon-close {
            position: absolute;
            right: 24px;
            top: 24px;
            z-index: 1;
            padding: 5px;
            
            @include breakpoint('tablet-portrait-max') {
                right: 10px;
                top: 10px;
                padding: 15px;
            }
        }

        .c-gallery {
            display: flex;
            flex-flow: wrap;
            margin: 0 auto;
            top: 50px;

            @include breakpoint('tablet-portrait-max') {
                top: 0px;
                align-items: center;
            }

            &__full-img {
                height: auto;
                max-height: 100%;
                cursor: zoom-in;
                width: auto;
                
                @include breakpoint('tablet-portrait-max') {
                    width: 80%;
                }
            }

            .glide {        
                &__slides {
                    touch-action: auto;
                }
       
                &__slide {
                    display: flex;
                    justify-content: center; 
                    align-items: center;
                    position: relative; 
                    height: 80vh;
                }

                &__arrow {

                    &--left {
                        left: 10px;
                    }
                    &--right {
                        right: 10px;
                    }
                }
            }

            .js-zoom-in {
                position: absolute;
                object-fit: contain;
                transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
                cursor: grab;
                max-height: none;
            }

            .categorybadge {
                display: none;
            }
        }
    }
    &__right {
        $max-width-right-panel: 340px;

        order: 3;
        flex: 2;
        max-width: $max-width-right-panel;
        display: flex;
        flex-flow: column;
        margin-top: 5px;

        @include breakpoint('tablet-portrait-max') {
            max-width: none;
            margin-top: 25px;
        }

        .c-product-price {
            margin-bottom: 5px;

            @include breakpoint('tablet-portrait-max') {
                margin-bottom: 10px;
            }

            &__row {
                margin-bottom: 8px;

                @include breakpoint('tablet-portrait-max') {
                    margin-bottom: 10px;
                }
            }

            &__original {
                color: $red;
                -webkit-text-decoration-line: line-through;
                text-decoration-line: line-through;
                padding-right: 5px;
                @include font-size(22px);

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(18px);
                }
            }

            &__currency {
                color: $black;
                @include font-size(24px);

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(21px);
                }
            }

            &__amount {
                padding-left: 5px;
                color: $black;
                line-height: normal;
                @include font-size(36px);

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(30px);
                }
            }

            &__iva {
                color: $gray;
                padding-left: 5px;
                @include font-size(14px);
            }

            &__weight-price {
                @include font-size(14px);
            }
        }

        .c-discount {
            margin: 4px 0;
            display: flex;
            flex-flow: column;

            @include breakpoint('tablet-portrait-max') {
                justify-content: space-between;
                flex-flow: row;
                align-items: flex-end;
            }
        }

        .custom-select {
            max-width: 150px;
            width: 100%;
        }

        .info-club {
            display: flex;
            align-items: center;
            margin-top: 8px;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }

            .iconclub {
                color: $red;
                height: 25px;
                width: 25px;
            }

            &__description {
                color: $gray;
                padding-left: 10px;
            }

            &__points {
                padding-left: 5px;
            }
        }

        .c-selector-dropdown {
            margin: 30px -5px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include breakpoint('tablet-portrait-max') {
                margin: 20px -5px 5px;
            }

            &__item {
                margin: 0 5px 15px;      
            }

            &__info {
                margin-bottom: 8px;
            }

            &__description {
                @include font-size(14px);        
            }

            &__more-descr {
                @include font-size(13px);
            }
        }
        
        .product-add-to-cart {
            .btn {
                display: flex;
                height: 50px;
                width: 100%;
    
                .iconcart {
                    width: 22px;
                    height: 22px;
                    margin-right: 12px;

                    @include breakpoint('tablet-portrait-max') {
                        margin-right: 15px;
                    }
                }

                .btn-label {
                    @include hidden();
                }
            }

            .btn-blue {
                margin: 16px 0;
                
                svg {
                    color: $white;
                    transform: rotate(45deg);
                    margin-right: 12px;
                }
            }

            .product-qnt__container {
                height: 50px;
                margin-bottom:0px;
            }
        }

        .product-info-references {
            padding: 16px 0;
            line-height: 1.5;
            @include font-size(13px);
        }

        .product-cta {
            margin: 10px 0 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include breakpoint('tablet-portrait-max') {
                margin-top: 20px;
            }

            &__item {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 10px;
                @include font-size(13px);

                &:first-child {
                    margin-right: 8px;
                }

                svg {
                    //color: $gray;
                    width: 20px;
                    height: 20px;
                }
            }

            &__text,
            .empty-note {
                //color: $black;
                padding-left: 10px;
                font-weight: bold;;
            }

            .preview-note {
                font-style: italic;
                font-weight: bold;
                display: flex;
                align-items: center;
                padding-left: 10px;
            }
        
            .ellipsis-note {
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 0.08em;
            }
        }

        .product-pick-up-info {
            margin: 10px 0 15px;
            color: $gray;
            cursor: pointer;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                margin-top: 10px;
            }
        }

        .product-shipping {

            &__item {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
            }

            .off {
                opacity: 0.5;
                color: $gray-light;
            }

            .on {
                opacity: 0.5;
                color: $green;
            }

            &__description {
                color: $gray;
                padding-left: 10px;
                @include font-size(13px);

                &:first-child {
                    padding-left: 15px;
                }
            }
        }
    }

    &__carousel-references {
        display: flex;
        order: 4;
        width: 100%;
        flex-flow: column;
        @include z-index(one);
    }
    &__bottom {
        $gray-tab: #c6c6c6;
        $gray-tab-background: $gray-ultralight;
        $description-tab: #555555;
        margin-top: 90px;
        order: 5;
        width: 100%;
        display: flex;
        flex-flow: column;
        background-color: $gray-tab-background;
        padding: 0 65px;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }

        .product-details-features {
            position: relative;

            &__icon {
                position: absolute;
                left: 5px;
                top: -15px;

                @include breakpoint('tablet-portrait-max') {
                    display: none;
                }
            }

            &__text {
                color: rgba($black, 0.85); 
                margin: 20px 0 20px 65px;
                @include font-size(18px);

                @include breakpoint('tablet-portrait-max') {
                    margin: 35px 0 30px 20px;
                    @include font-size(16px);
                }
            }
        }

        .c-tab {
            display: flex;

            &__links {
                display: flex;
                flex-flow: column;
                width: 255px;
                background-color: $white;
                border-right: 1px solid $gray-tab;
                border-left: 1px solid $gray-tab;
                padding-bottom: 100px;
            }

            &__link {
                background-color: $white;                
                border: none;
                border-bottom: 1px solid $white;
                border-top: 1px solid $gray-tab;
                border-right: 1px solid white;
                cursor: pointer;
                transition: .3s ease-in-out;
                padding-left: 15px;
                height: 60px;
                width: 100%;
                @include font-size(16px);

                &:last-child {
                    border-bottom: 1px solid $gray-tab;
                }

                &:hover {
                    color: $red;          
                }

                &.active {
                    color: $red;
                    border-right: 10px solid $red;
                    border-top: 1px solid $red;
                    border-bottom: 1px solid $red;
                    border-radius: 0 5px 5px 0;
                    width: calc(100% + 10px);

                    @include breakpoint('tablet-portrait-max') {
                        width: 100%;
                        border-radius: 0;
                    }
                }
            }

            &__contents {
                display: flex;
                flex: 1 1 0%;
                padding-bottom: 100px;
            }

            &__c-content {
                display: none;
                padding: 5px 0 0 55px;
                flex: 1 1 0%;

                @include breakpoint('tablet-portrait-max') {
                    padding: 0;
                }
            }

            &__content {
                margin-bottom: 25px;
                width: 50%;
                padding: 0 3%;
                max-width: 620px;
                float: left;

                &:nth-child(2n + 0) {
                    float: right;
                }

                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                }

                &.full-width-accordion {
                    width: 100%;
                    max-width: inherit;

                    .c-tab__description {
                        width: 100%;
                        font-size: 14px;
                        overflow: hidden;
                        max-height: none;
                        transition: max-height .3s ease-out;
                        height: auto;

                        &.toggle-reduce {
                            max-height: 70px;

                            @include breakpoint('tablet-portrait-max') {
                                max-height: 80px;
                            }

                            &.toggle-reduce-bigger {
                                max-height: 235px;
                            }
                        }
                    }

                    .c-tab__cta {
                        padding-top: 8px;
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                table {
                    width: 100%;
                    table-layout: fixed;
                }

                th {
                    color: $black;
                    font-weight: bold;
                }

                tr {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    @include font-size(13px);

                    &:nth-child(odd) {
                        background-color: $white;
                    }
                }

                td,th {
                    padding: 10px;
                    text-align: center;
                    vertical-align: middle;
                }

                td {
                    color: $description-tab;

                    &:first-child {
                        color: $black;
                        font-weight: bold;
                    }
                }
            }

            &__tabs-panel {
                padding: 25px 15px 0;

                @include breakpoint('tablet-portrait-min') {
                    padding: 0;
                }
            }

            &__title {
                color: $red;
                margin-bottom: 25px;
                @include font-size(28px);
            }

            &__subtitle {
                color: $black;
                margin-bottom: 5px;

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(16px);
                }
            }

            &__description {
                color: $description-tab;
                line-height: 1.71;
                width: 70%;
                @include font-size(13px);

                @include breakpoint('tablet-portrait-max') {
                    line-height: 1.91;
                    width: 90%;
                }
            }
        }
        
        .glide {
            &__arrow {
                background-color: inherit;
                box-shadow: none;
                width: auto;
                height: auto;

                &--left {
                    left: -30px;
                }

                &--right {
                    right: -30px;
                }

                .arrow {
                    border: solid $gray-dark;
                    border-width: 0 2px 2px 0;
                    padding:5px;
                }
            }

            &__bullet {
                width: 5px;
                height: 5px;
            }
        }
    }
    
    &__carousel {
        width: 100%;
        display: flex;
        flex-flow: column;
        background-color: $gray-ultralight;
        padding: 0 65px;
        order: 6;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 16px;
        }
    }

    &__further-description {
        order: 7;
    }
        
    #zoomModal.c-gallery {
        max-width: 100%;
        max-height: calc(100vh - 50px);
        width: auto;
        
        @include breakpoint('tablet-portrait-max') {
            max-height: 100vh;
            width: 100%;
        }
    }

    .product-item {
        &[data-stock="inStock"] {
            .product-add-to-cart {
                .btn.btn-green {    
                    .btn-label.inStock {
                        @include visible();
                    }
                }
            }
        }

        &[data-stock="lowStock"] {
            .product-add-to-cart {
                .btn.btn-green {
                    background-color: $white;
                    border-color: $green;
                    color: $green;
        
                    svg {
                        @include hidden();
                    }
        
                    .btn-label.lowstock {
                        @include visible();
                    }
                }
            }
        }

        &[data-stock="outOfStock"] {
            .product-add-to-cart {
                .btn.btn-green {    
                    .btn-label.outOfStock {
                        @include visible();
                    }
                }
            }
        }
    }

    .container-clpregulation {
        display: flex;
        flex-wrap: wrap;
    }

    .item-clpregulation {
        padding: 16px 8px 0;
        text-align:center;
        @include font-size(12px);

        img {
          height: 50px;
          width: 50px;
        }
    }
}
