.workwithus-details {
    padding: 24px 16px;
    overflow: hidden;

    @include breakpoint("tablet-portrait-min") {
        padding: 24px 64px 95px;
    }

    &__main-info {
        padding-bottom: 20px;
    }

    &__title {
        padding-top: 10px;
        @include font-size(24px);

        @include breakpoint("tablet-portrait-min") {
            @include font-size(32px);
        }
    }

    &__job-info {
        display: flex;
        flex-direction: column;
        padding-top: 16px;

        @include breakpoint("tablet-portrait-min") {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__c-select {
        @include breakpoint("phone-min") {
            display: flex;
            justify-content: space-between;
            margin: 0 -12px;
        }

        @include breakpoint("tablet-portrait-min") {
            margin-right: 12px;
            max-width: 700px;
            flex: 1;
        }

        .row {
            @include breakpoint("phone-min") {
                flex: 1;
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        .customselect {
            width: 100%;
            margin-bottom: 24px;

            @include breakpoint("tablet-portrait-min") {
                margin-bottom: 0;
            }

            &__button {
                @include font-size(14px);
            }
        }
    }

    &__other-info {
        display: flex;
        flex-direction: column;

        @include breakpoint("phone-min") {
            flex-direction: row;
            align-items: center;
        }

        @include breakpoint("tablet-portrait-min") {
            width: 485px;
        }

        @include breakpoint("desktop-small-min") {
            width: 530px;
        }
    }

    &__c-job-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 28px;

        @include breakpoint("phone-min") {
            padding: 0;
            flex: 1;
            margin-right: 32px;
        }
    }

    &__job-time {
        @include breakpoint("tablet-portrait-min") {
            margin-right: 12px;
            align-items: center;
            display: flex;
            @include font-size(18px);
        }
    }

    &__job-time-label {
        padding-right: 50px;

        @include breakpoint("tablet-portrait-min") {
            padding-right: 25px;
        }
    }

    &__share {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        @include breakpoint("phone-min") {
            margin: 0;
        }

        span {
            padding-top: 10px;
            @include font-size(13px);

            @include breakpoint("tablet-portrait-min") {
                padding-top: 5px;
            }
        }

        .share {
            display: flex;

            &__cta {
                margin: 5px 10px;
            }
        }

        &.popover label.tooltipBubble {
            left: auto;
            right: -16px;
            top: 135%;

            @include breakpoint("tablet-portrait-min") {
                right: -12px;
            }

            &::after {
                left: auto;
                right: 24px;

                @include breakpoint("tablet-portrait-min") {
                    right: 20px;
                }
            }
        }
    }

    &__cta {
        max-width: 100%;
        width: 345px;
        margin: 0 auto;

        @include breakpoint("phone-min") {
            width: 220px;
            margin: 0 0 0 auto;
        }

        @include breakpoint("tablet-portrait-min") {
            width: 175px;
        }

        @include breakpoint("desktop-small-min") {
            width: 220px;
        }
    }

    &__c-section {
        padding: 12px 0;
        display: flex;
        flex-direction: column;
    }

    &__section-title {
        @include font-size(18px);

        @include breakpoint("tablet-portrait-min") {
            @include font-size(20px);
        }
    }

    &__section-text {
        line-height: 1.5;
        padding-top: 25px;
        overflow: hidden;
        max-height: 3000px;
        transition: max-height 0.3s ease-out;
        height: auto;
        @include font-size(16px);

        ul,
        ol {
            display: block;
            margin: 1em 0;
            padding: 0 0 0 40px;
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: decimal;
        }

        li {
            list-style: inherit;
        }

        &.toggle-reduce {
            max-height: 170px;

            @include breakpoint("tablet-portrait-min") {
                max-height: none;
            }
        }
    }

    &__section-cta {
        padding-top: 15px;
        font-weight: normal;
        color: $gray-dark;
        margin: 0 auto;
    }
}
