/* mulish-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish Light 300';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/mulish-v13-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/mulish-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish Medium 500';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/mulish-v13-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish SemiBold 600';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/mulish-v13-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish Bold 700';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/mulish-v13-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish ExtraBold 800';
    font-style: normal;
    font-weight: 800;
    src: url('../../fonts/mulish-v13-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* mulish-900 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish Black 900';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/mulish-v13-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$default-font-size: 16px;
$lato-font: 'Lato', sans-serif;
$muli-font-300: 'Mulish Light 300', sans-serif;
$muli-font: 'Mulish', sans-serif;
$muli-font-500: 'Mulish Medium 500', sans-serif;
$muli-font-600: 'Mulish SemiBold 600', sans-serif;
$muli-font-700: 'Mulish Bold 700', sans-serif;
$muli-font-800: 'Mulish ExtraBold 800', sans-serif;
$muli-font-900: 'Mulish Black 900', sans-serif;

@function calculateRem($size) {
    $remSize: calc($size / $default-font-size);
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    & {
        font-size: calculateRem($size);
    }
}