    .support-detail{

        .go-back{
            padding: 8px 0 8px 16px;
            background-color: #f2f2f2;

            .arrow{
                vertical-align: middle;
                padding: 2px;
                border-color: $gray;
            }

            &__home{
                font-size: 13px;
                line-height: normal;
                color: $gray;
            }
        }

        .breadcrumbs{
            padding: 31px 0 16px 65px;

            li{
                margin: 0;

                &:last-child{

                    span{
                        font-weight: bold;
                    }
                }
            }
        }

        &__category{
            padding: 16px 0 32px 63px;

            @include breakpoint ('tablet-portrait-max') {
                padding: 16px 0 24px 16px;
            }

            .category-label{
                line-height: 1.5;
                font-size: 18px;
                margin-bottom: 10px;
            }

            .category-name{
                line-height: normal;
                font-size: 20px;
                font-weight: bold;
            }
        }

        &__faq{
            margin-bottom: 32px;
            background-color: $gray-ultralight;

            .faq-label{
                border-bottom: 1px solid #e0e0e0;
                padding: 16px 0 10px 63px;
                line-height: 1.5;
                font-size: 18px;

                @include breakpoint ('tablet-portrait-max') {
                    padding: 16px 0 10px 16px;
                }
            }
        }
    }