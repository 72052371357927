.workwithus-landing {
    padding: 30px 0 40px;

    //CMSParagraphComponent

    @include breakpoint("tablet-portrait-min") {
        padding: 12px 0 60px;
    }
    .content {
        line-height: 1.5;

        @include breakpoint("tablet-portrait-max") {
            @include font-size(16px);
        }
    }

    .component-wrapper {
        padding: 12px 16px;
        
        @include breakpoint("tablet-portrait-min") {
            padding-left: 64px;
            padding-right: 64px;
        }
    }

    .other-component-wrapper {
        padding: 12px 0;

        @include breakpoint("tablet-portrait-min") {
            padding-left: 64px;
            padding-right: 64px;
        }
    }


    &__title {
        padding: 0 16px 5px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0 64px;
            @include font-size(24px);
        }
    }

    .linkedin-component {
        display: flex;
        max-width: 450px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        padding: 36px 16px;
        flex-direction: column;

        @include breakpoint('phone-min') {
            flex-direction: row;
            max-width: 755px;
        }
        

        @include breakpoint('tablet-portrait-min') {
            padding: 48px 0;
        }

        .iconlinkedin-banner {
            width: 100%;
            height: 75px;

            @include breakpoint('phone-min') {
                width: 415px;
            }
        }

        &__cta {
            margin-top: 16px;
            width: 100%;
           
            @include breakpoint('phone-min') {
                margin: 0;
                width: 218px;
            }
        }
    }

    .spontaneous-application {
        padding: 6px 16px 16px;
    
        @include breakpoint('tablet-portrait-min') {
            padding: 16px 64px;
            display: flex;
            flex-flow: wrap;
        }
    
        &__c-description {
            padding-bottom: 25px;

            @include breakpoint('tablet-portrait-min') {
                width: 58%;
                padding: 12px 2% 0 0;
                display: flex;
                flex-direction: column;
            }
        }
    
        &__description {
    
            @include breakpoint('tablet-portrait-min') {
                padding-right: 3%;
            }
        }
    
        &__wrapper {
            @include breakpoint('tablet-portrait-min') {
                width: 42%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    
        &__title {
            padding-bottom: 16px;
            @include font-size(18px);
    
            @include breakpoint('tablet-portrait-min') {
                padding-bottom: 25px;
                @include font-size(24px);
            }
        }
    
        &__text {
            line-height: normal;
            @include font-size(14px);
    
            @include breakpoint('tablet-portrait-min') {
                @include font-size(16px);
            }
        }
    
        &__cta {
            width: 100%;
            margin-top: 30px;
    
            @include breakpoint('tablet-portrait-min') {
                max-width: 218px;
                margin: 16px 0 0 auto;
            }   
        }
    }

    .frequently-asked-questions {
        margin-top: 0;
        padding: 0 16px;

        .title {
            display: none;
        }

        @include breakpoint('tablet-portrait-min') {
            padding: 0 64px;
        }
    }
}