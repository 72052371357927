.products-carousel {
    width: 100%;

    @include breakpoint('tablet-portrait-min') {
       clear: both;
    }

    &__c-cta {
        display: flex;
        padding: 0 16px;
    }

    &__cta {
        margin: 0 auto;
        min-width: 220px;

        a {
            display: flex;
            width: 100%;
            justify-content: center;
            height: 100%;
            align-items: center;
            padding: 0 16px;
        }
        
        @include breakpoint('tablet-portrait-max') {
            width: 345px;
        }
    }
}

.carousel-title {
    padding: 24px 0;
    font-weight: bold;
    @include font-size(18px);

    @include breakpoint('tablet-portrait-max') {
        padding: 20px 25px;
    }
}

.products-carousel-container {
    display: flex;
    width: 100%;
    padding: 20px 100px;

    @include breakpoint('tablet-portrait-max') {
        padding: 0px;
    }

    .glide__arrows {
        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
    }

    .glide__track {
        padding-bottom: 50px;
        margin-bottom: -20px;
    }

    .glide__arrow {
        top: 36%;
    }

    .glide--disabled {
        .glide__slides {
            margin: 0 auto;
            transform: none!important;
        }
    }
}

.banners-carousel-container {
    .glide {
        &__arrow--left {
            left: 32px;

            @include breakpoint('tablet-portrait-max') {
                left: 16px;
            }
        }

        &__arrow--right {
            right: 32px;

            @include breakpoint('tablet-portrait-max') {
                right: 16px;
            }
        }
    }
}

.js-carousel {
    width: 100%;
}

.glide {
    position: relative;
    width: 100%;

    &--disabled {
        .glide__arrow,
        .glide__dot {
            display: none;
        }
    }

    &__track {
        overflow: hidden;
    }

    &__slides {
        position: relative;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        touch-action: pan-Y;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        will-change: transform;
    }

    &__slide {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        white-space: normal;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;

        .hit {
            width: 100%;
        }
    }

    &__arrow {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        width: 50px;
        height: 80px;
        border-radius: 10px;
        box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.05);
        background-color: $white;
        cursor: pointer;
        transition: opacity .15s ease,border .3s ease-in-out;
        transform: translateY(-50%);
        border: 0;
        @include z-index(two);

        .arrow {
            border: solid $gray-light;
            border-width: 0 3px 3px 0;
            padding: 7px;

            &__left {
                margin-left: 5px;
            }

            &__right {
                margin-right: 5px;
            }
        }

        &--circle {
            border-radius: 100%;
            width: 38px;
            height: 38px;
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.15);
            background-color: $white;

            .arrow {
                border: solid $gray-dark;
                border-width: 0 2px 2px 0;
                padding: 5px;
            }
        }
    }

    &__arrow--left {
        left: -90px;
    }

    &__arrow--right {
        right: -90px;
    }

    &__bullets {
        text-align: center;
        width: 100%;
        margin-top: 8px;
        min-height: 30px;
    }

    &__bullet {
        $self: &;
        background-color: $white;
        width: 9px;
        height: 9px;
        padding: 0;
        border-radius: 50%;
        border: 1px solid $gray-dark;
        transition: all .3s ease-in-out;
        cursor: pointer;
        line-height: normal;
        margin: 0 .25em;

        &--mini {
            background-color: $white;
            width: 6px;
            height: 6px;
        }

        &--mini#{ $self }--active,
        &--mini#{ $self }:hover {
            background-color: $gray-dark;
        }

        &--active {
            background-color: $gray-dark;
        }
    }

    .hit {
        &__content {
            border-radius: 10px;
        }
    }
}
