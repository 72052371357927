.guide-redemption {

    &__header {
        margin: 16px 16px 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 16px 65px 0;
        } 
    }

    &__title {
        margin-bottom: 16px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            margin-bottom: 8px;
        } 
    }

    &__items {
        padding: 12px 16px;
        display: flex;
        flex-flow: column;

        @include breakpoint('tablet-portrait-min') {
            padding: 24px 65px;
            flex-flow: row;
            justify-content: center;
        } 
    }
    

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;

        @include breakpoint('tablet-portrait-min') {
            flex-flow: column;
            margin: 0 3%;
            width: calc(25% - 6%);
        }

        img { 
            width: 40%;
            
            @include breakpoint('tablet-portrait-min') {
                max-width: 200px;
                width: 100%;
            } 
        }

        &--info {
            flex-basis: 53%;

            @include breakpoint('tablet-portrait-min') {
                text-align: center;
                padding-top: 16px;
            } 

            >div {
                &:last-child {
                    margin-top: 8px;
                }
            }
        }
    }
}