.home-page {
    img {
        width: 100%;
        height: auto;
    }

    .glide {
        &__bullets {
            width: 100%;
            text-align: center;
        }

        &__bullet {
            background-color: $white;
            width: 6px;
            height: 6px;

            &--active,
            &:hover {
                background-color: $gray-dark;
            }
        }
    }

    &__hero-slider {
        padding-top: 20px;
        padding-bottom: 20px;

        .glide {    
            &__bullets {
                position: absolute;
                bottom: 24px;
            }
        }
    }

    &__products-carousel {
        @include breakpoint('tablet-portrait-max') {
            padding: 20px 0;
        }
    }

    .carousel-title {
        background-color: $white;
        padding: 24px 65px;
    }
    
    &__banners {
        padding: 70px 100px 20px;
 
        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }

        .content {
            padding: 16px 0;

            @include breakpoint('tablet-portrait-max') {
                padding: 25px 0 0;
            }
        }

        .full-banner {

            .content {
                @include breakpoint('tablet-portrait-max') {
                    padding: 25px 16px 0;
                }
            }
            .simple-banner {
                padding: 16px 0;

                @include breakpoint('tablet-portrait-max') {
                    padding: 25px 0 0;
                }
            }
        }

        .single-banner {
            display: flex;
            flex-wrap: wrap;
            padding: 0px 25px;

            @include breakpoint('tablet-portrait-max') {
               margin: 0;
               padding: 0 16px;
               flex-direction: column;
            }
    
            &__wrapper {
                flex: 0 0 33.333333%;
                padding: 16px 25px;

                @include breakpoint('tablet-portrait-max') {
                    padding: 25px 0 0;
                    width: 100%
                }
            }
        }

        .single-banner-paragraph__wrapper {
            width: 100%;
            margin: 0 25px;

            @include breakpoint('tablet-portrait-max') {
                margin: 0;
            }
        }
    }

    &__products-carousel.editorial-page {
        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }   
    }

    &__banners.editorial-page {
        padding: 0 100px;
        
        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }
    }
}