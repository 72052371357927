.store {
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,.12);

    .iconbennet-text, .icondrive-text, .iconhome-text {
        width: 55px;
        height: 19px;

        @include breakpoint('tablet-portrait-min') {
            width: 70px;
            height: 22px;
        }
    }

    &__content {
        margin: 16px 16px 0;
    }

    &__wrapper {
        cursor: pointer;
    }

    &__name {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 16px 0 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }

        .distance {
            text-transform: lowercase;
        }
        
        > h2 {
            font: inherit;
        }
    }

    &__description {
        padding: 8px 0 0;
        line-height: 1.5;
        min-height: 68px;
        display: flex;
        flex-flow: column;
        @include font-size(14px);

        .mallName, .phone {
            @include breakpoint('tablet-portrait-max'){
                padding-top: 6px;
            }
        }
    }

    &__hours {
        padding-top: 16px;
        display: flex;
        align-items: center;
        flex-flow: row;
        line-height: 1.5;
        @include font-size(13px);

        .oval {
            width: 18px;
            height: 18px;
            margin: 0 8px 0 0;
            border-radius: 100%;

            &--green {
                background-color: $green;
            }

            &--gray {
                background-color: $gray-light;
            }
        }
        .opening-hours{
            flex-basis: calc(100% - 18px);
        }
    }

    &__cta {
        display: flex;
        justify-content: space-between;
        padding: 24px 70px 16px 0px;

        @include breakpoint('tablet-portrait-max') {
            padding: 24px 0 16px;
            max-width: 331px;
        }

        .btn {
            padding: 0 12px;
            @include font-size(13px);
        }
    }

    &__flag {
        background-color: $white;
        display: flex;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        flex-flow: wrap;
        line-height: 1.5;
        justify-content: space-between;

        > div {
            padding: 16px;
        }
    }

    &__associated {
        background-color: $gray-light;
        color: $white;

        &--drive {
            width: 100%;
        }

        .iconbennet-text, .icondrive-text, .iconhome-text {
            color: $white;
        }

        > div {
            &:first-child {
                padding-bottom: 0;
            }

            &:last-child {
                padding-top: 0;
            }
        }
    }

    &__services {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        flex-flow: wrap;

        img {
            width: 150px;
        }
    }

    &__not-found {
        color: $red;
        padding: 16px;
        @include font-size(20px);
    }
}