.company {
    &__hero {
        @include breakpoint("tablet-portrait-max") {
            padding: 24px 0 16px;
        }
    }

    &__container {
        padding: 0 16px 24px;

        @include breakpoint("tablet-portrait-min") {
            padding: 0 64px 60px;
        }
    }

    .mirror-explanation {
        &__c-description {
            @include breakpoint("tablet-portrait-min") {
                width: 70%;
            }
        }

        &__wrapper {
            @include breakpoint("tablet-portrait-min") {
                width: 30%;
            }
        }

        &:nth-child(even) {
            .mirror-explanation__c-description {
                @include breakpoint("tablet-portrait-min") {
                    padding-right: 0;
                }
            }
        }
    }
}

.company-landing {
    .initiative-section {
        margin: 0 0 16px;

        @include breakpoint("tablet-portrait-min") {
            margin: 0 60px 10px;
        }
    }

    .products-carousel {

        &__c-cta {
            padding-left: 0;
            padding-right: 0;
        }
    
        .carousel-title {
            @include breakpoint("tablet-portrait-max") {
                padding-left: 0;
            }
        }

        &-container {
            @include breakpoint("tablet-portrait-min") {
                padding: 24px 90px 12px;
            }
        }
    }

    .mirror-explanation--contact {
        .mirror-explanation__c-description {
            padding-top: 16px;

            @include breakpoint("tablet-portrait-min") {
                padding-right: 0;
                padding-left: 3%;
            }
        }
    }
}

.company-about {
    overflow: hidden;

    .mirror-explanations {
        padding-bottom: 4px;

        @include breakpoint("tablet-portrait-min") {
            padding-bottom: 16px;
        }
    }

    .single-banner {
        padding: 12px 0;

        @include breakpoint("tablet-portrait-min") {
            padding: 16px 0;
        }
    }
}

.contact-company {
    overflow: hidden;

    @include breakpoint("tablet-portrait-min") {
        width: 780px;
    }

    .header-mobile-panel__title {
        @include breakpoint("tablet-portrait-max") {
            padding-left: 0;
        }
    }

    &__content {
        padding: 0;
    }

    &__c-form {
        padding: 40px 60px;
        overflow-y: auto;
        max-height: 70vh;

        @include breakpoint("tablet-portrait-max") {
            max-height: none;
            // 100vh - footer - header
            height: calc(100vh - 122px);
            height: calc(var(--vh, 1vh) * 100 - 122px);
            padding: 20px 24px;
        }
    }

    &__form {
        max-width: none;
    }

    .row {
        display: flex;
        flex-flow: wrap;
        position: relative;
    }

    .form-group {
        width: 100%;

        @include breakpoint("tablet-portrait-min") {
            margin-left: 8px;
            margin-right: 8px;
        }

        .customselect {

            &__text {
                @include font-size(14px);
            }

            &__list {
                max-height: 160px;
            }
        }

        select {
            left: 50%;
            bottom: 0;
        }

        &--50 {
            width: 100%;

            @include breakpoint("tablet-portrait-min") {
                width: calc(50% - 16px);
            }
        }

        &--checkbox .error-message {
            bottom: -50px;
            left: 52px;

            @include breakpoint("tablet-portrait-min") {
                left: 42px;
            }
        }

        input[type=checkbox] {
            display: block;
            left: 25px;
            bottom: 10px;
        }
    }

    input[type="file"] {
        
        @include breakpoint("tablet-portrait-min") {
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
        }
    }

    input[type="file"] + .btn {
        position: absolute;
        right: 0;
        top: 7px;
        @include font-size(11px);

        @include breakpoint("phone-small-max") {
            top: 32px;
        }
    }

    .btn-plus {
        margin-left: 8px;
        padding: 8px;
        margin-bottom: 40px;
    }
}
