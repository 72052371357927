.info-time-slots {

    &__content {
        padding: 25px;

        @include breakpoint('tablet-portrait-max') {
            padding: 16px;
        }
    }

    &__description {
        display: flex;
        align-items: flex-end;
        padding: 0 6px;
        margin-bottom: 20px;
        justify-content: space-between;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            margin-bottom: 30px;
        }

        .btn {
            width: 210px;
        }
    }

    &__pos {
        padding-right: 40px;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 0 15px;
        }

        p {
            line-height: 1.5;
            @include font-size(14px);
        }
    }

    &__calendar {
        border-radius: 4px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    }

    &__footer {
        padding: 20px 40px;

        @include breakpoint('tablet-portrait-max') {
            padding: 20px 16px;
        }

        .empty {
            color: $green;

            .bullet {
                background-color: $green;
            }
        }

        .almost-full {
            color: $orange-secondary-first;;

            .bullet {
                background-color: $orange-secondary-first;;
            }
        }

        .full {
            color: $gray-light;

            .bullet {
                background-color: $gray-light;
            }
        }
    }

    .calendar {
        &__day {
            padding: 15px 0;
            margin: 0 15px;
            color: $green;
            border-bottom: 2px solid $gray-ultralight;
        }

        &__container {
            padding: 20px 15px;
            
        }

        &__c-timeslot {
            padding: 15px 0;
            margin: 0 -8px;
            display: flex;
            flex-flow: wrap;
        }

        &__timeslot {
            margin: 0 8px 10px;
            color: $green;
            border: 1px solid $green;
            padding: 2px 2px 0 2px;
            @include font-size(14px);

            &--disabled {
                color: $gray;
                border: none;
                padding: 3px 3px 0 3px;
            }

            &--almost-full {
                border: none;
                border-bottom: 1px dotted $orange-secondary-first;
                color: $orange-secondary-first;
                padding: 3px 3px 0 3px;
            }
        }
    }
    
    .glide__arrows {
        position: absolute;
        right: 20px;
        top: 12px;
        width: 55px;
        display: flex;
        justify-content: space-between;
        @include z-index('one');
    }

    .glide__arrow {
        position: initial;
        display: inline-block;
        box-shadow: none;
        transform: none;
        width: auto;
        height: auto;
        padding: 4px;

        &--disabled {
            color: $gray-light;
        }

        .arrow {
            border: solid #333;
            border-width: 0 2px 2px 0;
            padding: 4px;
        }
    }
}

#unavblTimeSlots {
    .info-time-slots__description {
        margin: 15px 0 0;
    }

    .calendar__c-timeslot {
        padding: 0;
    }
}