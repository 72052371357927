.article {
    $self: &;

    /**** articleDescriptionCarousel ****/

    .breadcrumbs {
        @include breakpoint('tablet-portrait-min') {
            padding-top: 30px;
            padding-bottom: 0;
        }
    }

    &__boxed {
        @include breakpoint('tablet-portrait-min') {
            padding: 15px 65px 0; //stesso padding desktop
        }
    }

    &__main-info {
        display: flex;
        flex-flow: wrap;
        padding-top: 30px;
        border-bottom: 1px solid $gray-light;

        @include breakpoint('tablet-portrait-min') {
            border: none;
        }

        &--themed {
            @include breakpoint('tablet-portrait-min') {

                #{ $self }__description {
                    padding: 40px 65px;
                    display: flex;
                    width: 100%;
                    flex-flow: wrap;
                }

                #{ $self }__title {
                    font-style: italic;
                    padding: 12px 0 0;
                    order: 3;
                    width: 100%;
                    @include font-size(24px);  
                }

                #{ $self }__categories {
                    padding: 0px;
                    order: 1;
                    padding-right: 30px;
                    @include font-size(20px);
                }

                #{ $self }__c-image-cta {
                    width: 100%;
                    position: relative;
                }

                #{ $self }__ctas {
                    position: absolute;
                    right: 65px;
                    bottom: -120px;
                }

                #{ $self }__details {
                    padding: 0;
                    order: 1;
                }

                #{ $self }__ingredients {
                    order: 4;
                }
            }
        }
    }

    &__description {
        padding: 0 24px;
        width: 100%;

        @include breakpoint('tablet-portrait-min') {
            width: 48%;
            order: 2;
            padding: 0 30px;
        }
    }

    &__title {
        line-height: 1.5;
        padding: 5px 0;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            padding: 10px 0 5px;
            @include font-size(28px);
        }
    }

    &__categories {
        @include font-size(14px);

        @include breakpoint('tablet-portrait-min') {
            line-height: 1.5;
            @include font-size(22px);
        }
    }

    &__details {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 15px;

        @include breakpoint('tablet-portrait-min') {
            max-width: 330px;
        }
    }

    &__detail {
        display: flex;
        align-items: center;
        color: $gray;
        @include font-size(13px);

        span {
            padding-left: 5px;
        }
    }

    &__c-image-cta {
        width: 100%;

        @include breakpoint('tablet-portrait-min') {
            width: 52%;
            order: 1;
        }
    }

    &__ctas {
        display: flex;
        padding: 20px 60px;
        justify-content: space-between;
        align-items: center;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 5px;
            max-width: 280px;
            justify-content: initial;
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        font: inherit;
        font-weight: bold;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 10px;
            flex: unset;
        }

        span {
            padding-top: 10px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-min') {
                padding-top: 5px;
            }
        }

        .share {
            display: flex;

            &__cta {
                margin: 5px 10px;
            }
        }
    }

    &__tab { 
        padding: 20px;

        @include breakpoint('tablet-portrait-min') {
            border: none;
        }
    }

    &__intro-paragraph {
        @include breakpoint('tablet-portrait-max') {
            padding: 24px 16px;
        }
    }
    .article-description {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
    
        &__ctn {
            order: 2;

            .glide {

                &__slide {
                    padding: 0 16px;

                    @include breakpoint('tablet-portrait-min') {
                        padding: 0;
                    }
                }

                &__arrows {
                    display: flex;
                    padding: 0 16px;

                    @include breakpoint('tablet-portrait-min') {
                        padding: 0;
                    }
                }

                &__c-arrow {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &--left {
                        margin-right: auto;
                    }

                    &--right {
                        margin-left: auto;
                    }
                }

                &__arrow {

                    &--circle {
                        position: initial;
                        transform: none;
                    }
                }

                &__label-arrow {
                    color: $gray;
                    padding: 0 10px;
                    @include font-size(14px);

                    @include breakpoint('tablet-portrait-min') {
                        @include font-size(16px);
                    }
                }
            }
        }

        &__title {
            order: 1;

            @include breakpoint('tablet-portrait-min') {
                font-weight: bold;
                padding: 20px 0 0;
                @include font-size(24px);
            }
        }

        .content {
            padding: 16px;

            @include breakpoint('tablet-portrait-min') {
                padding: 16px 0;
            }
        }

        .bennet-banner {
            @include breakpoint('tablet-portrait-min') {
                padding: 0;
            }        
        }
    }


    &__ingredients {

        @include breakpoint('tablet-portrait-min') {
            padding-top: 20px;
        }
    }
}

/**** recipeIngredients ****/
.recipe-ingredients {
    display: flex;
    flex-direction: column;

    .content {
       @include breakpoint('tablet-portrait-max') {
            padding: 16px 16px 0;
        } 
    }

    &__title {
        order: 1;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 0 20px;
            @include font-size(24px);

        }
    }

    &__c-person {
        display: flex;
        align-items: center;
        padding: 25px 30px 30px;
        order: 2;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 0 40px 8px;
        }

        .product-qnt__container {
                max-width: 176px;
                margin-left: 25px;
        }
    }

    &__num-person-label {
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(16px);
        }
    }

    &__list {
        order: 3;
        padding: 0px 0 20px;
        display: flex;
        flex-flow: column;

        @include breakpoint('tablet-portrait-min') {
            padding: 0;
        }
    }
}

/**** Ingredient ****/
.ingredient {
    display: flex;
    padding: 0 30px 10px;

    @include breakpoint('tablet-portrait-min') {
        padding: 0 0 8px 10px;
    }

    b {
        max-width: 120px;
    }

    b,span {
        flex: 1;
    }
}

.author {
    padding: 16px 0;

    @include breakpoint('tablet-portrait-max') {
        .favourite-article {
            width: 34px;
            height: 34px;
            right: -8px;
            top: -5px;

            svg {
                height: 18px;
            }
        }
    }

    @include breakpoint('tablet-portrait-min') {
        padding: 30px 0;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        height: 125px;
        padding: 0 30px 0 40px;
    
        @include breakpoint('tablet-portrait-min') {
            align-items: initial;
            justify-content: center;
            padding: 0px;
            margin: 0 auto;
            width: 74%;
            overflow: hidden;
        }
    }

    &__background {
        @include breakpoint('tablet-portrait-min') {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            z-index: -1;
            left: 0;
            bottom: 0;
        }
    }

    &__c-img {
        position: relative;

        @include breakpoint('tablet-portrait-min') {
            order: 2;
            flex-basis: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }

        img {
            width: 90px;
            height: 90px;
            border-radius: 100%;

            @include breakpoint('tablet-portrait-min'){
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0;
            }
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        margin-left: 25px;

        @include breakpoint('tablet-portrait-min'){
            flex-basis: 50%;
            padding: 24px 0px 29px 40px;
            justify-content: center;
            margin: 0;
            position: relative;
            overflow: hidden;
        }
    }

    &__name {
        padding-bottom: 8px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min'){
            line-height: normal;
            font-weight: bold;
            @include font-size(18px);
        }
    }

    &__job {
        padding-bottom: 16px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min'){
            line-height: normal;
            padding-bottom: 8px;
            @include font-size(14px);
        }
    }

    &__description {
        @include breakpoint('tablet-portrait-min') {
            line-height: 1.6;
            padding-bottom: 16px;
            display: none;
        }
    }

    &__cta {
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
           line-height: normal;
           @include font-size(13px);
        }

        &:hover {
            .iconheart-favourite {
                color: $gray-dark;
            }
        }
    }
}