.suggested-articles,
.authors {
    padding: 16px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 20px 0;
        clear: both;
        width: 100%;
    }

    &__ctn {
        background-color: $gray-ultralight; //Grafica $gray-ultralight
        padding-bottom: 25px;
    }
    
    &__c-title {
        padding-bottom: 8px;
    }

    &__title {
        padding: 20px 16px 12px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding-left: 65px;
        }
    }

    &__c-featured {
        padding: 20px 16px 0;
    }

    &__c-carousel {
        padding-top: 16px;

        @include breakpoint('tablet-portrait-min') {
            padding: 15px 95px 0;
        }
    }

    .glide {
        &__slide {
            display: flex;
            height: auto;
        }

        &__arrow {
            &--left {
                left: -30px;
            }

            &--right {
                right: -30px;
            }
        }
    }
}