.popup-news {
    padding: 15px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 25px 0;
    }

    &.popup {
        width: 725px;

        @include breakpoint('tablet-portrait-max') {
            //top: 30%;
            top:100px;
            width: 90%;
            margin: 0 5%;
            border-radius: 4px;
            height: auto;
            //max-height: calc(100vh - 33%);
            max-height: calc(var(--vh, 1vh)*100 - 105px);
        }
    }

    .icon-close {
        position: absolute;
        right: 25px;

        @include breakpoint('tablet-portrait-max') {
            background-color: rgba(45,45,46,.8);
            color: $white;
            width: 24px;
            height: 24px;
            right: 15px;

            .iconclose {
                width: 10px;
                height: 10px;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 18px 0 25px;

        @include breakpoint('tablet-portrait-min') {
            flex-direction: row;
            padding-top: 0;
        }

        svg {
            width: 30px;
            height: 30px;

            @include breakpoint('tablet-portrait-min') {
                height: 34px;
                width: 34px;
            }
        }
    }

    &__title {
        padding-top: 16px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0 0 0 16px;
            @include font-size(20px);
        }
    }

    &__content {
        margin: 0 10px 0 15px;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 25px 0 30px;
            overflow-y: auto;
            max-height: 46vh;
        }
    }
}

.news {
    padding-bottom: 35px;

    @include breakpoint('tablet-portrait-min') {
        padding-bottom: 20px;
    }

    &:last-child {
        padding: 0;
    }

    &__title {
        line-height: normal;
        @include font-size(16px);
    }

    &__text {
        line-height: normal;
        padding-top: 8px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
            line-height: 1.9;
            padding-top: 6px;
            @include font-size(14px);
        }
    }
}