.form-step {
    $self: &;
    border-radius: 4px;
    box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    margin-bottom: 24px;

    &__header {
        margin: 0 0 0 -30px;
        border-radius: 50px 0 0 50px;
        overflow: hidden;
        display: flex;
        box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.12);

        @include breakpoint('tablet-portrait-min') {
            margin: 0 0 0 -50px;
        }
    }

    &__c-img {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        padding: 5px;
        background-color: $white;
        width: 60px;

        @include breakpoint('tablet-portrait-min') {
            width: auto;
        }
    }

    &__c-info {
        flex: 1;
        padding-left: 5px;

        @include breakpoint('tablet-portrait-min') {
            padding-left: 16px;
        }
    }

    &__main-info {
        display: flex;
    }

    &__img-label {
        position: absolute;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(24px);
        }
    }

    &__title {
        font-weight: bold;
        padding-top: 20px;
        @include font-size(20px);

        @include breakpoint('tablet-portrait-min') {
            padding-top: 24px;
            @include font-size(24px);
        }
    }

    &__edit {
        cursor: pointer;
        margin: 24px 15px 0 auto;
        @include hidden();

        @include breakpoint('tablet-portrait-min') {
            margin-right: 24px;
        }
    }

    &__container {
        background-color: $white;
        border-radius: 0 0 4px 4px;
        padding: 5px 0 32px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 0 24px;
        }
    }

    &__subsection-title {
        padding: 12px 0 16px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(18px);
        }
    }

    &__subsection-subtitle {
        padding-bottom: 20px;
        @include font-size(14px);
    }

    &__add-section {
        width: 285px;
        margin: 0 auto 40px;

        @include breakpoint('tablet-portrait-min') {
            width: 210px;
            margin: 0;
            @include font-size(13px);
        }
    }

    &__c-form {
        display: flex;
        flex-direction: column;
    }

    &__form {
        max-width: none;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 310px;
        padding: 0 16px 0 32px;

        //StepTwo
         //StepTwo & StepThree

        @include breakpoint('tablet-portrait-min') {
            padding: 0 32px 0 60px;
            margin-top: -16px;
        }

        input[type=checkbox]+label, input[type=radio]+label {
            padding-left: 28px;
        }

        select {
            left: 50%;
            bottom: 0;
        }

        .row {
            display: flex;
            flex-flow: column;

            //StepTwo & StepThree

            @include breakpoint('tablet-portrait-min') {
                margin: 0 -8px;
                flex-flow: row;
                align-items: center;
            }
            &--dynamic {
                position: relative;
                padding-right: 40px;
            }
        }

        .form-group {
            width: 100%;
            margin-bottom: 25px;

            //StepTwo & StepThree

            @include breakpoint('tablet-portrait-min') {
                margin: 0 8px 30px;
                flex: 1;
                width: auto;
            }

            &--radio {
                .top-label {
                    padding-bottom: 10px;
                    @include font-size(14px);
                }

                label:first-of-type {
                    margin-right: 20px;
                }
            }
            &--dynamic-select {
                @include breakpoint('tablet-portrait-min') {
                    min-width: 240px;
                    max-width: 240px;
                }
            }

            &--sm {
                @include breakpoint('tablet-portrait-min') {
                    max-width: 115px;
                }
            }
        }
        .field-qualification-ctn {
            display: flex;
            flex: 1;
            flex-direction: column;

            @include breakpoint('tablet-portrait-min') {
                flex-direction: row;
            }
        }
        .dynamic-delete {
            position: absolute;
            right: 0;
            top: 15px;

            @include breakpoint('tablet-portrait-min') {
                top: 15%;
            }
        }

        .customselect__list-item {
            &--disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    &__summary {
        line-height: normal;
    }

    &__go-next-step {
        margin: 5px auto 0;
        max-width: 285px;
        width: 100%;


        @include breakpoint('tablet-portrait-min') {
            width: 220px;
            margin: 5px 0 0 auto;
        }
    }

    &__desktop-summary,
    &__mobile-summary {
        line-height: normal;
        @include hidden();

        @include breakpoint('tablet-portrait-min') {
            padding: 10px 0;
            @include font-size(13px);
        }
    }

    &--disabled {
        #{ $self } {  
            
            &__header {
                
                align-items: center;

                @include breakpoint('tablet-portrait-min') {
                    margin-left: -40px;
                }
            }

            &__c-img {
                width: 58px;
                height: 58px;
                padding: 2px;
            }

            &__img {
                filter: grayscale(1);
                opacity: 0.5;
            }

            &__img-label {
                color: $gray;
                @include font-size(16px);
            }
            
            &__title {
                color: $gray;
                padding: 0 0 0 16px;
                font-weight: normal;
                @include font-size(16px);

                @include breakpoint('tablet-portrait-min') {
                    padding: 0 0 0 40px;
                    @include font-size(20px);
                }
            }

            &__edit,
            &__container,
            &__mobile-summary,
            &__dekstop-summary {
                @include hidden();
            }

            &__edit .iconpencil {
                &:hover {
                    color: $green;
                }
                @include breakpoint('tablet-portrait-max') {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    &--closed {
        #{ $self } {

            &__img-label {
                color: $red;
            }

            &__container {
                padding: 0 34px 20px;

                @include breakpoint('tablet-portrait-min') {
                    @include hidden();
                }
            }

            &__form {
                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                } 
            }

            &__edit {
                @include visible();
            }

            &__desktop-summary {
                @include breakpoint('tablet-portrait-min') {
                    @include visible();
                }
            }

            &__mobile-summary {
                @include breakpoint('tablet-portrait-max') {
                    @include visible();
                }
            }
        }
    }
}