.account-receipts {

    @include breakpoint('tablet-portrait-max') {
        overflow: hidden;
    }
    h2 {
        font-size: inherit;
    }
    &__ctn {
        display: flex;
        overflow: hidden;
        flex-flow: wrap;
        margin: 0 -16px;
    }

    &__item, &__search {
        display: flex;
        flex-flow: column;
        padding: 24px;
        background-color: $white;
        margin: 16px 0;
        line-height: 1.7;
        max-width: 100%;
        box-shadow: 0 2px 36px 0 rgb(0 0 0 / 12%);

        @include breakpoint('tablet-portrait-min') {
            border-radius: 8px;
        }

        &--inputs {
            display: flex;
            flex-flow: column;

            @include breakpoint('tablet-portrait-min') {
                flex-flow: row;
                justify-content: space-between;
            }
        }

        .form-group {
            width: 100%;

            @include breakpoint('tablet-portrait-min') {
                width: 49%;
            }
        }

        &--info {
            display: flex;
            justify-content: space-around;
            padding-top: 16px;

            >div {
                flex-basis: 50%;
            }
        }

        &--cta {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 26px;
        }

        &--icon-cta {
            margin-right: 25px;

            &:active,
            &:focus {
                color: gray;
            }   

            svg {
                width: 22px;
            }
        }


        &--show-cta {
            flex-basis: 50%;

            &:active,
            &:focus {
                color: $gray-dark;
                box-shadow: none;
                border-color: $gray-dark;
            }   
        }
    }

    &__item {
        width: 100%;
        margin: 16px;

        @include breakpoint('phone-min') {
            width: calc(50% - 32px);
        }

        @include breakpoint('tablet-portrait-min') {
            width: 100%;
        }

        @include breakpoint('desktop-small-min') {
            width: calc(50% - 32px);
        }
    }

    &__no-result {
        padding: 16px 40px;
        @include font-size(20px);

        @include breakpoint('tablet-portrait-min') {
            padding: 16px;
        }
    }

    .frequently-asked-questions {
        //box-shadow: 0 2px 36px 0 rgb(0 0 0 / 12%); da controllare, rimane box bianco se le faq non ci sono
    }
}