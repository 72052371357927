/*! critical:start */
*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

html, main, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
aside, details, figcaption, figure, main,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1.5;
    font-style: normal;
    letter-spacing: normal;
}

ol, ul, li {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    color: $gray-dark;
    text-decoration: none;

    &:hover {
        color: $green;
    }
}

hr {
    margin: 0;
    border: 0;
    height: 1px;
    background: $gray-light;
}

b, strong {
    font-weight: 700;
}

input, button, select, textarea {
    outline: 0;
    font-family: $muli-font;
    text-align: left;
}

input[type=checkbox],
input[type=radio] {
    display: none;
    @include hidden();
}

input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -o-appearance     : none;
        appearance        : none;
    }
}

.wrapper-full-width {
    width: 100%;
}

.red {
    color: $red;
}

.green {
    color: $green;
}

.gray {
    color: $gray;
}
.dark-gray {
    color: $gray-dark;
}
/*! critical:end */

.noscroll-pc {
    overflow: hidden!important;
    transition: inherit!important;
    //height: 100%;

    @include breakpoint('tablet-landscape-min') {
        margin-right: 17px;
    }

    header {
        @include breakpoint('tablet-landscape-min') {
            transition: inherit!important;
        }
    }

    .header__top, .header__bottom{
        @include breakpoint('tablet-landscape-min') {
            margin-right: 17px;
            transition: inherit!important;
        }
    }
}

html.noscroll-pc {
    margin-right: 0;
}

.noscroll-mac {
    overflow: hidden!important;
    transition: inherit!important;
    // position: fixed;
    // left: 0;
    // right: 0;
}

.noscroll-custom {
    overflow: hidden!important;
    transition: inherit!important;
    height: 100%;
}
/*! critical:start */
.hide {
    display: none!important;
}

.show {
    display: block!important;
}

.mobile-hide {
    @include breakpoint('tablet-portrait-max') {
        display: none !important;
    }
}

.mobile-hide-phone {
    @include breakpoint('phone-max') {
        display: none !important;
    }
}

.desktop-hide-phone {
    @include breakpoint('phone-min') {
        display: none !important;
    }
}


.desktop-hide {
    @include breakpoint('tablet-portrait-min') {
        display: none !important;
    }
}

.select-hide {
    border: 0;
    @include hidden();
}

.pointevents-disable {
    pointer-events: none;
}
/*! critical:end */

.js-accordion-container {
    .js-accordion-list {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    &.accordion-opened {
        .js-accordion-list {
            opacity: 1;
            max-height: 100%;
            visibility: visible;
            transition: all .3s ease-in-out;
        }
    }
}

//new topbar
#scroll-observed {
    position: absolute;
    top: 95px;
    width: 1px;
    height: 0;
    left: 0;
}

//Position of trigger for backToTopButton
.js-trigger-backtotop {
    position: absolute;
    top: 1600px;
}

/*! critical:start */

#wrapperCheckout {
    margin: 93px 0 auto;
}

//new topbar
#wrapper {
    margin: 175px auto 0;
}

#wrapper,
#wrapperCheckout {
    @include breakpoint('tablet-portrait-max') {
        margin: 0;
    }
    .close {
        @include close();
    }

    .open {
        @include open();
    }
}

.img-responsive {
    width: 100%;
    height: auto;
}
/*! critical:end */

.transition {
    transition: visibility .3s, opacity .3s ease-in-out;
}

.img-cover {
    display: flex;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.user-name {
    text-transform: lowercase;
    display: inline-block;

    &:first-letter {
        text-transform: uppercase;
    }
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    height: 100%;
}

.resp-register-ctn {
    padding-top: 1600px;

    @include breakpoint('phone-max') {
        padding-top: 1850px;
    }
}

.resp-update-profile-ctn {
    padding-top: 724px;

    @include breakpoint('tablet-portrait-max') {
        padding-top: 2250px;
    }

    @include breakpoint('phone-max') {
        padding-top: 2500px;
    }

    @media (max-width: 575px) {
        padding-top: 3800px;
    }
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.resp-update-profile-iframe {
    top: -24px;

    @include breakpoint('tablet-portrait-max') {
        top: 0;
    }
}

.js-add-note {
    display: none!important;
}

.content {
    h1, h2, h3 {
        font: revert;
    }
}

.content-page {
    padding: 50px 100px;
    line-height: 1.5;
    overflow-x: auto;
    overflow-y: hidden;

    @include breakpoint('tablet-portrait-max') {
        padding: 25px 16px;
    }
}

.youtube-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0; /* 16:9 */

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.tab {
    border-top: 1px solid $red;
    border-bottom: 1px solid $red;
    border-right: 10px solid $red;
    color: $red;
    @include font-size(16px);
}

.frequently-asked-questions {
    padding: 32px 16px;
    display: flex;
    flex-flow: column;
    margin-top: 24px;

    >div {
        order: 1;
    }

    .title {
        order: 0;
        @include font-size(18px);
    }
}

.favourite-article {
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include z-index('one');
}

.add-favourite {

    &--active .iconheart-favourite {
        color: $gray-dark;
    }
    
    @include breakpoint('tablet-portrait-min') {
        &:hover .iconheart-favourite {
            color: $gray-dark;
        }
    }
}

//Two BennetBanner at 50% (add overflow:hidden container)
.twin-banner {

    @include breakpoint('tablet-portrait-min') {
        display: flex;
        margin: 0 -16px;
    }

    &__item {
        padding: 12px 0;

        @include breakpoint('tablet-portrait-min') {
            width: 50%;
            padding: 16px;
        }
    }
}

.icon-info {
    border: solid 1.5px $gray-dark;
    border-radius: 50%;
    margin: 0 8px;
    padding: 0px 6px;
    @include font-size(11px);
}

.screen-reader-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    width: 1px;
}   


button:focus, input:focus, textarea:focus, select:focus, a:focus, [role="button"]:focus, .glide__bullet .image-container:focus {
    outline: none;
}
.user-is-tabbing button:focus, 
.user-is-tabbing input:focus, 
.user-is-tabbing textarea:focus, 
.user-is-tabbing select:focus, 
.user-is-tabbing a:focus, 
.user-is-tabbing [role="button"]:focus, 
.user-is-tabbing .glide__bullet .image-container:focus {
    outline: 2px solid $black;
    outline-offset: 2px;
}

button:focus svg, a:focus svg, [role="button"]:focus svg {
    fill: #007bff;
}

.focus-inverted {
    *:focus {
        outline-color: white;
    }
}
