.recipe-products {
    padding: 16px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 20px 0;
        clear: both;
        width: 100%;
    }

    &__wrapper {
        background-color: $gray-ultralight; //Grafica $gray-ultralight
        padding-bottom: 16px;
    }

    &__ctas {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        align-items: center;

        @include breakpoint('tablet-portrait-min') {
            flex-direction: initial;
            justify-content: flex-end;
            padding: 0 115px;
        }

        span {
            padding-left: 20px;
        }
    }

    &__add-to-cart {
        margin-bottom: 25px;
        padding: 10px 20px;
        position: relative;
        @include btn-mobile('lg');

        svg {
            width: 24px;
            height: 24px;
        }

        @include breakpoint('tablet-portrait-min') {
            order: 2;
            margin: 0 0 0 50px;
        }
    }

    &__add-to-wishlist {
        position: relative;
        @include font-size(13px);
    }

    div.products-carousel {
        padding: 0;

        @include breakpoint('tablet-portrait-min') {
            padding: 0;
        }

        .carousel-title {
            border-bottom: 1px solid $gray-light;
            padding: 20px 65px;
    
            @include breakpoint('tablet-portrait-max') {
                padding: 16px 25px 12px 16px;
            }
        }
    }

    div.products-carousel-container {
        padding: 20px 0;

        .glide__arrow {
            &--left {
                left: -50px;
            }

            &--right {
                right: -50px;
            }
        }

        @include breakpoint('tablet-portrait-min') {
            padding: 24px 115px;
        }
    }
}