
.global-alerts {
    position: fixed;
    right: 30px;
    top: 170px;
    width: 33%;
    line-height: 1.5;
    transition: all 0.3s ease-in-out;
    transform: translateY(0); //new topbar
    @include z-index(global-message);
    
    @include breakpoint('tablet-portrait-max') {
        top: 100px;  //new topbar
        width: auto;
        right: 4px;
        left: 4px;
    }

    .alert {
        padding: 12px 16px;
        border-radius: 6px;
        box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.21);
        background-color: $white;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        @include font-size(14px);

        .iconspeaker {
            @include hidden();
        }

        .alert-text {
            line-height: 1.5;
            padding-left: 20px;
            max-width: 90%;
            @include font-size(14px);
        }

        svg {
            margin-right: 16px;
            height: 35px;
            width: 35px;
        }

        .iconclose {
            height: 12px;
            width: 12px;
            margin: 0 0 0 16px;
            cursor: pointer;
        }

        &.alert-info {
            border: 1px solid $green-secondary-first;
        }

        &.alert-notice {
            border: 1px solid $blue;
        }

        &.alert-warning {
            border: 1px solid $orange-secondary-first;
        }

        &.alert-danger {
            border: 1px solid $red-secondary-first;
        }
    }
}

.checkout {
    .global-alerts {
        position: static;
        width: 100%;

        .alert {
            box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);
            margin: 10px 15px 0px 0;

            @include breakpoint('tablet-portrait-max') {
                margin: 0;
                border: 0;
                background-color: $gray-ultralight;
            }

            .iconspeaker {
                height: 25px;
                width: 25px;
                @include visible();
            }

            .iconclose {
                @include hidden();
            }
        
            &--green {
                border: solid 2px $green-dark-rgb-3;

                svg {
                    color: $green;
                }
            }
        
            &.alert-danger,
            &.alert-info,
            &.alert-warning {
                border: solid 2px $red;
        
                svg {
                    color: $red;
                }
            }
        }
    }   
}

.address-alert {
    color: red;
    padding: 12px 16px;
    border: 1px solid red;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 13px;
}

.reminders {
    position: fixed;
    right: 30px;
    top: 170px;
    width: 33%;
    transition: all 0.3s ease-in-out;
    transform: translateY(0);
    border-radius: 6px;
    @include z-index(global-message);
    
    @include breakpoint('tablet-portrait-max') {
        top: 160px;
        width: auto;
        right: 16px;
        left: 16px;
    }

    .reminder {
        margin-bottom: 20px;
        position: relative;
        
        &__header {
            background-color: $red;
            padding: 16px 20px;
            display: flex;
            color: $white;
            align-items: center;
            border-radius: 5px 5px 0 0;
            border: 1px solid $gray-light;
            border-bottom: none;
    
            svg {
                margin-left: auto;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    
        &__content {
            background-color: $white;
            border-radius: 0 0 5px 5px;
            border: 1px solid $gray-light;
            border-top: none;
            display: flex;
            align-items: center;
            padding: 18px 20px;

            svg {
                width: 25px;
                height: 25px;
            }
        }

        &__text {
            line-height: 1.5;
            padding-left: 16px;
        }
    }

    .timeline {
        width: 100%;
        height: 4px;
        background-color: $green;
        border-radius: 0 5px 5px 5px;
        position: relative;
        top: -4px;
    }

    .css-animationGoRigth {
        animation-name: exit;
        animation-duration: 1s;
        animation-delay: 0s;
        animation-timing-function: linear;
    
        @include breakpoint('tablet-portrait-max') {
            animation-name: exit-small;
        }
    
        @keyframes exit {
            from { right: 0px; }
            to { right: calc(2.5 * ( -33vw - 40px)); }
        }
    
        @keyframes exit-small {
            from { right: 0px; }
            to { right: calc(2.5 * ( -100vw + 40px)); }
        }
    }
}