.customer-thankyou {
    padding: 24px 0;
    display: flex;
    flex-direction: column;

    @include breakpoint("tablet-portrait-min") {
        padding: 24px 0 60px 54px;
        flex-direction: row;
        align-items: center;
    }

    &__content {
        order: 2;
        padding: 30px 16px 0;
        display: flex;
        flex-direction: column;

        @include breakpoint("tablet-portrait-min") {
            order: 1;
            width: 48%;
            padding: 0 6% 0 0;
        }

        @include breakpoint("desktop-small-min") {
            padding-right: 12%;
        }
    }

    &__title {
        line-height: 1.5;
        @include font-size(18px);

        @include breakpoint("tablet-portrait-min") {
            line-height: 1.56;
            @include font-size(32px);
        }
    }

    &__text {
        line-height: 1.71;
        padding: 16px 0 32px;
        @include font-size(14px);
        
        @include breakpoint("tablet-portrait-min") {
            padding: 16px 0;
            line-height: 1.5;
            @include font-size(18px);
        }
    }

    &__cta {
        padding: 0 16px;
        min-width: 220px;
        max-width: 345px;
        width: 100%;
        margin: 0 auto;

        @include breakpoint("tablet-portrait-min") {
            margin: 0;
        }
    }

    &__img {
        order: 1;
        box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.21);

        @include breakpoint("tablet-portrait-min") {
            order: 2;
            width: 52%;
        }
    }
}
