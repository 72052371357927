.support{

    .go-back{
        padding: 8px 0 8px 16px;
        background-color: #f2f2f2;

        .arrow{
            vertical-align: middle;
            padding: 2px;
            border-color: $gray;
        }

        &__home{
            font-size: 13px;
            line-height: normal;
            color: $gray;
        }
    }

    .breadcrumbs{
        padding: 31px 0 21px 65px;

        li{
            margin: 0;

            &:last-child{

                span{
                    font-weight: bold;
                }
            }
        }
    }

    &__hero{
        padding: 20px 0px;
        img{
            width: 100%;
        }
    }

    &__categoriesFaq{

        .title{
            padding: 12px 63px 0px;
            font-size: 18px;
            line-height: 1.5;

             @include breakpoint('tablet-portrait-max'){
                    padding: 24px 0px 16px 20px;
             }
        }

        .support-carousel{
            padding: 0px 115px 16px;
            position: relative;

            .categoryFaq-box{
                border-radius: 5px;
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
                display: flex;
                margin: 16px 0px;
                padding: 16px 0px 16px 16px;
                a{
                    line-height: 1.5;
                }
            }

            .glide__slides {
                align-items: center;
            }

            .glide__arrow--right {
                right: -55px;
            }

            .glide__arrow--left {
                left: -55px;
            }
        }

        .category-container{
            display: flex;
            flex-wrap: wrap;
            margin-left: 16px;
            width: 100%;

            .categoryFaq-box{
                padding: 16px 36px 16px 16px;
                border-radius: 5px;
                box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
                background-color: #ffffff;
                margin-right: 16px;
                flex-basis: calc(50% - 24px);
                margin-bottom: 16px;
                align-items: center;
                display: flex;
            }
        }
    }

    &__faq{
        margin-bottom: 32px;

        @include breakpoint('tablet-portrait-max'){
        margin-bottom: 24px;
        }

        .title{
            padding: 16px 63px 8px;
            font-size: 18px;
            line-height: 1.5;

            @include breakpoint('tablet-portrait-max'){
                padding: 16px 0px 25px 16px;
            }
        }

        .faq-box-list{
            padding: 0 65px 42px;

            @include breakpoint('tablet-portrait-max'){
                padding: 0 16px 24px 16px;
            }

            .faq__container{
                padding: 16px 0px;
                border-bottom: 1px solid #e0e0e0;

                @include breakpoint('tablet-portrait-max'){
                    margin-right: 0;
                }

                .js-accordion-selector{
                    @include breakpoint('tablet-portrait-max'){
                        margin: 0 12px 0 8px;

                        .faq__question{
                            line-height: normal;

                            b{
                                margin-right: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__login{
        width: calc(100% - 128px);
        margin: 0px auto 32px;
        display: flex;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        height: 140px;

        @include breakpoint('tablet-portrait-max'){
            margin: 0 0 24px 0;
            width: 100%;
            height: 168px;
        }
        .login-image{
            flex-basis: 100%;
            order: 2;
            position: relative;

            @include breakpoint('tablet-portrait-max'){
            background-image: linear-gradient(to bottom, rgba(233, 233, 233, 0.4) -221%, #ffffff 221%);
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;

                @include breakpoint('tablet-portrait-max'){
                    z-index: -1;
                }
            }
        }

        .login-container{
            position: absolute;
            padding: 32px 0px 0px 50px;
            display: flex;
            flex-flow: column;
            width: 47%;
            line-height: normal;
            @include z-index('one');

            @include breakpoint('tablet-portrait-max'){
                width: calc(100% - 32px);
                padding: 0;
                margin: 32px 16px 32px 16px;
                line-height: 1.5;
            }

            .login-title{
                font-weight: bold;

                @include breakpoint('tablet-portrait-max'){
                    font-size: 18px;
                }
            }

            &__buttons{
                padding: 16px 0 0 0;

                @include breakpoint('tablet-portrait-max'){
                    display: flex;
                }

                .btn{
                    width: 176px;
                    padding: 10px 13px 10px 14px;
                    &-red{
                        margin-right: 16px;
                   }

                    @include breakpoint('tablet-portrait-max'){
                        width: 163px;
                   }
                }
            }
        }
    }
}