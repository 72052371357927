
.order-entry {
    border-top: solid 2px $gray-light;
    display: flex;
    padding: 15px 0;

    @include breakpoint('tablet-portrait-max') {
        flex-flow: wrap;
        padding: 18px 0;
        border: none;
    }

    &__left {
        display: flex;
        width: 50%;
        padding-right: 10px;

        @include breakpoint('tablet-portrait-max') {
            order: 1;
            width: auto;
            flex: 1;
        }
    }

    &__image {
        height: 100px;
        display: flex;
        align-items: center;

        img {
            max-width: 100px;
            height: auto;
        }

        @include breakpoint('tablet-portrait-max') {
            height: 60px;

            img {
                height: 60px;
            }
        }
    }

    &__description {
        width: 100%;
        padding-left: 8%;

        @include breakpoint('tablet-portrait-max') {
            padding-left: 5%;
        }
    }

    &__name {
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(13px);
        }
    }

    &__brand {
        padding-top: 10px;
        text-transform: uppercase;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(12px);
        }
    }

    &__weight  {
        padding-top: 8px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            padding-top: 15px;
        }
    }

    &__specifications {
        width: 30%;
        line-height: 1.6;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            order: 3;
            width: 100%;
            display: flex;
            justify-content: space-between;
            line-height: normal;
            padding-top: 15px;
            border-top: solid 2px $gray-light;
            margin-top: 15px;
        }
    }

    &__c-specification {
        @include breakpoint('tablet-portrait-max') {
            display: flex;
            flex-direction: column;
        }
    }

    &__specification-label {
        @include breakpoint('tablet-portrait-max') {
            padding-bottom: 10px;
            @include font-size(12px);
        }
    }

    &__summary {
        width: 20%;

        @include breakpoint('tablet-portrait-max') {
            order: 2;
            margin-left: auto;
            text-align: right;
            width: auto;
            flex: 1;
        }
    }

    &__c-unit-price {
        padding-top: 5px;
        @include font-size(13px);
    }

    &__unit-price-label {
        @include breakpoint('tablet-portrait-max') {
        
        }
    }

    &__price {
        @include breakpoint('tablet-portrait-max') {
            padding-top: 10px;
            @include font-size(20px);
        }
    }

    &__price-label-mobile {
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            @include visible();
            @include font-size(12px)
        }
    }
}