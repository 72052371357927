.magazine {

    &--flex  {
        @include breakpoint('tablet-portrait-min') {
            display: flex;
            flex-flow: wrap;
        }
    }

    .content {
        line-height: 1.75;

        li,ol,ul {
            list-style: initial;
        }
        
        ul, ol { 
            display: block;
            margin: 1em 0;
            padding: 0 0 0 40px;
        }

        @include breakpoint('tablet-portrait-min') {
            line-height: 1.5;
        }
    }

    /**** ProductCarouselComponent ****/
    .products-carousel {
        padding: 16px 0 0;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 65px 12px;
        }

        .carousel-title {
            color: $red;
            font-weight: normal;
            padding: 0 0 24px;
            
            @include breakpoint('tablet-portrait-max') {
                padding-left: 16px;
            }
        }
        
        .glide__arrows {
            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }
    }

    .products-carousel-container {
        padding-bottom: 20px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0px 50px 20px;
        }

        .glide__arrow {
            &--left {
                left: -50px;
            }

            &--right {
                right: -50px;
            }
        }
    }
}