.account-address {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 10px 20px;
    position: relative;
    display: flex;

    @include breakpoint('tablet-landscape-max') {
        padding: 0 0 15px;
        max-width: none;
        flex: 0 0 100%;
    }

    .address-info,
    .address-edit {
        border-radius: 8px;
        padding:  20px 20px 25px;

        @include breakpoint('tablet-portrait-max') {
            border-radius: 0; 
            padding: 24px;
        }

        &__header {
            display: flex;
            padding-bottom: 25px;
        }

        &__favourite {
            display: flex;
            align-items: center;
            cursor: pointer;
            @include font-size(14px);

            .iconheart {
                color: transparent;
                margin-right: 5px;
            }

            .red {
                color: $red;
            }
        }

        .favourite-info-text,
        .favourite-edit-text {
            text-decoration: underline;

            > h2 {
                font-size: inherit;
            }
        }

        &__edit-cta,
        &__close {
            margin-left: auto;
            cursor: pointer;
            color: $gray-dark;
    
            .iconclose {
                width: 15px;
                height: 15px;
            }
        }
    }

    .address-info {
        $this: &;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        border: 1px solid $white;
        flex: 1 1 0%;

        &__title {
            padding-bottom: 15px;

            > h3 {
                font-size: inherit;
            }
            
            @include breakpoint('tablet-portrait-max') {
                padding-bottom: 12px;
                @include font-size(16px);
            }
        }
    
        &__content {
            padding-bottom: 20px;
            max-width: 330px;
            @include font-size(14px);
    
            span {
                white-space: nowrap;
            }
        }
    
        &__note {
            @include font-size(14px);
        }
    }

    .address-edit {      
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        background: $white;   
        margin: 0 10px;

        &.close {
            max-width: none;
        }
        
        .row {
            display: flex;
            justify-content: center;
            margin: 0 -10px;

            @include breakpoint('tablet-portrait-max') {
                flex-flow: wrap;
            }

            .form-group {
                margin-left: 10px;
                margin-right: 10px;
                flex: 1 1 0%;

                @include breakpoint('tablet-portrait-max') {
                    flex: 0 0 100%;
                }
            }

            &--radio {
                flex-direction: column;
                margin: 0 0 10px;
                @include hidden();

                label {
                    margin-bottom: 10px;
                }
            }

            &--address {
                .form-group:first-child {
                    flex: 2;
                }
            }

            &--phone {
                @include breakpoint('tablet-portrait-max') {
                    flex-flow: nowrap;
                    margin: 0 -20px;
                }
                .prefix {
                    max-width: 85px;
                }

                .phone-number {
                    @include breakpoint('tablet-portrait-max') {
                        flex: 1 1 0%;
                    }
                }
            }

            &--lift {
                .form-group {
                    .label-for-radio {
                        margin-right: 10px;
                        @include font-size(14px);
                    }

                    label {
                        padding: 0 10px 0 22px;
                    }
                }
            }
        }

        .textarea-wrapper {
            position: relative;

            .clear-note {
                position: absolute;
                bottom: 12px;
                right: 12px; 
                text-decoration: underline;
                cursor: pointer;
                letter-spacing: 0.1px;
                padding: 10px 0 10px 10px;
                @include font-size(11px);
                @include z-index(two);
            }
        }

        &__policy {
            text-decoration: underline;
            margin-bottom: 30px;
            letter-spacing: 0.12px;
            display: flex;
            @include font-size(13px);
        }

        &__footer {
            display: flex;
            justify-content: space-between;

            @include breakpoint('tablet-portrait-max') {
                flex-direction: column-reverse;
            }

            .btn {
                width: 100%;
                max-width: 200px; //IE11

                @include breakpoint('tablet-portrait-max') {
                    flex: initial;
                    max-width: unset;
                }

                &:first-child {
                    margin-right: 10px;
                    @include font-size(13px);

                    @include breakpoint('tablet-portrait-max') {
                       margin: 10px 0 0;
                    }
                }
            }
        }
    }

    &--selected {
        .address-info {
            border: solid 1px $red;

            @include breakpoint('tablet-portrait-max') {
                border: none;
            }
        }

        .iconheart {
            color: $red !important;
        }

        .favourite-info-text,
        .favourite-edit-text {
            text-decoration: none !important;
        }
    }
}
