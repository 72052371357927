.checkout-item {
    border-top: 1px solid $gray-light;
    display: flex;
    flex-flow: column;

    &:first-child {
        border-top: 0;
    }

    &__container {
        display: flex;
        width: 100%;
    }

    &__left {
        display: flex;
        width: 70%;
        align-items: center;
        padding-right: 10px;
    }

    &__image {
        height: 100px;
        display: flex;
        align-items: center;

        img {
            max-width: 100px;
            height: auto;
        }
    }

    &__description {
        width: 100%;
        padding-left: 8%;
    }

    &__name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        @include font-size(14px);
    }

    &__brand {
        margin-top: 10px;
        display: flex;
        text-transform: uppercase;
        @include font-size(13px);
    }

    &__c-weight-info {
        flex-flow: column;
        display: flex;
        line-height: 1.5;
        margin-top: 10px;
        @include font-size(13px);
    }

    &__summary {
        display: flex;
        width: 30%;
        padding-top: 24px;
        align-items: center;
        justify-content: flex-end;
    }

    &__price {
        text-align: right;
    }

    &__quantity {
        height: 35px;
        margin: 20px 0 24px;
        @include font-size(12px);
    }

    &__cta {
        margin: 20px 0 auto auto;

        .icontrash-border {
            cursor: pointer;
        }
    }

    &__promo {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding-bottom: 4px;

        .promo-item__wrapper {
            padding-right: 8px;
        }
    }
}