.change-address-popup,
.max-threshold-prd,
.shipment-availability {

    &__header .iconshippinghome {
        color: transparent;
    }
}

.max-threshold-prd,
.shipment-availability {
    overflow: hidden;

    .off {
        opacity: 0.5;
        color: $gray-light;
    }

    &__c-info {
        background-color: $white;
        padding: 24px 24px 16px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);

        @include breakpoint('tablet-portrait-max') {
            padding: 24px 16px 20px;
        }
    }

    &__info {
        line-height: 1.5;
    }

    &__container {
        @include breakpoint('tablet-portrait-max') {
           // 100vh - footer - header
           height: calc(100vh - 122px);
           height: calc(var(--vh, 1vh) * 100 - 122px);
           overflow-y: auto;
        }
    }

    &__content {
        padding: 0 25px;
        max-height: 40vh;
        overflow-y: auto;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 20px;
            max-height: none;
        }

        .cart-item {
            border-top: 1px solid $gray-light;

            @include breakpoint('tablet-portrait-max') {
                margin: 0;
                padding: 20px 0 10px;
            }

            &:first-child {
                border: none;
            }

            &__left {
                flex: 1;
                width: initial;

                @include breakpoint('tablet-portrait-max') {
                    padding: 0;
                }
            }

            &__summary {
                margin-left: auto;
                text-align: right;
                width: initial;
                padding-top: 30px;

                @include breakpoint('tablet-portrait-max') {
                    padding: 20px 0 0;

                    .cart-item__max-shipping-qnt,
                    .cart-item__quantity-popup {
                        @include hidden();
                    }
                }
            }

            &__name {
                @include font-size(13px);

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(12px);
                }
            }

            &__brand {
                text-transform: uppercase;
                @include font-size(12px);
            }

            &__weight {
                color: $gray;
                @include font-size(12px);
            }

            &__unit-price {
                color: $gray-dark;
                font-weight: bold;
                @include font-size(18px);

                @include breakpoint('tablet-portrait-max') {
                    padding-top: 10px;
                }
            }

            &__c-unit-price {
                @include breakpoint('tablet-portrait-max') {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__c-quantity-availability,
            &__price,
            &__promo,
            &__cta,
            &__add-note,
            &__mobile-promo,
            &__unit-price-label {
                @include hidden();
            }

            &__unit-price-label-popup {
                @include breakpoint('tablet-portrait-max') {
                    @include visible();
                    @include font-size(12px);
                 }
            }

            &__additional-info {
                max-width: none;
                justify-content: initial;

                @include breakpoint('tablet-portrait-max') {
                   @include hidden();
                }
            }

            &__description {
                padding-left: 15px;
            }

            &__c-weight-info,
            &__brand {
                margin-top: 8px;
            }

            &__c-weight-info {
                flex-direction: column;
                align-items: initial;
            }

            &__bottom-popup-mobile {
                @include breakpoint('tablet-portrait-max') {
                    padding-top: 10px;
                    order: 3;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    @include visible();
                }
            }

            &__max-shipping-qnt,
            &__quantity-popup {
                padding-top: 8px;
                @include font-size(14px);

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(12px);
                }
            }
        }
    }
}

.max-threshold-prd {
    &__bottom-info {
        line-height: 1.5;
        padding: 10px 25px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            padding: 16px 18px;
            border-top: 2px solid $gray-ultralight;
        }
    }

    .cart-item__max-shipping-qnt {
        @include visible();
    }
}

.shipment-availability {
    &__info{
        padding-bottom: 20px;
    }

    &__c-radio {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint('tablet-portrait-max') {
            align-items: initial;
            flex-direction: column;
            @include font-size(16px);
            
            input[type=radio]+label:first-child {
                margin-bottom: 10px;
            }
        }
    }

    .cart-item__quantity-popup {
        @include visible();
    }
}

// Popup productNote
.add-note {
    &__title {
        padding-bottom: 10px;
    }
}

.min-threshold__info {
    line-height: 1.5;
    padding-bottom: 10px;

    @include breakpoint('tablet-portrait-max') {
        @include font-size(16px);
    }
}

.change-address-popup {
    max-height: 90vh;

    @include breakpoint('tablet-portrait-max') {
        max-height: inherit;
        overflow: hidden;
    }

    &__content {
        @include breakpoint('tablet-portrait-max') {
           // 100vh - footer - header - goback
           height: calc(100vh - 122px - 50px );
           height: calc(var(--vh, 1vh) * 100 - 122px - 50px);
           overflow-y: auto;
        }
    }

    &__info {
        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
    }

    &__addresses {
        .address {
            border: 2px solid $gray-light;
            padding: 10px;
            opacity: 0.5;
            border-radius: 8px;
            cursor: pointer;
            margin: 20px 0;

            &:hover {
                border: 2px solid $red;
                opacity: 1;
            }

            @include breakpoint('tablet-portrait-max') {
                flex-direction: column;
                align-items: initial;
                margin: 0 0 20px;
            }

            &__container {
                display: flex;
                align-items: center;
            }

            &__name {
                flex: 1;
                padding-right: 5px;
                line-height: normal;
                @include font-size(14px);

                @include breakpoint('tablet-portrait-max') {
                    padding-bottom: 5px;
                }
            }

            &__content {
                line-height: 1.5;
                flex: 2;
                @include font-size(13px);
            }

            &--selected {
                border-top: 2px solid $red;
                border-bottom: 2px solid $red;
                border-left: 2px solid $red;
                border-right: 10px solid $red;
                opacity: 1;
                pointer-events: none;
            }

            &--disabled {
                border: 2px solid;
                background-color: #e9e9ec;
                opacity: 0.33;
                cursor: default;
                
                &:hover {
                    border: 2px solid;
                    opacity: 0.33;
                }
            }

            &__free-shipping {
                @include font-size(12px);
            }

            &__ztl {
                padding-top: 8px;
                line-height: 1.5;
                @include font-size(11px);
            }
        }
    }

    &__c-add-address {
        .add-address-cta {
            color: $dark-blue;
            display: inline-flex;
            align-items: center;
            margin-top: 25px;
            cursor: pointer;
            
            .iconcircle-plus {
                margin-right: 10px;
            }
        }

        .cart-address-edit {
            margin-top: 20px;
            
            .row {
                display: flex;
                justify-content: center;
                margin: 0 -10px;
    
                @include breakpoint('tablet-portrait-max') {
                    flex-flow: wrap;
                    margin: 0;
                }
    
                .form-group {
                    margin-left: 10px;
                    margin-right: 10px;
                    flex: 1;
    
                    @include breakpoint('tablet-portrait-max') {
                        flex: 0 0 100%;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
    
                &--radio {
                    flex-direction: column;
                    margin: 0 0 10px;
                    @include hidden();
    
                    label {
                        margin-bottom: 10px;
                    }
                }
    
                &--address {
                    .form-group:first-child {
                        flex: 2;
                    }
                }

                &--phone {
                    @include breakpoint('tablet-portrait-max') {
                        flex-flow: nowrap;
                    }

                    .prefix {
                        max-width: 85px;

                        @include breakpoint('tablet-portrait-max') {
                            margin-right: 20px;
                        }
                    }

                    .phone-number {
                        @include breakpoint('tablet-portrait-max') {
                            flex: 1 1 0%;
                        }
                    }
                }
    
                &--lift {
                    .form-group {
                        .label-for-radio {
                            margin-right: 10px;
                            @include font-size(14px);
                        }
    
                        label {
                            padding: 0 10px 0 22px;
                        }
                    }
                }
            }
    
            .textarea-wrapper {
                position: relative;
    
                .clear-note {
                    position: absolute;
                    bottom: 12px;
                    right: 12px; 
                    text-decoration: underline;
                    cursor: pointer;
                    letter-spacing: 0.1px;
                    padding: 10px 0 10px 10px;
                    @include font-size(11px);
                    @include z-index(two);
                }
            }
    
            &__policy {
                text-decoration: underline;
                margin-bottom: 30px;
                letter-spacing: 0.12px;
                display: flex;
                @include font-size(13px);
            }
        }
    }   
}

.change-drive-forHd,
.no-hd-Address {
    &__info {
        line-height: normal;
    }
}

.change-home-associated-popup {
    color: $black;

    &__timeslot {
        padding: 16px 0 8px;

        >p {
            padding-bottom: 20px;
        }
    }

    .btn-timeslots {
        margin-top: 32px;
        cursor: pointer;
    }
}

.validation-by-rules {
    ul, li, ol {
        list-style: inside;
    }
}