.cart-deliv-pickup {
    box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px $gray-ultralight;

    @include breakpoint('tablet-portrait-max') {
        visibility: hidden;
        opacity: 0;
        width: 100%;
        left: 0;
        height: calc(var(--vh, 1vh) * 100);
        max-height: calc(var(--vh, 1vh) * 100);    
        position: fixed;
        top: 0;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $white;
        z-index: z-index('backdrop-up');
        transition: visibility .3s, opacity .3s ease-in-out;
    }

    &__header {
        background-color: $gray-ultralight;
        padding: 16px;

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
    }

    &__header-title {
        line-height: 1.5;
        color: $black;
        @include font-size(16px);
    }

    &__content {
        padding: 0 10px;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 12px;
        }
    }

    &__cta {
        padding: 24px 20px 16px;

        @include breakpoint('tablet-portrait-max') {
            padding: 24px 12px 16px;
            box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.06);
            border: solid 1px $gray-ultralight;
        }

        .btn {
            width: 100%;
            
            span {
                color: $white;
            }
        }
    }

    &__footer {
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            @include visible();
        }
    }

    .bennet-box {
        position: relative;
        border: solid 1px $gray-light;
        margin: 38px 0 0;
        cursor: pointer;
    
        &__img {
            position: absolute;
            top: -12px;
            left: 10px;
            padding: 0 5px;
            background-color: $white;
        }
    
        &--selected {
            border-top: 1px solid $red;
            border-bottom: 1px solid $red;
            border-left: 1px solid $red;
            border-right: 16px solid $red;
            border-radius: 0 5px 5px 0;
    
            .bennet-box {
                &__content,
                &__footer {
                    opacity: 1;
                }
    
                &__cta svg.iconpickinstore {
                    color: #9ed5a0;
                }
    
                &__footer svg {
                    color: $green;
                }
            }
        }
    
        &__container {
            padding: 16px 0 20px;
            display: flex;
            flex-direction: column;
        }
    
        &__info {
            padding: 0 16px;
            line-height: 1.5;
            @include font-size(14px);

            ul {
                padding-left: 16px;
            }
    
            li {
                list-style: disc;
            }
        }
    
        &__content {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            opacity: 0.5;
            //padding-bottom: 22px;
        }
    
        &__title {
            font-weight: bold;
            text-transform: uppercase;
            padding: 0 16px;
            @include font-size(18px);
        }
    
        &__subtitle {
            padding: 10px 16px 0;
            line-height: normal;
            @include font-size(13px);
        }

        &__info-restricted {
            padding: 15px 16px 0;
            line-height: normal;
            @include font-size(13px);
        }
    
        &__available-closer {
            border-top: solid 2px $gray-light;
            border-bottom: solid 2px $gray-light;
            margin: 15px 0 0px;
            padding: 3px 16px;
            line-height: 1.5;
        }
    
        &__cta {
            padding: 0 16px;
            margin-left: auto;
            padding-top: 25px;
            display: flex;
            align-items: center;
    
            .shippinghome,
            .pickinstore {
                height: 24px;
                width: 24px;
            }
            
            svg {
                color: $white;
            }
            span {
                margin-left: 12px;
                cursor: pointer;
                @include font-size(14px);
            }
        }
    
        &__add-address {
            color: $dark-blue;
            display: flex;
            align-items: center;
            padding: 25px 16px 0px;
    
            .iconcircle-plus {
                padding-right: 5px;
                cursor: pointer;
            }
        }
    
        &__footer {
            box-shadow: 0 -2px 12px 0 rgba(63, 63, 63, 0.18);
            display: flex;
            padding: 8px 15px;
            opacity: 0.5;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }
    
        &__footer-text {
            padding-left: 15px;
            line-height: 1.5;
            @include font-size(13px);
        }
    }
}
