.cart {
    display: flex;
    margin-top: 20px;
    flex-direction: column;

    // Start mobile Bottom info

    // End mobile Bottom info

    @include breakpoint('tablet-portrait-max') {
        flex-direction: column;
        margin: 0;
    }

    .off {
        opacity: 0.5;
        color: $gray-light;
    }

    &__container {
        display: flex;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
        }
    }

    &__go-checkout {
        display: flex;

        @include breakpoint('tablet-portrait-max') {
            padding-top: 10px;
            .btn {
                width: 100%;
                @include btn-mobile('lg');

                a {
                    color: $white;
                }
            }
        }               
    }

    .cart-header {
        position: relative;
        display: flex;
        box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.06);
        padding: 10px 25px;
        height: 75px;

        @include breakpoint('tablet-portrait-max') {
            height: auto;
            flex-direction: column;
            box-shadow: none;
            padding: 16px 16px 0px;
        }
         
        &__observer {
            width: 1px;
            top: 85px;
            position: absolute;
        }
        
        &__products,
        &__amount {
            @include font-size(22px);
        }

        &__products {
            @include breakpoint('tablet-portrait-max') {
                padding-left: 20px;
                @include font-size(18px);
            }
        }
            
        &__title,
        &__products-label,
        &__amount-label {
            @include font-size(18px);
        }

        &__left,
        &__c-amount {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        &__left {
            @include breakpoint('tablet-portrait-max') {
                position: relative;
                height: 50px;
                order: 2;
                flex-direction: row;
                justify-content: initial;
                padding-top: 10px;
                align-items: center;
            }
        }
              
        &__right {
            margin-left: auto;
            display: flex;

            @include breakpoint('tablet-portrait-max') {
                order: 1;
                flex-direction: column;
                margin: 0;
                text-align: center;
            }
        }

        &__mobile-bag {
            @include hidden();

            @include breakpoint('tablet-portrait-max') {
                margin-left: auto;
                border-radius: 16px;
                box-shadow: 0 4px 8px 0 rgba(45, 45, 46, 0.12);
                background-color: $gray-ultralight;
                display: flex;
                align-items: center;
                cursor: pointer;
                @include visible();
                @include z-index(one);

                .iconspeaker {
                    width: 25px;
                    height: 25px;
                    margin: 6px 15px;
                }
            }

            &.open {
                position: absolute;
                right: 0;
                width: 260px;
            }

            &-content {
                padding: 5px;
                @include font-size(12px);
            }
        }

        &__c-amount {
            text-align: right;
            padding-right: 30px;

            @include breakpoint('tablet-portrait-max') {
                padding: 0;
            }              
        }

        &__amount-info {
            @include font-size(14px);

            @include breakpoint('tablet-portrait-max') {
                text-align: center;
                @include font-size(12px);
            }           
        }

        .btn {
            padding: 0 40px;

            a {
                color: $white;
            }
        }
    }

    .cart-items {
        padding: 0 25px;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }
            

        &__header {
            padding: 18px 0px 8px 0;
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }               
        }

        &__left-header {
            width: 45%;
            display: flex;
            align-items: center;
        }

        &__category-icon {
            width: 30px;
            height: auto;
            margin-right: 15px;
        }

        &__quantity,
        &__amount {
            color: $gray;
            @include font-size(13px);
        }

        &__quantity {
            width: 25%;
        }

        &__amount {
            width: 25%;
        }

        .cart-item.js-article[data-code="P_2768182"],
        .cart-item.js-article[data-code="P_2995942"],
        .cart-item.js-article[data-code="P_3233681"]  {
            @include hidden();
        }
    }

    .cart-item-container {
        @include breakpoint('tablet-portrait-max') {
            display: flex;
            flex-direction: column;
        }
    }

    .mobile-bottom-info {
        display: flex;
        flex-flow: column;
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            height: 195px;
            @include visible();
        }

        &__cta {            
            display: flex;
            align-items: center;
            left: 0;
            right: 0;
            bottom: 0;
            height: 75px;
        
            &--fixed {
                position: fixed;
                background-image: linear-gradient(to bottom, transparent, #696969 180%, #696969);    
                @include z-index('backToTop'); 
            }
        }
    
        &__open-panel {
            width: 87%;
            height: 32px;
            border-radius: 0 15px 15px 0;
            background-color: $white;
            display: flex;
            align-items: center;
            transition: all 0.1s ease-in-out;
            box-shadow: 0 2px 10px 2px rgba(0,0,0,0.2);
            padding: 0 16px 0 8px;
            cursor: pointer;
    
            .icondrive-text {
                width: 76px;
                height: 22px;
            }
    
            &-text {
                padding: 0 10px;
                @include font-size(13px);
            }
    
            .iconsec-arrow-right {
                margin-left: auto;
            }
    
            &--small {
                width: 36%;
    
                .mobile-bottom-info__open-panel-text {
                    display: none;
                }
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;
        }

        &__go-checkout-fixed {
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.1);
            margin: 0 16px 0 auto;
            padding: 0 20px;

            a {
                color: $white;
            }
        }

        &__summary {
            padding: 8px 16px;
        }

        &__c-amount {
            text-align: right;
        }

        &__amount-label {
            @include font-size(16px);
        }

        &__amount {
            @include font-size(24px);
        }

        &__amount-info {
            padding-top: 8px;
            @include font-size(12px);
        }
    }


    &__left-panel,
    &__c-info-box {
        flex: 0 0 73%;
        max-width: 73%;
        border-left: none;
        padding-right: 15px;
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            max-width: none;
            padding: 0;
        }
            
    }

    &__wrapper {
        border: solid 1px $gray-ultralight;

        @include breakpoint('tablet-portrait-max') {
            border: none;
        }
            
    }

    &__delivery-pickup,
    &__c-help {
        flex: 0 0 27%;
        max-width: 27%;
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            max-width: none;
            padding: 0;
        }
            
    }

    &__delivery-pickup-wrapper  {
        position: sticky;
        top: 95px;

        @include breakpoint('tablet-portrait-max') {
            position: initial;
        }
    }

    &__ctas {
        margin-left: auto;
        padding: 16px 0 30px;
        display: flex;
        
        @include breakpoint('tablet-portrait-max') {
            justify-content: space-between;
            margin: 0;
            padding: 10px 16px;
        }
        
        .btn {
            margin-left: 30px;
            @include font-size(13px);

            svg {
                width: 25px;
                height: 25px;
                margin-right: 15px;
            }

            @include breakpoint('tablet-portrait-max') {
                margin: 0;

                svg {
                    margin-right: 10px;
                }
            }

            
        }
    }

    &__carousel {
        padding: 20px 0;

        .products-carousel-container {
            padding-bottom: 20px;
        }

        @include breakpoint('tablet-portrait-min') {    
            .products-carousel-container {
                padding: 0 30px 20px 50px;

                .hit__content {
                    padding-left: 8px;
                    padding-right: 8px;
                }

                .glide__arrow--right,
                .glide__arrow--left { 
                    width: 30px;
                    height: 50px;
                    padding: 0; //IE11
                    
                    .arrow__right {
                        margin-right: 8px;
                    }

                    .arrow__left {
                        margin-left: 8px;
                    }
                }

                .glide__arrow--right {
                    right: -35px;
                }

                .glide__arrow--left {
                    left: -35px;
                }
            }
        }

        .glide__arrows {
            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }

        .carousel-title {
            padding: 0 25px;
            @include font-size(18px);
    
            @include breakpoint('tablet-portrait-max') {
                padding: 0 16px;
            }       
        }

        .glide__bullets {
            @include hidden();

            @include breakpoint('tablet-portrait-max') {
                margin-top: 10px;
                text-align: center;
                @include visible();
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        padding: 20px 0;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
            align-items: initial;
            padding: 20px 0 0;
        }
    }

    .cart-info-deliv {       
        padding: 20px;
        color: $gray;
        line-height: 1.5;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            
        }
    
        @include breakpoint('tablet-portrait-max') {
            padding: 20px 16px;
            @include font-size(13px);
        }
        
        ul {
            padding-left: 16px;
        }

        li {
            list-style: disc;
        }
    }

    &__c-info-box {
        @include breakpoint('tablet-portrait-max') {
            order: 2;
            padding: 16px 0 0;
        }
    }

    &__c-help {
        @include breakpoint('tablet-portrait-max') {
            order: 1;
            padding: 0 16px; 
        }
    }

    &__info-box {
        padding-left: 25px;

        @include breakpoint('tablet-portrait-max') {
            background-color: $gray-ultralight;
            padding: 12px 16px;
        }

        p{
            text-align: justify;
            line-height: 1.5;
            color: $gray;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                @include font-size(11px);
            }
        }
    }

    &__help {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 20px;

        @include breakpoint('tablet-portrait-max') {
            margin: 0;
        }

        .btn {
            padding: 0 25px;
            @include font-size(13px);
        }
    }

    &__help-text {
        padding-right: 20px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            font-weight: bold;
        }
    }
}
