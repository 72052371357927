.searchbox-results {
    $search-gray: $gray-dark;
    $search-red: #f9413a;
    $search-yellow: #ffcb00;

    position: absolute;
    width: 94%;
    left: 3%;

    &.topquery {
        position: relative;
        left: auto;
        width: calc(100% - 5px);
        margin-top: 1px;

        h3 {
            padding-bottom: 10px;
            font-family: $muli-font-900;
        }

        .searchbox-results__title {
            font-family: $muli-font-700;
        }

        @include breakpoint('desktop-medium-min') {
            width: 697px;
        }
    }

    @include z-index('backdrop-up');

    @include breakpoint('tablet-portrait-max') {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        left: 0;
    }

    &__buttons {
        display: flex;
        background-color: $white;
        padding-bottom: 20px;

        @include breakpoint('tablet-portrait-min') {
            position: absolute;
            width: 80%;
            left: 1px;
            top: 1px;
            padding: 0;
        }

        @include breakpoint('tablet-landscape-min') {
            width: 70%;
        }
    }

    &__button {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        cursor: pointer;
        @include font-size(14px);

        &.not-active {
            color: $white;
            background-color: #b3dab1;

            &:hover {
                background-color: adjust-color(#b3dab1, $lightness: -10%);
            }

            &.btn-search-products {
                border-radius: 0 0 16px 0;
            }

            &.btn-search-magazine {
                border-radius: 0 0 0 16px;
            }
        }
    }

    &__content {
        display: flex;
        border-radius: 5px;
        background-color: $white;
        padding: 15px 20px;
        border: 2px solid $gray-light;
        overflow-y: auto;
        max-height: calc(var(--vh, 1vh) * 100 - 160px); //new topbar //new topbar

        @include breakpoint('tablet-portrait-max') {
            border-radius: 0;
            border: none;
            flex-direction: column;
            padding: 5px 16px 16px;
            max-height: calc(100vh - 279px); //new topbar
            max-height: calc(var(--vh, 1vh) * 100 - 279px); //new topbar
        }

        &.topquery {
            flex-direction: column;
        }
    }

    &__products, &__articles {
        width: 70%;
        order: 1;
        height: 100%;
        padding: 35px 0 45px;

        @include breakpoint('tablet-landscape-max') {
            width: 80%;
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            order: 2;
            padding: 0;
        }
    }

    &__categories {
        width: 30%;
        padding-left: 3%;
        order: 2;
        height: 100%;
        padding-bottom: 45px;

        @include breakpoint('tablet-landscape-max') {
            width: 20%;
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            order: 1;
            padding: 0 0 10px;
        }

        .categories-result {
            max-height: 250px;
            overflow-y: auto;
            @include overflow();

            @include breakpoint('tablet-portrait-max') {
                max-height: 50px;
                overflow-y: hidden;
                overflow-x: auto;
                white-space: nowrap;
            }

            .thirdcategories-search {
                padding: 7px 15px;
                margin: 0px 10px 10px 0;
                border: 1px solid $gray;
                background-color: $gray-ultralight;
                border-radius: 5px;
                width: max-content;
                display: inline-flex;
                cursor: pointer;
                color: $gray-dark;
                @include font-size(14px);

                @include breakpoint('tablet-portrait-max') {
                    padding: 8px;
                }
            }
        }
    }

    &__title {
        color: $search-gray;
        padding-bottom: 20px;

        @include breakpoint('phone-max') {
            padding-bottom: 10px;
        }
    }

    &__banner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
        flex-flow: column;

        img {
            max-width: 100%;
        }

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
    }

    .article-result {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        padding-right: 16px;
        cursor: pointer;
        @include font-size(14px);

        > span {
            display: flex;
            align-items: center;
        }

        img {
            width: 50px;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
        }
    }

    .no-article-result {
        display: flex;
        margin: 48px 0;
    }

    .product-result {
        display: flex;
        padding: 15px 25px 10px;
        border-bottom: 1px solid $gray-ultralight;
        border-radius: 2px;
        align-items: stretch;

        &__label-wrapper {
            display: inline-flex;
            width: 100%;
            justify-content: center;
        }

        &__label-product {
            color: $white;
            background-color: $new-product-box-background-color;
            padding: 4px 8px;
            border-radius: 2px;
            top: 8px;
            max-width: 110px;
            @include font-size(11px);

            @include breakpoint('phone-max') {
                border-radius: 2px 0px 0px 2px;
                align-self: flex-end;
                max-width: 90px;
            }

        }

        @include breakpoint('phone-max') {
            flex-flow: wrap;
            padding: 15px 0;
        }

        &:hover {
            background-color: rgba($search-yellow, 0.23);
            border-bottom: 1px solid $white;

            @include breakpoint('phone-max') {
                background-color: unset;
            }
        }

        &__image {
            display: flex;
            align-items: center;
            position: relative;

            @include breakpoint('phone-max') {
                flex: 0 0 20%;
                justify-content: center;
                display: flex;
            }

            &--item {
                width: 100px;
                height: 100px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 100px;
                    max-height: 100px;

                    @include breakpoint('phone-max') {
                        width: 77px;
                    }
                }

                .categorybadge {
                    position: absolute;
                    right: -10px;
                    top: 10px;

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        &__description {
            display: flex;
            flex: 1;
        }

        &__c-description {
            padding-left: 24px;
            display: flex;
            flex-direction: column;
            margin-right: auto;

            @include breakpoint('phone-max') {
                padding-left: 10%;
                margin-right: 10px;
            }
        }

        &__name {
            line-height: 1.5;
            margin-bottom: 6px;
            color: $gray-dark;

            @include breakpoint('phone-max') {
                line-height: 1.5;
                flex: 1;
                @include font-size(15px);
            }
        }

        &__brand {
            line-height: 1.5;
            margin-bottom: 5px;
            text-transform: uppercase;
            @include font-size(14px);

            @include breakpoint('phone-max') {
                line-height: 1.5;
                @include font-size(13px);
            }
        }

        &__weight {
            color: $gray;
            line-height: normal;
            @include font-size(13px);

            @include breakpoint('phone-max') {
                margin-bottom: 5px;
            }
        }

        &__promo {
            display: flex;
            margin-top: 4px;
            align-items: center;
        }

        &__c-prices {
            display: flex;
            flex: 1;
            align-items: center;
            flex-flow: column;
            justify-content: space-between;
            max-width: 150px;

            @include breakpoint('phone-max') {
                flex: none;
                align-items: flex-end;
            }
        }

        &__price-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 6px;
        }

        &__original {
            color: $search-red;
            text-decoration: line-through;
            @include font-size(18px);

            @include breakpoint('phone-max') {
                margin: -5px 0 5px;
                @include font-size(16px);
            }
        }

        &__price {
            font-weight: bold;
            @include font-size(22px);

            @include breakpoint('phone-max') {
                @include font-size(18px);
            }
        }

        &__actions {
            display: flex;
            flex-direction: column;
            flex: 2;
            max-width: 250px;
            margin-left: auto;
            justify-content: flex-end;

            @include breakpoint('phone-max') {
                max-width: none;
                flex: 0 0 100%;
                margin-top: 15px;
            }

            .c-dropdown {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;

                &__item {
                    flex: 1 0 auto;
                    width: 100%;
                    max-width: 117px;
                    height: 37px; //IE 11

                    @include breakpoint('phone-max') {
                        max-width: 140px;
                        height: 40px;
                    }

                    &:first-child {
                        margin-right: 5px;
                    }
                }

                .customselect {
                    height: 100%;
                    width: 100%;

                    &__list-item,
                    &__text {
                        @include font-size(13px);
                    }

                    &__button,
                    &__list-item {
                        padding: 2px 10px;
                    }
                }
            }

            .c-cta {
                width: 100%;

                .btn {
                    display: flex;
                    height: 40px;

                    @include breakpoint('phone-max') {
                        margin: 0 10px 8px;
                    }

                    .iconcart {
                        margin-right: 15px;
                    }

                    .btn-label.outofstock, .btn-label.lowstock {
                        margin-left: 15px;
                        @include hidden();
                    }
                }
            }

            .iconcart {
                width: 22px;
                height: 22px;
            }
        }

        &[data-stock="lowStock"] {
            .c-cta {
                .btn {
                    background-color: $white;
                    border-color: $green;
                    color: $green;

                    svg {
                        @include hidden();
                    }

                    .btn-label {
                        @include hidden();

                        &.lowstock {
                            @include visible();
                        }
                    }
                }
            }
        }

        &[data-stock="outOfStock"] {
            .c-cta {
                .btn {
                    background-color: $gray;
                    border-color: $gray;
                    cursor: default;
                    box-shadow: none;

                    svg {
                        @include hidden();
                    }

                    .btn-label {
                        @include hidden();

                        &.outofstock {
                            @include visible();
                        }
                    }
                }
            }
        }
    }

    .view-all {
        position: absolute;
        padding: 16px;
        background-color: white;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 2px;
        margin-left: 2px;
        margin-right: 20px;

        @include breakpoint('tablet-portrait-max') {
            position: sticky;
            margin: 0;
            bottom: 0;
            box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.2);
        }

        > div {
            width: 77%;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }
        }

        .btn {
            padding: 0 12px;
            height: 30px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                padding: 0;
                width: 100%;
                max-width: 345px;
                height: 36px;
            }
        }
    }

    li {
        display: flex;
        align-items: center;
        column-gap: 15px;
        padding-bottom: 10px;

        &:hover {
            cursor: pointer;
            color: $green;
        }
    }
}