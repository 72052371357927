.article-card,
.author-card,
.article-listing-card {
    border-radius: 4px;
    display: flex;
    width: 100%;
    position: relative;

    > a {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    &__c-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 160px;
        background-color: $white;
    }

    &__img {
        border-radius: 4px 4px 0 0 ;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__info {
        border-radius: 0 0 4px 4px;
        display: flex;
        flex: 1; //flex: 0 0 auto;
        background-color: $white;
        justify-content: space-between;
    }

    &__categories,
    &__job {
        margin-bottom: 5px;
        color: $gray-dark;
        @include font-size(14px);
    }

    &__categories {
        height: 28px;
    }

    &__title {
        height: 45px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        @include font-size(16px);
    }

    &__details {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
    }

    &__detail {
        display: flex;
        align-items: center;
        color: $gray;
        @include font-size(13px);

        span {
            padding-left: 5px;
        }
    }

    &__tags,
    &__description {
        color: $gray;
        margin-top: 4px;
        line-height: normal;
        @include font-size(13px);
    }
}

.article-card,
.author-card {
    &__info {
        flex-direction: column;
        padding: 24px 10px 16px;
    }

    &__tags,
    &__description {
        display: flex;
        flex   : 1;
        flex-flow: wrap;
    }
}

.article-listing-card {
    margin-bottom: 25px;

    .favourite-article {
        top: 16px;
        right: 16px;
    }

    @include breakpoint('phone-min') {
        width: 33.333333%;
        padding: 8px;
        margin-bottom: 0;
    }

    @include breakpoint('tablet-portrait-min') {
        width: 50%;
    }

    @include breakpoint('tablet-landscape-min') {
        width: 33.333333%;
    }

    @include breakpoint('desktop-small-min') {
        width: 25%;
    }

    &:hover {
        .article-listing-card__arrow {
            border-color: $green;
        }
    }

    &__c-img {
        height: 200px;
    }

    &__title {
        padding-right: 16px;
        
        @include breakpoint('phone-min') {
            padding-right: 0px;
        }

        @include breakpoint('tablet-portrait-min') {
            margin-bottom: 8px;
        }
    }

    &__info {
        padding: 20px 16px;
        align-items: center;
    }

    &__subtitle {
        line-height: 1.5;
        color: $gray-dark;
        padding-right: 16px;
        @include font-size(13px);

        @include breakpoint('phone-min') {
            height: 54px;
            padding: 0;
        }
    }

    &__arrow {
        margin: 12px 0 0 -12px;

        @include breakpoint('phone-min') {
            @include hidden();
        }
    }
}