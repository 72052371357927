/*! critical:start */
@mixin hidden() {
    margin: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
    opacity: 0;
}

@mixin visible() {
    position: relative;
    width: auto;
    height: auto;
    clip: auto;
    opacity: 1;
}

@mixin close() {
    visibility: hidden;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    border: 0;
    // transition: visibility .3s, opacity .3s ease-in-out;
}

@mixin open() {
    opacity: 1;
    visibility: visible;
    // transition: visibility .3s, opacity .3s ease-in-out;
}

@mixin hide() {
    display: none;
}
/*! critical:end */