.product-recommendation {
    .carousel-title {
        padding: 16px 64px 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 16px 0 0 16px;
        }
    }
}

#productReferencesPopup, #bundlePopup {
    @include breakpoint('tablet-portrait-min') {
        left: 3%;
        right: 3%;
        width: 94%;
    }

    @include breakpoint('desktop-large-min') {
        left: 50%;
        right: auto;
        width: 100%;
        margin-left: -800px;
        max-width: 1600px;
    }

    .popup__references-description {
        padding: 18px 60px;
        line-height: 1.5;

        @include breakpoint('tablet-portrait-max') {
            padding: 12px 16px;
        }
    }

    .popup__content {
        background-color: $gray-ultralight;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }
    }

    .products-carousel-container {
        padding: 0 0 20px;
    }

    .glide__track {
        padding-bottom: 85px;
        margin-bottom: -18px;
    }

    .glide__arrow--left {
        left: -45px;
    }

    .glide__arrow--right {
        right: -45px;
    }

    .customselect__list {
        max-height: 176px;
    }
}

#productReferences, #bundles {
    width: 100%;
    margin-bottom: 44px;
    
    .product-references-description {
        padding: 12px 65px;
        line-height: 1.5;

        @include breakpoint('tablet-portrait-max') {
            padding: 12px 16px;
        }

        &.btn {
            padding: 0 24px;
            margin: 0 65px;

            @include breakpoint('tablet-portrait-max') {
                margin: 16px;
            }

            &:hover {
                .arrow {
                    border: solid $green;
                    border-width: 0 2px 2px 0;
                }
            }
        }

        .arrow {
            margin-left: 12px;
        }
    }
}
