.wishlist-color {

    .account__title {
        @include breakpoint('tablet-portrait-max') {
            padding-left: 16px;
        }
    }
    
    input[type=radio] {
        padding-left: 20px;
        margin: 0 8px;

        &+label {
            padding-left: 40px;

            @include breakpoint('desktop-small-max') {
                padding-left: 30px;
            }

            @include breakpoint('tablet-landscape-max') {
                padding-left: 25px;
            }

            @include breakpoint('tablet-portrait-max') {
                padding-left: 40px;
            }
        }

        &+label:after {
            box-shadow: none;
            width: 16px;
            height: 16px;
            top: 4px;
            left: 4px;
            @include z-index(one);
        }

        &:checked+label:before {
            background-color: $white;
            width: 24px;
            height: 24px;
            top: 0;
            border: 0;
            left: 0;
        }
    }

    .customselect__list-item {
        &.yellow-wishlist {
            border-left: 10px solid $dark-blue;
        }

        &.blue-wishlist {
            border-left:10px solid $coal;
        }

        &.green-wishlist {
            border-left:10px solid $green-light;
        }

        &.pink-wishlist {
            border-left:10px solid $purple;
        }

        &.orange-wishlist {
            border-left:10px solid $orange-light;
        }

        &.default {
            border-left: 10px solid $red;
        }
    }

    input[type=radio],
    .wishlist-popup__content--item {
        &.yellow-wishlist {
            background-color: $dark-blue;
    
            i {
                color: $dark-blue;
            }
            
            &+label:after {
                background-color: $dark-blue;
            }
    
            &:checked+label:before {
                box-shadow: inset 0 0 0 2px $dark-blue;                 
            }
        }
    
        &.blue-wishlist {
            background-color: $coal;
    
            i {
                color: $coal;
            }
            
            &+label:after {
                background-color: $coal;
            }
    
            &:checked+label:before {
                box-shadow: inset 0 0 0 2px $coal;                        
            }
        }
    
        &.green-wishlist {
            background-color: $green-light;
    
            i {
                color: $green-light;
            }
            
            &+label:after {
                background-color: $green-light;
            }
    
            &:checked+label:before {
                box-shadow: inset 0 0 0 2px $green-light;                        
            }
        }
    
        &.pink-wishlist {
            background-color: $purple;
    
            i {
                color: $purple;
            }
    
            &+label:after {
                background-color: $purple;
            }
    
            &:checked+label:before {
                box-shadow: inset 0 0 0 2px $purple;                        
            }
        }
    
        &.orange-wishlist {
            background-color: $orange-light;
    
            i {
                color: $orange-light;
            }
    
            &+label:after {
                background-color: $orange-light;
            }
    
            &:checked+label:before {
                box-shadow: inset 0 0 0 2px $orange-light;                        
            }
        }

        &.default {
            background-color: $red;
    
            i {
                color: $red;
            }
        }
    } 
}

.wishlist {
    @include breakpoint('desktop-small-max') {
        @include font-size(14px);
    }

    &__paragraph {
        line-height: normal;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 16px    
        }
        
        .content {
            margin-bottom: 15px;
        }
    }

    .btn {
        padding: 12px 24px;

        @include breakpoint('desktop-small-max') {
            padding: 0 16px;
            height: 40px;
        }

        @include breakpoint('tablet-portrait-max') {
            padding: 0 24px;
        }

        &.long-btn {
            width: 280px;

            @include breakpoint('desktop-small-max') {
                width: 250px;
            }

            @include breakpoint('tablet-portrait-max') {
                width: auto;
                margin-left: 12px;
                padding: 0 38px;
            }
        }

        &.shopping-starts {
            border: 1px solid $green;
            color: $green;
        }

        >span {
            @include breakpoint ('tablet-portrait-max') {
                @include hidden();
            }
        }
    }

    .btn-transparent {
        padding: 0;
    }

    form {
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 75px;
        margin: 0 16px;

        @include breakpoint('tablet-portrait-max') {
            height: auto;
            flex-flow: column;
            align-items: unset;
            margin: 0;
            padding: 0 16px;
        }

        .form-group {
            width: 330px;
            position: relative;
            top: 15px;
            margin-right: 10px;

            @include breakpoint('desktop-small-max') {
                width: 180px;
            }

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
                margin-right: 0;
            }
        }

        ul {
            display: flex;
            flex-flow: row;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                margin: 10px 0;
            }
        }

        .iconclose {
            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }
    }

    .header-mobile-panel--red {
        @include breakpoint('tablet-portrait-max') {
            display: none;
        }

        @include breakpoint('tablet-portrait-max') {
            display: flex;
            position: fixed;
            width: 100%;
            left: 0;
            @include z-index(three);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 16px 16px;
        }

        @include breakpoint('tablet-portrait-max') {
            flex-flow: column;
        }

        &--sortby {
            display: flex;
            align-items: center;
        }

        label {
            padding-right: 10px;
            @include font-size(14px);

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }

        .customselect {
            width: 300px;

            @include breakpoint('desktop-small-max') {
                width: 250px;
            }

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
                margin-top: 16px;
            }
        }

        &--btn {
            display: inline-flex;
            align-items: center;
            color: $dark-blue;
            cursor: pointer;
            @include font-size(16px);

            svg {
                margin-right: 8px;
            }
            span {
                color: $gray-dark;
            }
        }

        &--item {
            border-radius: 8px;
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
            background-color: $white;
            margin-bottom: 24px;

            @include breakpoint('tablet-portrait-max') {
                margin: 0 16px 16px;
            }

            .buttons {
                display: flex;

                .btn {
                    margin-left: 10px;

                    @include breakpoint('desktop-small-max') {
                        width: 105px;
                    }
                }

                @include breakpoint('tablet-portrait-max') {
                    text-align: right;
                    display: block;
                    padding: 25px 0;
                    border-top: 1px solid $gray-light;
                }
            }
        }
    }

    &__item {
        margin-bottom: 24px;
        border-radius: 8px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: $white;

        @include breakpoint('tablet-portrait-max') {
            margin: 0 16px 16px;
        }

        .iconheart {
            color: transparent;
            cursor: pointer;

            @include breakpoint('tablet-portrait-max') {
                margin-left: auto;
            }
        }

        .iconemail {
            cursor: pointer;
            @include breakpoint('tablet-portrait-max') {
                margin: 0 18px;
            }
        }

        .iconshippinghome {
            color: $gray-light;
            opacity: 0.5;

            @include breakpoint('tablet-portrait-max') {
                width: 22px;
                height: 22px;
            }
        }

        .iconcart {
            width: 22px;
            height: 22px;
            margin-right: 12px;
            cursor: pointer;

            @include breakpoint('tablet-portrait-max') {
                margin: 0;
            }
        }

        .iconclose,
        .iconpencil,
        .iconsavelist {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        &.yellow-wishlist {
            border-left:5px solid $dark-blue;

            @include breakpoint ('tablet-portrait-max') {
                border-left: 8px solid $dark-blue;
            }
        }
    
        &.blue-wishlist {
            border-left:5px solid $coal;

            @include breakpoint ('tablet-portrait-max') {
                border-left: 8px solid $coal;
            }
        }
    
        &.green-wishlist {
            border-left:5px solid $green-light;

            @include breakpoint ('tablet-portrait-max') {
                border-left: 8px solid $green-light;
            }
        }
    
        &.pink-wishlist {
            border-left:5px solid $purple;

            @include breakpoint ('tablet-portrait-max') {
                border-left: 8px solid $purple;
            }
        }
    
        &.orange-wishlist {
            border-left:5px solid $orange-light;

            @include breakpoint ('tablet-portrait-max') {
                border-left: 8px solid $orange-light;
            }
        }

        &.default {
            border: 2px solid $red;
            border-left: 16px solid $red;

            @include breakpoint ('tablet-portrait-max') {
                border-left: 8px solid $red;
            }

            .iconheart {
                color: $red;
            }
        }

        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 16px 10px 24px;

            @include breakpoint('tablet-portrait-max') {
                flex-flow: column;
                align-items: unset;
                padding: 16px;
            }
        }

        &--content {
            display: flex;
            padding: 16px 24px;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
            background-color: rgba(216, 216, 216, 0);
            flex-flow: column;

            @include breakpoint('tablet-portrait-max') {
                margin-top: 45px;
                padding: 16px;
            }

            >div {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
            }

            .filter-container {
                @include breakpoint('tablet-portrait-max') {
                    flex-flow: column;
                }
            }

            .select-promo {
                display: inline-flex;
                align-items: center;
                font-weight: bold;
                @include font-size(14px);

                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                    order: 1;
                    margin-top: 12px;
                }

                svg {
                    cursor: pointer;
                    width: 50px;
                    height: 30px;
                    margin-right: 15px;
                }
            }

            .customselect {
                width: 280px;

                @include breakpoint('desktop-small-max') {
                    width: 220px;
                }

                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                    margin-top: 12px;
                }
            }

            .sort-by {
                margin-left: auto;
                display: flex;
                align-items: center;

                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                    order: 0;
                }

                label {
                    padding-right: 10px;
                    @include font-size(14px);

                    @include breakpoint('tablet-portrait-max') {
                        @include hidden();
                    }
                }
            }

            form {
                margin: 0;
                width: 100%;
                height: 35px;

                @include breakpoint('tablet-portrait-max') {
                    height: auto;
                    padding: 0;
                }
            }
        }

        &--title {
            display: flex;
            flex-flow: column;
            width: 40%;
            cursor: pointer;
            @include font-size(18px);
            
            > h2 {
                font-size: inherit;
            }

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }

            .last-edit {
                color: $gray;
                margin-top: 8px;
                @include font-size(13px);

                @include breakpoint('tablet-portrait-max') {
                    margin-top: 12px;
                }
            }
        }

        &--container {
            @include breakpoint ('tablet-portrait-max') {
                position: fixed;
                top: 0;
                height: 100vh;
                max-height: calc(var(--vh, 1vh) * 100);
                background: white;
                right: 0;
                left: 0;
                overflow-y: auto;
                @include z-index(panel-mobile);

                &.yellow-wishlist {
                    border-left:8px solid $dark-blue;
                }
        
                &.blue-wishlist {
                    border-left:8px solid $coal;
                }
        
                &.green-wishlist {
                    border-left:8px solid $green-light;
                }
        
                &.pink-wishlist {
                    border-left:8px solid $purple;
                }
        
                &.orange-wishlist {
                    border-left:8px solid $orange-light;
                }

                &.default {
                    border-left: 8px solid $red;
                }
            }
        }

        &--action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 440px;

            @include breakpoint('tablet-portrait-max') {
                margin-top: 16px;
                width: 100%;
            }

            hr {
                width: 1px;
                height: 20px;
                background-color: $gray;

                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }
        }

        &--footer {
            display: flex;
            justify-content: space-between;
            margin: 0 24px;
            border-top: 1px solid $gray-light;
            padding: 24px 0;            

            @include breakpoint ('tablet-portrait-max') {
                padding: 26px 16px;
                position: fixed;
                bottom: 0;
                background: white;
                right: 0;
                left: 0;
                border: 0;
                box-shadow: 0 -2px 32px 0 rgba(0, 0, 0, 0.12);
                margin: 0;
                justify-content: space-between;
            }

            .remove {
                padding-left: 5px;
                background-color: transparent;
                border: none;
                @include font-size(13px);

                @include breakpoint ('tablet-portrait-max') {
                    padding: 0;
                }
            }

            .buttons {
                width: 375px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: right;

                .share {
                    background-color: transparent;
                    border: none;
                }

                &:first-child {
                    margin-right: 10px;
                }

                @include breakpoint ('tablet-portrait-max') {
                    width: 340px;
                }

                @include breakpoint ('tablet-portrait-max') {
                    width: auto;
                }

                @include breakpoint ('desktop-small-min') {
                    width: 420px;
                }
                
            }
        }
    }

    &__category {
        padding: 12px 24px 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
            margin-bottom: 90px;
        }

        &--header {
            padding: 12px 0 15px;
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                border-bottom: 1px solid $gray-light;
                margin: 8px 16px 16px;
            }

            .title {
                width: 45%;
                display: flex;
                align-items: center;

                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                }

                img {
                    max-width: 30px;
                    margin-right: 8px;

                    @include breakpoint('tablet-portrait-max') {
                        max-width: 30px;
                    }
                }
            }

            .price-unit {
                width: 33%;
            }

            .feature {
                width: 22%;
                min-width: 145px;
            }

            .feature,
            .price-unit {
                color: $gray;
                @include font-size(13px);

                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }
        }

        &--container {
            padding-bottom: 15px;

            @include breakpoint('tablet-portrait-max') {
                padding-bottom: 6px;
                border-bottom: 1px solid $gray-light;
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__product {
        border-top: 1px solid $gray-light;
        display: flex;
        height: 130px;

        @include breakpoint('tablet-portrait-max') {
            flex-flow: column;
            height: auto;
            position: relative;
            order: 2;
        }

        &--promo-header-mobile {
            position: relative;
            box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.12);
            order: 1;

            .arrow {
                border: solid $red; 
                border-width: 0 2px 2px 0;
            }

            .js-accordion-selector {
                padding: 12px 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                border-top: 1px solid $gray-light;
                color: $red;
                cursor: pointer;
                @include font-size(14px);
            }

            .js-accordion-list {
                position: absolute;
                top: 100%;
                background-color: $white;
                left: 0;
                border-top: 1px solid $gray-ultralight;
                display: flex;
                align-items: flex-end;
                padding: 16px;
                min-height: 65px;
                justify-content: space-between;
                width: 100%;
                box-shadow: 8px 4px 16px 0 rgba(0,0,0,.12);
                @include z-index(one);
            }

            &.accordion-opened {
                .js-accordion-selector {
                    color: $gray-dark;
                }

                .arrow {
                    border: solid $gray-dark; 
                    border-width: 0 2px 2px 0;
                }
            }
        }

        &--left {
            display: flex;
            width: 45%;
            padding: 16px 0;

            @include breakpoint('tablet-portrait-max') {
                width: 75%;
                position: relative;
                padding: 16px 0 50px 16px;
            }
        }

        &--center {
            width: 22%;
            padding: 16px 30px 16px 0;
            min-width: 145px;

            @include breakpoint('tablet-portrait-max') {
                width: auto;
                border-top: 1px solid $gray-light;
                padding: 14px 0;
                margin: 0 16px;
                min-width: unset;
            }
        }

        &--right {
            display: flex;
            width: 33%;
            padding: 16px 0;

            @include breakpoint('tablet-portrait-max') {
                padding: 0;
            }
        }

        &--image {
            height: 100px;
            width: 100px;
            display: flex;
            align-items: center;
            position: relative;

            @include breakpoint('tablet-portrait-max') {
                height: 60px;
                width: 60px;
            }

            .categorybadge {
                width: 30px;
                position: absolute;
                top: 0px;
                right: -10px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        &--description {
            padding: 0 2% 0 8%;
            display: flex;
            flex-flow: column;
            max-width: 80%;
            @include font-size(14px);

            @include breakpoint('tablet-portrait-max') {
                padding-top: 4px;
                width: 100%;
                @include font-size(12px);
            }

            >div {
                margin-top: 8px;
            }
        }

        &--info {
            color: $gray;
            @include font-size(13px);
        }

        &--brand {
            text-transform: uppercase;
            @include font-size(13px);
        }

        &--note {
            display: flex;
            align-items: center;
            @include font-size(13px);

            .iconnota,
            .iconchange-nota {
                width: 18px;
                height: 18px;
                margin-right: 5px;

                @include breakpoint('tablet-portrait-max') {
                    width: 20px;
                    height: 20px;
                }
            }

            .preview-note {
                font-style: italic;
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        
            .ellipsis-note {
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 0.08em;
            }

            @include breakpoint('tablet-portrait-max') {
                position: absolute;
                bottom: 10px;
                left: 16px;
            }

            .notes {
                width: 140px;
                margin-right: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;

                @include breakpoint('tablet-portrait-max') {
                    @include font-size(12px);
                }
            }
        }

        &--quantity {
            display: flex;
            flex-flow: column;

            @include breakpoint ('tablet-portrait-max') {
                flex-flow: row;
                justify-content: flex-end;
            }

            >div,
            select {
                width: 100%;
                margin-bottom: 6px;
                @include font-size(13px);

                @include breakpoint ('tablet-portrait-max') {
                    width: 25%;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    height: 38px;
                    align-items: center;
                    margin-bottom: 0;

                    .current {
                        padding-top: 8px;
                        @include font-size(20px);
                    }
                }
            }

            .product-qnt__container,
            .customselect {
                height: 30px;
                flex-flow: row;
                align-items: unset;

                &__list-item,
                &__text {
                    @include font-size(13px);
                }

                &__button,
                &__list-item {
                    padding: 2px 10px;
                }

                .product-qnt__less,
                .product-qnt__plus {
                    @include font-size(20px);
                }

                @include breakpoint ('tablet-portrait-max') {
                    width: 40%;
                    margin: 0 1%;
                    height: 40px;
                }
            }

            .product-qnt__container {
                @include breakpoint ('tablet-portrait-max') {
                    width: 20%;
                    min-width: 70px;
                }
            }
        }

        &--summary {
            width: 100%;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }

            >div {
                margin-bottom: 8px;   
            }
        }

        &--promo {
            @include breakpoint('tablet-portrait-max') {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }

        &--price {
            .current {
                font-weight: bold;
                @include font-size (20px);    
            }
        }

        &--action {
            display: inline-flex;
            flex-flow: column;
            align-items: center;
            justify-content: space-around;
            padding-bottom: 10px;

            .current {
                @include font-size(20px);
            }

            .btn {
                padding: 6px 20px;
            }

            @include breakpoint('tablet-portrait-max') {
                position: absolute;
                top: 15px;
                right: 15px;
                height: 108px;
                padding: 0;
            }

            &.trash {
                justify-content: center;

                @include breakpoint('tablet-portrait-max') {
                    justify-content: space-around;
                    height: 120px;
                }
            }

            hr {
                width: 100%;

                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }

            .iconcart {
                margin: 0;
            }

            .tooltip {
                background-color: transparent;
                border: none;
            }
        }

        &--unavailable {
            display: none;
        }

        &[data-stock="outOfStock"] {
            .wishlist__product--left,
            .wishlist__product--center {
                filter: grayscale(1);
                opacity: 0.6;
            }
            
            .wishlist__product--quantity,
            .wishlist__product--summary,
            .wishlist__product--action,
            + .wishlist__product--promo-header-mobile {
                display: none;
            }
    
            .wishlist__product--action.trash,
            .wishlist__product--unavailable {
                display: inline-flex;
                margin: 0 0 0 auto;
            }
        }
    }
}