.skeleton {
  background-color: #f0f0f0;
  animation: shine 2s infinite ease-in-out;
}

@keyframes shine {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}