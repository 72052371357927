.press-release {
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    border: 2px solid $white;
    background-color: $white;
    min-height: 145px;
    display: flex;
    flex-direction: column;

    @include breakpoint('tablet-portrait-min') {
        padding: 16px;
        min-height: 130px;
    }

    &--evidence {
        border: 2px solid $green;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
    }

    &__date {
        @include font-size(18px);
    }

    &__title {
        font-weight: bold;
        padding-bottom: 6px;
        flex: 1 0 auto;
        @include font-size(16px);

    }

    &__text {
        @include font-size(16px);
    }
}