// Test Button

$btn-mobile-sizes: (
    'lg': 50px,
    'md': 40px,
    'sm': 36px,
    'xs': 30px,
);

@function btn-mobile($key) {
    @return map-get($btn-mobile-sizes, $key);
}

@mixin btn-mobile($key) {
    & {
        height: btn-mobile($key);
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $gray-dark;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: solid 1px $gray;
    background-color: $white;
    line-height: 1.5;
    border-radius: 5px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    @include font-size(16px);

    @include breakpoint('tablet-portrait-max') {
        @include font-size(14px);
    }
    
    &:hover,
    &:focus {
        color: $green;
        border-color: $green;
        text-decoration: none;
    }

    &:active,
    &.active {
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        color: $green;
        border-color: $green;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: default;
        box-shadow: none;
        opacity: 0.6;
        pointer-events: none;
    }

    &--lg {
        height: 50px;
    }

    &--md {
        height: 40px; 
    }

    &--sm {
        height: 30px;
    }
}

@mixin btn($primary, $background, $background-active, $border, $border-active) {
    color: $primary;
    background-color: $background;
    border-color: $border;

    &:focus {
        color:  $primary;
        background-color: $background-active;
        border-color: $border-active;
    }
    &:hover {
        color: $primary;
        background-color: $background-active;
        border-color: $border-active;
    }
    &:active,
    &.active {
        color: $primary;
        background-color: $background-active;
        background-image: none;
        border-color: $border-active;

        &:hover,
        &:focus {
            color: $primary;
            background-color: $background-active;
            border-color: $border-active;
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        background-color: $gray-light;
        border-color: $gray-light;
        opacity: 1;

        &:hover,
        &:focus {
            background-color: $gray-light;
            border-color: $gray-light;
        }
    }
}

.btn-green {
    @include btn($white, $green, $green-dark, $green, $green-dark);
}
  
.btn-red {
    @include btn($white, $red, $red-dark, $red, $red-dark);
}

.btn-blue {
    @include btn($white,$blue, $blue, $blue, $blue);
}

.btn-transparent {
    color: $gray;
    background-color: transparent;
    border: none;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &--dark {
        color: $gray-dark;
    }

    &:focus,
    &:hover{
        color:  $green;
        border: none;
    }
    
    &:active,
    &.active {
        color: $green;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;

        &:hover,
        &:focus {
            color: $green;
            border: none;
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            opacity: 0.6;
            cursor: default;
        }
    }
}