.faq {

    &__title {
        @include font-size(20px);

        @include breakpoint('tablet-portrait-max') {
            padding: 0 24px;
            @include font-size(18px);
        }
    }

    &__c-info {
        p {
            padding-top: 25px;
            line-height: 1.5;
            @include font-size(14px);
    
            .icondrive-text,
            .iconhome-text,
            .iconbennet-text {
                margin-bottom: -6px;
                position: relative;
    
                @include breakpoint('tablet-portrait-max') {
                    margin-bottom: -5px;
                }
            } 
        }

        @include breakpoint('tablet-portrait-max') {
            padding: 0 24px;
        }
    }

    &__content {
        @include breakpoint('tablet-portrait-max') {
            padding: 0 24px;
        }
    }

    .accordion-opened {
        .faq__question,
        .arrow {
            color: $gray-dark;
            border-color: $gray-dark;
        }
    }

    &__container {
        padding: 24px 0;
        border-bottom: solid 2px $gray-light;

        .js-accordion-selector{
            line-height: 1.64;

            .category-title{
                font-weight: 500;
                color: $green;
                @include font-size(14px);
   
                @include breakpoint ('tablet-portrait-max') {
                    @include font-size(13px);
                }
            }
        }
    }

    &__question {
        color: $gray;
        display: flex;
        align-items: center;
        cursor: pointer;

        b {
            margin-right: 10px;

            @include breakpoint ('tablet-portrait-max') {
                margin-right: 40px;
            }
        }

        .arrow {
            margin-left: auto;
            margin-right: 3px;
            border-color: $gray;
        }
    }

    &__response {
        padding: 25px 24px 0;
        line-height: normal;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            padding: 25px 15px 0 0;  
            @include font-size(13px);       
        }

        .extra-content{
            margin-top: 16px;

            .radio-buttons{
                margin-top: 16px;

                label{
                    padding-left: 26px;
                    margin-right: 60px;
                }

                .js-first-copy{
                    margin-top: 8px;
                }

                .js-second-copy{
                    margin-top: 8px;
                    display: flex;

                    @include breakpoint ('tablet-portrait-max') {
                        flex-wrap: wrap;
                        p:first-child{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__c-banner {
        margin: 40px 0;
        position: relative;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    
        @include breakpoint('tablet-portrait-max') {
            margin: 25px 0;
            border-top: 2px solid $gray-ultralight-rgb-8;            
        }
    }

    .banner-component__img,
    .banner__component {
        width: 100%;
    
        img {
            width: 100%;
            height: auto;
        }
    
        a {
            flex: 1;
            display: flex;
        }
    }
}


