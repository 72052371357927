.reward-details {
    &__ctn {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;

        @include breakpoint('tablet-portrait-min') {
            flex-direction: row;
            padding: 0 65px 35px;
            align-items: flex-start;
        }
    }

    &__c-img {
        @include breakpoint('tablet-portrait-min') {
            width: 26%;
        }
    }

    &__img {
        @include breakpoint('tablet-portrait-max') {
            width: 56%;
            margin: 0 auto;
            max-width: 400px;
            display: block;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 28px 16px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 0 0 55px;
        }
    }

    &__threshold {
        color: $green;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
            padding-bottom: 35px;
        }
    }

    &__threshold-label {
        padding-left: 5px;
    }

    &__title {
        min-height: 52px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            min-height: 78px;
            @include font-size(28px);
        }
    }

    &__brand {
        min-height: 38px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            min-height: 55px;
            @include font-size(22px);
        }
    }

    &__details {
        color: $gray-light;
        min-height: 28px;

        @include breakpoint('tablet-portrait-min') {
            min-height: 45px;
        }
    }

    &__info-points {
        @include font-size(30px);
    }

    &__popup-cta,
    &__store {
        display: flex;
        align-items: center;
        margin: 36px auto 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 40px 0 0;
        }
    }

    &__popup-cta {
        cursor: pointer;

        &:hover {
            color: $green;
            border-color: $green;
        }
    }

    &__popup-cta-label,
    &__store-label {
        padding: 0 10px 0 15px;
        @include font-size(16px);
    }

    &__ctas {
        display: flex;
        align-items: center;
        margin: 25px auto 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 -12px 0 auto;
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        font: inherit;
        margin: 0 12px;

        span {
            padding-top: 15px;
            @include font-size(16px);
        }

        .share {
            display: flex;

            &__cta {
                margin: 5px 10px;
            }
        }
    }

    &__tabs {
        padding-bottom: 20px;
        overflow: hidden;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 65px 20px;
        }
    }

    &__c-tab {

        .js-accordion-list {
            @include breakpoint('tablet-portrait-min') {
                @include open();
            }
        }
    }

    &__tab-title {
        padding: 20px;
        cursor: pointer;

        @include breakpoint('tablet-portrait-min') {
            font-weight: bold;
            border: none;
            padding: 0;
            pointer-events: none;
            @include font-size(24px);
        }
    }

    &__text {
        padding: 28px;
        line-height: 1.6;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            padding: 28px 0;
        }

        ul, ol {
            display: block;
            margin: 1em 0;
            padding: 0 0 0 40px;
        }

        ul {
            list-style-type: disc;
            }

        ol {
            list-style-type: decimal;
        }

        li {
            list-style: inherit;
        }
    }
}

.order-book-reward {
    .header-mobile-panel__title {
        @include breakpoint('tablet-portrait-max') {
            padding-left: 0;
        }
    }

    &__content {
        padding: 0;
    }

    &__c-info {
        display: flex;
        padding: 20px 16px;
        flex-direction: column;

        @include breakpoint('phone-min') {
            flex-direction: row;
            align-items: flex-start;
        }

        @include breakpoint('tablet-portrait-min') {
            padding: 18px 60px;
        }
    }

    &__text {
        @include font-size(13px);

        @include breakpoint('phone-min') {
            padding-right: 20px;
        }

        @include breakpoint('tablet-portrait-min') {
            width: 55%;
            @include font-size(14px);
        }
    }
    
    &__img {
        width: 200px;
        margin: 18px auto 0;

        @include breakpoint('phone-min') {
            width: 250px;
            min-width: 250px;
            margin: 0;
        }

        @include breakpoint('tablet-portrait-min') {
            width: 45%;
            min-width: unset;
        }
    }

    form {
        max-width: none;
    }

    &__form-wrapper {
        overflow-y: auto;
        max-height: 70vh;

        @include breakpoint("tablet-portrait-max") {
            max-height: none;
            // 100vh - footer - header
            height: calc(100vh - 122px);
            height: calc(var(--vh, 1vh) * 100 - 122px);
        }
    }

    &__c-row {
        overflow: hidden;
    }

    .row {
        padding: 0 16px 10px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 60px 10px;
        }

        &__receipts {
            display: flex;
            margin: 0 -5px;
            align-items: center;

            .form-group,
            .form-separator {
                margin: 0 5px 30px;
            }

            .form-group {
                flex: 1;
            }
        }
    }

    &__receipt-code {
        padding-bottom: 10px;
    }

    &__footer .btn {
        @include breakpoint('tablet-portrait-min') {
            width: auto;
            min-width: 175px;
            margin: 0 auto;
        }
    }
}

.response-reward {
    &__footer .btn {
        @include breakpoint('tablet-portrait-min') {
            margin: 0 auto;
        }
    }
}