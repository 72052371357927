.categories-carousel {
    @include breakpoint("tablet-portrait-min") {
        padding-left: 64px;
        padding-right: 64px;
    }

    &__title {
        padding-left: 16px;
        @include font-size(18px);

        @include breakpoint("tablet-portrait-min") {
            padding: 0;
        }
    }

    &__carousel {
        padding-top: 24px;

        > div:not(.glide--disabled) {
            @include breakpoint("tablet-portrait-min") {
                padding: 0 50px;
            }
        }

        .glide {
            &__track {
                margin: -25px 0;
                padding: 25px 0;
            }

            &__arrow--left {
                left: -45px;
            }

            &__arrow--right {
                right: -45px;
            }
        }

        .glide--disabled {
            padding: 0 16px;

            @include breakpoint("tablet-portrait-min") {
                padding: 0;
            }
        
            .glide {
                &__track {
                    overflow: initial;
                }

                &__slides {
                    width: 100% !important;
                    transform: none !important;
                }

                &__slide {
                    flex: 1;
                }
            }
        }
    }
}
