.press-productrecallnotice {
    padding: 0 16px 24px;
    overflow: hidden;
    display: flex;
    flex-flow: column;

    @include breakpoint('tablet-portrait-min') {
        padding: 0 64px 60px;
    }

    .landing-rewards__banner {
        margin: 24px 0 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 16px 0 0;
        }
    }

    &__title {
        padding-top: 24px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding-top: 30px;
            @include font-size(24px);
        }
    }

    &__paragraph {
        padding-top: 8px;
        line-height: 1.5;
    }

    &__noresult {
        padding-top: 24px;
    }

    &__content {
        display: flex;
        flex-flow: wrap;
        margin: 16px 0 20px;

        @include breakpoint('phone-min') {
            margin-left: -8px;
            margin-right: -8px;
        }

        @include breakpoint('tablet-portrait-min') {
            margin-top: 8px;
        }

        .press-release {
            margin: 8px 0;
            width: 100%;

            @include breakpoint('phone-min') {
                width: calc(50% - 16px);
                margin: 8px;
            }

            @include breakpoint('tablet-portrait-min') {
                width: calc(33.333% - 16px);
            }
        }
    }

    &__view-all {
        width: 106px;
        margin: 0 auto;
        @include font-size(16px);
    }
}