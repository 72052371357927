.account.content section.account-section--favourite {
    @include breakpoint('tablet-portrait-min') {
        padding-left: 0;
    }
}

.favourite-authors,
.favourite-articles,
.favourite-awards {
    overflow: hidden;
    position: relative;

    .account__title {
        padding-left: 45px;
        background-color: $white;

        @include breakpoint('tablet-portrait-max') {
            padding-left: 16px;
        }
    }

    &__ctn {
        padding: 0 16px 16px;
        margin: 0 -16px;
        display: flex;
        flex-flow: wrap;

        @include breakpoint('tablet-portrait-min') {
            padding-left: 45px;
            margin: 0 -8px;
        }
    }

    &__no-result {
        padding: 16px;
        > h2 {
            font-size: inherit;
        }
        @include breakpoint('tablet-portrait-min') {    
            padding: 16px 10px;
        }
    }
}

.favourite-awards {
    background-color: $gray-ultralight;
    height: 100%;

    &__ctn {
        @include breakpoint('tablet-portrait-min') {    
            padding-top: 8px;
        }
    }

    .award-card {
        margin: 12px 16px;
        width: 100%;

        @include breakpoint('phone-min') {
            width: calc(50% - 32px);
            margin: 16px;
        }

        @include breakpoint('tablet-portrait-min') {
            width: calc(50% - 16px);
            margin: 8px;
        }
    }
}

.favourite-articles__ctn {
    background-color: $gray-ultralight;

    @include breakpoint('tablet-portrait-min') {
        background-color: transparent;
    }

    .article-card {
        margin: 12px 16px;
        background-color: $white;
        width: 100%;

        @include breakpoint('phone-min') {
            width: calc(50% - 32px);
            margin: 16px;
        }
    
        @include breakpoint('tablet-portrait-min') {
            margin: 8px;
            width: calc(50% - 16px);
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(110, 110, 110, 0.19);
        }

        @include breakpoint('desktop-small-min') {
            width: calc(33.333% - 16px);
        }

        @include breakpoint('desktop-medium-min') {
            width: calc(25% - 16px);
        }

        &__info {
            padding: 22px 16px 16px;
        }

        &__categories {
            height: 15px;
        }

        &__title {
            > h2 {
                font-size: inherit;
            }
            @include breakpoint('tablet-portrait-min') {
                height: 44px;
            }
        }

        &__details {
            padding-top: 10px;
            margin: 0 -6px;
            flex-flow: wrap;

            @include breakpoint('tablet-portrait-min') {
                padding-top: 5px;
            }
        }

        .article__detail {
            padding: 0 4px 6px;

            @include breakpoint('tablet-portrait-min') {
                padding: 10px 4px 0;
            }

            svg {
                @include breakpoint('tablet-portrait-min') {
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .favourite-article {
            right: 16px;
            top: 16px;
        }
    }
}

.favourite-author {
    display: flex;
    border-radius: 4px;
    box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    padding: 20px 18px;
    align-items: center;
    margin: 12px 16px;
    width: 100%;

    .favourite-article {
        width: 32px;
        height: 32px;
        right: -8px;
        top: -5px;

        svg {
            height: 18px;
        }
    }

    @include breakpoint('phone-min') {
        width: calc(50% - 32px);
        margin: 16px;
    }

    @include breakpoint('tablet-portrait-min') {
        margin: 8px;
        width: calc(50% - 16px);
        padding: 25px 16px;
    }

    @include breakpoint('desktop-small-min') {
        width: calc(33.333% - 16px);
    }

    &__c-img {
        width: 90px;
        height: 90px;
        min-width: 90px;
        position: relative;

        @include breakpoint('tablet-portrait-min') {
            width: 75px;
            height: 75px;
            min-width: 75px;
        }
    }

    &__img {
        border-radius: 100%;
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-left: 22px;

        @include breakpoint('tablet-portrait-min') {
            padding-left: 15px;
        }
    }

    &__title {
        line-height: normal;
        > h2 {
            font-size: inherit;
        }
        
        @include font-size(16px);
    }

    &__job {
        padding-top: 8px;

        @include breakpoint('tablet-portrait-min') {
            padding-top: 10px;
            @include font-size(13px);
        }
    }

    &__cta {
        margin-top: 16px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
            margin-top: 24px;
        }
    }
}