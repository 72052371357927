.bennet-banner {
    padding: 16px;
    // Utility

    @include breakpoint('tablet-portrait-min') {
        padding: 20px 65px;
        clear: both;
        width: 100%;
    }

    &__title {
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding-bottom: 6px;
        }
    }

    &__ctn {

        @include breakpoint('tablet-portrait-min') {
            display: flex;
            margin: 0 -12px;
            flex-flow: wrap;
        }
    }

    &__item {
        padding: 0;
        width: 100%;

        &:last-child {
            padding-bottom: 0;
        }

        &--ONEONFOUR {
            padding: 0;
        }

        @include breakpoint('tablet-portrait-min') {
            padding: 0 12px;

            &--ONEONTWO {
                width: 50%;
            }

            &--ONEONFOUR {
                width: 25%;
            }

            &--ONEONTHREE {
                width: 33.333%;
            }
    
            &--TWOONTHREE {
                width: 66.666%;
            }
    
            &--THREETHREE {
                width: 100%;
                padding-bottom: 12px;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    &__smartedit {
        width: 100%;
    }
}

.bennet-banner-carousel {
    .glide {
        &__arrow {
            &--left {
                left: 24px;
            }

            &--right {
                right: 24px;
            }
        }
    }
}