.promotions {
    padding-bottom: 20px;

    .breadcrumbs {
        @include breakpoint('tablet-portrait-min') {
            padding-left: 42px;
        }       
    }

    &__header {
        margin: 16px;

        @include breakpoint('tablet-portrait-min') {
            margin: 20px 50px;
        }
    }

    &__c-header {
        @include breakpoint('tablet-portrait-min') {
            display: flex;
        }
    }

    &__title {
        margin-bottom: 20px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            font-weight: bold;
            margin: 0;
            display: inline-flex;
            flex: 1;
            @include font-size(20px);
        }

        h1 {
            display: inline-flex;
        }
    }

    &__pos {
        font-weight: bold;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            margin-left: auto;
        }
    }

    &__change-pos {
        cursor: pointer;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            margin-right: 20px;
        }
    }

    &__description {
        margin-top: 25px;
        line-height: 1.5;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            width: 50%;
        }
    }

    &__c-flyers-carousel {
        margin: 12px 0;
        background-color: $gray-ultralight;
        padding: 24px 0;
    }

    &__carousel-title {
        padding: 0 16px 24px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0 65px 20px;
        }
    }

    &__flyers-carousel {
        .glide__slides {
            margin: 0 auto;
        }
        
        .glide__bullets {
            margin-top: 12px;
        }

        @include breakpoint('tablet-portrait-min') {
            padding: 0 64px;

            .glide {
                &__arrow--right {
                    right: -50px;
                }
                
                &__arrow--left {
                    left: -50px;
                }
            }
        }
    }

    .flyer-banner {
        border-radius: 4px;

        &__img {
            border-radius: 4px ;
        }
    }

    .flyer-banner__c-img {
        a {
            display: flex;

            &:last-child {
                margin-top: 12px;
            }
        }
    }

    .banners-carousel-container {
        padding: 12px 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 12px 8px;
        }
    }

    .promotions-single-banner {
        padding: 8px 65px;

        @include breakpoint('tablet-portrait-max') {
            padding: 8px;
        }
    }

    .products-carousel {
        .carousel-title {
            padding: 0 65px 24px;
            font-weight: normal;
            color: $red;

            @include breakpoint('tablet-portrait-max') {
                padding-left: 16px;
            }
        }

        .products-carousel-container {
            padding-bottom: 20px;

            @include breakpoint('tablet-portrait-min') {
                padding: 24px 110px 20px;
            }
        }

        .glide {
            &__arrow--right {
                right: -50px;
            }
            &__arrow--left {
                left: -50px;
            }
        }
    }

    .bennet-banner {
        padding-top: 12px;
        padding-bottom: 12px;

        @include breakpoint('tablet-portrait-max') {
            padding: 4px 8px 12px;
        }

        &__item {
            @include breakpoint('tablet-portrait-min') {
                padding: 0 12px;
            }
        }
    }
}