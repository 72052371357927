.header {
    transition: all 0.3s ease-in-out;
    transform: translateY(0);
    $this: &;

    &.translate {
        transition: all 0.3s ease-in-out;
        transform: translateY(-96px); //new topbar
        
        //new topbar

        // & ~ .modal {
        //     top: 85px;
        //     max-height: calc(100vh - 85px);
        // }

        //new topbar
        @include breakpoint('tablet-portrait-max') {
            transition: all 0.3s ease-in-out;
            transform: translateY(-30px);
        }

        & ~ .global-alerts {
            transition: all 0.3s ease-in-out;
            transform: translateY(-96px);
            
            //new topbar
            @include breakpoint('tablet-portrait-max') {
                transition: all 0.3s ease-in-out;
                transform: translateY(-30px);
            }
        }

        & ~ .reminders {
            transition: all 0.3s ease-in-out;
            transform: translateY(-96px);
            
            //new topbar
            @include breakpoint('tablet-portrait-max') {
                transition: all 0.3s ease-in-out;
                transform: translateY(-30px);
            }
        }

        #{$this}__bottom--logo {
            @include breakpoint('tablet-portrait-min') {
                bottom: 30px;
                transition: all 0.3s ease-in-out;
            }

            svg {
                color: $red;
                width: 170px;

                @include breakpoint('desktop-small-max') {
                    width: 150px;
                }

                @include breakpoint('tablet-landscape-max') {
                    width: 130px;
                }

                @include breakpoint('tablet-portrait-max') {
                    color: $white;
                }
            }
        }

        .backdrop,
        .minicart-panel {
            transform: translateY(96px); //new topbar
        }

        .searchbox-results__content {
            max-height: calc(100vh - 70px);

            @include breakpoint('tablet-portrait-max') {
                max-height: calc(100vh - 249px); //new topbar
                max-height: calc(var(--vh, 1vh) * 100 - 249px); //new topbar
            }
        }
        .right-panel {
            @include breakpoint('tablet-landscape-max') {
                top: 30px;
            }
        }

        #main-menu {
            .nav__panel {
                max-height: calc(100vh - 65px);

                @include breakpoint('tablet-landscape-max') {
                    max-height: 100vh;
                    max-height: calc(var(--vh, 1vh) * 100);
                    margin-top: 96px;
                    //transform: translateY(+56px); //new topbar
                    // CSS specific to iOS devices
                    @supports (-webkit-touch-callout: none) {
                        max-height: calc(var(--vh, 1vh) * 100 + 32px);
                    }
                }

                @include breakpoint('tablet-portrait-max') {
                    margin-top: 30px; //new topbar
                }      
            }

            .nav__mobile {
                top: 30px; //new topbar
            }

            .icon-close {
                @include breakpoint('tablet-landscape-max') {
                    margin-top: 96px; //new topbar
                }
            }
        }
    }

    .backdrop {
        transform: translateY(0);
    }

    &__bottom--logo {
        svg {
            transition: all 0.3s ease-in-out;
        }
    }
}
