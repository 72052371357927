.list-container, .search-container {
    display: flex;
    background-color: $gray-ultralight;

    &.nosearch {
        height: 400px;
    }
}

.no-products-banner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 7rem;
    background-color: #F0F0F0;
    padding: 0 64px;

    @include breakpoint('tablet-portrait-max') {
      padding: 0 16px;
    }

    h2 {
        font-size: 1.8rem;

        @include breakpoint('tablet-portrait-max') {
            font-size: 1.2rem;
        }
    }
}

.facets {
    display: flex;
    flex-flow: column;

    &__container {
        padding-top: 20px;
        width: 300px;
        background-color: $white;
        @include z-index(one);

        @include breakpoint('tablet-portrait-max') {
            position: fixed;
            top: 0;
            bottom: 0;
            width: 100%;
            padding: 0;
            @include close();
            @include z-index(filter-mobile);
        }

        &.open {
            max-width: 100vw;
            max-height: 100vh;
            transition: all .3s ease-in-out;
            @include open();
        }
    }

    &__content {
        border-right: 1px solid $gray-light;
        height: 100%;

        @include breakpoint('tablet-portrait-max') {
            padding-bottom: 65px;
            overflow-y: auto;
            position: relative;
        }
    }

    &__title {
        &--mobile {
            background-color: $red;
            padding: 16px;
            color: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include font-size(20px);

            svg {
                cursor: pointer;
            }
        }
    }

    &__category {
        padding: 25px 15px 25px 65px;
        border-right: 10px solid $red;
        border-top: 1px solid $red;
        border-bottom: 1px solid $red;
        border-radius: 0 5px 5px 0;
        width: calc(100% + 10px);
        text-transform: lowercase;
        @include font-size(18px);

        strong, b {
            display: inline-block;
        }

        strong::first-letter, b::first-letter {
            text-transform: uppercase;
        }

        @include breakpoint('tablet-portrait-max') {
            border: 0;
            width: 100%;
            padding: 16px 16px;
            text-transform: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            b {
                line-height: 1.1!important;
            }

            .red {
                font-size: inherit;
            }

            .remove-all {
                position: relative;
                inset: 0;
                padding: 10px;

                &.ghosted {
                    opacity: 60%;
                }

            }
        }
    }

    &__show {
        &--products{
            position: fixed;
            bottom: 0;
            padding: 10px 16px;
            box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.2);
            width: 100%;
            background-color: $white;
            @include z-index (one);

            .btn {
                width: 100%;
                text-align: center;
                padding: 10px 0;
            }
        }
    }

    &-skeleton {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 0.8rem;

        .skeleton {
            width: 100%;
            min-height: 20rem;
        }
    }
}

.info-bar {
    padding: 27px calc(5.5% + 8px) 16px calc(3.5% + 8px);
    width: 100%;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint('desktop-small-max') {
        padding: 27px calc(3.5% + 8px) 16px;
    }

    @include breakpoint ('tablet-portrait-max') {
        flex-flow: column;
        padding: 25px 16px 20px;

        &.hide-top-padding {
            padding: 0 16px 20px;
        }
    }

    .search {
        font-weight: 600;
        @include font-size(20px);

        @include breakpoint ('tablet-portrait-max') {
            @include font-size(18px);
        }

        &__container {
            display: flex;
            flex-flow: column;
            //width: 40%;

            @include breakpoint ('tablet-portrait-max') {
                width: 100%;
                order: 0;
                justify-content: space-between;
                flex-flow: row;
            }
        }

        &__title {
            text-transform: lowercase;
            color: $black;
            @include font-size(20px);

            strong, h1 {
                display: inline-block;
                text-transform: lowercase;
            }

            strong::first-letter, h1::first-letter {
                text-transform: uppercase;
            }

            @include breakpoint ('tablet-portrait-max') {
                color: $red;
                text-transform: none;
                @include font-size(18px);
            }
        }

        &__red {
            color: $red;
        }

        &__found {
            padding-top: 4px;

            &--category {
                padding-right: 16px;
                @include font-size(18px);
            }
        }
    }

    .flag {
        &__container {
            display: flex;
            flex-flow: column;
            //width: 20%;

            @include breakpoint ('tablet-portrait-max') {
                width: 100%;
                order: 2;
                justify-content: space-between;
                flex-flow: row;
                align-items: center;
            }

            .select-filter {
                @include hidden();

                @include breakpoint ('tablet-portrait-max') {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    @include visible();
                    @include font-size(14px);

                    span {
                        padding-left: 10px;
                    }
                }

                svg {
                    color: #9CD69E;
                }
            }

            .select-promo {
                display: flex;
                align-items: center;
                @include font-size(14px);

                @include breakpoint ('tablet-portrait-max') {
                    font-weight: bold;
                }

                svg {
                    cursor: pointer;
                    margin-right: 8px;
                }
            }
        }
    }

    .sortby {
        width: 100%;
        display: flex;
        align-items: center;

        @include breakpoint ('tablet-portrait-max') {
            flex-direction: row;
            gap: 16px;
        }

        &__container {
            display: flex;
            font-family: $lato-font;
            justify-content: flex-end;
            //width: 40%;
            @include font-size(14px);

            @include breakpoint ('tablet-portrait-max') {
                width: 100%;
                order: 1;
                padding: 25px 0;
            }
        }

        label {
            padding-right: 15px;
            @include breakpoint ('tablet-portrait-max') {
                padding-right: 0;
                width: auto;
                white-space: nowrap;
                font-weight: 600;
                // @include hidden();
            }
        }

        .customselect {
            width: 240px;
            @include font-size(15px);

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
            }

            &__button {
                background-color: #FFFFFF;
                border: 2px solid #E6E6E6;
            }

            &__list {

                background-color: #FFFFFF;

                &-item {
                    background-color: #FFFFFF;

                    &:hover {
                        background-color: #E6E6E6;
                    }

                }

                &.active {
                    border-top: solid 0 #E6E6E6;
                    border-left: solid 2px #E6E6E6;
                    border-right: solid 2px #E6E6E6;
                    border-bottom: solid 2px #E6E6E6;
                }
            }
        }

        select {
            margin-left: 10px;
            height: 46px;
            font-weight: 400;
            display: flex;
            @include font-size(15px);

            @include breakpoint('tablet-portrait-max') {
                margin-left: 0;
            }
        }
    }
}

.hits, .hits-no-promo {
    padding: 15px 5.5% 150px 3.5%;
    display: flex;
    flex-flow: wrap;
    background-color: #F0F0F0;

    @include breakpoint('desktop-small-max') {
        padding: 15px 3.5% 150px;
    }

    @include breakpoint('tablet-portrait-max') {
        padding: 16px 8px 150px;
    }

    &__container {
        width: calc(100% - 300px);

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
        }

        .products-carousel {
            padding: 16px 0;
            box-shadow: 0 12px 36px 0 rgb(0 0 0 / 12%);
        }

        .carousel-title  {
            @include breakpoint('tablet-portrait-min') {
                padding-left: 2%;
            }
        }

        .back-to-top {
            position: fixed;
            right: 3%;
            bottom: 100px;
            transition: all 0.3s ease-in-out;
            box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.2);
            width: 45px;
            height: 45px;
            @include z-index('backToTop');

            .arrow__up {
                margin: 5px 7px 0 7px;
            }
        }

        &--article {
            @include breakpoint('tablet-portrait-max') {
                padding: 16px;
            }
        }
    }

    .no-hits {
        font-family: $muli-font;
        font-weight: 600;
        color: $red;
        width: 100%;
        @include font-size(24px);

        @include breakpoint('tablet-portrait-max') {
            padding: 20px;
            text-align: center;
            width: 100%;
        }

        .suggestion {
            color: $gray-dark;
            padding-top: 8px;

            &__link {
                text-decoration: underline;
            }
        }
    }
}

.banner-listing {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-flow: column;
    padding-bottom: 20px;

    img {
        width: 100%;
        margin-top: 25px;

        @include breakpoint('tablet-portrait-max') {
            margin-top: 20px;
        }
    }

    @include breakpoint('tablet-portrait-max') {
        width: 100%;
    }

    .content {
        padding-top: 16px;
        line-height: 1.5;

        @include breakpoint('tablet-portrait-max') {
            padding: 16px 0;
        }
    }
}

.flyer-cta {
    display: inline-flex;
    align-items: center;
    padding: 25px 0 0;
    @include font-size(16px);

    @include breakpoint('tablet-portrait-max') {
        padding: 20px 16px 0;
    }

    &__text {
        padding-left: 10px;
    }

    svg {
        width: 24px;
        height: 24px;
    }
}

.search-cms-container {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .carousel-title {
        padding: 16px 64px 0 64px;
        font-size: 1.3rem;

        @include breakpoint('tablet-portrait-max') {
            padding: 16px;
        }
    }

    .categories-carousel {

        &__title {
            color: $black !important;
            padding: 0 64px;
            font-size: 1.3rem;
            font-weight: bold;

            @include breakpoint('tablet-portrait-max') {
                padding: 0 16px;
            }
        }

        @include breakpoint("tablet-portrait-min") {
            padding: 0;
        }
    }
}