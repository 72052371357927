@mixin overflow() {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        box-shadow: inset 0 0 5px $gray;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $gray;
        border-radius: 5px;
    }    
}
