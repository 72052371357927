.bennet-product-listing,
.bennet-product-details {
    overflow: hidden;
    padding-bottom: 24px;

    @include breakpoint("tablet-portrait-min") {
        padding-bottom: 60px;
    }

    &__hero {
        @include breakpoint("tablet-portrait-max") {
            padding: 24px 0 16px;
        }
    }

    &__content {
        padding: 0 16px;

        @include breakpoint("tablet-portrait-min") {
            padding: 0px 64px;
        }

        &--items {
            background-color: $gray-ultralight;
            margin: 16px 0;

            @include breakpoint("tablet-portrait-min") {
                padding: 0 50px 16px;
            }
        }
    }

    &__items {
        display: grid;
        grid-template-columns: auto;
        padding: 16px 0 20px;

        @include breakpoint("phone-min") {
            margin-left: -12px;
            margin-right: -12px;
            grid-template-columns: auto auto;
        }

        @include breakpoint("tablet-portrait-min") {
            padding-bottom: 0;
            grid-template-columns: auto auto auto;
        }

        .bennet-card {
            margin: 12px 0;
            width: 100%;

            &--feature {
                margin: 8px 0;
            }

            @include breakpoint("phone-min") {
                margin: 12px;
                width: calc(100% - 24px);
            }
        }
    }

    .single-banner {
        padding: 12px 16px;

        @include breakpoint("tablet-portrait-min") {
            padding: 16px 64px;
        }
    }
}

.bennet-product-listing {
    &__title {
        padding: 16px 0 8px;
        @include font-size(16px);

        @include breakpoint("tablet-portrait-min") {
            padding-top: 28px;
            @include font-size(18px);
        }
    }

    &__subtitle {
        line-height: 1.5;

        @include breakpoint("tablet-portrait-min") {
        }
    }

    .products-carousel {
        @include breakpoint("tablet-portrait-min") {
            padding: 0 64px;
        }

        .carousel-title {
            @include breakpoint("tablet-portrait-max") {
                padding-left: 16px;
            }
        }

        &-container {
            @include breakpoint("tablet-portrait-min") {
                padding-bottom: 0;
            }
        }
    }
}

.bennet-product-details {
    &__title {
        padding: 12px 16px 0;
        @include font-size(16px);

        @include breakpoint("tablet-portrait-min") {
            line-height: 1.5;
            padding: 30px 64px 0;
            @include font-size(18px);
        }
    }

    .content {
        padding: 0 16px;
        @include font-size(16px);

        @include breakpoint("tablet-portrait-min") {
            padding: 0px 64px;
        }
    }

    .component {
        padding: 16px 0;
    }

    .products-carousel {
        background-color: $gray-ultralight;

        @include breakpoint("tablet-portrait-min") {
            padding: 0;
        }

        .carousel-title {
            padding: 25px 16px 16px;
            border-bottom: 1px solid $gray-light;
            @include font-size(18px);

            @include breakpoint("tablet-portrait-min") {
                padding-left: 64px;
            }
        }

        &-container {
            padding: 16px 0 24px;

            @include breakpoint("tablet-portrait-min") {
                padding: 24px 120px;
            }
        }

        .glide__arrow {
            &--left {
                left: -45px;
            }

            &--right {
                right: -45px;
            }
        }
    }

    .suggested-articles {
        padding: 0;
    }

    .magazine-banner {
        padding: 0 16px;

        @include breakpoint("tablet-portrait-min") {
            padding: 0px 64px;
        }
    }
}
