.three-cards {
    
    &__wrapper {
        padding: 16px;
        background-color: $gray-ultralight;

        @include breakpoint("tablet-portrait-min") {
            padding: 24px 64px;
        }
    }

    &__title {
        @include font-size(18px);
    }

    &__ctn {
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        @include breakpoint("tablet-portrait-min") {
            flex-direction: row;
            margin: 0 -12px;
        }
    }

    &__small-ctn {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @include breakpoint("tablet-portrait-min") {
            margin: 0 12px;
            flex: 1;
        }
    }

    .homepage-card-big {
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        background-color: $white;

        flex-direction: column;
        flex: 1;

        @include breakpoint("tablet-portrait-min") {
            margin: 0 12px;
        }

        a {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
        }

        &__c-img {
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint("tablet-portrait-min") {
                height: 165px;
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            min-height: 135px;

            @include breakpoint("tablet-portrait-min") {
                min-height: unset;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            padding: 16px;

            @include breakpoint("tablet-portrait-min") {
                padding: 18px 24px;
            }
        }

        &__title {
            line-height: 1.5;
            @include font-size(18px);

            @include breakpoint("tablet-portrait-min") {
                @include font-size(20px);
            }
        }

        &__text {
            padding-top: 10px;
            @include font-size(14px);

            @include breakpoint("tablet-portrait-min") {
                @include font-size(13px);
            }
        }
    }

    .homepage-card-small {
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        background-color: $white;

        &:first-child {
            margin-bottom: 16px;
        }

        @include breakpoint("tablet-portrait-min") {
            flex: 1;

            &:first-child {
                margin-bottom: 24px;
            }
        }

        a {
            display: flex;
            width: 100%;
            height: 100%;
        }

        &__c-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34%;
            min-width: 115px;

            @include breakpoint("tablet-portrait-min") {
                min-width: 215px;
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            min-height: 110px;

            @include breakpoint("tablet-portrait-min") {
                min-height: unset;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 16px;
            justify-content: center;
        }

        &__text {
            padding-top: 10px;
            @include font-size(13px);
        }
    }
}
