.hit {
    float: left;
    width: 25%;
    padding: 6px;
    display: flex;
    line-height: normal;

    @include breakpoint('desktop-small-max') {
        width: 33.333333%;
    }

    @include breakpoint('tablet-landscape-max') {
        width: 50%;
    }

    @include breakpoint('tablet-portrait-max') {
        width: 33.333333%;
    }

    @include breakpoint('phone-max') {
        width: 100%;
        padding: 2px;
    }

    &__content {
        background-color: $white;
        padding: 12px 16px;
        flex-direction: column;
        flex: 1 1 0%;
        display: flex;
        position: relative;
        min-width: 100px;
        box-shadow: 0px 4px 12px 0 hsl(0deg 0% 67% / 29%);

        @include breakpoint('phone-max') {
            padding: 16px;
        }
    }
    
    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        position: relative;

        &--container {
            display: inline-block;
            position: relative;
        }

        img {
            width: 100%;
            margin: 0 auto;
            display: block;
        }

        .hit__categorybadge {
            img {
                width: 40px;
                height: 40px;
            }
        }
    }

    &__categorybadge {
        position: absolute;
        top: 15px;
        right: -15px;
    }

    &__new-product {
        color: $white;
        background-color: $new-product-box-background-color;
        padding: 8px;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: -4px;
        @include font-size(11px);
        @include z-index(one);
    }

    &__label-product {
        color: $white;
        background-color: $new-product-box-background-color;
        padding: 8px;
        border-radius: 0 5px 5px 0;
        position: absolute;
        top: 8px;
        left: 0;
        @include font-size(11px);
        @include z-index(one);
    }

    &__shippinghome {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;

        .tooltipBubble {
            top: 110%;
        }

        svg {
            color: $gray-light;
            opacity: 0.5;
        }
    }

    &__title {
        height: 70px;
        padding-top: 6px;
        line-height: 1.5;
        color: $gray-dark;
        overflow: hidden;
        @include font-size(15px);

        @include breakpoint('phone-max') {
            padding: 10px 0 16px;
            height: 80px;
            line-height: 1.5;
        }
    }

    &__top-info {
        display: flex;
        flex-direction: column;
    }

    &__brand {
        text-transform: uppercase;
        padding: 4px 0 8px;
        height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            height: 16px;
            padding: 0;
        }   
    }

    &__icon-info {
        width: 30px;
        height: 30px;
        margin: 4px 0;
    }

    .row-mobile { 
        @include breakpoint('tablet-portrait-max') {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
        }

        .hit__sizing {
            color: $gray;
            height: 12px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                height: auto;
            }
        }

        .hit__icon-info {
            display: flex;
            align-items: center;
            margin: 4px 0;
            width: auto;
            height: 50px;

            .tooltip {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 44px;
                height: 44px;
            }

            .product-image-info__item {
                margin-right: 8px;
            }

            .tooltipBubble {
                @include breakpoint('tablet-portrait-max') {
                    display: block;
                }
            }

            label.tooltipBubble {
                top: 110%;
            }

            @include breakpoint('tablet-portrait-max') {
                height: auto;
                .tooltip {
                    justify-content: flex-end !important;
                }

                label.tooltipBubble {
                    top: 100%;
                    left: auto;

                    &:after {
                        left: auto !important;
                        right: 10% !important;
                    }
                }
            }

            img {
                max-height: 30px;
                max-width: 30px;
            }
        }
    }

    hr {
        background-color: $gray-light;
        height: 1px;
    }
    
    &__bottom-info {
        padding: 4px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        flex: 1 0 auto; 
    }

    &__promo {
        display: flex;
        align-items: center;
        flex: 0 0 63%;
    }

    &__price {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        max-width: 100px;
        justify-content: center;
        height: 25px;
        flex: 0 0 37%;

        .original {
            color: $red;
            text-decoration: line-through;

            @include breakpoint('phone-max') {
                @include font-size(14px);
            }   
        }

        .current {
            font-family: $muli-font-900;
            @include font-size(18px);
        }
    }

    &__btn {
        padding: 0 0 10px;

        @include breakpoint('phone-max') {
            padding-bottom: 20px;
        }

        &.skeleton {
            width: 100%;

            > button {
                border: none;
            }
        }

        > button {
            width: 100%;
            height: 40px;

            svg {
                height: 25px;
            }

            .btn-label {
               // @include hidden();
               display: none;
            }
        }
    }

    &__weight-options {
        &--container {
            position: absolute;
            top: 0px;
            bottom: 35px;
            left: 0px;
            right: 0px;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            @include z-index(two);

            @include breakpoint('tablet-portrait-max') {
                bottom: 45px;
            }
        }

        &--backdrop {
            opacity: 0.4;
            background-color: $gray-dark;
            height: calc(100% - 130px);
            border-radius: 10px 10px 0 0;
        }

        &--content {
            background-color: $white;
            padding: 12px;

            .c-selector-dropdown__item {
                margin: 8px 0 12px;               
            }

            .customselect {
                height: 35px;
                width: 100%;
                margin-top: 4px;

                &__list {
                    max-height: 150px;
                }
            }
        }
    }

    &__wishlist {
        color: $black;
        height: 25px;
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font-size(13px);

        b, svg {
            cursor: pointer;
            display: inline-flex;
        }

        .js-product-references {
            display: none;
        }
    }

    &__availability-time {
        color: $gray;
        flex: 1 1 auto;
        @include font-size(13px);
    }

    &[data-stock="lowStock"] {
        .hit__btn {
            .btn {
                background-color: $white;
                border-color: $green;
                color: $green;
    
                svg {
                    @include hidden();
                }
    
                .btn-label.lowstock {
                    // @include visible();
                    display: block;
                }
            }
        }

        .hit__wishlist {
            .js-product-references {
                display: block;
            }
        }
    }

    &[data-stock="outOfStock"] {
        .hit__btn {
            .btn {
                background-color: $gray;
                border-color: $gray;
                cursor: default;
                box-shadow: none;
    
                svg {
                    @include hidden();
                }
    
                .btn-label.outofstock {
                    // @include visible();
                    display: block;
                }
            }
        }
    }
}