    .support__contacts{
        width: calc(100% - 130px);
        display: flex;
        margin: 0px auto 32px;
        box-shadow: 0 2px 36px 0 rgba(0,0,0,.12);
        height: 215px;
        position: relative;

        @include breakpoint('tablet-portrait-max'){
            margin: 0 0 24px 0;
            width: 100%;
            height: 295px;
            background-image: linear-gradient(to bottom, rgba(233, 233, 233, 0.7) -278%, #ffffff 224%);
        }

        .contact-box{
            position: absolute;
            display: flex;
            flex-direction: row;
            width: 55%;
            top: 0;
            bottom: 0;
            align-items: center;
            justify-content: space-around;
            z-index: 1;

            @include breakpoint('tablet-portrait-max'){
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
            }

            .contact-text{
                max-width: calc(100% - 172px);
                padding: 24px;
                line-height: 1.5;

                @include breakpoint('tablet-portrait-max'){
                    padding: 15px;
                }
            }

            .button-container{
                width: 172px;

                @include breakpoint('tablet-portrait-max'){
                    padding-left: 15px;
                }
                a{
                    padding: 10px 14px 10px 13px;
                    display: flex;
                }
            }
        }

        .contact-image{
            flex-basis: 100%;
            position: relative;
            overflow: hidden;
            img{
                height: 100%;
                object-fit: cover;
                position: absolute;
                right: 0;
                z-index: -1;

                @include breakpoint('desktop-medium-min'){
                    width: 100%;
                }

                @include breakpoint('tablet-portrait-max'){
                    z-index: 0;
                }
            }
        }
    }