.change-drive {
    $this : &;

    @include breakpoint('tablet-portrait-max') {
        background-color: $gray-ultralight;
    }

    &__content {
        b {
            white-space: nowrap;
        }
    }

    &__info {
        line-height: 1.74;
        color: $black;
    }

    &__name, 
    &__nearest {
        font-weight: bold;
        text-transform: uppercase;
    }

    &__2nd-info {
        display: flex;
        flex-flow: wrap;
        align-items: center;

        #{$this}__info:first-child {
            white-space: nowrap;
        }
    }

    &__icon-wrapper {
        display: flex;
    }

    &__store {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
            align-items: unset;
            margin-top: 0;
        }
    }
}