.donate-points-popup {
    .header-mobile-panel__title {
        @include breakpoint('tablet-portrait-max') {
            padding-left: 0;
        }
    }

    &__content {
        padding: 0;

        form {
            max-width: none;
        }

        .form-group {
            margin: 0 60px 40px;

            @include breakpoint('tablet-portrait-max') {
                margin: 0 24px 40px;
            }
        }

        input::placeholder {
            @include breakpoint('phone-max') {
                @include font-size(12px);
            }
        }
    }

    &__text {
        padding: 20px 60px;

        @include breakpoint('tablet-portrait-max') {
            padding: 20px 24px;
            @include font-size(14px);
        }
    }
}
