.minicart-panel {
    overflow-y: hidden;

    @include breakpoint('tablet-portrait-max') {
        @include hidden();
    }

    .btn {
        padding: 4px 10px;
    }

    .product-qnt__container {
        width: 150px;
    }
    
    &__pickup {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        background-color: $green-dark-rgb-3;
        color: $green;
        height: 40px;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__header {
        padding: 16px 24px;
        background-color: $gray-ultralight;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--description {
            display: inline-flex;
            align-items: center;
            font-weight: bold;
            @include font-size(18px);

            .price,
            .quantity {
                @include font-size(30px);
            }
        }

        .btn-green-dark {
            width: 40%;
        }
    }
    
    &__container {
        padding: 4px 24px 16px;
        overflow-y: auto;
        max-height: calc(100vh - 380px);
        max-height: calc(var(--vh, 1vh) * 100 - 380px);
        @include overflow();

        .cart-items__left-header {
            margin: 12px 0 8px;

            img {
                @include hidden();
            }
        }

        .minicart-panel__item.js-article[data-code="P_2768182"],
        .minicart-panel__item.js-article[data-code="P_2995942"],
        .minicart-panel__item.js-article[data-code="P_3233681"] {
            @include hidden();
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #888888;

        // img {
        //     width: 100%;
        // }

        >div {
            display: flex;
            align-items: center;
        }

        &--image {
            width: 100px;
            height: 100px;
            margin: 4px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .categorybadge {
                position: absolute;
                top: 0px;
                right: -15px;
                width: 30px;

                img {
                    max-width: 100%;
                }
            }

            img {
                max-width:100%;
            }
        }

        &--brand {
            text-transform: uppercase;
        }

        &--content {
            padding: 16px 32px 32px;
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            max-width: 235px;
            color: $gray;

            >span {
                padding: 4px 0;
                @include font-size(12px);
            }

            &.price {
                align-items: flex-end;
                padding: 16px 0 32px;
                width: 190px;
            }

            &.amount {
                align-items: baseline;
                flex-flow: row;
                justify-content: space-between;
                padding: 16px 0 32px;
                width: 190px;

                svg {
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }
            }

            .price {
                font-weight: bold;
                @include font-size(18px);
            }
    
            .quantity {
                color: $gray;
                @include font-size(14px);
            }
        }

        &--unavailable {
            display: none;
        }

        &--content.price {
            .minicart-panel__item--unavailable {
                display: block;
            }
        }

        &[data-stock="outOfStock"] {
            .minicart-panel__item--image {   
                filter: grayscale(1);
                opacity: 0.6;
            }

            .product-qnt__container.minicart {
                display: none;
            }
    
            .minicart-panel__item--unavailable {
                display: block;
            }
        }
    }

    &__info {
        padding: 8px 24px;
        background-color: $gray-ultralight;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        
        >div {
            display: flex;
            align-items: center;

            >span {
                margin-left: 16px;
                @include font-size(14px);
            }
        }
    }

    &__buttons {
        padding:24px 24px 12px;
        display: flex;
        flex-flow: column;

        >a {
            width: 100%;
        }

        .btn-transparent {
            @include font-size(13px);
        }

        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            @include font-size(13px);

            svg {
                width: 24px;
                height: 24px;
                margin-right: 16px;
            }
        }
    }
}

body.page-cartPage {
    .minicart-panel__item[data-calculated="false"] {
        .minicart-panel__item--image,
        .minicart-panel__item--content:not(.amount),
        .minicart-panel__item--unavailable {   
            filter: grayscale(1);
            opacity: 0.6;
        }

        .product-qnt__container.minicart {
            display: none;
        }

        .minicart-panel__item--unavailable {
            display: block;
        }
    }
}