.unsubscribe-newsletter {
    padding: 40px 0 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint("tablet-portrait-min") {
        align-items: center;
        flex-direction: row;
    }

    &__content {
        order: 2;
        padding: 30px 16px 0;
        display: flex;
        flex-direction: column;

        @include breakpoint("tablet-portrait-min") {
            order: 1;
            text-align: center;
        }
    }

    &__title {
        line-height: 1.5;
        @include font-size(18px);

        @include breakpoint("tablet-portrait-min") {
            @include font-size(32px);
        }
    }

    &__text {
        line-height: 1.71;
        padding: 16px 0 32px;
        @include font-size(14px);

        b {
            @include breakpoint("tablet-portrait-max") {
                display: inherit;
            }
        }
        
        @include breakpoint("tablet-portrait-min") {
            padding: 16px 0;
            @include font-size(18px);
        }
    }

    &__cta {
        padding: 0 16px;
        width: 100%;

        @include breakpoint("tablet-portrait-min") {
            width: 250px;
            margin: 0 auto;
        }
    }
}
