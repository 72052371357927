.ordered-awards {

    &__ctn {
        margin-bottom: 24px;
        h2, h3 {
            font-size: inherit;
        }
    }

    &__c-cta {
        display: flex;
        background-color: $gray-ultralight;
    }

    &__cta {
        width: 220px;
        margin: 24px auto;

        @include breakpoint('tablet-portrait-min') {
            width: 280px;
        }
    }

    &__no-result {
        padding: 16px;
        font-size:16px;

        @include breakpoint('tablet-portrait-min') {
            padding: 16px 0;
        }
    }

    .ordered-awards-carousel .award-card__threshold {
        display: none;
    }
    
    .account__title {
        @include breakpoint('tablet-portrait-max') {
            padding-left: 16px;
        }
    }

    .awards-carousel {

        &__c-carousel {
            @include breakpoint('tablet-portrait-min') {
                padding: 24px 60px;
            }
        }
    
        .glide {
            &__arrow--right {
                right: -50px;
            }
            
            &__arrow--left {
                left: -50px;
            }
        }
    }

    .remaining-points {
        padding: 16px;

        @include breakpoint('tablet-portrait-min') {
            padding: 24px 0;
        }

        &__title {
            padding-bottom: 20px;
            @include font-size(18px);
            
            > h2 {
                font: inherit;
            }
        }

        &__text {
            line-height: 1.55;
            @include font-size(16px);
        }

        &__c-img {
            position: relative;
            padding-top: 15px;
            max-width: 500px;
            margin: 0 auto;
        }

        &__wrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &--two {
                @include breakpoint('phone-max') {
                    .remaining-points__points {        
                            font-size: 9vw;
                    }
                    .remaining-points__label, 
                    .remaining-points__year {
                        @include font-size(16px);
                    }   
                }
            }
        }

        &__year {
            padding-right: 10px;
        }

        &__points {
            @include font-size(70px);
        }

        &__label, &__year {
            @include font-size(18px);
        }
    }
}