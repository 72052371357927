.homepage-bennetclub {
    
    &__wrapper {
        overflow: hidden;
        position: relative;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        margin: 0 16px;
        height: 435px;

        &--two {
            height: 540px;
        }

        @include breakpoint("phone-min") {
            height: 270px;
        }

        @include breakpoint("tablet-portrait-min") {
            margin: 0 64px;
            height: 195px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 10%;

        @include breakpoint("tablet-portrait-min") {
            object-position: top;
        }

        &--mobile-hide {
            @include breakpoint('phone-max') {
                display: none;
            }

        }
        &--desktop-hide {
            @include breakpoint('phone-min') {
                display: none;
            }
        }

    }

    &__ctn {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 24px;

        @include breakpoint("tablet-portrait-min") {
            flex-direction: row;
            padding: 24px 40px;
        }
    }

    &__title {
        line-height: 1.5;
        @include font-size(20px);
    }

    &__c-first {
        width: 480px;

        @include breakpoint("tablet-portrait-min") {
            width: auto;
            min-width: 280px;
        }

        &--two {
            @include breakpoint("tablet-portrait-min") {
                min-width: 350px;
            }

            @include breakpoint("desktop-small-min") {
                min-width: 480px;
            }
        }
    }

    &__c-points {
        padding-top: 30px;
        display: flex;

        @include breakpoint("tablet-portrait-min") {
            padding-top: 40px;
        }
    }

    &__points {
        flex: 1;
        min-width: 170px;
        padding-bottom: 35px;

        @include breakpoint("tablet-portrait-min") {
            padding: 0;
        }
    }

    &__year {
        padding-bottom: 5px;
        @include font-size(16px);
    }

    &__balance {
        @include font-size(40px);

        @include breakpoint("tablet-portrait-min") {
            @include font-size(32px);
        }

        @include breakpoint("desktop-small-min") {
            @include font-size(40px);
        }
    }

    &__label {
        @include font-size(20px);
    }

    &__c-second {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-flow: wrap;

        @include breakpoint("phone-min") {
            margin: 0 -8px;
        }

        @include breakpoint("tablet-portrait-min") {
            margin: 0 -8px 0 auto;
        }
    }

    &__cta {
        height: 55px;
        border-radius: 4px;
        box-shadow: 0 2px 36px 0 rgb(0 0 0 / 12%);
        background-color: $white;
        padding: 5px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 18px;
        @include font-size(14px);

        &:hover i {
            border-color: $green;
        }

        @include breakpoint("phone-min") {
            flex: 1;
            margin: 0 8px;
        }

        @include breakpoint("tablet-portrait-min") {
            flex: none;
            width: 180px;
        }

        @include breakpoint("desktop-small-min") {
            width: 220px;
        }
    }
}
