.article-tags {
    @include breakpoint('tablet-portrait-min') {
        padding-left: 65px;
        clear: both;
        width: 100%;
    }

    ul {
        position: relative;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        &::-webkit-scrollbar {
            display: none;
        }

        &.active {
            cursor: grabbing;
            cursor: -webkit-grabbing;
        }
    }

    li {
        display: inline;
        margin: 0 1%;
        width: auto;
    }
}

.article-tag {
    display: inline-flex;
    align-items: center;
    border-radius: 30px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.12);
    height: 58px;
    justify-content: space-around;
    padding: 4px 12px;
    margin: 24px 0 16px;
    min-width: 100px;

    &__img {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: none;
    }

    &__title {
        text-align: center;
        @include font-size(13px);
    }
}