.categories {
    padding: 16px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 20px 65px;
        clear: both;
        width: 100%;
    }

    &__title {
        padding: 0 16px 16px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding-left: 0px;
        }
    }

    &__list {
        display: flex;
        flex-flow: wrap;
        padding: 0 8px;
    }

    &__carousel {
        padding: 0 65px;
        position: relative;

        &.glide--disabled .glide__slides {
            width: 100% !important;
        }

        .glide__arrow {
            &--left {
                left: -45px;
            }
            &--right {
                right: -45px;
            }
        }
    }
}

.category-box {
    $self: &;
    border-radius: 5px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    width: calc(50% - 16px);
    margin: 8px;
    height: 90px;

    @include breakpoint('tablet-portrait-min') {
        width: initial;
        margin: 20px 0;
        height: 160px;
    }

    &__wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        padding: 10px 8px;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 8px;
        }

        .btn {
            @include font-size(14px);
        }
    }

    &__icon {
        width: 25px;
        height: auto;
        padding-bottom: 15px;
    }

    &__title {
        text-align: center;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-min') {
            font-weight: bold;
            @include font-size(16px);
        }
    }
    
    &--secondary {
        display: flex;
        border-radius: 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
        height: 58px;
        margin: 0;
        width: auto;
        
        #{ $self }__wrapper {
            flex-direction: row;
            display: flex;
            justify-content: initial;
            padding: 5px 12px 5px 20px;
            border-radius: 30px;
        }

        

        #{ $self }__icon {
            height: 40px;
            width: auto;
            max-width: 65px;
            object-fit: cover;
            padding: 0;
        }

        #{ $self }__title {
            padding-left: 16px;
            font-weight: normal;
            text-align: left;
            @include font-size(14px);  
        }
    }
}