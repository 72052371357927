.order-confirmation {
    display: flex;
    align-items: center;
    
    // .explanatory-box__cta-container {
    //     justify-content: flex-start;
    // }

    @include breakpoint('tablet-portrait-max') {
        flex-direction: column;
    }

    &__info {
        width: 50%;
        padding: 20px 60px 80px;

        @include breakpoint('desktop-small-max') {
            padding: 20px 30px 40px;
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            order: 2;
            padding: 5px 24px 40px;
        }
    }

    &__first-info {
        line-height: 1.5;
        @include font-size(32px);

        @include breakpoint('desktop-small-max') {
            @include font-size(28px);
        }

        @include breakpoint('tablet-portrait-max') {
            @include font-size(18px);
        }

        svg {
            width: 135px;
            height: 45px;
            margin-bottom: -10px;

            @include breakpoint('tablet-portrait-max') {
                width: 105px;
                height: 30px;
                margin-bottom: -8px;
            }

            .iconhome-text {
                top: -4.5px;
            }
        }

        &--img {
            display: inline-block;
        }
    }

    &__second-info {
        padding-top: 40px;
        line-height: 1.6;
        @include font-size(18px);

        @include breakpoint('desktop-small-max') {
            padding-top: 25px;
            @include font-size(16px);
        }

        @include breakpoint('tablet-portrait-max') {
            @include font-size(13px);
        }
    }

    &__third-info {
        padding-top: 40px;
        line-height: 1.5;
        @include font-size(18px);

        @include breakpoint('desktop-small-max') {
            padding-top: 30px;
            @include font-size(16px);
        }

        @include breakpoint('tablet-portrait-max') {
            padding-top: 20px;
            @include font-size(13px);
        }
    }

    &__cta {
        padding-top: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint('desktop-small-max') {
            padding-top: 30px;
        }

        @include breakpoint('tablet-portrait-max') {
            flex-flow: column;
        }

        .btn {
            padding: 0 25px;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
                @include font-size(16px);
            }
        }
    }

    &__banner {
        width: 50%;

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            order: 1;
        }

        .banner-component__img,
        .banner__component {
            width: 100%;
    
            img {
                width: 100%;
                height: auto;
            }
        
        }
    }

    .receipt-info {
        @include breakpoint('tablet-portrait-max') {
            padding-top: 45px;
            order: 1;
        }
        .tooltipBubble {
            left: -85px;
        }
    }
}
