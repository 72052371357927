
    .support__guide{
        background-color: $gray-ultralight;
        display: flex;
        flex-flow: column;
        margin-bottom: 32px;

        @include breakpoint('tablet-portrait-max'){
            margin-bottom: 24px;
        }
        .guide__list{
            display: flex;
            order: 2;
            flex-wrap: wrap;
            width: calc(100% - 112px);
            margin: 0 49px 0 63px;

            @include breakpoint('tablet-portrait-max'){
                    margin: 0;
                    width: 100%;
            }

            .guide-container{
                background-color: #ffffff;
                display: flex;
                flex-basis: calc(25% - 16px);
                height: 95px;
                margin: 24px 16px 24px 0px;

                @include breakpoint('tablet-portrait-max'){
                    flex-basis: calc(100% - 32px);
                    margin: 16px auto 0;

                    &:last-child{
                        margin-bottom: 16px;
                    }
                }

                img{
                   width: 50px;
                   border-radius: 7.7px 0 0 7.7px;
                   object-fit: cover;
                }

                .text-guide{
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    margin: 0 16px;

                    @include breakpoint('tablet-portrait-max'){
                         padding: 16px 43px 15px 16px;
                         margin: 0;
                    }

                    .category-name{
                        font-size: 14px;
                        line-height: 1.5;
                        font-weight: 500;
                    }

                    .guide-box{
                        margin-top: 4px;
                        a{
                            font-size: 16px;
                            line-height: 1.5;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .title{
            padding: 16px 0 10px 59px;
            border-bottom: 1px solid #e0e0e0;
            font-size: 18px;
            line-height: 1.5;

            @include breakpoint('tablet-portrait-max'){
                padding: 16px 0 10px 16px;
            }
        }
    }
