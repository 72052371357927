.sticky-banner {
    border-top: 1px solid $green;
    border-bottom: 2px solid $green;
    color: $green;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    text-align: center;
    //margin: 135px auto 0;
    @include font-size(18px); 

    @include breakpoint('tablet-portrait-max') {
        @include hidden();
    }
}