.flyers {

    &__title {
        padding: 30px 16px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            font-weight: bold;
            padding: 30px 65px 30px;
            @include font-size(20px);
        }
    }

    &__c-store {
        display: flex;
        flex-direction: column;
        padding: 0 16px;

        .customselect {
            width: 100%;
            margin-top: 10px;
        }

        @include breakpoint('tablet-portrait-min') {
            padding: 0 0 0 65px;
            flex-direction: row;
            align-items: center;
            @include font-size(14px);

            .customselect {
                width: 345px;
                margin: 0 0 0 10px;
            }
        }
    }

    &__c-carousel {
        @include breakpoint('tablet-portrait-min') {
            padding-left: 45px;
            padding-bottom: 30px;
        }
    }

    &__bar-cta {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        font: inherit;
        font-weight: bold;

        span {
            padding-top: 10px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-min') {
                padding-top: 5px;
            }
        }

        .share {
            display: flex;

            &__cta {
                margin: 5px 10px;
            }
        }
    }

    &__c-go-cta {
        min-height: 75px;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;

        a {
            color: $gray-dark !important;
            background-image: none !important;
            border: solid 1px $gray !important;
            background-color: $white;
        }

        .btn {
            width: 100%;
            margin-top: 8px;
            @include font-size(13px);

            &:active,
            &.active {
                background-image: none;
                -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
                box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
                color: $gray-dark;
                border-color: $gray-dark;
                background-color: $gray-light-bg;
            }
        }

    }
}

.flyer-card {
    border-radius: 4px;
    padding: 8px;
    margin: 24px 0;
    position: relative;
    border: solid 2px $gray-ultralight;
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: auto;

    &__c-img {
        display: flex;
        align-items: center;
        overflow: hidden;
        margin: 0 auto;
    }

    &__img {
        border-radius: 4px;
        width: 100%;
    }

    &__info {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__title {
        min-height: 40px;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        @include font-size(14px);
    }

    &__subtitle {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font-size(13px);

        > span {
            display: flex;
        }
    }
}

#changePosFlyers .popup__content {
    line-height: 1.5;
}

.flyer-card.glide__slide {
    overflow: hidden; /* Nasconde qualsiasi eccedenza dell'immagine */
    visibility: hidden;
}
