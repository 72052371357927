#hasPromotion {
    @include hidden();
}

.facet {
    $this: &;
    order: 2;

    &.accordion-opened {
        #{$this}__title {
            @include breakpoint('tablet-portrait-max') {
                border-top: 1px solid $red;
                border-bottom: 1px solid $red;
                color: $red;
            }
        }

        #{$this}__list {
            @include breakpoint('tablet-portrait-max') {
                border-right: 10px solid $red;
                padding: 20px 0;
            }
        }
    }

    &__title {
        padding: 16px 20px 16px 65px;
        background-color: $gray-ultralight;
        width: 100%;

        @include breakpoint('tablet-portrait-max') {
            padding: 16px 20px 16px 16px;
        }

        h5 {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include font-size(16px)
        }

        .icon {
            @include font-size(9px);
        }
    }

    &__list {
        padding: 5px 0;
        text-transform: lowercase;

        h2, span {
            display: inline-block;
        }

        span::first-letter, h2::first-letter {
            text-transform: uppercase;
        }

        @include breakpoint('tablet-portrait-max') {
            padding-top: 0;
        }
    }

    hr {
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            position: relative;
            height: 4px;
            background: $gray-ultralight;
            margin-bottom: 8px;
            @include visible();
        }
    }

    &__selected {
        padding: 6px 8px;
        border: 2px solid $green;
        border-radius: 5px;
        color: $green;
        width: max-content;
        display: inline-flex;
        margin-bottom: 5px;
        cursor: pointer;
        align-items: center;
        max-width: 100%;
        @include font-size(13px);

        span {
            display: inline-block;
            text-transform: lowercase;
        }

        span::first-letter {
            text-transform: uppercase;
        }

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
        
        &--title {
            order: 0;
            padding: 16px 20px 16px 65px;
            background-color: $gray-ultralight;
            width: 100%;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }

        
        &--list {
            order: 1;
            padding: 16px 20px 16px 65px;

            @include breakpoint('tablet-portrait-max') {
                padding: 0;
            }

        }

        &.remove-all {
            border: 2px solid $gray;
            color: $gray;

            @include breakpoint('tablet-portrait-max') {
                position: absolute;
                top: 64px;
                right: 16px;
                padding: 5px 10px;
                margin: 0;
                border: 1px solid $gray-dark;
                color: $gray-dark;
                @include visible();
            }
        }

        .facet-remove {
            margin-right: 5px;
            cursor: pointer;
        }
    }

    &__item {
        margin: 20px 20px 20px 65px;
        display: flex;
        align-items: center;
        font-weight: bold;

        label {
            @include breakpoint('tablet-portrait-min') {
                width: 100%;
                justify-content: space-between;
            }
        }

        a {
            color: $gray-dark;
            transition: .2s ease-in-out;

            &:hover, &.active {
                color: $green;
            }
        }

        @include breakpoint('tablet-portrait-max') {
            margin: 0 20px 0 16px;
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        .no-item {
            margin: 20px 20px 20px 85px;
        }
        
        &.nth-show:nth-child(n + 6):not(.selected) {
            display: none;
        }

        &:nth-child(n + 7):not(.selected) {
            &:nth-last-child(1) {
                display: flex;
            }
        }
    }

    &__viewall {
        margin: 20px 20px 20px 65px;
        display: none;
        cursor: pointer;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            margin-left: 16px;
            margin-bottom: 0;
        }
    }
}
