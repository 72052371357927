
#loginModal, #loginModalMFA {
    min-height: 480px;

    @include breakpoint('desktop-small-min') {
        left:20%;
        right:20%;
        width:60%;
    }

    .signin__container{
        padding: 16px;

        &--login{
            padding: 0 16px;
        }
    }

    .mfa-input{
        display: flex;
        justify-content: center;
        gap: 8px;

        &__digit{
            width: 40px;
            height: 45px;
            padding: 8px;
            text-align: center;
            appearance: textfield;
            -webkit-appearance: textfield;
            background-color: $gray-ultralight;
            border: 1px solid $border-mobile;
            border-radius: 8px;
            @include font-size(18px);
        }

        &__digit:focus{
            outline: none;
            border: 1px solid $gray;
        }
    }

    .mfa-heading{
        text-align: center;
        font-weight: bold;
        @include font-size(22px);

        @include breakpoint('tablet-portrait-max') {
            line-height: 1.5;
            @include visible();
            @include font-size(16px);
        }
    }

    .mfa-subheading{
        padding: 8px 16px;
    }

    #loginMFAForm{
        padding: 8px 16px;

        .form-group{
            margin-bottom: 16px;
        }

        .mfa-copy{
            margin-top: 16px;

            a[href^="tel"] {
                text-decoration: underline;
            }
        }
    }

    .mfa-container{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;
    }

    .mfa-switch{
        text-decoration: underline;

        &:hover{
            cursor: pointer;
        }
    }

    .mfa-sms-button{
        text-decoration: underline;
        @include font-size(14px);

        &:hover{
            cursor: pointer;
        }

    }

    .mfa-sms-button.disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    .mfa-btn-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;

        @include breakpoint ('phone-max') {
            flex-direction: column;
            justify-content: center;
            gap: 8px;
        }

        .btn-transparent{
            position: static;
            padding: 0;
            @include visible();
        }

    }

    .mfa-sms{
        .mfa-provider{
            @include hidden();
        }
    }

    .mfa-provider{
        .mfa-sms{
            @include hidden();
        }
    }

}
.signin {
    .icon-close {
        position: absolute;
        right: 24px;
        top: 24px;
        @include z-index(one);

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
    }

    &__container {
        width: 50%;
        border-radius: 8px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        background-color: $white;
        display: flex;
        top: 40px;
        right: 8%;
        flex-flow: column;
        position: absolute;
        max-width: 700px;
        @include z-index(one);

        @include breakpoint('tablet-portrait-max') {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
            border-radius: 0;
        }

        &--header {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px;

            .heading {
                text-align: center;
                @include font-size(22px);

                @include breakpoint('tablet-portrait-max') {
                    line-height: 1.5;
                    @include visible();
                    @include font-size(16px);
                }
            }

        }

        &--buttons {
            display: inline-flex;
        }

        &--login, &--register {
            form {
                display: flex;
                flex-flow: column;
                padding: 30px 45px;

                @include breakpoint('tablet-portrait-max') {
                    padding: 24px 16px;
                }

            }

            .label {
                padding-bottom: 24px;
            }

            .forgotten-password {
                padding: 16px 0px 4px;
                text-align: right;
                @include font-size(14px);
            }

            .remember-me{
                &__container {
                    padding: 16px 0 28px;
                }
            }

            .btn {
                padding: 0 16px;

                &-container {
                    justify-content: space-between;
                    display: flex;
                }

                &-red {
                    margin-left: auto;

                    @include breakpoint('phone-max') {
                        margin: 0;
                        width: 100%;
                        height: 50px;
                    }

                    &[disabled] {
                        background-color: $red-dark;
                        border-color: $red-dark;
                    }
                }

                &-transparent {
                    position: relative;
                    left: -16px;

                    @include breakpoint('phone-max') {
                        @include hidden();
                    }
                }
            }


        }
    }

    &__button {
        width: 50%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        background-color: $white;
        color: $black;
        @include font-size(14px);

        &:hover {
            color: $black;
        }

        &.btn-register {
            border-radius: 0 8px 0 0;

            @include breakpoint('tablet-portrait-max') {
                border-radius: 0;
            }
        }

        &.btn-login {
            border-radius: 8px 0 0 0;

            @include breakpoint('tablet-portrait-max') {
                border-radius: 0;
            }
        }

        span {
            padding: 4px;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }

        .title {
            @include font-size(22px);

            @include breakpoint('tablet-portrait-max') {
                line-height: 1.5;
                @include visible();
                @include font-size(16px);
            }
        }

        &.not-active {
            background-color: $green-background;
            color: $gray;

            &:hover {
                background-color: adjust-color($green-background, $lightness: -10%);
            }

            &.btn-register {
                border-radius: 0 8px 0 16px;

                @include breakpoint('tablet-portrait-max') {
                    border-radius: 0 0 0 16px;
                }
            }

            &.btn-login {
                border-radius: 8px 0 16px 0;

                @include breakpoint('tablet-portrait-max') {
                    border-radius: 0 0 16px 0;
                }
            }
        }
    }

    &__page {
        position: relative;
    }
}