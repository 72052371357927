.opportunity-carousel {
    padding: 20px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 16px 64px 20px;
    }

    &__title {
        padding-left: 16px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0;
            @include font-size(24px);
        }
    }

    &__ctn {
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        @include breakpoint('tablet-portrait-min') {
            flex-direction: row;
            padding-top: 20px;
        }
    }

    &__carousel {

        @include breakpoint('tablet-landscape-min') {
            width: 75%;
        }

        @include breakpoint('tablet-portrait-min') {
            width: 82%;
        }

        >div:not(.glide--disabled) {

            @include breakpoint('tablet-portrait-min') {
                padding: 0 50px;
            }

            .glide__track {
                box-shadow: 0 2px 36px 0 rgb(0 0 0 / 12%);
            }
        } 
    }

    .opportunity-card {
        flex: 1;
    }

    .glide {
        &__slide {
            display: flex;
            height: auto;
        }

        &--disabled .glide{
            &__track {
                overflow: initial;
            }

            &__slides {
                width: 100%!important;
            }

            &__slide {
                flex: 1;
            }
        }

        &__arrow {
            &--left {
                left: -52px;
            }

            &--right {
                right: -52px;
            }
        }
    }

    &__cta {
        border-radius: 4px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 1;
        @include font-size(16px);

        @include breakpoint('tablet-landscape-min') {
            width: 25%;
        }

        @include breakpoint('tablet-portrait-min') {
            width: 18%;
            margin-left: 16px;
            padding: 0 16px;
            text-align: center;
        }
    }

    &__only-cta {
        flex: 1;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0 16px;
        padding: 20px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            margin: 0;
        }
    }
}