.icon {
    &logo-b {
        width: 35px;
        height: 35px;
    }

    &logo-bennet {
        width: 130px;
        height: 20px;
    }

    &close {
        width: 10px;
        height: 10px;
        
        @include breakpoint('tablet-portrait-max') {
            width: 15px;
            height: 15px;
        }
    }

    &heart-favourite {
        width: 26px;
        height: 24px;
        color: $white;
    }

    &heart,
    &pencil,
    &trash-border,
    &email{
        width: 25px;
        height: 20px;
    }

    &arrow-forward,
    &search,
    &geoloc,
    &addlist,
    &savelist,
    &nota,
    &shippinghome,
    &pickinstore,
    &drive-border,
    &home,
    &filter-mobile,
    &calendar,
    &instagram,
    &tiktok,
    &youtube,
    &linkedin,
    &facebook,
    &twitter,
    &x,
    &sec-arrow-right,
    &bag-border,
    &delivery-border,
    &change-nota,
    &dots,
    &sunrise,
    &sunset,
    &speaker,
    &timer,
    &people,
    &easy,
    &medium,
    &hard,
    &share,
    &heart-border,
    &download,
    &pinterest,
    &google,
    &magazine,
    &whatsapp,
    &customercare,
    &storefinder,
    &contact-phone,
    &workwithus {
        width: 25px;
        height: 25px;
    }

    &timer,
    &people,
    &easy,
    &medium,
    &hard {
        @include breakpoint('tablet-portrait-max') {
            width: 18px;
            height: 18px;
        } 
    }



    &change-order,
    &cancel-order,
    &order-detail {
        width: 22px;
        height: 22px;

        @include breakpoint('tablet-portrait-max') {
            width: 25px;
            height: 25px;
        }
    }

    &promo,
    &timeslot,
    &list,
    &lunchbox,
    &profile,
    &cart,
    &flier,
    &club,
    &drive,
    &user,
    &circle-plus,
    &donate-points {
        width: 30px;
        height: 30px;
    }

    &service-c,
    &service-l,
    &service-2,
    &service-7,
    &service-8,
    &service-12,
    &service-14,
    &service-15,
    &service-16,
    &service-17,
    &service-21,
    &service-22,
    &service-23,
    &service-25,
    &service-26,
    &laptop,
    &store {
        width: 35px;
        height: 35px;
    }

    &service-a,
    &service-b,
    &service-24,
    &service-10 {
        width: 50px;
        height: 50px; 
    }

    &promo-on,
    &promo-off,
    &phone {
        width: 35px;
        height: 30px;
    }

    &group{
        width: 40px;
        height: 40px;
    }

    &blocknote {
        width: 50px;
        height: 50px;
    }

    &cart,
    &club-mobile,
    &user-mobile,
    &search,
    &geoloc {
        @include breakpoint('tablet-portrait-max') {
            width: 25px;
            height: 25px;
        }
    }

    &bennet-text{
        width: 82px;
        height: 24px;
        color: #e30613;

        @include breakpoint('tablet-portrait-max') {
            width: 65px;
            height: 20px;
        }
    }

    &home-text,
    &drive-text {
        width: 80px;
        height: 24px;
        color: $green;

        @include breakpoint('tablet-portrait-max') {
            width: 65px;
            height: 20px;
        }
    }

    &home-text {
        top: -3.5px;
        position: relative;
    }

    &show-pwd,
    &hide-pwd {
        height: 18px;
        width: 18px;
        position: absolute;
        right: 12px;
        cursor: pointer;
        top: 16px;
        @include z-index(two);
    }

    &check,
    &trophy {
        width: 20px;
        height: 20px;
    }

    &references {
        width: 20px;
        height: 20px;
        color: $blue;
    }

    &bundle {
        width: 20px;
        height: 20px;
        color: $gray;
    }

    &user-big,
    &store-big,
    &basket,
    &delivery-big,
    &list-big,
    &shopping-bags,
    &promo-big {
        width: 60px;
        height: 60px;

        @include breakpoint("tablet-portrait-min") {
            width: 100px;
            height: 100px;
        }
    }
    &instacook {
        display: none;
        margin-right: 8px;
        width: 30px;
        height: 30px;
        
        @include breakpoint('desktop-small-max') {
            width: 25px;
            height: 25px;
        }
    }
    &bell {
        display: none;
        width: 20px;
        height: 20px;
    }
}

.arrow {
    border: solid $gray-dark; 
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;

    &__down {
        transform: rotate(45deg);
        margin: 0 0px 7px 5px;
    }

    &__up {
        transform: rotate(-135deg);
        margin: 5px 0 0px 7px;
    }

    &__right {
        transform: rotate(-45deg);
    }

    &__left {
        transform: rotate(135deg);
    }
}

.plus-minus {
    height: 16px;
    width: 16px;
    position: relative;

    .horizontal {
        position: absolute;
        background-color: $gray-dark;
        width: 100%;
        height: 20%;
        top: 40%;
    }

    .vertical {
        position: absolute;
        background-color: $gray-dark;
        width: 20%;
        height: 100%;
        left: 40%;
    }
}

.icon-close {
    border-radius: 100%;
    background-color: rgba(45,45,46,.8);
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    @include breakpoint('tablet-portrait-max') {
        color: initial;
        background: none;
        width: auto;
        height: auto;
    }
}
