.dashboard-promo-scuola-page, 
.dashboard-promo-sport-page {
    height: 100%;
    line-height: 1.7;

    .js-tab-content {
        display: none;
        height: calc(100% - 116px);
    }

    .info-content {
        margin: 16px;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .coupon {
        border-radius: 4px;
        box-shadow: 0 2px 36px 0 rgb(0 0 0 / 12%);
        background-color: #fff;
        margin: 24px 16px;
        padding: 16px;

        @include breakpoint('tablet-portrait-min') {
            display: flex;
        }

        &__description {
            @include breakpoint('tablet-portrait-min') {
                display: flex;
                flex-flow: column;
                justify-content: center;
                width: 75%;
            }

            > div {
                padding-bottom: 32px;

                &:last-child {
                    @include breakpoint('tablet-portrait-min') {
                        padding-bottom: 0;
                    }
                }
            }

            &--item {
                display: inline-flex;
                flex-flow: column;
                padding-right: 12px;

                > span, > b {
                    padding: 4px 0;
                }
            }
        }

        &__qr-code {
            display: flex;

            @include breakpoint('tablet-portrait-min') {
                width: 25%;
            }

            img {
                width: 80%;
                margin: auto;
                max-width: 250px;

                @include breakpoint('tablet-portrait-min') {
                    width: 100%;
                }
            }
        }
    }
}