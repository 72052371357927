.club {
    position: relative;
    overflow: hidden;

    &__background  {
        top: 600px;
        position: absolute;
        animation: 3s linear;
        @include z-index('negative');

        &--left {
            left: -40px;
            animation-name: run-left-small;

            @include breakpoint('desktop-small-min') {
                animation-name: run-left;
                left: 0;
            }

            @keyframes run-left {
                from { left: -150px; }
                to { left: 0; }     
            }

            @keyframes run-left-small {
                from { left: -150px; }
                to { left: -40px; }     
            }
        }

        &--right {
            right: -40px;
            animation-name: run-right-small;

            @include breakpoint('desktop-small-min') {
                animation-name: run-right;
                right: 0;
            }

            @keyframes run-right {
                from { right: -150px; }
                to { right: 0; }
            }

            @keyframes run-rigth-small {
                from { right: -150px; }
                to { right: -40px; }     
            }
        }
    }

    &__wrapper {
        margin: 0 4%;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 11.5%;
        }
    }

    &__banner {
        padding: 20px 0;
        margin: 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 64px;
            padding: 40px 0;
        }
    }
    
    .account__banner {
        @include breakpoint('tablet-portrait-max') {
            margin-bottom: 35px;
        }

        @include breakpoint('tablet-portrait-min') {
            margin-left: 65px;
            margin-right: 65px;
        }
    }

    .super-hero-tab {
        padding-top: 20px;

        @include breakpoint('tablet-portrait-max') {
            padding-top: 0;
        }
    }

    .support__login {
        margin-top: 20px;
        margin-bottom: 20px;

        @include breakpoint('tablet-portrait-min') {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .frequently-asked-questions {
        padding: 0;
        margin: 20px 4% 60px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0;
            margin: 40px 64px 80px;
            background-color: $white;
        }
    }
}