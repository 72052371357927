.landing-layout1, .landing-layout2 {
    .bennet-banner-carousel { 
        .glide {
            &__bullets {
                margin: 0;
                
                @include breakpoint("tablet-portrait-min") {
                    position: absolute;
                    bottom: 12px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: auto;               
                }
            }

            &__bullet {
                border: 1px solid $gray-dark;
            }

            &__arrows {
                @include hidden();
            }
        }
    }

    .bennet-banner {
        padding: 0;
    }

    .products-carousel {

        .carousel-title {
            padding: 0 0 0 16px;

            @include breakpoint("tablet-portrait-min") {
                padding: 0;
            }
        }

        &-container {
            padding: 20px 0 0;

            @include breakpoint("tablet-portrait-min") {
                padding: 20px 50px 0;
            }

            .glide {
                &__arrow--left {
                    left: -50px;
                }

                &__arrow--right {
                    right: -50px;
                }
            }
        }
    }

    .component-wrapper {
        padding: 12px 16px;
        position: relative;

        @include breakpoint("tablet-portrait-min") {
            padding: 12px 64px;
        }
    }

    .other-component-wrapper {
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;

        .content {
            padding: 0 16px;

            @include breakpoint("tablet-portrait-min") {
                padding: 0px 64px;
            }
        }
    }

    //Custom padding
    .product-carousel-cover {
        @include breakpoint("phone-min") {
            padding: 0 16px;
        }
    }
    
    .products-carousel,
    .product-carousel-cover {
        @include breakpoint("tablet-portrait-min") {
            padding: 0 64px;
        }
    }
}
