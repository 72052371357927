// primary colors
$red: #E30613;
$red-dark: #A6020B;
$black: #2D2D2D;
$blue: #3636DA;
$blue-rgb-7: 0, 188, 212;
$gray-dark: #2D2D2D;
$gray: #595959;
$gray-light: #888888;
$gray-light-rgb: 136, 136, 136;
$border-mobile: #e0e0e0;
$green: #008A00;
$green-dark: #006400;
$green-brand: #00A13A;
$green-rgb-0: rgb(76, 187, 80);
$green-rgb-3: rgb(76, 187, 80);
$green-rgb-4: rgb(76, 187, 80);
$green-rgb-6: rgb(76, 187, 80);
$green-dark-rgb-1: 0, 138, 0;
$green-dark-rgb-3: 0, 138, 0;
$green-dark-rgb-4: 0, 138, 0;
$green-dark-rgb-7: 0, 138, 0;
$dark-blue: #3636DA;
$coal: #12A5A5;
$green-light: #0B9A0B;
$purple: #B50595;
$orange-light: #ED6E00;


// background colors
$white: #FFF;
$gray-ultralight: #F9F9F9;
$gray-light-bg: #E6E6E6;
$gray-ultralight-rgb-4: rgba(242, 242, 242, 0.4);
$gray-ultralight-rgb-5: rgba(242, 242, 242, 0.5);
$gray-ultralight-rgb-8: rgba(242, 242, 242, 0.8);
$new-product-box-background-color: #284c83;
$green-background: #deefdd;

// secondary colors
$red-secondary-first:#e60000;
$orange-secondary-first:#B84D00;
$orange-secondary-first-rgb-3: rgba(184, 77, 0, 0.3);
$yellow-secondary-first:#FFC64C;
$green-secondary-first:#44C48F;
$blue-secondary-first:#33A0DF;
