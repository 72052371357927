.promo-item {
    &__wrapper {
        display: flex;
        align-items: center;
        line-height: 1.5;
    }
        
    &__badge {
        display: flex;
        align-items: center;
        padding-right: 8px;

        .promo-item__icon {
            height: 40px;
            width: auto;    
        }
    }

    &__detail {
        display: flex;
        flex-flow: column;
        color: $gray;
        @include font-size(11px);
    }

    &__fidelity {
        @include font-size(16px);
    }

    &__expired {
        display: flex;
        line-height: 1.5;
        letter-spacing: normal;
        @include font-size(13px);
    }
}