.contacts{
    width: 830px;

    @include breakpoint('tablet-portrait-max'){
        width: 100%;
    }

    &__header{
        display: flex;
        align-items: center;
        padding: 20px 24px;
        background-color: #fef7f3;
        line-height: 1.5;

        .icon{
            display: flex;
            align-items: center;
        }
        .title{
            margin: 0 auto 0 16px;
            font-size: 20px;
            font-weight: bold;
        }
    }

    &__channels{
        display: flex;
        justify-content: center;
        padding: 16px 0;

        @include breakpoint('tablet-portrait-max'){
            padding: 0 16px;
            flex-direction: column;
        }

        &--item {
            padding: 16px;
            width: 33%;

            @include breakpoint('tablet-portrait-max'){
                width: 100%;  
                padding: 24px 0;          
            }

            &:first-child {
                border-right: 1px solid #000000;

                @include breakpoint('tablet-portrait-max'){
                    border-right: none;
                    border-bottom: 1px solid #979797;
                }
            }

            &:last-child {
                border-left: 1px solid #000000;

                @include breakpoint('tablet-portrait-max'){
                    border-left: none;
                    border-top: 1px solid #979797;
                }
            }

            .iconlist, .iconemail, .iconcontact-phone {
                width: 25px;
                height: 25px;
            }

            .container-text {
                line-height: 1.75;

                .btn {
                    width: 100%;
                    margin-top: 8px;

                    @include breakpoint('tablet-portrait-max'){
                        width: auto;
                        padding: 12px;
                    }
                }
            }
        }

        .no-border {
            border: 0;
        }
    }

    &__message{
        height: 140px;
        padding: 16px 0 0 58px;
        line-height: 1.74;
        .message-text{
            color: #000000;
        }
    }

    &__footer{
        padding: 29px 599px 27px 59px;
        background-color: #f2f2f2;

        .button{
            line-height: normal;
            &__close{
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }

    .contacts-header{

        .go-back{
            padding: 14px 0 15px 27px;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            background-color: $red;
            color: #fff;
            line-height: normal;
            i{
                border-right: solid $white;
                border-bottom: solid $white;
                padding: 4px;
            }
            p{
                margin-left: 17px;
            }
        }

        .header-title{
            margin: 16px 16px 0px 16px;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.75;
        }
    }
}