.opportunity-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    border: 2px solid $white;

    &:hover {
        border: 2px solid $green;
        color: inherit;
    }

    @include breakpoint('tablet-portrait-min') {
        padding: 24px 16px 30px;
    }

    &__job {
        min-height: 38px;
        @include font-size(16px);
    }

    &__c-detail {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

    &__detail {
        padding-top: 10px;
        @include font-size(14px);

        &--first {
            flex: 1 0 auto;
        }
    }
}