.store-detail {

    &__tab {
        padding: 20px 16px;

        @include breakpoint('tablet-portrait-min') {
            border: none;
            @include font-size(18px);
        }
    }

    &__hourly-info {
        @include breakpoint('tablet-portrait-min') {
            padding: 0 55px 30px 40px;
            display: flex;
        }
    }

    &__timeslot {
        @include breakpoint('tablet-portrait-min') {
            display: flex;
            flex: 1;
            min-width: 0;
            margin: 0 -16px;
        }
    }

    &__services {
        @include breakpoint('tablet-portrait-min') {
            padding: 0 55px 0px 40px;
        }

        &.highlight {
            img, svg {
                width: 40px;
                height: 40px;
            }
        }
    }

    .important-news {
        margin: 1px 0 25px;
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 16px;
        box-shadow: 0 4px 8px 0 rgba(45, 45, 46, 0.12);
        border-top: 1px solid $green;
        border-bottom: 1px solid $green;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 24px;
            margin: 24px 55px 40px;
            border-radius: 8px;
            border: 1px solid $green;
            height: 78px;
        }

        svg {
            color: $green;

            @include breakpoint('tablet-portrait-min') {
                width: 34px;
                height: 34px;
            }

        }

        &__cta {
            padding-left: 16px;
            cursor: pointer;
            white-space: nowrap;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-min') {
                padding-left: 20px;
                @include font-size(20px);
            }
        }

        &__title {
            padding: 0 25px;
            white-space: nowrap;
            @include font-size(18px);
        }

        &__description {
            overflow     : hidden;
            line-height: 1.5;
            text-overflow: ellipsis;
            white-space  : nowrap;
            padding-right: 0.08em;
            @include font-size(18px);
        }
    }

    .main-info {
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-min') {
            flex-direction: row;
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
            padding: 26px 55px;
            justify-content: space-between;
        }

        &__ctn {
            padding: 20px 16px 24px;
            display: flex;
            flex-direction: column;
            line-height: normal;

            @include breakpoint('tablet-portrait-min') {
                flex-direction: row;
                align-items: center;
                padding: 0 40px 0 0 ;
            }
        }

        &__name {
            @include font-size(18px);

            @include breakpoint('tablet-portrait-min') {
                padding-right: 40px;
                @include font-size(20px);
            }

            @include breakpoint('desktop-small-min') {
                padding-right: 100px;
            }
        }

        &__hours {
            padding-top: 10px;
            @include font-size(13px);

            .oval {
                min-width: 18px;
            }

            @include breakpoint('tablet-portrait-min') {
                padding: 0;
                @include font-size(16px);
                
            }
        }

        &__services {
            display: flex;
            padding: 15px 16px 15px;

            @include breakpoint('tablet-portrait-min') {
                padding: 0;
                align-items: center;

            }
        }

        &__service {
            height: 12px;
            display: inline-flex;
            
            &:first-child {
                margin-right: 10%;
            }

            @include breakpoint('tablet-portrait-min') {
                height: auto;
                margin-right: 20px;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    .store-info {

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 55px 30px 40px;
        }

        &__box {
            @include breakpoint('tablet-portrait-min') {
                display: flex;
            }
        }

        .store-detail__tab {
            @include breakpoint('tablet-portrait-min') {
                @include font-size(20px);
            }
        }

        &__wrapper {
            @include breakpoint('tablet-portrait-min') {
                width: 320px;
            }

            // @include breakpoint('tablet-landscape-min') {
            //     width: 320px;
            // }

            @include breakpoint('desktop-small-min') {
                width: 435px;
            }
        }

        &__ctn {
            padding: 20px 16px 8px;

            @include breakpoint('tablet-portrait-min') {
                padding: 8px 40px 8px 16px;
            }
        }

        &__logo {
            max-width: 80px;

            @include breakpoint('tablet-portrait-min') {
                max-width: 246px;
            }

            svg {
                width: 100px;
                height: 100px;
            }
        }

        &__c-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 0;

            @include breakpoint('tablet-portrait-min') {
                font-weight: bold;
                padding: 16px 0 24px;
                @include font-size(20px);
            }
        }

        &__link {
            padding-right: 10px;
            line-height: normal;
        }

        &__address {
            line-height: 1.5;

            @include breakpoint('tablet-portrait-min') {
                line-height: 1.63;
            }
        }

        &__distance {
            white-space: nowrap;
        }

        &__directions {
            display: block;
            display: flex;
            align-items: center;
            margin-top: 16px;
            max-width: 210px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                max-width: 140px;
                margin-top: 25px;
                @include btn-mobile('sm');
            }
        }

        #map {
            height: 330px;

            @include breakpoint('tablet-portrait-min') {
                width: calc(100% - 320px);
                height: 335px;
            }

            @include breakpoint('tablet-landscape-min') {
                // width: calc(100% - 320px);
                height: 285px;
            }

            @include breakpoint('desktop-small-min') {
                width: calc(100% - 435px);
            }
        }
    }

    .store-hours {

        @include breakpoint('tablet-portrait-min') {
            width: 320px;
        }

        // @include breakpoint('tablet-landscape-min') {
        //     width: 320px;
        // }

        @include breakpoint('desktop-small-min') {
            width: 435px;
        }

        .store-detail__tab {
            @include breakpoint('tablet-portrait-min') {
               padding-top: 35px;
            }
        }

        &__ctn {
            padding: 16px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-min') {
                padding: 8px 16px;
                @include font-size(14px);
            }
    
            @include breakpoint('desktop-small-min') {
                @include font-size(16px);
            }
        }

        &__days {
            padding: 15px 0 10px;

            @include breakpoint('tablet-portrait-min') {
                padding-top: 30px;
            }
        }

        &__day {
            padding-bottom: 12px;
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-min') {
                padding-bottom: 20px;
                max-width: 300px;
            }

            span {
                flex: 1;
                line-height: 1.5;
            }

            .highlight{
                font-weight: bold;
            }
        }


        &__more-info {
            padding-bottom: 10px;
            line-height: normal;
            text-transform: capitalize;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-min') {
                padding-bottom: 16px;
                @include font-size(14px);
            }

            @include breakpoint('desktop-small-min') {
                @include font-size(16px);
            }
        }
    }

    .info-time-slots {
        @include breakpoint('tablet-portrait-min') {
            flex: 1;
            min-width: 0;
            margin: 0 16px;
        }

        .store-detail__tab {
            @include breakpoint('tablet-portrait-min') {
                padding: 35px 32px 25px;
            }
        }

        &__pos {
            padding-left: 28px;

            @include breakpoint('tablet-portrait-max') {
                padding: 16px 16px 12px;
            }
        }

        &__associated {
            display: inline-block;
            padding: 12px 28px;
            background-color: rgba(68,163,61,.3);
            margin-top: 12px;
            line-height: 1.5;
            width: 100%;

            @include breakpoint('tablet-portrait-max') {
                padding: 12px 16px;
            }
        }

        &__calendar {
            @include breakpoint('tablet-portrait-min') {
               border-radius: 0;
               height: 100%;
            }

            @include breakpoint('tablet-portrait-max') {
                box-shadow: none;
             }
        }

        .glide__arrows {
            right: 24px;
            top: 20px;

            @include breakpoint('tablet-portrait-max') {
                right: 10px;
                top: 10px;
            }
        }

        .calendar {
            
            &__day {
                @include breakpoint('tablet-portrait-min') {
                    padding: 25px 0 15px;
                    margin: 0 28px;
                }
            }

            &__container {
                padding: 20px 28px;

                @include breakpoint('tablet-portrait-max') {
                    padding: 20px 15px 10px;
                }
            }

            &__timeslot {
                @include breakpoint('tablet-portrait-min') {
                    @include font-size(16px);
                }
            }
        }
    }

    .store-services {
        display: flex;
        flex-flow: wrap;
        margin: 0 -8px;
        padding: 12px 16px;
        width: 100%;
        
        @include breakpoint('tablet-portrait-min') {
            display: flex;
            flex-flow: wrap;
            padding: 0 16px;
            margin: 0 -10px;
        }
        

        &__c-service {
            display: flex;
            align-items: center;
            width: 50%;
            padding: 16px 8px;
            line-height: 1.5;

            @include breakpoint('tablet-portrait-min') {
                width: 20%;
                padding: 16px 10px;
            }

            a {
                display: flex;
                align-items: center;
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &__service-name {
            padding-left: 10px;
        }
    }

    .carousel-title {
        color: $red;
        padding-left: 55px;
        font-weight: normal;

        @include breakpoint('tablet-portrait-max') {
            padding-left: 16px;
        }
    }

    .store-carousel {
        padding: 16px 0;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 0;
            clear: both;
        }

        &__ctn {
            background-color: $gray-ultralight; //Grafica $gray-ultralight
            padding-bottom: 25px;
        }
        
        &__title {
            padding: 20px 16px 12px;
            @include font-size(18px);

            @include breakpoint('tablet-portrait-min') {
                padding-left: 55px;
            }
        }

        &__c-carousel {
            padding-top: 16px;

            @include breakpoint('tablet-portrait-min') {
                padding: 15px 100px 0;
            }
        }

        .glide {
            &__slide {
                height: auto;
                background-color: $white;
                box-shadow: 0 2px 36px 0 rgba(0,0,0,.15);
            }

            &__arrow {
                &--left {
                    left: -90px;
                }

                &--right {
                    right: -90px;
                }
            }
        }
    }

    .js-accordion-list {

        @include breakpoint('tablet-portrait-min') {
            opacity: 1;
            max-height: -webkit-fit-content;
            max-height: -moz-fit-content;
            max-height: fit-content;
            visibility: visible;
        }
    }

    .js-accordion-container:not(.accordion-opened) {  
        .tab {
            @include breakpoint('tablet-portrait-max') {
                border-right: none;
                border-color: $gray-light;
                color: $gray-dark;
                border-bottom: none;
            }
        }

        &.last .tab {
            @include breakpoint('tablet-portrait-max') {
                border-bottom: 1px solid $gray-light ;
            }
        }
    }

    .js-accordion-selector {
        @include breakpoint('tablet-portrait-max') {
            cursor: pointer;
        }
    }
}
    