.header-panel {
    display: flex;
    align-items: center;
    background-color: $gray-ultralight;
    padding: 20px 25px 20px 20px;

    &__icon {
        width: 40px;
        height: 40px;
        color: $black;
        opacity: 0.5;
        margin-right: 8px;
    }
    
    &__title {
        @include font-size(20px);
    }
    
    &__close {
        margin-left: auto;
    }
}

#profileNotCompliantPopup, #mustResetPasswordPopup {
    .header-panel__close, .header-mobile-panel__close {
        @include hidden();
    }
}