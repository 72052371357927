.footer {
    display: flex;
    flex-flow: column;
    position: relative;
    @include z-index(footer);

    &__break {
        max-width: 1680px;
        display: flex;
        margin: 0 auto;

        @include breakpoint('tablet-portrait-max') {
            max-width: 100%;
            flex-flow: column;
        }
    }

    &-1 {
        box-shadow: 13px 0 16px 0 rgba(171, 171, 171, 0.29);
        background-color: $white;
    }

    &-2 {
        background-color: $red;
        color: $white;

        img {
            width: 80px;

            @include breakpoint('tablet-portrait-max') {
                width: 55px;
            }
        }
    }

    &__slots {
        display: flex;
        width: 75%;
        flex-flow: row;
        padding: 32px 0 24px 40px;

        @include breakpoint ('tablet-portrait-max') {
            flex-flow: column;
            width: 100%;
            padding: 0;
        }
    }

    .footer-slot {
        display: inline-flex;
        width: 25%;
        flex-flow: column;
        padding: 0 8px;

        @include breakpoint ('tablet-portrait-max') {
            width: 100%;
            box-shadow: 0 13px 16px 0 rgba(171, 171, 171, 0.06);
            border: solid 1px #f2f2f2;
            padding: 0;
        }

        &__container {
            padding: 0 24px;

            @include breakpoint ('tablet-portrait-min') {
                opacity: 1;
                max-height: fit-content;
                visibility: visible;
                padding: 0;
            }
        }

        &__item {
            position: relative;
            display: flex;
            margin-bottom: 20px;

            @include breakpoint ('tablet-portrait-max') {
                justify-content: flex-end;
            }

            &:first-child {
                @include breakpoint ('tablet-portrait-max') {
                    margin-top: 12px;
                }
            }

            a {
                margin-right: auto;
                @include font-size(14px);

                @include breakpoint ('tablet-portrait-max') {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0;
                    top: 0;
                    @include z-index(one);
                }
            }
        }

        .arrow {
            border-width: 0 1px 1px 0;
            margin-right: 2px;

            @include breakpoint ('tablet-portrait-min') {
                @include hidden();
            }
        }

        .title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            min-height: 32px;

            @include breakpoint('tablet-portrait-max') {
                justify-content: space-between;
                margin-bottom: 0;
                cursor: pointer;
                padding: 10px 24px;
                min-height: auto;
                @include font-size(16px);
            }
        }

        .plus-minus {
            width: 12px;
            height: 12px;

            @include breakpoint('tablet-portrait-min') {
                @include hidden();
            }
        }

        .horizontal {
            transition: all 0.3s ease-in-out;
            transform: rotate(-90deg);
            opacity: 1;
        }

        .vertical {
            transition: all 0.3s ease-in-out;
            transform: rotate(-90deg);
        }

        &.accordion-opened {
            .title {
                color: $red;
                background-color: $gray-ultralight;
            }

            .vertical {
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                background: $red;
            }

            .horizontal {
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }

    &__social {
        display: flex;
        flex-flow: column;
        box-shadow: -8px 15px 16px 15px rgba(171, 171, 171, 0.13);
        border: solid 1px $gray-ultralight;
        padding: 12px 24px;

        @media (max-width: 992px) and (min-width: 768px) {
            flex-flow: row;
        }

        @include breakpoint ('tablet-portrait-min') {
            width: 25%;
            padding: 0;
            justify-content: flex-end;
        }

        img {
            width: 100%;
        }

        &--text {
            padding: 0;

            @include breakpoint ('tablet-portrait-min') {
                padding: 0 10%;
            }
        }

        &--stores {
            display: flex;
            flex-direction: row;
        }

        &--subtitle {
            @include font-size(16px);

            @include breakpoint ('tablet-portrait-min') {
                padding-top: 10px;
                @include font-size(13px);
            }
        }

        .social {
            display: flex;
            justify-content: space-between;
            max-width: 270px;
            margin: 20px 13% 0 0;

            @media (max-width: 992px) and (min-width: 768px) {
                margin-right: 25%;
            }

            @include breakpoint ('tablet-portrait-min') {
                max-width: 200px;
                margin-left: 10%;
            }

            svg {
                margin-right: 10px;
                width: 20px;
                height: 20px;
    
                @include breakpoint ('tablet-portrait-min') {
                    margin-right: 5px;
                }
            }
        }

        .iconlogo-bennet {
            color: $red;
            margin-top: 16px;
        }
    }

    &__c-social {
        @media (max-width: 992px) and (min-width: 768px) {
            flex-basis: 46%;
            margin-top: 28px;
        }
    }

    &__c-app {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 0;
        max-width: 300px;

        @media (max-width: 992px) and (min-width: 768px) {
            margin: 0;
            max-width: inherit;
            align-items: inherit;
            flex-basis: 54%;
        }

        @include breakpoint ('tablet-portrait-min') {
            margin: 10px 10%;
            max-width: 250px;
            align-items: flex-end;
        }

        .app-store {
            @media (max-width: 992px) and (min-width: 768px) {
                margin-top: 28px;
            }

            img {
                width: 100px;
                margin-top: 8px;
                display: block;
                
                @include breakpoint ('phone-min') {
                    display: inline-block;
                    margin: 10px 12px 0 0;
                }

                @include breakpoint ('tablet-landscape-min') {
                    margin: 10px 24px 0 0;
                }
            }
        }

        .sign-of-year {
            width: 90px;
            padding-bottom: 2px;
        }
    }

    &__sub--info {
        display: flex;
        justify-content: space-between;
        color: $white;
        flex-flow: column;
        padding: 16px 24px;
        line-height: 1.5;
        @include font-size(13px);

        @include breakpoint ('tablet-portrait-min') {
            padding: 16px 48px;
            @include font-size(14px);
        }
    }

    &__sub--company {
        padding: 16px 0 8px;

        @include breakpoint ('tablet-portrait-min') {
            padding: 0 0 8px
        }
    }
}


.lite-footer {
    &__service-number {
        padding-left: 15px;
        @include font-size(14px);
    }

    .footer {
        &__slots {
            @include breakpoint ('tablet-portrait-min') {
                padding-top: 10px;
                padding-bottom: 10px;

                .title {
                    margin: 0;
                }
            }
        }

        &__social {
            @include breakpoint ('tablet-portrait-min') {
                flex-flow: initial;
                padding: 0px 45px 0 15px;
                align-items: center;
                justify-content: space-between;
                min-width: 520px;

                &--text {
                    padding: 0;
                }

                &--subtitle {
                    padding: 0;
                    @include font-size(16px);
                }

                .social {
                    margin: 0;

                    a {
                        margin: 0 8px;
                    }
                }
            }
        }

        &__c-social {
            display: inline;

            @media (max-width: 992px) and (min-width: 768px) {
                flex-basis: 100%;
                margin-top: 0;
            }

            @include breakpoint('tablet-portrait-min') {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}
