.carousel-services {
    background-color: $gray-ultralight;
    padding: 24px 0;
    margin: 20px 0;

    @include breakpoint("tablet-portrait-min") {
        padding: 24px 64px;
    }

    .glide {

        &--disabled .glide__slides {
            margin: 0 auto;
            transform: none!important;
        }

        &__arrow {
            top: 60%;

            &--left {
                left: 16px;

                @include breakpoint("tablet-portrait-min") {
                    left: 0;
                }
            }

            &--right {
                right: 16px;

                @include breakpoint("tablet-portrait-min") {
                    right: 0;
                }
            }
        }
    }

    .service-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        &__icon {
            background-color: $white;
            border-radius: 100%;
            display: flex;
            width: 52px;
            height: 52px;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;

            svg:not(.iconservice-a):not(.iconservice-b):not(.iconservice-24):not(.iconservice-10) {
                width: 28px;
                height: 28px;
            }

            img,
            .iconservice-a,
            .iconservice-b,
            .iconservice-24,
            .iconservice-10 {
                width: 45px;
                height: 45px;
            }
        }
    
        &__title {
            text-align: center;
        }
    }
}