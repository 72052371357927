.account-order {
    $self: &;
    box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-bottom: 25px;

    @include breakpoint('tablet-portrait-max') {
        margin-bottom: 16px;
    }

    &.new {
        pointer-events: none;
        background-color: $gray-light-bg;
        opacity: 0.4;
    }

    &__header {
        display: flex;
        border-bottom: 2px solid $gray-ultralight;
        height: 45px;

        @include breakpoint('tablet-portrait-max') {
            height: 50px;
            border-bottom: 3px solid $gray-ultralight;
        }
    }

    &__c-status {
        display: flex;
        align-items: center;
        margin: 0 24px;
    }

    &__status {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        display: inline-block;

        &--green {
            background-color: $green;
        }

        &--op-green {
            background-color: $green;
            opacity: 0.4;
        }

        &--red {
            background-color: $red;
            opacity: 0.5;
        }

        &--yellow {
            background-color: $orange-secondary-first;
            opacity: 0.5;
        }
    }

    &__status-label {
        padding-left: 5px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            padding-left: 12px;
            @include font-size(13px);
        }
    }

    &__cta-label {
        padding-left: 5px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            padding-left: 12px;
            @include font-size(13px);
        }
    }

    &__dropdown-cta {
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            display: flex;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            @include visible();
        }
    }

    &__dropdown-checkbox {
        display: none;

        ~#{ $self }__wrapper-cta {
            @include breakpoint('tablet-portrait-max') {
                display: none;
            }
        }

        &:checked~#{ $self }__wrapper-cta {
            @include breakpoint('tablet-portrait-max') {
                display: block;
            }
        }
    }

    &__c-cta {
        display: flex;
        margin-left: auto;
        position: relative;

    }

    &__wrapper-cta {
        display: flex;
        margin-left: auto;

        @include breakpoint('tablet-portrait-max') {
            position: absolute;
            top: 30px;
            box-shadow: 0 2px 36px 0 rgba(45, 45, 46, 0.2);
            background-color: $white;
            left: -170px;
            min-width: 200px;
        }

        hr {
            border: solid 1px $gray-ultralight;

            @include breakpoint('tablet-portrait-min') {
                @include hidden();
            } 
        }
    }

    &__cta {
        display: flex;
        align-items: center;
        margin: 0 20px 0 5px;
        color: $gray-dark;

        @include breakpoint('tablet-portrait-max') {
            margin: 0;
            padding: 15px 20px;
        }
    }

    &__container {
        padding: 18px 0 12px;
    }

    &__wrapper {
        display: flex;
        margin: 0 -1%;
        justify-content: space-between;
        padding: 0 25px;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
        }
    }

    &__main-info,
    &__delivery,
    &__summary,
    &__actions {
        margin: 0 1%;
    }

    &__main-info {
        @include breakpoint('tablet-portrait-max') {
            display: flex;
            justify-content: space-between;
        }
    }

    &__delivery {
        @include breakpoint('tablet-portrait-max') {
            padding-top: 30px;
        }
    }

    &__number {

        color: $black;
        > h2 {
            font-size: inherit
        }
    }

    &__date {
        color: $gray-light;
        padding-top: 10px;
        > h3 {
            font-size: inherit
        }
        @include font-size(14px);
    }

    &__barcode {
        cursor: pointer;
        margin-top: 35px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            cursor: pointer;
            padding: 0;
            @include font-size(13px);
        }
    }

    &__delivery-mode {
        margin-top: -4px;
        white-space: nowrap;
    }

    &__delivery-place {
        color: $black;
        padding: 5px 0 10px;

        @include breakpoint('tablet-portrait-max') {
            @include font-size(13px);
        }
    }

    &__delivery-date {
        padding-bottom: 10px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            display: inline-block;
            @include font-size(13px);
        }
    }

    &__summary {
        text-align: right;

        @include breakpoint('tablet-portrait-max') {
            display: inline-block;
            text-align: left;
            padding-top: 10px;
        }
    }

    &__points {
        padding-top: 10px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(13px);
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: row-reverse;
            padding-top: 20px;
            justify-content: space-between;
        }
    }

    &__reorder {
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            padding: 0 25px;
            @include btn-mobile('md');
        }
    }

    &__add-to-list {
        margin-top: 12px;
        padding: 0 2px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            margin: 0;
            @include btn-mobile('md');
        }

        .iconsavelist {
            width: 22px;
            height: 22px;
        }

        b {
            margin-left: 5px;
        }
    }

    &__details {
        padding: 0 25px;

        @include breakpoint('tablet-portrait-max') {
            float: left;
            visibility: hidden;
            padding: 0;
            opacity: 0;
            width: 100%;
            left: 0;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            position: fixed;
            top: 0;
            right: 0;
            max-height: 100vh;
            max-height: calc(var(--vh, 1vh) * 100);
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $white;
            z-index: z-index('backdrop-up');
            transition: visibility .3s, opacity .3s ease-in-out;
        }
    }

    &__footer-mobile {
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            background-color: white;
            position: fixed;
            bottom: 0;
            height: 75px;
            box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 0 24px;
            @include visible();
        }
    }

    .js-accordion-list {
        @include breakpoint('tablet-portrait-max') {
            max-height: unset;
        }
    }

    &--removed {
        #{ $self } {
            &__status {
                background-color: $red;
                opacity: 0.5;
            }
            &__cta--change,
            &__cta--remove,
            &__barcode {
                @include hidden();
            }

            &__summary {
                color: $gray-light;
            }
        }
    }
}

.order-entries {  
    &__header {

        @include breakpoint('tablet-portrait-max') {
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        }

        &--mobile {
            @include hidden();

            @include breakpoint('tablet-portrait-max') {
                padding: 18px 24px;
                @include visible();
            }
        }

        .account-order__number {
            @include font-size(16px);
        }
    }

    &__barcode {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0;
        flex-flow: column;

        @include breakpoint('tablet-portrait-max') {
            padding: 24px;
        }

        &--info {
            line-height: 1.5;
            padding-bottom: 36px;
            @include font-size(14px);
        }

        img {
            width: 275px;
        }
    }

    &__wrapper {
        @include breakpoint('tablet-portrait-max') {
            max-height: calc(100vh - 75px - 52px);
            max-height: calc(var(--vh, 1vh) * 100 - 75px - 52px);
            overflow-y: auto;
        }
    }

    &__c-category {
        padding-top: 20px;

        @include breakpoint('tablet-portrait-max') {
            padding-top: 16px;
        }
    }

    &__container {
        @include breakpoint('tablet-portrait-max') {
            padding: 0px 24px;
        }
    }
    
    &__category-header {
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        
        @include breakpoint('tablet-portrait-max') {
            border-bottom: solid 2px $gray-light;
        }
    }

    &__left-header {
        width: 50%;
        display: flex;
        align-items: center;

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
        }
    }

    &__category-icon {
        width: 30px;
        height: auto;
        margin-right: 15px;

        @include breakpoint('tablet-portrait-max') {
            width: 25px;
        }
    }

    &__quantity,
    &__specifications {
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }  
    }

    &__quantity {
        width: 30%;
    }

    &__specifications {
        width: 20%;
    }
    
    .order-entry.js-article[data-code="P_2768182"],
    .order-entry.js-article[data-code="P_2995942"],
    .order-entry.js-article[data-code="P_3233681"]  {
        @include hidden();
    }
}