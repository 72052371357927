.checkout {
    display: flex;
    margin-top: 40px;
    flex-direction: column;

    @include breakpoint('tablet-portrait-max') {
        flex-direction: column;
        margin: 0;
    }

    &__container {
        display: flex;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
        }
    }

    &__left-panel,
    &__c-info-box {
        flex: 0 0 73%;
        max-width: 73%;
        border-left: none;
        padding-right: 15px;
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            max-width: none;
            padding: 0;
        }          
    }

    &__c-steps {
        padding-left: 24px;

        @include breakpoint('tablet-portrait-max') {
            padding: 12px 0 0;
        }           
    }

    &__summary,
    &__c-help {
        flex: 0 0 27%;
        max-width: 27%;
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            max-width: none;
            padding: 0;
        }  
    }

    &__summary-wrapper  {
        position: sticky;
        top: 95px;

        @include breakpoint('tablet-portrait-max') {
            position: relative;
            top: 0;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        padding: 20px 0;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
            align-items: initial;
            padding: 0;
        }
    }

    .checkout-info-deliv {       
        padding: 15px;
        color: $gray;
        line-height: 1.5;
        @include font-size(14px);
    
        @include breakpoint('tablet-portrait-max') {
            padding: 20px 16px;
            background-color: $gray-ultralight;
            @include font-size(13px);
        }

        ul {
            padding-left: 16px;
        }

        li {
            list-style: disc;
        }
    }

    &__c-help {
        @include breakpoint('tablet-portrait-max') {
            background-color: $gray-ultralight;
            padding: 20px 16px 40px;
            justify-content: flex-end;
            flex-direction: row;
        }
    }

    &__info-box {
        padding-left: 25px;

        @include breakpoint('tablet-portrait-max') {
            background-color: $gray-ultralight;
            padding: 12px 16px;
        }

        .content{
            text-align: justify;
            line-height: 1.5;
            color: $gray;
            @include font-size(11px);
        }
    }

    &__help {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 20px;

        @include breakpoint('tablet-portrait-max') {
            margin: 0;
            padding: 0;
        }

        .btn {
            width: 120px;
            @include font-size(13px);
        }
    }

    &__help-text {
        padding-right: 20px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            font-weight: bold;
        }
    }
}


.receipt-info {
    padding: 16px 0;

    .tooltipBubble {
        width:210px;
        top: -100px;
        left: -90px;
        font-size: 12px;
        line-height: normal;
        text-align: center;
        background-color: $gray-dark;
        padding: 8px 12px 10px;

        @include breakpoint('tablet-portrait-max') {
            display: block;
        }

        &:after {
            border-bottom: none;
            border-top: 5px solid $black;
            bottom: -5px;
            left: 50%;
            margin-left: -2.5px;
        }
    }
}
