/*! critical:start */
.header {
        
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include z-index(header);

    &__break {
        position: relative;
        max-width: 1680px;
        display: flex;
        padding: 0 25px;
        width: 100%;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 10px 0 5px;
            //max-width: 100%;
        }
    }

    &__top-bar {
        background-color: $green-background;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        //padding-right: 32px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            height: 30px;
            @include font-size(12px);
            @include z-index(backdrop-up);
        }

        &--c-links {
            display: flex;
            align-items: center;
            margin: 0 -25px;
            max-width: 600px;

            @include breakpoint('tablet-landscape-max') {
                margin: 0 -16px;
            }
        }

        &--link {
            margin: 0 25px;

            @include breakpoint('tablet-landscape-max') {
                margin: 0 16px;
            }
        }


        &--link > a {
            overflow: hidden;
        }

        &--c-right {
            margin-left: auto;

            @include breakpoint('tablet-portrait-max') {
                margin: 0;
                display: flex;
                flex: 1;
            }

            @media only screen and (max-width: 390px) {
                justify-content: space-between;
            }
        }

        &--info {
            padding: 0 16px;

            @include breakpoint('tablet-portrait-max') {
                line-height: normal;
            }

            @media only screen and (max-width: 390px) {
                padding: 0;
            }
        }

        &--order {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 390px) {
                
            }
        }

        &--btn {
            height: 22px;
            padding: 0 12px;
            margin-left: 16px;
            @include font-size(12px);

            @include breakpoint('tablet-portrait-max') {
                margin-left: 8px;
            }

            @media only screen and (max-width: 390px) {
                padding: 0 4px;
                flex: 1;
                line-height: normal;
            }
        }
    }

    &__top {
        background-color: $red;
        color: $white;
        height: 55px;
        display: flex;
        justify-content: center;
        position: relative;

        //&--flier, 

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }

        &--links {
            display: inline-flex;
            align-items: center;
            height: 100%;
            margin: 0 10px 0 190px;
            flex: 0 1 35%;
            justify-content: space-around;

            @include breakpoint('desktop-small-max') {
                margin: 0 5px 0 180px;
                flex: 0 1 40%;
            }

            @include breakpoint('tablet-landscape-max') {
                margin: 0 5px 0 150px;
            }

            .header-links {
                display: flex;
                flex: 1;
                justify-content: space-between;
            }

            a {
                color: $white;
                font-weight: bold;
                margin: 0 5px;
                display: inline-flex;
                align-items: center;
            
                @include breakpoint('desktop-small-max') {
                    @include font-size(14px);
                }
            }

            a[href*="instacook"] .iconinstacook {
                display: block;
            }
        }

        hr {
            margin: 0.8em 0;
            background-color: $white;
            width: 2px;
            height: auto;
            border-radius: 15px;
        }

        &--c-right {
            display:flex;
            margin-left: auto;
            flex: 0 1 48%;
            max-width: 525px;
            justify-content: flex-end; //redheader //redheader //redheader

            @include breakpoint('tablet-landscape-max') {
                flex: 0 1 45%;
            }
        }
        &--pos, &--club {
            display: inline-flex;
            align-items: center;
            margin: 5px 16px;
            cursor: pointer;
            white-space: nowrap;
            min-width: 170px;
            background-color: transparent;
            border: none;
            color: #fff;
            //justify-content: center; //redheader //redheader
            //flex:1; //redheader

            @include breakpoint('tablet-landscape-max') {
                margin: 20px 8px;
            }

            .small-title {
                @include font-size(13px);
            }

            .title {
                font-weight: bold;
                @include font-size(14px);
            }

            .iconuser, .icondrive, .iconflier {
                margin-right: 10px;

                @include breakpoint('desktop-small-max') {
                    width: 25px;
                    height: 25px;
                }
            }

            a {
                color: $white;
                font-weight: bold;
                @include font-size(14px);
            }
        }

        &--club {
            div {
                @include hidden();
                @include font-size(14px);

                &.active {
                    padding-top: 4px;
                    overflow: visible;
                    @include visible();
                }
            }
        }

        &--pos {
            margin-right: auto; //redheader
        }
    }

    &__bottom {
        background-color: $white;
        border-bottom: 1px solid $gray-light;
        height: 80px;
        display: flex;
        justify-content: center;
        position: relative;

        @include breakpoint('tablet-portrait-max') {
            background-color: $red;
            height: 52px;
            border-bottom: 0;
            @include z-index(backdrop-up);
        }

        &--logo {
            position: absolute;
            bottom: 95px;
            left: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: auto;
            transition: all 0.3s ease-in-out;

            @include breakpoint('tablet-portrait-max') {
                position: initial;
                order: 2;
                width: auto;
            }

            svg {
                width: 170px;
                height: 25.73px;
                color: $white;
                
                @include breakpoint('desktop-small-max') {
                    width: 150px;
                }

                @include breakpoint('tablet-landscape-max') {
                    width: 130px;
                }

                @include breakpoint('tablet-portrait-max') {
                    height: 25px;
                    max-width: 100%;
                }
            }
        }

        &--navigation {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex: 0 0 17%;
            order: 2;
            margin-left: 160px;

            @include breakpoint('tablet-portrait-max') {
                order: 1;
                margin: 0;
                flex: initial;
            }
        }

        &--search {
            padding-right: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex: 0 4 47%;
            order: 3;

            .searchbox {
                width: 100%;
                height: 45px;
                max-width: 800px;

                @include breakpoint('tablet-portrait-max') {
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 82px; //new topbar
                    max-width: none;
                    background-color: $white;
                    height: initial;
                    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
                }

                form {
                    height: 100%;
                    width: 100%;
                    display: inline-flex;
                    -webkit-box-pack: start;
                    justify-content: flex-start;
                    -webkit-box-align: center;

                    @include breakpoint('tablet-portrait-max') {
                        padding: 10px 16px;
                        max-width: none;
                    }

                    .smarteditpanel-cta {
                        display: none;
                    }
                }
                
                input {
                    width: 100%;
                    height: 100%;
                    border: solid 2px #888888;
                    border-radius: 5px;
                    padding-left: 12px;
                    background-color: $white;
                    @include font-size(16px);

                    @include breakpoint('tablet-portrait-max') {
                        height: 42px;
                        @include font-size(14px);
                    }

                    &::placeholder {
                        font-style: italic;
                    }

                    &:focus {
                        border-color: $green;
                        outline: none;
                    }

                    svg {
                        @include breakpoint('tablet-portrait-max') {
                            width: 22px;
                            height: 22px;
                            cursor: pointer;
                        } 
                    }


                }

                &__button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    padding: 0 15px;
                    font-size: 1rem;
                    background-color: $green;
                    color: $white;
                    cursor: pointer;
                    border: 0;
                    text-align: center;
                    margin: 0;
                    position: relative;
                    left: -5px;
                }
            }
        }
        
        &--c-right {
            display: flex;
            margin-left: auto;
            flex: 0 0 24%;
            justify-content: space-around;
            max-width: 350px;
            order: 4;

            @include breakpoint('tablet-portrait-max') {
                margin-right: 5px;
                width: 50%;
                flex: none;
            }
        }

        &--timeslot,
        &--shoppinglist, 
        &--minicart, 
        &--club,
        &--drive, 
        &--search-button {
            display: inline-flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            margin: 10px;
            text-align: center;
            cursor: pointer;
            position: relative;
            color: $gray-dark;
            background-color: transparent;
            border: none;

            @include breakpoint('tablet-portrait-max') {
                margin: 0 10px;

                svg {
                    color: $white;
                    cursor: pointer;
                }
            }

            &.empty {
                cursor: default;
                &:hover {
                    color: inherit;
                };
            }

            a, span {
                font-weight: 600;
                padding-top: 5px;
                //color: $gray-dark;
                //cursor: pointer;
                @include font-size(13px);

                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }

            .cart-mobile {
                padding: 5px;
                @include visible();
            }

            .number-of-products:not(:empty) {
                position: absolute;
                top: -2px;
                border-radius: 100%;
                width: 22px;
                height: 22px;
                right: -2px;
                background: $green;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                color: $white;
                @include font-size(11px);

                @include breakpoint('tablet-portrait-max') {
                    color: $green;
                    background-color: $white;
                    position: absolute!important;
                    right: 0px;
                    top: 3px;
                    width: 20px!important;
                    height: 20px!important;
                    font-weight: bold;
                    @include visible();
                }
            }

            .wishlist-link {
                padding-top: 0;
                flex-flow: column;
                display: flex;
                align-items: center;
            }
        }

        &--minicart {
            @include breakpoint('tablet-portrait-max') {
                margin: 0 5px;
            }
        }

        &--club,
        &--drive,
        &--search-button {
            @include breakpoint('tablet-portrait-min') {
                @include hidden();
            }
        }

        &--timeslot,
        &--shoppinglist {
            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }
    }
}

.lite-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
    border-bottom: solid 1px $gray-ultralight;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 45%, $gray-ultralight 280%);
    @include z-index(header);

    @include breakpoint('tablet-portrait-max') {
        background-image: none;
    }


    &__top {
        height: 13px;
        background-color: $red;

        @include breakpoint('tablet-portrait-max') {
            height: 6px;
        }
    }

    &__bottom {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0;
        margin: auto;
        border: solid 1px #888888;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 90%, #333333 280%);

        @include breakpoint('tablet-portrait-max') {
            height: 46px;
        }
    }

    &__logo {
        left: 35px;
        position: absolute;

        @include breakpoint('tablet-portrait-max') {
            left: 15px;
        }

        svg {
            width: 170px;
            height: 25.73px;
            color: $red;
            
            @include breakpoint('desktop-small-max') {
                width: 150px;
            }

            @include breakpoint('tablet-landscape-max') {
                width: 130px;
            }

            @include breakpoint('tablet-portrait-max') {
                width: 82px;
            }
        }
    }

    &__title {
        font-weight: bold;
        @include font-size(24px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(16px);
        }
    }

    &__go-cart {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: 60px;

        @include breakpoint('tablet-portrait-max') {
            right: 35px;
        }
    }

    &__go-cart-label {
        padding-top: 5px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            @include hidden();
        }
    }

    & + .container {
        @include breakpoint('tablet-portrait-max') {
            margin-top: 70px;
        } 
    }
}
/*! critical:end */