.landing-rewards {
    overflow: hidden;

    &__bar {
        display: flex;
        background-color: $gray-ultralight;
        padding: 20px 16px;
        flex-flow: column;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 65px;
            flex-flow: row;
            align-items: center;
        }
    }

    &__c-bar {
        display: flex;
        margin-left: auto;
        margin-top: 12px;

        @include breakpoint('tablet-portrait-min') {
            margin-top: 0;
        }
    }

    &__bar-cta {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        font: inherit;
        font-weight: bold;

        span {
            padding-top: 10px;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-min') {
                padding-top: 5px;
            }
        }

        .share {
            display: flex;

            &__cta {
                margin: 5px 10px;
            }
        }
    }

    &__c-iframe {
        margin-bottom: 32px;
        padding-top: 540px;

        @include breakpoint('tablet-portrait-min') {
            margin-bottom: 40px;
            padding-top: 980px;
        }
    }
    
    &__banner {
        display: flex;
        flex-flow: column;
        background-image: linear-gradient(to bottom, rgba(233, 233, 233, 0.7) 50%, #ffffff 221%);
        margin: 32px 16px;

        @include breakpoint('tablet-portrait-min') {
            flex-flow: row;
            align-items: center;
            margin: 32px 65px;
        }

        img {
            width: 100%;

            @include breakpoint('tablet-portrait-min') {
                width: 60%;
            }            
        }

        &--box {
            padding: 24px 16px; 

            @include breakpoint('tablet-portrait-min') {
                padding: 0 0 0 40px;
            }   
        }

        &--title {
            text-transform: uppercase;
            padding-bottom: 24px;
            line-height: 1.5;
            @include font-size(16px);

            @include breakpoint('tablet-portrait-min') {
                padding-bottom: 8px;
                @include font-size(20px);
            }  
        }

        &--cta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 24px;

            @include breakpoint('tablet-portrait-min') {
                justify-content: flex-start;
                padding-top: 16px;

                a:first-child {
                    margin-right: 24px;
                }
            }

            .btn {
                flex-basis: 70%;

                @include breakpoint('tablet-portrait-min') {
                    flex-basis: 60%;
                    min-width: 218px;
                } 
            }
        }
    }

    &__title {
        display: flex;
        flex-flow: column;
        padding: 0 16px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 65px;
        }

        .btn {
            margin-top: 24px;
            border: 0;
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);

            @include breakpoint('tablet-portrait-min') {
                padding: 0 32px;
                height: 60px;
                margin-top: 0;
            }

            .arrow {
                margin-left: 32px;
                padding: 3px;
            }
        }
    }

    &__list {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        padding: 16px;
        margin: 0 -12px;
        //display: grid;
        //grid-template-columns: 47% 47%;

        @include breakpoint('tablet-portrait-min') {
            //grid-template-columns: 30% 30% 30%;
            margin: 0 -24px;
            padding: 16px 65px;
        } 

        img {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s;
        }

        &--item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 12px;
            overflow: hidden;
            width: calc(50% - 24px);

            &:hover img {
                transform: scale(1.2);
            }

            @include breakpoint('tablet-portrait-min') {
                width: calc(33.3333% - 48px);
                margin: 12px 24px;
            } 
    
            >span {
                position: absolute;
                width: 60%;
                text-align: center;
                line-height: 1.5;
                color: $white;
                @include font-size(16px);
                
                @include breakpoint('phone-min') {
                    @include font-size(24px);
                } 
                @include breakpoint('tablet-portrait-min') {
                    @include font-size(26px);
                } 
            }
        }
    }
}