.account-nav {
    .arrow__right:not(.arrow__card) {        
        @include breakpoint ('tablet-portrait-min') {
            @include hidden();
        }
    }

    .bell {
        animation: ring-bell 3s ease infinite;
        color: $gray-dark;
        margin-left: 8px;
        transform: rotate(-10deg);
    }
    
    @keyframes ring-bell {
        0%{
            transform: rotate(0deg);
        }
        10%, 20%, 30%, 40%, 50%, 60%{
            transform: rotate(5deg);
        }
        15%, 25%, 35%, 45%, 55%{
            transform: rotate(-5deg);
        }
        70%, 100%{
            transform: rotate(0deg);
        }
    }
    
    &__banner {
        height: 140px;

        .img-cover {
            img {
                position: relative;
                left: -30px;
                object-position: left;
                width: auto;

                @include breakpoint ('tablet-portrait-max') {
                    object-position: right;
                    //object-fit: none;
                    left: -210px;
                }
            }
        }
    
        .content {
            padding: 16px 0 16px 165px;
            flex-direction: column;
            justify-content: space-between;

            &__right {
                flex-direction: column;
            }
    
            &__card {
                order: 2;
            }
        }
    }

    &__title {
        box-shadow: 0 13px 16px 0 rgba(171, 171, 171, 0.08);
        padding: 11px 24px;
        border-top: 2px solid $gray-ultralight-rgb-8;
        font-weight: bold;
        @include font-size(16px);

        @include breakpoint ('tablet-portrait-max') {
            @include font-size(16px);
        }

        a {
            @include breakpoint('tablet-portrait-max') {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    &__container {
        padding: 16px 40px;
        color: $gray-dark;
        display: flex;
        flex-flow: column;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            padding: 16px 24px;
        }

        &.buttons {
            justify-content: space-around;
            flex-flow: row;

            @include breakpoint ('tablet-portrait-max') {
                flex-flow: column;
            }
        }

        a {
            margin: 8px 0;
            display: inline-flex;
            font-weight: normal;

            @include breakpoint ('tablet-portrait-max') {
                margin: 16px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include font-size(13px);
            }

            >span {
                display: flex;
                align-items: center;
            }

            &.red {
                color: $red;

                .bell {
                    color: $red;
                }
            }

            &:hover {
                color: $green;

                .bell {
                    color: $green;
                }
            }
        }

        &--component {
            position: relative;

            a {
                @include breakpoint ('tablet-portrait-max') {
                    position: relative;
                    @include z-index(one);
                }
            }

            .arrow__right {
                @include breakpoint ('tablet-portrait-max') {
                    position: absolute;
                    top: 35%;
                    right: 0;
                }
            }
        }

        &--item {
            display: inline-flex;
            flex-flow: column;
            width: 220px;

            @include breakpoint ('tablet-portrait-max') {
                width: auto;
            }

            a { 
                @include breakpoint ('tablet-portrait-max') {
                    justify-content: center;
                    height: 50px;
                }

                &:hover {
                    color: $white;
                }
            }

            span {
                text-align: center;
                padding: 8px 0 16px;

                @include breakpoint ('tablet-portrait-max') {
                    text-align: left;
                    padding: 0;
                }
            }
        }
    }

    &__logout {
        padding: 0 24px 20px;

        @include breakpoint('tablet-portrait-max') {
            text-align: right;
            margin-top: 0px;
        }

        a {
            padding: 0 32px;
            
            @include breakpoint('tablet-portrait-max') {
                padding: 0 40px;
                @include btn-mobile('xs');
                @include font-size(13px);
            }
        }
    }
}