.step {
    $self: &;
    border-radius: 8px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-bottom: 24px;

    @include breakpoint('tablet-portrait-max') {
        border-radius: 0;
        margin-bottom: 15px;
        padding: 16px;
    }

    &__summary {
        padding-top: 10px;
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            padding-top: 4px;
            @include font-size(13px);
        }
    }

    &--disabled {
        #{ $self } {  
            
            @include breakpoint('tablet-portrait-max') {
                padding: 15px;
            }
            
            &__title {
                color: $gray-light;
            }

            &__edit,
            &__container,
            &__summary {
                @include hidden();
            }

            &__edit .iconpencil {
                @include breakpoint('tablet-portrait-max') {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    &--closed {
        #{ $self } {

            @include breakpoint('tablet-portrait-max') {
                padding: 15px;
            }

            &__container{
                @include hidden();
            }

            &__edit,
            &__summary {
                @include visible();
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        @include font-size(20px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(16px);
        }
    }

    &__edit {
        cursor: pointer;
        @include hidden();
    }

    &__container {
        padding-top: 25px;

        @include breakpoint('tablet-portrait-max') {
            padding-top: 12px;
        }
    }

    &__summary {
        color: $gray;
        line-height: normal;
    }

    &__footer {
        padding-top: 15px;
        display: flex;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
            padding-top: 10px;
        }
    }

    &__go-next-step {
        padding: 0 60px;
        margin-left: auto;

        @include breakpoint('tablet-portrait-max') {
            padding: 0 35px;
            @include font-size(13px);
            @include btn-mobile('sm');
        }
    }
}

.step-one {

    .box {
        border-radius: 4px;
        border: solid 1px rgba(45, 45, 46, .3);
        cursor: pointer;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

        &:hover {
            border: 1px solid $green;
        }

        &-day {
            padding: 15px 12px;
            line-height: 1.5;
            text-transform: capitalize;
            word-break: break-word;

            @include breakpoint('tablet-portrait-max') {
                padding: 10px 8px;
            }

            &__title {
                margin: auto;
                width: 80px;
            }
        }

        &-hours {
            padding: 12px 18px;
            width: 170px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include breakpoint('tablet-portrait-max') {
                padding: 5px 15px;
                width: 155px;
            }

            .iconsunrise,
            .iconsunset {
                @include breakpoint('tablet-portrait-max') {
                    margin-left: 35px;
                    height: 18px;
                    width: 18px;
                }
            }
        }

        &-time-slot {
            margin: 0 15px 15px 0;
            padding: 10px 6px;
            border: solid 1px rgba(76, 187, 80, .3);
            color: #4cbb50;

            @include breakpoint('tablet-portrait-max') {
                padding: 8px 6px;
                line-height: normal;

                span {
                    display: block;
                }
            }

            &__info-separator {
                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }

            &__info,
            &__price {
                @include font-size(14px);
            }

            &__price {
                text-align: right;
                padding-top: 6px;
            }
        }

        &--almost-full {
            border: solid 1px rgba(255, 159, 28, .3);
            color: #ff9f1c;
        }

        &--disabled {
            border: solid 1px rgba(189, 189, 189, .3);
            color: #bdbdbd;
            pointer-events: none;

            .box-time-slot__price {
                @include hidden();
            }
        }

        &--selected {
            box-shadow: 0 0 30px 0 rgba(68, 163, 61, .1);
            border: solid 1px #44a33d;
            background-color: #44a33d;
            color: $white;
            pointer-events: none;

            .iconsunrise,
            .iconsunset {
                color: $white;
            }
        }
    }

    .days {
        &__carousel {
            .glide__arrow {
                position: initial;
                display: inline-block;
                box-shadow: none;
                transform: none;
                width: auto;
                height: auto;

                &--disabled {
                    color: $gray-light;
                }

                .arrow {
                    border: solid #333;
                    border-width: 0 2px 2px 0;
                    padding: 4px;
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
        }

    }

    &__c-hours {
        padding-top: 25px;
    }

    .hours {
        &__c-daily-phases {
            padding-top: 20px;
            display: flex;

            @include breakpoint('tablet-portrait-max') {
                white-space: nowrap;
                overflow-x: auto;
            }
        }

        &__time-container {
            overflow-x: auto;
        }

        &__time-slot {
            display: flex;
            flex-flow: wrap;
            padding-top: 25px;
            min-width: 600px;
            position: relative;
        }
    }

    &__time-info {
        line-height: 1.5;

        @include breakpoint('tablet-portrait-max') {
            order: 2;
            padding-top: 25px;
            @include font-size(13px);
        }
    }

    &__time-info-mode {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }
}

.step-two {
    .services {
        display: flex;
        flex-direction: column;

        tbody,
        th {
            display: flex; // IE11
        }

        tbody {
            flex-direction: column; // IE11
        }

        tr {
            display: flex;
            align-items: center;
            padding: 8px 0;

            @include breakpoint('tablet-portrait-max') {
                justify-content: space-between;
            }

            &:first-child {
                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }
        }

        td,th {
            display: flex;
            flex: 1;
            line-height: 1.5;  // IE11

            &:nth-child(1) {
                flex: 2;
                flex-flow: column;
            }

            &:nth-child(2) {
                display: flex;
                justify-content: space-between;
                flex: 2;

                @include breakpoint('tablet-portrait-max') {
                    flex: 1;
                    flex-flow: column;
                }

                >span{
                    text-align: center;
                    width: 50%;

                    @include breakpoint('tablet-portrait-max') {
                        width: 100%;
                    }

                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
        
        &__quantity {
            max-width: 150px;
            margin-left: auto;
         
            .product-qnt__less,
            .product-qnt__plus {
                font-weight: 400;
                padding-bottom: 5px;
                @include font-size(28px);
            }
        }
    }

    &__toggle-services {
        padding: 12px 0 15px;
        display: flex;
        align-items: center;

        .iconpromo-off,
        .iconpromo-on {
            width: 120px;
            cursor: pointer;
            flex: none;

            @include breakpoint('tablet-portrait-max') {
                width: 45px;
            }
        }
    }

    &__toggle-services-label {
        padding-left: 15px;
    }

    &__c-checkbox {
        padding-top: 15px;

        input[type="checkbox"] + label {
            @include breakpoint('tablet-portrait-max') {
                display: inline-block;
            }

            &:after,
            &:before {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.step-three {

    &__info {
        padding-bottom: 10px;
    }

    &__c-checkbox {
        padding-top: 15px;

        input[type="checkbox"] + label {
            @include breakpoint('tablet-portrait-max') {
                display: inline-block;
            }

            &:after,
            &:before {
                height: 20px;
                width: 20px;
            }
        }
    }
}
