.event-careers {

    @include breakpoint("tablet-portrait-min") {
        padding: 8px 0;
    }

    &__header {
        padding: 0 16px 24px;

        @include breakpoint("tablet-portrait-min") {
            padding: 0 0 24px;
        }
    }

    &__title {
        padding-bottom: 15px;
        @include font-size(24px);

        @include breakpoint("tablet-portrait-min") {
            padding-bottom: 20px;
            @include font-size(30px);
        }
    }

    &__text {
        line-height: normal;
        @include font-size(16px);
    }

    &__ctn {
        @include breakpoint("tablet-portrait-min") {
            display: flex;
            align-items: center;
        }
    }

    &__c-banner {
        padding: 0 16px;

        @include breakpoint("tablet-portrait-min") {
            display: flex;
            padding: 0;

            &--multi {
                width: 42%;
            }

            &--single {
                width: 75%;
            }
        }
    }

    &__carousel {
        @include breakpoint("tablet-portrait-min") {
            &--multi {
                width: 58%;
                padding: 0 68px 0 80px;
            }

            &--single {
                width: 25%;
                padding: 0 0 0 32px;
            }

            &:not(.glide--disabled) .glide__track {
                box-shadow: 0 1px 20px 0 rgba(110, 110, 110, 0.19);
            }
        }

        &.glide--disabled {
            @include breakpoint("tablet-portrait-min") {
                padding: 0 0 0 32px;
            }
    
            .glide {
                &__track {
                    overflow: initial;
                }
    
                &__slides {
                    width: 100% !important;
                    transform: none !important;
                    padding: 0 16px;
    
                    @include breakpoint("tablet-portrait-min") {
                        padding: 0;
                    }
                }
    
                &__slide {
                    flex: 1;
                }
            }
        }
        
    }

    &__carousel-title {
        font-size: 18px;
        padding-bottom: 16px;
    }

    .glide {
        &__slide {
            display: flex;
            height: auto;
            margin: 30px 0 16px;

            @include breakpoint("tablet-portrait-min") {
                margin: 0;
            }
        }

        &__arrow {
            &--left {
                left: -55px;
            }

            &--right {
                right: -65px;
            }
        }
    }
}


