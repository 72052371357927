
        .faq-container-list{
            padding: 24px 65px 0px 63px;

            @include breakpoint ('tablet-portrait-max') {
                padding: 16px 16px 0 16px;
            }

            .section-title{
                display: flex;
                flex-direction: column;

                .section-label{
                 line-height: 1.5;
                 padding-bottom: 8px;
                 color: #000000;
                }

                hr{
                    width: 100px;
                    background: #000000;
                }
            }

            .faq-box{
                .faq__container{
                    margin-top: 16px;
                    padding: 0;
                    background-color: #ffffff;
                    border-bottom: none;

                    .js-accordion-selector{
                        padding: 16px 24px 16px 24px;

                        @include breakpoint ('tablet-portrait-max') {
                            padding: 12px 16px 12px 16px;
                        }
                    }

                    .js-accordion-list{

                        .faq__response{
                            padding: 16px 24px 16px;
                            border-top: 1px solid #e0e0e0;
                            line-height: 1.71;

                            @include breakpoint ('tablet-portrait-max') {
                                padding: 16px 16px;
                            }
                        }
                    }
                }
            }

            &:last-child{
                padding-bottom: 24px;
            }
        }

