.bennetcard-carousel {    
    &__title {
        padding-left: 16px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0;
        }
    }

    &__c-carousel {
        @include breakpoint('tablet-portrait-min') {
            margin: 0 -16px;
        }
    }  

    .glide {
        &__slide {
            display: flex;
            height: auto;
            margin: 24px 0;
        }

        &__arrow {
            &--left {
                left: 16px;
            }

            &--right {
                right: 16px;
            }
        }
    }
}