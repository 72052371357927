.magazine-highlight {
    $self: &;
    padding: 16px;

    @include breakpoint('tablet-portrait-min') {
        padding: 20px 65px;
    }

    &--ONEONTHREE {
        @include breakpoint('tablet-portrait-min') {
            flex-basis: 33.3%;
        }    
    }
    &--TWOONTHREE {
        @include breakpoint('tablet-portrait-min') {
            flex-basis: 66.6%;
        }  
    }

    &--ONEONTHREE,
    &--TWOONTHREE {
        @include breakpoint('tablet-portrait-min') {
           display: flex;
           flex-direction: column;

           #{ $self }__description {
                flex: 1;
            }
        }
    }
    
    &--THREEONTHREE {
        @include breakpoint('tablet-portrait-min') {
            flex-basis: 100%;
        }
    }

    &__description {
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
        padding-bottom: 8px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            padding-bottom: 30px;
            @include font-size(28px);
        }
    }

    &__text {
        line-height: 1.71;
        overflow: hidden;
        max-height: 3000px;
        transition: max-height 0.3s ease-out;
        height: auto;
        @include font-size(14px);

        ul, ol { 
            display: block;
            margin: 1em 0;
            padding: 0 0 0 40px;
        }

        ul { 
            list-style-type: disc; 
         }

        ol { 
            list-style-type: decimal; 
        }

        li {
            list-style: inherit;
        }

        @include breakpoint('tablet-portrait-min') {
            line-height: 1.55;
            @include font-size(16px);
        }

        &.toggle-reduce {
            max-height: 70px;

            @include breakpoint('tablet-portrait-min') {
                max-height: none;
            }
        }
    }

    &__btn {
        display: flex;
        padding-top: 32px;
        justify-content: flex-end;
        align-items: center;

        @include breakpoint('tablet-portrait-min') {
            padding-top: 56px;
        }
        .btn {
            padding: 10px;
            width: 100%;

            @include breakpoint('tablet-portrait-min') {
                width: auto;
            }
        }
    }

    &__c-banner {
        display: flex;
        flex-flow: wrap;
        margin: 0 -5px;

        &--3 .highlight-banner {  
            &:nth-child(-n + 2) {
                flex: 1;
            }

            @include breakpoint('tablet-portrait-min') {
                &:first-child {
                    flex: 2;
                }

                &:not(:first-child) {
                    flex: 1;
                }
            }
        }
    }

    &__cta {
        display: flex;
        justify-content: center;
        padding: 16px;
    }

    &__read-all {
        @include font-size(14px);
    }

    &--1:not( &--ONEONTHREE):not( &--TWOONTHREE) {
        @include breakpoint('tablet-portrait-min') {
            display: flex;
            flex-flow: wrap;

            // #{ $self }__cta {
            //     width: 100%;
            // }

            #{ $self }__description {
                width: 55%;
                padding-right: 5%;
                justify-content: start;
            }

            #{ $self }__wrapper {
                width: 45%;
            }

            .highlight-banner {
                margin-top: 0;
            }
        }
    }
}

.highlight-banner {
    margin: 5px;
    flex: 1 1 100%;

        @include breakpoint('tablet-portrait-min') {
            flex: 1;
        }

    > * {
        border-radius: 4px;
    }

    iframe,video {
        width: 100%;
    }
}