.header-mobile-panel{
    $self: &;

    display: flex;
    background-color: $white;
    padding: 0 24px;
    align-items: center;
    border-bottom: 2px solid $gray-ultralight;
    height: 52px;
    position: sticky;
    top: 0;
    @include z-index(filter-mobile);

    img {
        height: 40px;
    }

    &__title {
        padding-left: 10px;
        font-weight: bold;
        display: inline-block;
        @include font-size(16px);
    }

    &__close {
        margin-left: auto;
        cursor: pointer;
        padding: 5px 0 5px 5px;
    }

    &--red {
        background-color: $red;
        padding: 0 20px 0 10px;
        color: $white;
        border: none;

        #{ $self }__title {
            font-weight: normal;
            @include font-size(20px);
        }
    }
}