.homepage-static-step {
    &__wrapper {
        border-radius: 4px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: $white;
        margin: 0 16px;
        padding: 24px 16px 8px;

        @include breakpoint("tablet-portrait-min") {
            margin: 0 64px;
            padding: 24px 32px;
        }
    }

    &__title {
        line-height: normal;
        @include font-size(18px);

        @include breakpoint("tablet-portrait-min") {
            @include font-size(20px);
        }
    }

    &__ctn {
        display: flex;
        flex-direction: column;
        padding-top: 16px;

        @include breakpoint("tablet-portrait-min") {
            flex-direction: row;
            padding: 36px 0 0;
        }
    }

    &__item {
        padding: 16px 0;
        display: flex;
        align-items: center;

        @include breakpoint("tablet-portrait-min") {
            padding: 0 16px;
            flex: 1;
            flex-direction: column;
        }
    }

    &__item-wrapper {
        display: flex;
        align-items: center;

        @include breakpoint("tablet-portrait-min") {
            flex-direction: column;
        }
    }

    &__c-item-info {
        display: flex;
        flex-direction: column;
        padding-left: 16px;

        @include breakpoint("tablet-portrait-min") {
            align-items: center;
            padding: 25px 0 0;
            text-align: center;
        }
    }

    &__item-title {
        @include breakpoint("tablet-portrait-min") {
            min-height: 48px;
            @include font-size(18px);
        }
    }

    &__item-subtitle {
        @include breakpoint("tablet-portrait-min") {
            @include font-size(18px);
        }
    }
}