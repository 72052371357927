#map {
    height: 100%;
    width: calc(100% - 500px);
    position: relative;
    @include z-index(zero);

    @include breakpoint('tablet-landscape-max') {
        width: calc(100% - 450px);
    }

    @include breakpoint('tablet-portrait-max') {
        height: 500px;
        width: 100%;
        margin-top: 16px;
    }

    .store__cta {
        padding: 24px 0 16px;
    }

    .gm-style .gm-style-iw-c {
        backdrop-filter: blur(2px);
        box-shadow: 0 16px 31px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.65);
        padding: 0;
        border-radius: initial;
        max-width: 375px !important;
        width: 100%;

        @include breakpoint('phone-max'){
            width: 300px;
        }
    }

    .gm-style .gm-style-iw-d {
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: 0;
        overflow: hidden!important;
    }

    button.gm-ui-hover-effect {
        width: 25px!important;
        height: 25px!important;
        top: 10px!important;
        right: 10px!important;

        img {
            width: 100%!important;
            height: 100%!important;
            margin: 0!important;
        }
    }
}

.map-mobile-hide {
    @include breakpoint('tablet-portrait-max') {
        position: fixed!important;
        left: -1500px;
    }
}