.btn-go-to {
    position: fixed;
    background-color: $white;
    border: 1px solid $green;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 10px;
    right: 16px;
    width: 110px;
    border-radius: 6px;
    @include z-index(backToTop);
    @include font-size(14px);

    &.recap {
        bottom: 100px;
    }

    @include breakpoint('tablet-portrait-min') {
        @include hidden();
    }
}

.checkout-summary {
    box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px $gray-ultralight;

    // &__footer {
    //     @include hidden();

    //     @include breakpoint('tablet-portrait-max') {
    //         @include visible();
    //     }
    // }

    &__header {
        background-color: $gray-ultralight;
        padding: 16px;
    }

    &__header-title {
        line-height: 1.5;
        color: $black;
        @include font-size(18px);
        
    }

    &__c-box {
        padding: 25px 20px 15px 15px;
        border-bottom: 1px solid $gray-ultralight;
    }

    &__c-cost {
        padding: 15px;
        line-height: 1.6;
        border-bottom: 1px solid $gray-ultralight;
    }

    &__cost {
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
        padding-top: 8px;
        align-items: center;
        @include font-size(13px);

        >span:first-child {
            max-width: 80%;
            padding-right: 12px;
        }
    }

    &__total {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    &__total-info {
        color: $gray;
        line-height: 1.5;
        padding-top: 20px;
        @include font-size(13px);
    }

    &__cta {
        padding: 25px 0px 10px;

        .btn {
            width: 100%;
        }
    }

    &__modify-cta {
        display: flex;
        flex-direction: column;
        padding: 15px;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: initial;
            justify-content: space-between;
        }

        .btn {
            padding: 0 5px;
            width: 100%;
            @include font-size(14px);

            @include breakpoint('tablet-portrait-max') {
                height: auto;
                min-height: 30px;
                @include font-size(12px);
            }

            &:first-child {
                margin-bottom: 10px;

                @include breakpoint('tablet-portrait-max') {
                    margin: 0 15px 0 0;
                }
            }
        }
    }

    &__c-total {
        padding: 15px;

        &.fixed {
            @include breakpoint('tablet-portrait-max') {
                position: fixed;
                bottom: 0;
                background-color: $white;
                box-shadow: 0 -2px 32px 0 rgba(0, 0, 0, 0.12);
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 24px 16px;
                @include z-index(one);

                .checkout-summary__total {
                    flex-flow: column;

                    span:last-child {
                        @include font-size(24px);
                    }
                }

                .checkout-summary__total-info {
                    @include hidden();
                }

                .checkout-summary__cta {
                    padding: 0;
                    //width: 150px;
                }
            }
        }
    }

    .bennet-box {
        position: relative;
        border-top: 1px solid $red;
        border-bottom: 1px solid $red;
        border-left: 1px solid $red;
        border-right: 16px solid $red;
        border-radius: 0 5px 5px 0;
    
        &__img {
            position: absolute;
            top: -12px;
            left: 10px;
            padding: 0 5px;
            background-color: $white;
        }
    
        &__container {
            padding: 20px 15px 20px;
            display: flex;
            flex-direction: column;
        }
    
        &__title {
            font-weight: bold;
            text-transform: uppercase;
            @include font-size(16px);
        }

        &__info {
            line-height: 1.6;
            padding-top: 10px;
            @include font-size(13px);
        }
    }
}
