.order-reward-listing {
    background-color: $gray-ultralight;
    padding: 0 16px 34px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include breakpoint('tablet-portrait-min') {
        padding-left: 65px;
        padding-right: 65px;
    }

    &__title,
    &__items-title {
        padding: 28px 0 5px;
        @include font-size(18px);
    }

    &__subtitle {
        padding: 28px 0 5px;
    }

    &__error-result {
        padding: 28px 0 100px;
    }

    &__items {
        display: flex;
        flex-flow: wrap;
        max-width: none;
    
        @include breakpoint('phone-min') {
            display: flex;
            flex-flow: wrap;
            margin: 0 -12px;
        }
    
        @include breakpoint('tablet-portrait-min') {
            margin: 0 -35px;
        }

        @include breakpoint('tablet-landscape-min') {
            margin: 0 -12px;
        }

        @include breakpoint('desktop-small-min') {
            margin: 0 -35px;
        }
    
        .award-card {
            margin: 12px 0;
            width: 100%;
    
            @include breakpoint('phone-min') {
                width: calc(50% - 24px);
                margin: 12px;
            }
    
            @include breakpoint('tablet-portrait-min') {
                width: calc(50% - 70px);
                margin: 12px 35px;
            }
    
            @include breakpoint('tablet-landscape-min') {
                width: calc(33.333% - 24px);
                margin: 12px;
            }

            @include breakpoint('desktop-small-min') {
                width: calc(33.333% - 70px);
                margin: 12px 35px;
            }
        }
    }

    &__no-result {
        padding: 20px 12px 50px;

        @include breakpoint('tablet-portrait-min') {
            padding: 20px 35px 50px;
        }
    }
}

.active-reward {
    overflow: hidden;

    @include breakpoint('tablet-portrait-min') {
        width: 780px;
    }
    

    .header-mobile-panel__title {
        @include breakpoint('tablet-portrait-max') {
            padding-left: 0;
        }
    }

    &__content {
        padding: 0;
    }

    &__text {
        padding: 20px 8px;

        @include breakpoint('tablet-portrait-max') {
            padding: 20px 0;
            @include font-size(14px);
        }
    }

    &__form {
        max-width: none;
    }

    &__c-form {
        display: flex;
        flex-flow: wrap;
        padding: 20px 60px;
        overflow-y: auto;
        max-height: 70vh;


        @include breakpoint('tablet-portrait-max') {
            max-height: none;
            // 100vh - footer - header
            height: calc(100vh - 122px);
            height: calc(var(--vh, 1vh) * 100 - 122px);
            padding: 20px 24px;
         }
    }

    .row {
        width: 100%;

        @include breakpoint('tablet-portrait-min') {
            width: calc(50% - 16px);
            margin: 0 8px;
        }
    }

    &__footer {
        width: 100%;
    }
}
