.drive-store {
    $this: &;

    border-bottom: 1px solid $gray-light;

    .drive-separator {
        display: none;

        &:nth-child(3) {
            display: block;
        }
    }

    &__wrapper {
        padding: 16px 24px;

        @include breakpoint('tablet-portrait-max') {
            padding: 25px 16px;                 
        }
    }

    &__name {
        text-transform: uppercase;

        @include breakpoint('tablet-portrait-max') {
            @include font-size(16px);                 
        }
    }

    &__open-hours {
        padding: 10px 0 10px;
        display: flex;
        align-items: center;
        
        @include breakpoint('tablet-portrait-max') {
            padding: 15px 0 12px;            
        }

        .iconcalendar {
            @include breakpoint('tablet-portrait-max') {
                width: 20px;
                height: 20px;          
            }
        }
    }

    &__delivery-info {
        display: flex;
        flex-direction: column;
    }

    &__pick-in-store,
    &__shipping-home {
        display: flex;
        align-items: center;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: column;
            align-items: initial;
            padding: 0 0 5px;
            
            #{$this}__text {
                padding: 4px 0 0;
            }        
        }
    }

    &__icon-wrapper {
        flex: 1;
        display: flex;
    }

    &__text {
        padding-left: 15px;
        flex: 2;
        @include font-size(13px);
    }

    &__c-cta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;

        .btn {
            width: 250px;

            @include breakpoint('tablet-portrait-max') {
                width: 165px;
                @include font-size(13px);
            }
        }

        .iconpickinstore {
            color: transparent;
            opacity: 0.5;

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }
    }

    &__cta-change {
        padding-left: 15px;
        color: $gray-dark;
        cursor: pointer;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
            @include font-size(14px);
        }

    }

    &__available-closer {
        background-color: $gray-light;
        color: $white;
        padding: 16px 24px;

        svg {
            color: $white;
        }

        @include breakpoint('tablet-portrait-max') {
            padding: 10px 16px 5px;
        }
    }

    &__title-available-info{
        padding-bottom: 12px;

        @include breakpoint('tablet-portrait-max') {
            padding-bottom: 5px;
            @include font-size(15px);
        }
    }

    &__title-available {
        font-weight: bold;
    }
}