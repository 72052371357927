.workwithus-form {
    &__header {
        padding: 24px 16px;

        @include breakpoint("tablet-portrait-min") {
            padding: 24px  130px 32px 64px;
        }
    }

    &__title {
        padding-bottom: 20px;
        @include font-size(24px);

        @include breakpoint("tablet-portrait-min") {
            @include font-size(32px);
        }
    }

    &__subtitle {
        padding-bottom: 12px;

        @include breakpoint("tablet-portrait-min") {
            @include font-size(20px);
        }
    }

    &__text {
        line-height: 1.5;
        overflow: hidden;
        max-height: 3000px;
        transition: max-height 0.3s ease-out;
        height: auto;
        @include font-size(16px);

        &--info {
            padding-bottom: 12px;
        }

        &.toggle-reduce {
            max-height: 125px;

            @include breakpoint("tablet-portrait-min") {
                max-height: 80px;
            }
        }
    }

    &__cta {
        padding-top: 15px;
        font-weight: normal;
        color: $gray-dark;
        margin: 0 auto;
        @include font-size(14px);
    }

    &__c-select {
        padding-top: 20px;
        display: flex;
        flex-direction: column;

        @include breakpoint("phone-min") {
            flex-direction: row;
            justify-content: space-between;
            margin: 0 -12px;
        }

        @include breakpoint("tablet-portrait-min") {
            margin-right: 12px;
            max-width: 700px;
            flex: 1;
        }

        .row {
            @include breakpoint("phone-min") {
                flex: 1;
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        .customselect {
            width: 100%;
            margin-bottom: 24px;

            @include breakpoint("tablet-portrait-min") {
                margin-bottom: 0;
            }

            &__button {
                @include font-size(14px);
            }
        }
    }

    &__c-step {
        margin: 0 0 0 40px;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 130px 0 100px;
        }
    }
}