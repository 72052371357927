.cart-item {
    border-top: solid 2px $gray-light;
    display: flex;
    height: 150px;

    // .empty-note {
    //     color: $gray;
    // }

    @include breakpoint('tablet-portrait-max') {
        border-top: 1px solid $gray-light;
        height: auto;
        flex-flow: wrap;
        align-items: center;
        order: 2;
    }

    &__left {
        display: flex;
        width: 45%;
        align-items: center;
        padding-right: 10px;

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            padding: 24px 16px 12px;
            align-items: flex-start;
            order: 0;
            position: relative;
        }
    }

    &__image {
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;

        img {
            max-width: 100px;
            height: auto;
        }

        @include breakpoint('tablet-portrait-max') {
            height: 60px;

            img {
                height: 60px;
            }
        }
    }

    &__categorybadge {
        position: absolute;
        top: 0px;
        right: -15px;
        img {
            width: 30px;
            height: auto;

            @include breakpoint('tablet-portrait-max') {
                width: 24px;
            }
        }
    }

    &__description {
        width: calc(100% - 100px);
        padding-left: 8%;

        @include breakpoint('tablet-portrait-max') {
            padding-left: 6%;
        }
    }

    &__name {
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(13px);
        }
    }

    &__brand {
        text-transform: uppercase;
    }

    &__brand,
    &__c-weight-info,
    &__additional-info {
        margin-top: 10px;
        display: flex;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            @include font-size(12px);
            button {
                background: none;
                border: none;
            }
        }
    }

    &__additional-info {
        max-width: 250px;
        justify-content: space-between;

        @include breakpoint('tablet-portrait-max') {
            position: absolute;
            bottom: 10px;
            left: 16px;

            a { //Tooltip
                display: flex;
            }
        }

        .tooltipBubble {
            width:130px;
        }
    }

    &__c-weight-info {
        flex-direction: column;
        display: flex;
        line-height: 1.5;

        @include breakpoint('tablet-portrait-max') {
            flex-direction: row;
            align-items: flex-start;
            margin-top: 6px;
        }

        .icon-mobile {
            margin-left: auto;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-flow: column;
        }
    }


    &__add-note {
        cursor: pointer;
        display: flex;
        align-items: center;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            margin-right: 10px;
        }

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;

            @include breakpoint('tablet-portrait-max') {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__tooltip {
        display: flex;
        align-self: center;
    }

    .preview-note {
        font-style: italic;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .ellipsis-note {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 0.08em;
    }

    .iconshippinghome {
        width: 22px;
        height: 22px;
    }

    &__c-quantity-availability {
        width: 25%;
        padding: 18px 5px 18px 0;

        @include breakpoint('tablet-portrait-max') {
            order: 2;
            width: calc(50% - 16px);
            margin-right: 16px;
            padding: 16px 0;
            text-align: right;
        }
    }

    &__quantity {
        height: 35px;
        max-width: 120px;
        margin: 20px 0 24px;

        @include breakpoint('tablet-portrait-max') {
            height: 40px;
            max-width: 135px;
            margin: 0 0 0 auto;
        }
        
        .product-qnt__less,
        .product-qnt__plus {
            font-weight: 400;
            padding-bottom: 6px;
            @include font-size(24px);
        }

        .product-qnt__value {
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                @include font-size(16px);
            }
        }
    }

    &__availability {
        cursor: pointer;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-max') {
            margin: 5px 0 0 auto;
            display: block;
            @include font-size(12px);
        }
    }

    &__unavailable {
        padding-top: 25px;
        display: none;
    }

    &__summary {
        display: flex;
        width: 30%;
        position: relative;
        padding-top: 24px;

        @include breakpoint('tablet-portrait-max') {
            order: 1;
            width: calc(50% - 16px);
            padding: 16px 0;
            justify-content: flex-start;
            align-items: center;
            margin-left: 16px;
        }
    }

    &__unit-price {
        padding-top: 6px;
    }

    &__promo-expired {
        @include font-size(13px);
    }

    &__promo-expired {
        padding-top: 10px;

        @include breakpoint('tablet-portrait-max') {
            padding-top: 15px;
        }
    }

    &__c-unit-price {
        color: $gray;
        padding-top: 4px;
        @include font-size(13px);
    }

    &__unit-price-label-popup {
        @include hidden();
    }

    &__c-price-promo {
        @include breakpoint('tablet-portrait-max') {
            height: 40px;
        }
    }

    &__price {
        @include breakpoint('tablet-portrait-max') {
            @include font-size(20px);
        }
    }

    &__bottom-popup-mobile,
    &__max-shipping-qnt,
    &__quantity-popup {
        @include hidden();
    }

    &__promo {
        display: flex;
        flex-flow: column;
        padding-top: 8px;

        @include breakpoint('tablet-portrait-max') {
            justify-content: space-between;
            flex-flow: row;
            width: 100%;
            padding: 0;
        }
    }

    &__cta {
        margin: 20px 1% 0 auto;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;
        
        button {
            background: none;
            border: none;
        }

        @include breakpoint('tablet-portrait-max') {
            @include hidden();

            button {
                background: none;
                border: none;
            }
        }

        .icontrash-border {
            cursor: pointer;

            @include breakpoint('tablet-portrait-max') {
                width: 18px;
                height: 18px;
                color: $gray;
            }
        }
    }

    &__label-lowstock {
        @include hidden();
    }

    &__mobile-promo {
        position: relative;
        box-shadow: 8px 4px 16px 0 rgba(0,0,0,.12);
        order: 1;
    }

    &__mobile-promo-title {
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        border-top: 1px solid #888888;
        color: #e21613;
        cursor: pointer;
        @include font-size(14px);

        .arrow {
            border: solid $red;
            border-width: 0 2px 2px 0;
        }
    }

    &__mobile-promo-content {
        position: absolute;
        top: 100%;
        background-color: $white;
        left: 0;
        border-top: 1px solid $gray-ultralight;
        display: flex;
        align-items: flex-end;
        padding: 16px;
        min-height: 65px;
        justify-content: space-between;
        width: 100%;
        box-shadow: 8px 4px 16px 0 rgba(0,0,0,.12);
        @include z-index(one);
    }

    .icon-references {
        display: none;
    }

    &[data-stock="lowStock"] {
        .cart-item__label-lowstock {
            padding: 4px 8px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $orange-secondary-first;
            color: $white;
            @include visible();
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                @include font-size(11px);
            }
        }

        .icon-references {
            display: block;
        }
    }

    &[data-stock="outOfStock"], &[data-calculated="false"] {
        .cart-item__image {
            filter: grayscale(1);
            opacity: 0.6;
        }

        .cart-item__quantity,
        .cart-item__c-price-promo,
        + .cart-item__mobile-promo {
            display: none;
        }

        .cart-item__unavailable {
            display: block;
        }
    }
}
