.mirror-explanation {
    padding: 16px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 25px 0 0;
        display: flex;
        flex-flow: wrap;
        align-items: center;
    }

    &__c-description {
        padding-bottom: 16px;
        @include breakpoint('tablet-portrait-min') {
            width: 73%;
            padding-right: 2%;
            display: flex;
            flex-direction: column;
        }
    }

    &__description {

        @include breakpoint('tablet-portrait-min') {
            padding-right: 3%;
        }
    }

    &__wrapper {
        @include breakpoint('tablet-portrait-min') {
            width: 27%;
        }
    }

    &__title {
        padding-bottom: 8px;
        @include font-size(20px);

        @include breakpoint('tablet-portrait-min') {
            padding-bottom: 25px;
            @include font-size(24px);
        }
    }

    &__text {
        line-height: normal;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(16px);
        }
    }

    &__cta {
        width: 100%;
        margin-top: 16px;
        text-decoration: underline;

        @include breakpoint('tablet-portrait-min') {
            width: max-content;
        }

        &.btn {
            text-decoration: none;
            padding: 0 60px;
        }
        
        &.right {
            margin: 16px 0 0 auto;
        }
    }

    &.reverse {
        .mirror-explanation__c-description {
            order: 2;

            @include breakpoint('tablet-portrait-min') {
                padding-left: 3%;
            }
        }

        .mirror-explanation__wrapper {
            order: 1;
        }
    }
}
