.is-smartedit {
    #_asm {
        display: none;
    }

    .yCmsComponent.smartEditComponent, .yCmsContentSlot.smartEditComponent {
        position: static!important;
    }

    .yCmsContentSlot.smartEditComponent[data-smartedit-component-id="NavigationSlot"] {
        height: 50px!important;
    }

    smartedit-element[data-smartedit-component-id="HeroBannerDesktopSlot"] {
        left: 0!important;
        width: 100%!important;
    }

    .yCmsContentSlot.smartEditComponent[data-smartedit-component-id="SearchBoxSlot"] {
        height: auto !important;

        .smarteditpanel-cta {
            cursor: pointer;
            display: block !important;
        }
    }
}

#smarteditoverlay {
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="HeroBannerDesktopSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="HeroBannerMobileSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="MagazineLandingSuperHeroTabsBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="MagazineSubHomeSuperHeroTabsBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="StoreFinderBottomHeroBannerSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="StoreDetailsBottomHeroBannerSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="PromotionsTopBannerBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="PromotionsBottomBannerBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="SupportCategoriesSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="SupportCategoriesSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="123ScontoSuperHeroTabBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="BennetClubSuperHeroTabBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="BennetClubInitiativeBoxSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="ServicesLandingCarouselBox"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="LandingPage1CategoriesUnderHeroBox"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="LandingPage1CategoriesBox"] {
        max-width: 100%!important;
        left: 0!important;
    }

    smartedit-element.smartEditComponentX[data-smartedit-component-id*="LandingPage1CategoriesBox"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="LandingPage1CategoriesUnderHeroBox"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="StoreFinderBottomHeroBannerSlot"],
    smartedit-element.smartEditComponentX[data-smartedit-component-id*="StoreDetailsBottomHeroBannerSlot"] {
        right: 0!important;
        margin: 0 auto;
    }
}

.smartEditComponent {    
    &:not([data-smartedit-component-type="MagazineHighlightComponent"]):not([data-smartedit-component-type="BennetCardComponent"]):not(.multiple-component__img):not(.multiple-component__content):not(.multiple-component__cta) {
        clear: both;
        width: 100%;
    }
    
    &[data-smartedit-component-type="MagazineHighlightComponent"] {

        @include breakpoint('tablet-portrait-min') {
            float: left;
        }
    
        &.ONEONTHREE {
            @include breakpoint('tablet-portrait-min') {
                width: 33.333%;
            }    
        }
        &.TWOONTHREE {
            @include breakpoint('tablet-portrait-min') {
                width: 66.666%;
            }  
        }
        &.THREEONTHREE {
            @include breakpoint('tablet-portrait-min') {
                width: 100%;
            }
        }
    
        .magazine-highlight {
            @include breakpoint('tablet-portrait-min') {
                width: 100%;
                max-width: none;
            }
        }
    }
}

.smartedit-app {
    max-width: 1680px;
    margin: 70px auto 0;

    .yCmsContentSlot {
        margin-bottom: 30px;
    }
}

#_asm {
    .ASM-btn-customer-list#product_1 {
        display: none;
    }
    .ASM-btn-customer360 {
        display: none!important;
    }
}