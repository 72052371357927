.error-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8% 9%;

    @include breakpoint('tablet-portrait-max') {
        flex-direction: column;
        padding: 25px 0 112px;
    }

    &__info {
        width: 50%;
        padding: 20px 60px 80px 0;

        @include breakpoint('desktop-small-max') {
            padding: 20px 12px 40px 0;
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            order: 2;
            text-align: center;
            padding: 5px 3.5%;
        }
    }

    &__title {
        line-height: 1.5;
        font-weight: bold;
        @include font-size(32px);

        @include breakpoint('desktop-small-max') {
            @include font-size(28px);
        }

        @include breakpoint('tablet-portrait-max') {
            padding-bottom: 5px;
            @include font-size(20px);
        }
    }

    &__subtitle {
        padding-top: 15px;
        line-height: 1.5;
        @include font-size(22px);

        @include breakpoint('desktop-small-max') {
            @include font-size(20px);
        }

        @include breakpoint('tablet-portrait-max') {
            padding: 0 50px;
            @include font-size(14px);
        }
    }

    &__text {
        padding-top: 5px;
        line-height: 1.5;
        @include font-size(18px);

        @include breakpoint('desktop-small-max') {
            @include font-size(16px);
        }

        @include breakpoint('tablet-portrait-max') {
            padding-top: 15px;
            @include font-size(14px);
        }
    }

    &__cta {
        padding-top: 30px;

        @include breakpoint('desktop-small-max') {
            padding-top: 30px;
        }

        @include breakpoint('tablet-portrait-max') {
            padding-top: 25px;
            margin: 0 30px;
        }

        .btn {
            padding: 0 45px;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
                @include font-size(16px);
            }
        }
    }

    &__banner {
        width: 50%;
        padding: 4% 0 8% 20%;

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            padding: 12% 28%;
            order: 1;
        }

        .banner-component__img,
        .banner__component {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }      
        }
    }

    &__img {
        margin: 5px 3.5% 0;

        @include breakpoint('tablet-portrait-min') {
            margin: 40px 0 0;
        }

        img {
            width: 100%;
        }
    }
}

.product-not-found {
    padding-top: 30px;
}