.product-carousel-cover {

    &__ctn {
        display: flex;

        @include breakpoint('phone-max') {
            flex-direction: column;
        }
    }

    &__carousel {
        @include breakpoint('phone-min') {
            width: calc(100% - 245px);
        }
    }

    div.products-carousel {
        padding: 0;
    }

    div.carousel-title {
        padding: 0 0 24px;

        @include breakpoint('phone-max') {
            display: none;
        }
    }

    div.products-carousel-container {
        .glide {
            &__arrow--left {
                left: -45px;
            }
        
            &__arrow--right {
                right: -45px;
            }
        }
    }

    .carousel-card {
        border-radius: 4px;
        padding: 16px;
        position: relative;
        border: solid 2px $gray-ultralight;
        background-color: $white;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        width: 245px;
        height: 514px;
        margin: 0 auto;
        top: 26px;
        box-shadow: 0 4px 12px 0 hsla(0,0%,67.1%,.29);

        @include breakpoint('phone-min') {
            height: 486px;
        }

        @include breakpoint('tablet-portrait-min') {
            height: 505px;
            margin: 0;
        }
    
        &__c-img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 285px;
            overflow: hidden;

            > a {
                height: 100%;
            }
        }
    
        &__img {
            border-radius: 4px;
            height: 100%;
            width: auto;
        }
    
        &__info {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
        }
    
        &__title {
            line-height: 1.5;
            @include font-size(14px);
        }
    
        &__subtitle {
            padding-top: 8px;
            @include font-size(13px);
        }
    
        &__cta {     
            width: 100%;
            margin: auto 0 26px;
    
            a {
                display: flex;
                width: 100%;
                justify-content: center;
                height: 100%;
                align-items: center;
                padding: 0 16px;
                color: $white;
    
                &:hover {
                    color: inherit;
                }
            }
        }
    }

    .nav {
        padding-bottom: 24px;

        @include breakpoint('phone-min') {
            @include hidden();
        }

        &__c-tab {
            display: flex;
            flex: 1;
            width: 100%;

            @include breakpoint('phone-max') {
                flex-direction: column;
                
                >div:first-child {
                    display: none;
                }
            }
        }

        &__item-border {
            display: none;
            height: 4px;
            position: absolute;
            width: 100%;
            bottom: -2px;
            background: $red;
            left: 0;
        }

        &__items {
            display: flex;
            padding: 0 8px;
        }

        &__item {
            padding: 20px 16px 15px;
            color: $gray;
            cursor: pointer;
            position: relative;
            flex: 1;
            text-align: center;

            &.active {
                font-weight: bold;
                color: $gray-dark;
                @include font-size(16px);
    
                .nav__item-border {
                    display: block;
                }
            }
        }

        &__border {
            height: 2px;
        }
    }
}