@include breakpoint('tablet-portrait-max') {
    .js-first-panel-mobile,
    .js-nav-panel {
        transition: all .3s linear;
        transform: translateX(0);

        &.close {
            transform: translateX(-2000px);
            transition: all .3s linear;
        }
    }

    .js-second-panel-mobile,
    .js-third-panel-mobile {
        transition: all .3s linear;
        transform: translateX(-2000px);

        &.open {
            transform: translateX(0px);
            transition: all .3s linear;
            max-width: 100vw;
        }
    }
}

.nav {
    .arrow__right, .arrow__left {
        display: none;
        cursor: pointer;

        @include breakpoint('tablet-portrait-max') {
            display: inline;
        }
    }

    .arrow__right {
        @include breakpoint('tablet-portrait-max') {
            margin: 5px 2px 5px auto;
        }
    }

    &__bar {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        justify-content: center;
        cursor: pointer;
        font-weight: bold;
        margin: 0 10px;
        text-align: center;

        @include breakpoint('tablet-portrait-max') {
            margin: 0;
        }

        >div {
            display: flex;
            align-items: center;
        }

        span {
            padding-right: 10px;
        }
    }
    
    .hamburger {
        width: 60px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
    
        &__inner, &__inner:after, &__inner:before {
            display: block;
            background-color: $white;
            border-radius: 5px;
            width: 24px;
            height: 2px;
            margin-top: -2px;
        }
    
        &__inner:after, &__inner:before {
            content: "";
            position: absolute;
            margin-top: 0;
        }
    
        &__inner:before {
            top: 17px;
        }
    
        &__inner:after {
            bottom: 18px;
        }
    }

    &__mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $white;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: auto;
        @include overflow();
        @include font-size(16px);
        @include z-index(backdrop-up);

        @include breakpoint('tablet-portrait-min') {
            @include hidden();
        }

        &::-webkit-scrollbar-track {
            margin-top: 52px;
        }

        &--container {
            padding: 16px 25px;
            border-bottom: 2px solid #f2f2f2;

            &:last-child {
                border: 0;
            }

            .item {
                display: inline-flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin: 16px 0;
                cursor: pointer;
                position: relative;

                &:hover {
                    color: $green;

                    a {
                        color: $green;
                    }
                }

                &__main {
                    display: inline-flex;
                    align-items: center;

                    &.js-login-panel, &.js-drive-panel {
                        position: absolute;
                        width: 100%;
                        @include z-index('one');
                    }

                    a {
                        position: absolute;
                        left: 0;
                        right: 0;
                        padding-left: 40px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        top: 0;
                        @include z-index('one');
                    }

                    svg {
                        margin-right: 16px;
                        height: 24px;
                        width: 24px;
                    }
                }

                &.link {
                    a {
                        padding: 0;
                    }

                    @include breakpoint('tablet-portrait-max') {
                        svg {
                            display: none;
                        }
    
                        a[href*="instacook"],
                        a[href*="customerCare"],
                        a[href*="storefinder"],
                        a[href*="flyer"],
                        a[href*="bennetClub"],
                        a[href*="magazine"],
                        a[href*="workwithus/landing"] {
                            left: 40px;

                            &:hover {
                                svg {
                                    color: $green;
                                }
                            }
                        }

                        a[href*="instacook"] ~ .iconinstacook,
                        a[href*="customerCare"] ~ .iconcustomercare,
                        a[href*="storefinder"] ~ .iconstorelocator,
                        a[href*="flyer"] ~ .iconflier-mobile-border,
                        a[href*="bennetClub"] ~ .iconclub-mobile,
                        a[href*="magazine"] ~ .iconmagazine,
                        a[href*="workwithus/landing"] ~ .iconworkwithus {
                            display: block;
                            
                        }
                    }
                }
            }            
        }
    }

    &__panel {
        position: absolute;
        top: 65px;
        left: 3%;
        width: 94%;
        background-color: $white;
        max-height: calc(100vh - 160px);
        max-height: calc(var(--vh, 1vh) * 100 - 160px);
        overflow-y: auto;
        border: 2px solid #888888;
        border-radius: 5px; //new topbar //new topbar
        @include font-size(16px);

        @include breakpoint('desktop-small-max') {
            width: 96%;
        }

        @include breakpoint('tablet-landscape-max') {
            top: 0;
            position: fixed;
            width: 100%;
            left: 0;
            max-height: 100vh;
            max-height: calc(var(--vh, 1vh) * 100);
            border-radius: 0;
            border: 0;
            height: 100vh;
        }

        @include breakpoint('tablet-portrait-max') {
            top: 52px;
            max-height: calc(100vh - 52px);
        }

        &--container {
            display: flex;
            position: relative;

            @include breakpoint('tablet-portrait-max') {
                flex-flow: column;
            }
        }

        .icon-close {
            position: fixed;
            transform: translate3d(0,0,0);
            right: 25px;
            top: 16px; // scroll iOS
            @include z-index(one);

            @include breakpoint('tablet-landscape-min') {
                @include hidden();
            }

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }

            .iconclose {
                display: block;
            }
        }

        .arrow__left {
            &.arrow {
                border: solid $white;
                border-width: 0 2px 2px 0;
                margin-right: 12px;
            }
        }
    }

    &__left {
        width: 300px;
        border-right: 1px solid $gray-light;

        @include breakpoint('desktop-small-max') {
            width: 230px;
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            border: 0;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100 - 52px);
            overflow-y: auto;
            @include overflow();
        }

        &::-webkit-scrollbar-track {
            @include breakpoint('tablet-portrait-max') {
                margin-top: 54px;
            }
        }
    }

    &__right {
        width: calc(100% - 300px);

        @include breakpoint('desktop-small-max') {
            width: calc(100% - 230px);
        }

        @include breakpoint('tablet-portrait-max') {
            max-width: 0;
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100 - 52px);
            overflow-y: auto;
            background-color: $white;
            @include overflow();
        }

        &::-webkit-scrollbar-track {
            @include breakpoint('tablet-portrait-max') {
                margin-top: 54px;
            }
        }

        .title {
            padding: 16px 65px 16px 40px;
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                background-color: $red;
                padding: 16px 24px;
            }

            img {
                max-height: 45px;

                @include breakpoint('tablet-portrait-max') {
                    @include hidden();
                }
            }
        
            >a {
                color: $red;
                padding-left: 16px;
                @include font-size(28px);

                @include breakpoint('tablet-portrait-max') {
                    color: $white;
                    padding: 0;
                    @include font-size(18px);
                }
            }
        }
    }

    &__menu {
        background-color: $red;
        color: $white;
        text-transform: uppercase;
        padding: 25px 25px 20px 25px;
        font-weight: bold;
        @include font-size(20px);

        @include breakpoint('tablet-portrait-max') {
            padding: 16px 24px;
            text-transform: none;
            font-weight: normal;
            cursor: pointer;
            display: flex;
            align-items: center;
            @include font-size(18px);
        }

        img {
            @include hidden();

            @include breakpoint('tablet-landscape-max') {
                height: 50px;
                width: auto;
                margin-right: 16px;
                @include visible();
            }

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }
    }

    &__firstlevel {
        padding: 16px 0;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }

        &--item {
            padding: 12px 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include font-size(14px);

            @include breakpoint('desktop-small-max') {
                padding: 12px 8px 12px 24px;
            }

            @include breakpoint('tablet-portrait-max') {
                margin: 12px 24px;
                padding: 8px 0;
                color: $black;
            }

            &:hover {
                color: $green;
            }

            &.active {
                @include breakpoint('tablet-portrait-min') {
                    width: calc(100% + 10px);
                    border-top: 1px solid $red;
                    border-bottom: 1px solid $red;
                    border-right: 10px solid $red;
                    border-radius: 0 5px 5px 0;
                    color: $red;
                    font-weight: bold;
                    transition: all 0.3s ease-in-out;
                }
            }

            >div {
                display: flex;
                align-items: center;
                width: 100%;

                >a {
                    width: 100%;
                }
            }

            img {
                margin-right: 10px;
                width: 30px;

                @include breakpoint('tablet-portrait-min') {
                    display: none;
                }
            }

            a {
                &:hover {
                    color: $green;
                }
            }

            .img-link {
                display: inline;
            }
        }
    }

    &__secondlevel {
        display: none;
        position: relative;

        &--container {
            padding: 25px 65px 25px 40px;
            line-height: 1.5;
            @include font-size(14px);

            @include breakpoint('desktop-small-max') {
                padding: 16px;
            }

            @include breakpoint('tablet-portrait-max') {
                display: flex;
                flex-flow: column;
                padding: 0;
            }
        }

        &--title {
            text-transform: uppercase;
            margin-bottom: 8px;
            margin-right: auto;
            color: $black;
            cursor: pointer;

            @include breakpoint('tablet-portrait-max') {
                color: $gray-dark;
                text-transform: none;
                margin: 6px 24px;
                padding: 8px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: auto;
            }

            a {
                color: $black;

                @include breakpoint('tablet-portrait-min') {
                    font-weight: bold;
                }

                &:hover {
                    color: $green;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
        }

        &--item {
            float: left;
            width: 20%;
            padding: 16px 20px;
            display: flex;
            flex-flow: column;

            @include breakpoint('tablet-portrait-max') {
                width: 100%;
                padding: 0;
            }

            &:nth-child(5n+1) {
                clear: both;
            }
        }
    }

    &__thirdlevel {
        &--container {
            display: flex;
            flex-flow: column;

            @include breakpoint('tablet-portrait-max') {
                max-width: 0;
                max-height: unset;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background-color: $white;
                @include z-index(one);
            }
        }

        &--title {
            @include hidden();

            @include breakpoint('tablet-portrait-max') {
                overflow: unset;
                @include visible();
            }
        }

        &--item {
            margin: 3px auto 3px 0;
            color: $gray;
            display: block;

            @include breakpoint('tablet-portrait-max') {
                color: $gray-dark;
                margin: 6px 24px;
                padding: 8px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
            }

            >a {
                @include breakpoint('tablet-portrait-max') {
                    color: $black;
                }

                &:hover {
                    color: $green;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
        }
    }

    &__banner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        img {
            max-width: 84%;
            margin: 20px 8% 40px;

            @include breakpoint('desktop-small-max') {
                max-width: 90%;
                margin: 20px 5% 40px;
            }
        }
    }
}