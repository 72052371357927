// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
$breakpoints: (
    'phone-small-min': ( min-width: 426px),
    'phone-small-max': ( max-width: 425px),
    'phone-min':  ( min-width:  768px ),
    'phone-max':  ( max-width:  767px ),
    'tablet-portrait-min': ( min-width: 993px),
    'tablet-portrait-max': ( max-width: 992px),
    'tablet-landscape-min': ( min-width: 1025px),
    'tablet-landscape-max': ( max-width: 1024px),
    'desktop-small-min': ( min-width: 1281px),
    'desktop-small-max': ( max-width: 1280px),
    'desktop-medium-min': ( min-width: 1441px),
    'desktop-medium-max': ( max-width: 1440px),
    'desktop-large-min': ( min-width: 1601px),
    'desktop-large-max': ( max-width: 1600px),
    'desktop-limit-min': ( min-width: 1680px),
    'desktop-limit-max': ( max-width: 1681px)
) !default;

@mixin breakpoint($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
  
    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}