.wishlist-popup {
    &__container {
        padding: 24px 32px;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
        }

        .customselect {
            width: 250px;

            @include breakpoint('phone-max') {
                width: 100%;
            }

            .customselect__list.active {
                position: relative;
                transition: all .2s ease-in-out;
            }
        }
    }

    &__content {
        padding-bottom: 24px;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-max') {
            padding: 24px 16px 0;
            @include font-size(16px);
        }

        &--item {
            display: inline-flex;
            margin: 8px 4px 8px 0;
            padding: 8px;
            border-radius: 8px;
            color: $white;
            align-items: center;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                margin: 12px 4px 12px 0;
            }

            i {
                margin-right: 10px;

                &:before {
                    background-color: $white;
                    border-radius: 100%;
                    content: "✔";
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &__product {
        display: flex;
        padding-bottom: 24px;

        @include breakpoint('tablet-portrait-max') {
            padding: 24px 16px;
            border-bottom: 2px solid $gray-ultralight;
        }

        &--image {
            img {
                max-width: 60px;
                height: auto;
            }
        }

        &--info {
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            margin-left: 16px;

            b {
                @include font-size(14px);
            }
        }

        &--brand {
            text-transform: uppercase;
        }
    }

    &__choose-item {
        @include breakpoint('tablet-portrait-max') {
            padding: 24px 16px 0;
        }
    }

    &__newlist {
        padding-top: 24px;

        @include breakpoint('tablet-portrait-max') {
            padding: 24px 16px;
        }

        &--btn {
            display: inline-flex;
            align-items: center;
            color: $dark-blue;
            cursor: pointer;
            @include font-size(16px);

            svg {
                margin-right: 12px;
            }
            span {
                color: $gray-dark;
            }
        }

        &--input {
            form {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                margin: 16px auto 0 0;

                @include breakpoint('phone-max') {
                    flex-flow: column;
                    align-items: unset;
                    height: 80px;
                }
            }

            .form-group {
                margin: 0;
                width: 250px;

                @include breakpoint('phone-max') {
                    width: 100%;
                    padding-bottom: 30px;
                }
            }

            ul {
                display: flex;
                flex-flow: row;
                align-items: center;    
            }
        }
    }
}
