.award-card {
    padding: 12px 8px;
    border-radius: 4px;
    background-color: $white;
    position: relative;
    display: flex;
    flex-direction: column;

    @include breakpoint('phone-min') {
        padding: 12px 10px;
    }

    @include breakpoint('tablet-portrait-min') {
        padding: 16px;
    }

    .favourite-article {
        top: 4px;
    }

    &__c-threshold {
        padding-bottom: 10px;
        display: flex;
        padding-right: 35px;
        min-height: 33px;
    }

    &__threshold {
        color: $green;
        display: flex;
        align-items: center;
        @include font-size(13px);
    }

    &__threshold-label {
        padding-left: 5px;
    }

    &__c-img {
        display: flex;
        align-items: flex-start;
        width: 95px;
    }

    &__container {
        display: flex;
        flex: 1;
        min-height: 118px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-left: 8px;
        width: calc(100% - 95px);
    }

    &__title {
        min-height: 34px;
        > h2 {
            font-size: inherit;
        }

        @include font-size(13px);
    }

    &__brand {
        min-height: 30px;
        @include font-size(13px);
    }

    &__c-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 30px;
    }

    &__details {
        line-height: 1.5;
        @include font-size(13px);
    }

    &__cta {
        margin-top: 12px;
        width: 100%;
        @include font-size(13px);
    }

    &__info-points {
        padding-top: 5px;
        @include font-size(16px);
    }

    &__nowrap {
        white-space: nowrap;
    }
}