
.super-hero-tab {
    position: relative;
    clear: both;
    width: 100%;
    
    .glide {
        &__bullets {
            position: absolute;
            bottom: 95px;

            @include breakpoint('tablet-portrait-min') {
                bottom: 110px;
            }
        }

        &__bullet {
            @include breakpoint('tablet-portrait-min') {
                background-color: $gray-light;

                &--active,
                &:hover {
                    background-color: $gray-dark;
                }
            }
        }
    }    
}

.magazine-banner {
    // &__round-img {
    //     border-radius: 100%;
    //     width: 120px;
    //     height: 120px;
    //     border: 5px solid white;
    //     position: absolute;
    //     bottom: -45px;
    //     left: 15px;
    //     box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
    // }

    iframe,video {
        width: 100%;
    }

    &__details {
        padding: 16px;
        background-color: $white;

        // &--shifted {
        //     padding-left: 140px;
        // }

        @include breakpoint('tablet-portrait-min') {
            padding: 40px 65px;
        }
    }

    &__title {
        font-style: italic;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(24px);
        }
    }

    &__subtitle {
        padding-top: 5px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
            padding-top: 12px;
            @include font-size(20px);
        }
    }
}