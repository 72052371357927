.tab-nav {
    $self: &;

    &__item-border {
        display: none;
        height: 4px;
        position: absolute;
        width: 100%;
        bottom: -2px;
        background: $red;
        left: 0;
    }

    &__items {
        display: flex;
        padding: 0 5px;

        @include breakpoint('tablet-portrait-max') {
            justify-content: space-around;
            max-width: 450px;
        }
    }

    &__item {
        padding: 20px 22px 15px;
        color: $gray;
        cursor: pointer;
        position: relative;

        @include breakpoint('tablet-portrait-max') {
            padding: 15px 0;
            justify-content: center;
            flex: 1;
            display: flex;
        }

        &.active {
            color: $gray-dark;

            #{ $self }__item-border {
                display: block;
            }
        }
    }

    &__border {
        height: 2px;
    }
}

.coupon-page {
    padding: 24px;
    h2 {
        font-size: inherit;
    }

    .absolute-hide {
        position: absolute;
        left: -30000px;
        opacity: 0;
    }

    @include breakpoint('tablet-portrait-min') {
        padding: 24px 0;
    }

    &__info {
        padding-bottom: 20px;
    }

    &__title {
        padding-bottom: 24px;
        @include font-size(16px);
        > h3 {
            font-size: inherit;
        }
    }

    &__wrapper {
        @include breakpoint('phone-min') {
            display: flex;
        }
    }

    &__text, &_cta {
        line-height: 1.5;
        @include font-size(14px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(14px);
        }
    }

    &__no-result {
        padding-top: 30px;
        > h3 {
            font-size: inherit;
        }
        @include font-size(20px);
    }

    &__cta {
        padding-top: 36px;
    }

    .explanatory-box__cta-container {
        justify-content: flex-start;
    }

    &__all-download {
        svg {
            margin-right: 12px;
        }
    }

    .frequently-asked-questions {
        padding-left: 0;
        padding-right: 0;
    }

    .coupon {
        border-radius: 4px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        margin: 16px -8px;

        @include breakpoint('tablet-portrait-min') {
            margin: 16px 0;
        }

        &.coupon--used {
            .coupon__title,
            .coupon__content-label,
            .coupon__c-paragraph,
            .coupon__num-barcode {
                color: gray;
            }

            .coupon__labels {
                filter: grayscale(100%);
            }

            .coupon__img,
            .coupon__barcode {
                opacity: 0.5;
                filter: grayscale(100%);
            }
        }

        &__top-bar {
            background-color: $red;
            border-radius: 4px 4px 0 0;
            padding: 10px 12px;

            @include breakpoint('tablet-portrait-min') {
                padding: 10px 20px;
            }
        }

        &__top-bar-label {
            color: $white;
        }

        &__header {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 16px;
            cursor: pointer;

            @include breakpoint('tablet-portrait-min') {
                padding: 25px 25px 15px;
            }
        }

        &__arrow {
            position: absolute;
            top: 20px;
            right: 18px;
            cursor: pointer;
        }

        &__top-info {
            display: flex;
            flex-direction: column;

            @include breakpoint('tablet-portrait-min') {
                flex-direction: row;
                align-items: center;
            }
        }

        &__title {
            @include font-size(24px);
        }

        &__validity {
            color: $gray;
            padding: 14px 0 0;

            @include breakpoint('tablet-portrait-min') {
                padding: 0 0 0 20px;
                @include font-size(14px);
            }
        }

        &__labels {
            padding-top: 20px;

            @include breakpoint('tablet-portrait-min') {
                display: flex;
                padding-top: 15px;
            }
        }   

        &__c-label {
            display: flex;
            align-items: center;
            
            &:first-child {
                @include breakpoint('tablet-portrait-min') {
                    padding-right: 20px;
                }
            }
        }

        &__label {
            padding-left: 5px;
            color: $gray;
            @include font-size(13px);
        }

        &__c-content {
            border-top: 2px solid $gray-ultralight;
        }

        &__content {
            padding: 16px;

            @include breakpoint('tablet-portrait-min') {
                padding: 15px 24px;
            }
        }

        &__content-label {
            line-height: normal;
        }

        &__content-container {
            display: flex;
            align-items: center;

            img {
                width: 70px;
                margin-left: 12px;
            }
        }

        &__c-imgs {
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            
            @include breakpoint('tablet-portrait-min') {
                flex-direction: row;
                margin: 0 auto;
                padding-top: 36px;
                justify-content: space-around;
                display: flex;
                align-items: center;
                max-width: 560px;
            }
        }

        &__img {
            width: 60%;
            max-width: 250px;
            margin: 0 auto;

            @include breakpoint('tablet-portrait-min') {
                width: 130px;
                margin: 0;
            }
        }

        &__c-barcode {
            display: flex;
            flex-direction: column;
            padding-top: 20px;

            @include breakpoint('tablet-portrait-min') {
                padding: 0;
            }
        }

        &__barcode {
            width: 70%;
            max-width: 460px;
            height: 45px;
            margin: 0 auto;

            @include breakpoint('tablet-portrait-min') {
                width: 220px;
            }
        }

        &__num-barcode {
            padding-top: 5px;
            margin: 0 auto;
            @include font-size(20px);

            @include breakpoint( 'phone-max') {
                @include font-size(17px);
            }

        }

        &__c-active-cta {
            display: flex;
            flex-direction: column;
            max-width: none;
        }

        &__active-cta {
            max-width: 350px;
            width: 100%;
            margin: 20px auto 0;

            @include breakpoint('tablet-portrait-min') {
                width: 250px;
            }
        }

        &__c-paragraph {
            padding-top: 36px;
            line-height: 1.7;

            @include breakpoint('tablet-portrait-min') {
                padding-top: 30px; 
                @include font-size(14px);
            }
        }

        &__c-ctas {
            display: flex;
            padding-top: 24px;

            @include breakpoint('tablet-portrait-min') {
                padding-top: 16px;
            }
        }

        &__cta {
            margin: 0 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            font: inherit;
            font-weight: bold;
    
            span {
                padding-top: 10px;
                @include font-size(13px);
    
                @include breakpoint('tablet-portrait-min') {
                    padding-top: 5px;
                }
            }

            svg {
                width: 25px;
                height: 25px;
            }
    
            .share {
                display: flex;
    
                &__cta {
                    margin: 5px 10px;
                }
            }
        }
    }
}

