.initiative-details,
.initiatives-listing {
    .single-banner {
        padding: 12px 0;

        @include breakpoint("tablet-portrait-min") {
            padding: 16px 0;
        }
    }
}

.initiatives-listing {
    overflow: hidden;
    padding: 0 16px 24px;

    @include breakpoint("tablet-portrait-min") {
        padding: 0 64px 60px;
    }

    &__hero {
        @include breakpoint("tablet-portrait-max") {
            padding: 24px 0 16px;
        }
    }

    &__title {
        padding-top: 16px;
        @include font-size(16px);

        @include breakpoint("tablet-portrait-min") {
            padding-top: 28px;
            @include font-size(18px);
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
        margin: 16px 0 0;

        @include breakpoint("phone-min") {
            margin-left: -12px;
            margin-right: -12px;
        }

        @include breakpoint("tablet-portrait-min") {
            margin-bottom: 20px;
            @include font-size(18px);
        }

        @include breakpoint("phone-max") {
            grid-template-columns: 1fr !important;
        }

        .bennet-card {
            margin: 12px 0;
            width: 100%;
            height: auto;
            align-items: center;

            .bennet-card {
                height: 100%;

                &__info {
                    width: 100%;
                }

            }

            @include breakpoint("phone-min") {
                // width: calc(50% - 24px);
                margin: 12px;
            }
        }

    }
}

.initiative-details {
    padding: 0 0 24px;

    @include breakpoint("tablet-portrait-min") {
        padding: 0 64px 60px;
    }

    &__hero {
        @include breakpoint("tablet-portrait-max") {
            padding: 24px 0 16px;
        }
    }

    &__title {
        padding-top: 12px;
        @include font-size(16px);

        @include breakpoint("tablet-portrait-min") {
            line-height: 1.5;
            padding: 30px 0 16px;
            @include font-size(24px);
        }
    }

    &__container {
        @include breakpoint("tablet-portrait-max") {
            padding: 0 16px;
        }
    }

    .bennet-banner {
        padding: 0;
    }

    .content {
        line-height: 1.5;
        @include font-size(16px);
    }

    .component {
        padding: 16px 0;
    }
}
