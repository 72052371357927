.bennet-club {

    .super-hero-tab{
        margin-bottom: 24px;

        @include breakpoint('tablet-portrait-min') {
            margin-bottom: 50px;
        }
    }

    &__hero {
        margin-bottom: 24px;

         @include breakpoint('tablet-portrait-min') {
            margin-bottom: 50px;
         }
              
        .hero-login {
            position: relative;

            &__content {
                position: absolute;
                width: 50%;
                top: 14%;
                left: 7%;

                @media screen and (max-width: 374px) {
                    top: 8%;
                    left: 8%;
                    display: flex;
                    height: calc(100% - 8%);
                    flex-flow: column;
                    justify-content: space-around;
                }

                @include breakpoint('phone-min') {
                    top: 24%;
                    left: 10%;
                    width: 40%;
                }

                @include breakpoint('tablet-portrait-min') {
                    top: 22%;
                    left: 8%;
                }
                
                @include breakpoint('tablet-landscape-min') {
                    top: 25%;
                }

                @include breakpoint('desktop-small-min') {
                    top: 27%;
                    left: 12%;
                }
            }

            &__text {
                line-height: 1.5;
                @include font-size(12px);

                @media screen and (max-width: 374px) {
                    line-height: normal;
                    @include font-size(11px);
                }

                @include breakpoint('phone-min') {
                    @include font-size(18px);
                }

                @include breakpoint('tablet-portrait-min') {
                    @include font-size(26px);
                }

                @include breakpoint('desktop-small-min') {
                    @include font-size(32px);
                }
            }

            &__ctas {
                padding-top: 10px;
                display: flex;

                @media screen and (max-width: 374px) {
                    padding-top: 5px;

                }

                @include breakpoint('phone-min') {
                    padding-top: 30px;
                }
            }

            &__cta {
                max-width: 218px;
                flex: 1;

                @media screen and (max-width: 374px) {
                    @include btn-mobile('sm');       
                }

                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    .magazine-highlight {
        padding: 0 4% 0px 4%;
        margin-bottom: 24px;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 11.6% 0;
            align-items: center;
            margin-bottom: 70px;
        }

        &__title{
            @include font-size(24px);

            @include breakpoint('tablet-portrait-min') {
                @include font-size(28px);
            }
        }
    }

    .mirror-explanations {
        padding-bottom: 0;
    }

    .mirror-explanation {
        padding: 16px 4% 0px 4%;

            @include breakpoint('tablet-portrait-min') {
            padding: 24px 11.6% 0;

            }

        &:last-child {
            padding-bottom: 24px;

            @include breakpoint('tablet-portrait-min') {
                padding-bottom: 70px;
            }
        }
    }

    .club__title{
        padding: 0 4% 0 4%;
        font-weight: bold;
        @include font-size(24px);
    
        @include breakpoint('tablet-portrait-min') {
            padding: 0 11.6% 0 11.6%;
            @include font-size(30px);
        }
    }

    //Come iscriversi
    .explanation{
        display: flex;
        flex-flow: column;
        margin: 0 4% 40px;

        @include breakpoint('tablet-portrait-min') {
            margin: 0 11.6% 80px 11.6%;
        }

        &-title{
            font-weight: bold;
            line-height: 1.5;
            padding-bottom: 23px;
            @include font-size(24px);

            @include breakpoint('tablet-portrait-min') {
                @include font-size(30px);
            }
        }

        &-container{
            display: flex;
            flex-flow: column;

            @include breakpoint('tablet-portrait-min') {
                flex-flow: row;
            }

            &__img{
                padding: 0px;
                width: 100%;

                @include breakpoint('tablet-portrait-min') {
                    width: 69%;
                }

                img{
                    height: auto;
                    width: 100%;
                }
            }

            &__pass{
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                width: 100%;
                padding-top: 24px;

                @include breakpoint('tablet-portrait-min') {
                    width: 73%;
                    position: relative;
                    right: 31px;
                    margin-right: -31px;
                }

                @include breakpoint('desktop-small-min'){
                    padding-top: 40px;
                }

                a{
                    width: 100%;
                    margin-top: 12px;

                    @include breakpoint('tablet-portrait-min') {
                        width: 218px;
                        margin: 0 0 0 auto;
                    }
                }

                .row{
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    margin-bottom: 20px;

                    @include breakpoint('tablet-portrait-min'){
                        margin-bottom: 0;
                    }

                    .oval-copy{
                        width: 62px;
                        height: 62px;
                        background-color: $gray-dark;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include breakpoint('desktop-small-min'){
                            width: 82px;
                            height: 82px;
                        }

                        span{
                            color: #ffffff;
                            font-weight: bold;
                            @include font-size(32px);

                            @include breakpoint('tablet-portrait-min'){
                                @include font-size(30px);
                            }

                            @include breakpoint('desktop-small-min'){
                                @include font-size(32px);
                            }
                        }
                    }

                    .container-text{
                        margin-left: 24px;
                        display: flex;
                        flex-flow: column;
                        padding: 5px 0 5px 0;

                        span{
                            line-height: normal;
                        }
                        &__subtitle{
                            @include font-size(14px);

                            @include breakpoint('tablet-portrait-min'){
                                @include font-size(13px);
                            }

                            @include breakpoint('desktop-small-min'){
                                @include font-size(14px);
                            }

                        }
                        &__title{
                            font-weight: bold;
                            padding-bottom: 8px;
                            @include font-size(16px);

                            @include breakpoint('tablet-portrait-min'){
                                @include font-size(15px);
                            }

                            @include breakpoint('desktop-small-min'){
                                padding-bottom: 16px;
                                @include font-size(16px);
                            }
                        }
                    }
                }
            }
        }
    }

    //Banner
    .bennetClub__banner{
        margin: 0 4% 40px;

        @include breakpoint('tablet-portrait-min'){
            width: calc(100% - 128px);
            margin: 0 auto 80px auto;
        }
    }

    //123Sconto
    .discount{
        display: flex;
        flex-flow: column;
        margin: 0 4% 32px;

        @include breakpoint('tablet-portrait-min'){
            margin: 0 11.6% 80px 11.6%;
            flex-flow: row;
        }

        &-banner{
            width: 100%;

            @include breakpoint('tablet-portrait-min'){
                width: 50%;
                order: 2;
                display: flex;
                align-items: center;
            }

            img{
                width: 100%;
                height: auto;
            }
        }

        &-description{
            display: flex;
            order: 2;
            flex-flow: column;
            justify-content: space-between;
            width: 100%;

            @include breakpoint('tablet-portrait-min'){
                width: 50%;
                padding-right: 32px;
                order: 1;
            }

            &__title{
                line-height: 1.5;
                font-weight: bold;
                margin: 24px 0 24px 0;
                @include font-size(24px);

                @include breakpoint('tablet-portrait-min'){
                    margin: 8px 0 32px 0;
                }

                @include breakpoint ('desktop-small-min'){
                    @include font-size(30px);
                }
            }

            .row{
                display: flex;
                flex-flow: row;
                align-items: center;
                margin-bottom: 20px;

                @include breakpoint('tablet-portrait-min'){
                   // margin-bottom: 0;
                }

                .oval-copy{
                    width: 62px;
                    height: 62px;
                    background-color: $gray-dark;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include breakpoint('desktop-small-min'){
                        width: 82px;
                        height: 82px;
                     }

                    span{
                        color: #ffffff;
                        font-weight: bold;
                        @include font-size(32px);

                        @include breakpoint('tablet-portrait-min'){
                            @include font-size(30px);
                        }

                        @include breakpoint('desktop-small-min'){
                            @include font-size(32px);
                        }
                    }
                }

                .container-text{
                    margin-left: 24px;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    padding: 5px 0px 5px 0;

                    span{
                        line-height: normal;
                    }
                    &__subtitle{
                        @include font-size(14px);

                        @include breakpoint('tablet-portrait-min'){
                            @include font-size(13px);
                        }

                        @include breakpoint('desktop-small-min'){
                            @include font-size(14px);
                        }
                    }
                    &__title{
                        font-weight: bold;
                        padding-bottom: 8px;

                         @include breakpoint('tablet-portrait-min'){
                            @include font-size(15px);
                         }

                        @include breakpoint('desktop-small-min'){
                            padding-bottom: 16px;
                            @include font-size(16px);
                        }
                    }
                }
            }

            a{
                width: 100%;
                margin-top: 12px;

                @include breakpoint('tablet-portrait-min'){
                    width: 218px;
                    margin-left: auto;
                    margin-top: 0;
                }
            }
        }
    }

    .bennet-banner {
        padding: 0 4% 24px 4%;

        @include breakpoint('tablet-portrait-min') {
            padding: 0 11.6% 80px;
        }
    }

    .support__login{
        margin: 0 0 32px;

        @include breakpoint('tablet-portrait-min'){
            margin: 0 auto 80px auto;
        }
    }

    .frequently-asked-questions{
        padding-top: 0;
        margin-top: 0;
    }

}
