.workwithus-listing {
    padding: 0 16px 24px;
    overflow: hidden;

    @include breakpoint("tablet-portrait-min") {
        padding: 0 64px 60px;
    }

    &__hero {

        @include breakpoint("tablet-portrait-max") {
            padding: 24px 0 16px;
        }
    }


    .filter-hide {
        display: none;
    }

    .opportunities-filter {
        padding: 16px 0;

        @include breakpoint("tablet-portrait-min") {
            padding: 32px 0 16px;
        }

        &__header {
            padding-bottom: 25px;

            @include breakpoint("tablet-portrait-min") {
                display: flex;
                align-items: center;
            }
        }

        &__title {
            padding-bottom: 15px;
            @include font-size(18px);

            @include breakpoint("tablet-portrait-min") {
                padding: 0;
            }
        }

        &__results {
            @include font-size(16px);

            @include breakpoint("tablet-portrait-min") {
                padding-left: 16px;
                @include font-size(14px);
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            max-width: none;

            @include breakpoint("tablet-portrait-min") {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__c-form {
            @include breakpoint("phone-min") {
                display: flex;
                justify-content: space-between;
                margin: 0 -12px;
            }

            @include breakpoint("tablet-portrait-min") {
                width: calc(100% - 220px);
            }
        }

        .row {
            margin-bottom: 24px;

            .customselect {
                width: 100%;
            }
            
            @include breakpoint("tablet-portrait-min") {
                margin-bottom: 0;
            }

            @include breakpoint("phone-min") {
                flex: 1;
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        .form-group {
            margin-bottom: 24px;

            @include breakpoint("tablet-portrait-min") {
                margin-bottom: 0;
            }
        }

        .customselect__button {
            @include font-size(14px);
        }

        &__cta {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            @include font-size(13px);

            @include breakpoint("tablet-portrait-max") {
                @include btn-mobile("sm");
            }

            @include breakpoint("tablet-portrait-min") {
                width: 220px;
                margin: 0;
            }
        }
    }

    &__items {
        display: flex;
        flex-flow: wrap;

        @include breakpoint('phone-min') {
            margin-left: -8px;
            margin-right: -8px;
        }

        .opportunity-card {
            margin: 8px 0;
            width: 100%;

            @include breakpoint('phone-min') {
                width: calc(50% - 16px);
                margin: 8px;
            }

            @include breakpoint('tablet-portrait-min') {
                width: calc(33.333% - 16px);
            }
        }
    }

    &__c-view-all {
        display: flex;        
    }

    &__view-all {
        width: 350px;
        margin: 16px auto 0;
        @include font-size(13px);
        
    }

    &__bottom-components {
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        @include breakpoint('phone-min') {
            flex-direction: row;
        }
    }

    .spontaneous-application,
    .linkedin-component {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        background-color: $white;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        padding: 18px 24px;        

        @include breakpoint('desktop-small-min') {
            flex-direction: row;
            align-items: center;
            padding: 24px;
        }

        &__cta {
            margin-top: 16px;
            width: 100%;
           
            @include breakpoint('desktop-small-min') {
                margin: 0;
                width: 218px;
            }
        }
    }

    .linkedin-component {
        margin-bottom: 16px;

        @include breakpoint('phone-min') {
            margin:0 24px 0 0;
        }

        .iconlinkedin-banner {
            width: 100%;
            height: 65px;

            @include breakpoint('phone-min') {
                height: 75px;
            }

            @include breakpoint('tablet-portrait-min') {
                height: 90px;
            }

            @include breakpoint('desktop-small-min') {
                width: 270px;
                height: 48px;
            }
        }
    }

    .spontaneous-application {
        &__content {
            @include breakpoint('desktop-small-min') {
                padding-right: 32px;
            }
        }

        &__title {
            padding-bottom: 12px;
            @include font-size(18px);
        }

        &__text {
            @include font-size(14px);
        }
    }
}
