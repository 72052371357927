.tooltip {
    position: relative;

    &:focus>label,
    &:hover>label {
        opacity: 1;
        margin: 0;
        visibility: visible;
    }
}

.tooltipBubble {
    margin-bottom: -.5em;
    transition: .1s all linear;
    opacity: 0;
    visibility: hidden;
    max-width: 230px;
    word-wrap: break-word;

    @include breakpoint('tablet-portrait-max') {
        display: none;
    }
}

label.tooltipBubble {
    position: absolute;
    color: $white;
    left: 0;
    top: 110%;
    background-color: $gray;
    border-radius: 3px;
    padding: 5px 10px;
    line-height: 1.5;
    pointer-events: none;
    @include font-size(12px);
    @include z-index(one);

    &:after {
        content: '';
        display: block;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $gray;
        position: absolute;
        bottom: 100%;
        left: 10%;
        margin-left: -2.5px;
    }
}

.popover {
    position: relative;

    &:focus>label,
    &:active>label {
        opacity: 1;
        margin: 0;
        visibility: visible;
    }

    @include breakpoint('tablet-portrait-max') {
        &:hover>label {
            opacity: 1;
            margin: 0;
            visibility: visible;
        }
    }

    label.tooltipBubble {
        left: -6%;
        background-color: $white;
        padding: 5px 15px;
        top: 120%;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.12);
        border-radius: 25px;
        pointer-events: initial;
        @include z-index('one');

        @include breakpoint('tablet-portrait-min') {
            left: 10%;
        }

        &:after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $white;
            left: 30%;
        }
    }
}

button.popover .tooltipBubble {
    display: block;
}