.social-network {
    padding: 16px 0;

    @include breakpoint('tablet-portrait-min') {
        padding: 20px 0;
        clear: both;
        width: 100%;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        padding: 18px 30px;
        background-color: $white;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        overflow: hidden;
        position: relative;
        
        @include breakpoint('tablet-portrait-min') {
            flex-direction: row;
            align-items: initial;
            margin: 0 auto;
            padding: 0px;
            width: 74%;
        }
    }

    &__background {
        position: absolute;
        object-fit: cover;
        height: 100%;
        left: 0;
        bottom: 0;

        @include breakpoint('tablet-portrait-min') {
            width: 100%;
            @include z-index('one');
        }
    }
    
    &__c-logo {
        @include breakpoint('tablet-portrait-max') {
            @include z-index('one');
        }

        @include breakpoint('tablet-portrait-min') {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-basis: 50%;
        }

        > a {
            @include z-index('one');
        }

    }

    &__logo {
        width: 90px;
        height: 90px;
        border-radius: 100%;

        @include breakpoint('tablet-portrait-min') {
            width: 108px;
            height: 108px;
        }
    }

    &__ctn {
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        flex: 1;
        @include z-index('one');

        @include breakpoint('tablet-portrait-min') {
            padding: 26px 0 2px 37px;
            flex-basis: 50%;
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        }
    }

    &__description{
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-min') {
            padding-bottom: 24px;
            flex-direction: row;
        }

        
    }

    &__title {
        padding-bottom: 10px;
        @include font-size(16px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0;
            @include font-size(24px);
        }
    }

    &__subtitle {
        padding-bottom: 15px;
        @include font-size(13px);

        @include breakpoint('tablet-portrait-min') {
            line-height: normal;
            margin: 0 6% 0 16px;
            padding: 0;
            @include font-size(20px);
        }
    }

    &__ctas {
        display: flex;
        margin: 0 -12px;
        align-items: center;

        @include breakpoint('tablet-portrait-min'){
            padding-bottom: 20px;
            margin: 0 -15px;
        }

        svg {
            @include breakpoint('tablet-portrait-max'){
                width: 20px;
                height: 20px;
            }
        }

        a {
            margin: 0 12px;

            @include breakpoint('tablet-portrait-min') {
                margin: 0 15px;
            }
        }
    }
}