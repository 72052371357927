.verification-cap-btn {
    position: fixed;
    bottom: 32px;
    left: 16px;
    background-color: $green;
    color: $white;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0 -2px 12px 0 $green-dark-rgb-4;
    border-radius: 12px 52px 52px 12px;
    width: 200px;
    justify-content: space-between;
    @include z-index(backToTop);

    @include breakpoint('tablet-portrait-min') {
        left: 50px;
        width: 230px;
    }

    @include breakpoint('desktop-limit-min') {
        left: calc((100% - 1680px)/2 + 50px);
    }

    svg {
        color: $white;
    }

    .arrow {
        border-color: $white;
        padding: 10px;
    }
}

.verification-cap-popup {
    max-height: 90vh;

    @include breakpoint('tablet-portrait-max') {
        max-height: inherit;
        overflow: hidden;
    }

    &__content {
        @include breakpoint('tablet-portrait-max') {
            // 100vh - footer - header
            height: calc(100vh - 122px - 50px );
            height: calc(var(--vh, 1vh) * 100 - 122px);
            overflow-y: auto;
        }
    }

    p {
        padding-bottom: 16px;
    }
}

#verifyCapForm {
    display: flex;
    flex-flow: column;
    position: relative;

    input {
        border: 1px solid hsla(0,0%,74.1%,.4);
        background-color: $white;
        border-radius: 5px;
        padding: 0 60px 0 16px;
        text-overflow: ellipsis;
        @include font-size(16px);
    }

    &.input-ok {
        .error-message {
            color: $green;
        }

        input {
            border: 1px solid $green;
        }
    }

    &.input-error {
        .error-message {
            color: $red;
        }

        input {
            border: 1px solid $red;
        }
    }
}