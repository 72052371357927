.banner-app {
    width: 100%;
    height: 60px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    position: fixed;
    top: 0;
    @include z-index(panel-mobile);
    @include font-size(14px);

    .iconlogo-b {
        margin-right: 8px;
        width: 50px;
        height: 50px;
    }

    .iconclose {
        width: 10px;
        height: 10px;
        cursor: pointer;
    }

    >div {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 8px;
    }

    a {
        padding: 4px 0;
        width: 120px;
    }
}

.banner-app-translate {
    .header {
        top: 60px;
    }

    .container {
        margin-top: 204px;
    }
}