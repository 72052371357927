.account__banner {
    margin-bottom: 30px;
    border-radius: 8px;
    height: 120px;

    @include breakpoint('tablet-portrait-max') {
        margin-bottom: 25px;
    }

    img {
        min-width: max-content;

        @include breakpoint('tablet-portrait-max') {
            min-width: unset;
        }
    }

    .content {
        padding: 25px 25px 25px 200px;

        @include breakpoint('desktop-medium-max') {
            padding-left: 170px;
        }

        &__right {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;

            @include breakpoint('tablet-portrait-max') {
                align-items: initial;
            }
        }

        &__card {
            @include breakpoint('tablet-portrait-max') {
                order: 2;
            }
        }

        &__points {
            @include breakpoint('tablet-portrait-min') {
                flex-flow: wrap;
                display: flex;
                margin-left: 15px;
                justify-content: flex-end;

                span {
                    display: flex;
                    padding-right: 15px;
                }
            }
        }
    }
}

.account__banner,
.account-nav__banner {
    position: relative;
    overflow: hidden;

    @include breakpoint('tablet-portrait-max') {
        height: 210px;
        border-radius: 0;
    }

    .img-cover {
        height: 100%;
    }

    .content {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        color: $white;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            justify-content: space-between;
            padding: 16px 24px;
            @include font-size(13px);
        }

        &__left {
            display: flex;

            @include breakpoint('tablet-portrait-max') {
                padding: 4px 0;
            }
        }

        &__right {
            display: flex;

            @include breakpoint('tablet-portrait-max') {
                flex-direction: column;
            }
            
            span {
                @include breakpoint('tablet-portrait-max') {
                    padding: 4px 0;
                }
                &:first-child {
                    padding-right: 15px;
                }
            }
        }

        &__card {
            display: flex;
            align-items: center;
            border: 1px solid $white;
            border-radius: 5px;
            width: 340px;
            padding: 8px 16px;
            cursor: pointer;
            margin-top: 15px;
            justify-content: space-between;
            @include font-size(14px);

            @include breakpoint('tablet-portrait-max') {
                padding: 5px 10px;
                width: auto;
                max-width: 340px;
                background-color: $white;
                @include font-size(13px);
            }

            img {
                margin: 0 auto;
            }

            .iconclub-mobile {
                width: 25px;
                height: 25px;
            }

            .arrow {
                border-color: $white;
            }

            &--text {
                display: flex;
                flex-flow: wrap;
                justify-content: space-around;
            }

            &--mobile {
                padding-bottom: 8px;
            }
        }
        
        &__donate {
            position: absolute;
            right: 20px;
            cursor: pointer;
            background-color: $white;
            border-radius: 50%;
            display: flex;
            width: 42px;
            align-items: center;
            height: 42px;
            top: 16px;
            justify-content: center;
            color: $black;

            &:hover {
                color: $green;
            }
        }
    }
}

.account-nav__banner {
    .content__donate {
        @include hidden();
    }
}