body.template-pages-account- {
    &accountLayoutPage .breadcrumbs.desktop-hide {
        @include hidden();
    }

    &productLayout2Page .breadcrumbs.mobile-hide span:last-child { 
        @include hidden(); 
    }
}

.breadcrumbs {
    width: 100%;
    padding: 20px 55px;

    @include breakpoint('tablet-portrait-max') {
        background-color: $gray-ultralight;
        color: $gray;
        padding: 8px 16px;
        @include font-size(13px);
    }

    &:first-child {
        margin-left: 0;
    }

    li {
        margin: 0 8px;
        display: inline-block;
        text-transform: lowercase;

        a, b {
            display: inline-block;
            text-transform: lowercase;
        }

        &::first-letter, a::first-letter, b::first-letter {
            text-transform: uppercase;
        }

        @include breakpoint('tablet-portrait-max') {
            margin: 0;
            span {
                margin: 0 8px;
                display: inline;
            }
        }

        &.active {
            text-decoration: underline;
        }

        &:nth-last-child(5):nth-child(1) ~ li:nth-last-child(3) a,
        &:nth-last-child(5):nth-child(1) ~ li:last-child a {
            text-decoration: underline;
        }

        .arrow {
            border-width: 0 1px 1px 0;
        }
    }
}