.searchbox-results {
    &.new-search {
        .suggestions-list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
            margin-right: 19px;
            border-bottom: 2px solid $gray-light-bg;

            .arrow {
                margin-right: 10px;
                border: solid #595959;
                border-width: 0 1px 1px 0;
                color: $gray;
            }

            &--link {
                width: 100%;
            }

            &--item {
                justify-content: space-between;
            }

            &--text {
                display: inline-flex;
                line-height: 17.57px;

                span:first-of-type::first-letter {
                    text-transform: uppercase;
                }

                @include font-size(14px);
            }

            .highlight {
                font-family: $muli-font-700;
            }
        }

        .suggestions-category {
            &__list {
                padding-left: 8px;

                &--item {

                }

                .in {
                    &:hover {
                        pointer-events: none;
                        color: inherit;
                    }
                }

                .category-link {
                    color: $green;
                    text-decoration: underline;
                    line-height: 17.57px;
                    text-transform: lowercase;
                    @include font-size(14px);

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }

        .term {
            display: inline-block;
            margin-bottom: 8px;
            margin-top: 8px;
            font-family: $muli-font-700;

            &::first-letter {
                text-transform: uppercase;
            }

            @include font-size(14px);
        }

        .magazine-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgba(68, 163, 61, 0.2);
            margin-top: 8px;
            margin-right: 19px;
            padding: 16px 24px 16px 16px;
            border-radius: 10px;

            .arrow {
                display: inline-flex;
                height: 8px;
                color: $gray;
            }

            &__wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                @include font-size(14px);
                color: $gray;
                line-height: 17.57px;
            }

            &__text {

            }

            &__results {
                margin-left: 26px;
            }

            &__icon-link:focus {
                background-color: 2px solid $green;
            }

            &__link {
                font-family: $muli-font-600;
                color: $green;
                text-decoration: underline;
            }
        }

        .searchbox-results {

            &__suggestions {
                &-title {
                    font-family: $muli-font-700;
                }
            }

            &__suggestions-category {
                margin-top: 16px;
                margin-right: 19px;
            }

            &__wrapper {
                display: flex;
                background-color: #fff;
                padding: 16px 32px 8px 16px;
                border-radius: 8px;
                border: 1px solid $gray;
                overflow-y: scroll;
                max-height: calc(100vh - 200px);
            }

            &__title {
                font-family: $muli-font-700;
            }

            &__hits {
                padding: 0;
                width: 80%;
            }

            &__banner {
                padding-right: 19px;

                .simple-banner {
                    display: flex;
                    justify-content: center;
                }
            }

            &__products {
                padding: 0 0 0 15px;
                width: 100%;
                border-left: 2px solid $gray-light-bg;

                &--header {
                    display: flex;
                    justify-content: flex-start;
                    gap: 32px;

                    .arrow {
                        height: 2px;
                        color: $gray;
                    }
                }

                .product-result {
                    padding: 8px 0 15px 6px;

                    &__name {
                        font-family: $muli-font-700;
                        @include font-size(16px);
                    }

                    &__brand {
                        font-family: $muli-font-300;
                        @include font-size(12px);
                    }

                    &__price {
                        font-family: $muli-font-900;
                    }

                    &__actions {
                        max-width: 292px;
                        justify-content: space-between;

                        .c-dropdown {
                            display: flex;
                            width: 100%;
                            gap: 8px;
                            justify-content: space-between;
                            margin-bottom: 34px;

                            &__title {
                                color: $gray;
                                font-family: $muli-font-700;
                                @include font-size(14px);
                            }

                            &__item {
                                max-width: calc(50% - 8px);

                                .customselect {
                                    background-color: transparent;

                                    &__button {
                                        background-color: #ffffff;
                                        border: 1px solid $gray-light;
                                        color: $gray;
                                    }

                                    &__text {
                                        font-family: $muli-font;
                                        font-style: italic;
                                        @include font-size(16px);
                                    }

                                    &__list {
                                        &-item {
                                            background-color: #FFFFFF;
                                            font-family: $muli-font;
                                            font-style: italic;
                                            @include font-size(16px);
                                        }
                                    }
                                }
                            }
                        }

                        .c-cta {
                            .btn {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            &__content {
                border: none;
                padding: 0;
                overflow-y: visible;
            }

            &__suggestions {
                width: 20%;
            }

            &__banner {
                img {
                    max-width: 278px;
                    max-height: 180px;
                }
            }
        }

        .js-addToCart {
            .btn-label {
                font-family: $muli-font-700;
                line-height: 20.08px;
            }
        }

        @include breakpoint('tablet-portrait-max') {
            width: 100%;
            left: 0;
            position: absolute;
            overflow-y: scroll;
            max-height: calc(100svh - 145px);

            .suggestions-list {
                position: relative;
                border: none;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: calc(100%);
                    height: 1px;
                    background-color: #e6e6e6;
                }
            }

            .magazine-box {

            }

            .searchbox-results {

                &__wrapper {
                    flex-direction: column;
                    padding-right: 18px;
                    border-radius: 0;
                    border: none;
                    max-height: none;
                    overflow-y: auto;
                }

                &__suggestions {
                    width: 100%;
                }

                &__hits {
                    width: 100%;
                }

                &__mobile-banner {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: #FFFFFF;

                    img {
                        max-width: 278px;
                        max-height: 180px;
                    }
                }

                &__suggestions-magazine {
                    position: relative;
                    margin-bottom: 10px;
                    padding-bottom: 32px;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: calc(100%);
                        height: 1px;
                        background-color: #e6e6e6;
                    }
                }

                &__content {
                    max-height: none;
                }

                &__products {
                    padding: 0;
                    border: none;

                    &--header {
                        justify-content: space-between;
                    }

                    .product-result {
                        height: auto;

                        &__image {
                            align-items: flex-start;
                        }

                        &__actions {
                            max-width: 100%;

                            .c-dropdown {
                                margin-bottom: 30px;
                            }

                            .c-cta {
                                .btn {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.margin-top-0 {
    margin-top: 0 !important;
}

.mobile-price-promo-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;

    .product-result {

        &__original {
            @include font-size(14px);
        }

        &__price {
        }

        &__prices {

        }

        &__price-wrapper {
            flex-direction: column-reverse;
        }
    }

}