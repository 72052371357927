.account {

    &.content {
        padding: 30px 65px 20px;
        display: flex;
        flex-direction: column;

        @include breakpoint('tablet-portrait-max') {
            padding: 0;
            display: block;    
        }

        .account-nav {
            border-radius: 0 0px 8px 8px;
            box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12); 
            flex-basis: 30%;
            max-width: 30%;
            @include z-index('one');

            @include breakpoint('tablet-portrait-max') {
                @include hidden();
            }
        }


        .account-nav h2, .account-nav h3 {
            font-size: inherit;
        }

        .account-section {
            padding: 2px 0 0 45px;
            flex-basis: 70%;
            max-width: 70%;
            align-self: stretch;

            @include breakpoint('tablet-portrait-max') {
                padding: 0;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

    &__container {
        display: flex;
        align-items: flex-start;
    }

    &__go-back {
        @include hidden();

        @include breakpoint('tablet-portrait-max') {
            box-shadow: 0 1px 0 0 #ebebeb;
            background-color: $gray-ultralight;
            height: 32px;
            flex: 0 0 100%;
            display: flex;
            @include visible();
        }
    }

    &__go-back-cta {
        display: flex;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;
        padding: 10px;
        
        span {
            color: $gray;
            padding-left: 5px;
            @include font-size(13px);
        }
        .arrow__left {
            &.arrow {
                border: solid $gray;
                border-width: 0 2px 2px 0;
                padding: 3px;
            }
        }
    }

    &__title {
        padding-bottom: 20px;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-max') {
            padding-left: 24px;
        }
    }

    .address-book {
        &__paragraph {
            line-height: normal;
            @include breakpoint('tablet-portrait-max') {
                margin: 0 24px;
            }

            .content {
                margin-bottom: 15px;
            }
        }

        &__header {
            display: flex;
            margin-bottom: 25px;

            @include breakpoint('tablet-portrait-max') {
                margin: 0 24px 25px;
            }
        }

        &__add-cta {
            padding-left: 8px;
        }

        &__add {
            cursor: pointer;
            color: $dark-blue;

            @include breakpoint('tablet-portrait-max') {
                @include font-size(16px);
            }
        }

        &__add,
        &__info,
        &__icon-title {
            display: flex;
            align-items: center;
        }

        &__info {
            margin-left: auto;
            
            span {
                padding-right: 10px;
                @include font-size(14px);

                @include breakpoint('phone-max') {
                    @include font-size(8px);
                }
            }

            .iconbennet-text,
            .iconhome-text {
                width: 105px;
                height: 30px;

                @include breakpoint('phone-max') {
                    width: 60px;
                    height: 20px;
                }
            }
        }

        &__content {
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__footer {
            height: 350px;

            @include breakpoint('tablet-portrait-max') {
                height: 550px;
            }
        }
    }

    .account-club {
        display: flex;
        flex-direction: column;

    
        .glide__bullets {
            text-align: center;
        }

        .glide__bullet {
            opacity: 0.5;
            margin-top: 10px;
            
            &--active {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }

        &__card-carousel {
            display: flex;

            img {
                width: 100%;
                height: auto;
            }

            @include breakpoint('tablet-portrait-max') {
               margin-left: 24px;
            }

            @include breakpoint('phone-max') {
                margin: 0;
            }

            .glide__slide {
                max-width: 200px;

                @include breakpoint('phone-max') {
                    max-width: none;
                }
            }

            .glide__bullets {
                @include hidden();
    
                @include breakpoint('phone-max') {
                    @include visible();
                }
            }
        }

        &__card {
            position: relative;
        }

        &__card-text {
            position: absolute;
            top: 0;
            color: white;
            font-size: 10px;
            height: 100%;
            padding: 10% 10% 33% 10%;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include breakpoint('phone-max') {
                font-size: 2.8vw;
            }
        }

        &__info {
            margin-top: 25px;
            line-height: 1.5;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                padding: 0 24px;
                margin-top: 20px;
            }
        }

        &__banner {
            margin-top: 20px;
            position: relative;
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                margin-top: 25px;
                border-top: 2px solid $gray-ultralight-rgb-8;            
            }
        }

        &__img-component {
            flex: 0 0 100%;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }

            a {
                flex: 1;
                display: flex;
            }
        }

        &__c-promo {
            display: flex;
            flex-direction: column;
            padding: 25px 0;

            @include breakpoint('tablet-portrait-max') {
                border-top: 2px solid $gray-ultralight-rgb-8;
            }
        }

        &__promo-carousel {
            display: flex;
            flex-direction: column;
            padding-top: 15px;

            .glide__slides {
                padding-bottom: 10px;
            }

            @for $i from 1 through 3 {
                .glide__bullet:first-child:nth-last-child(#{$i}),
                .glide__bullet:first-child:nth-last-child(#{$i}) ~ .glide__bullet {
                   display: none;

                    @include breakpoint('tablet-portrait-max') {
                        display: inline-block;
                    }
                }
            }

            .glide__slide {
                display: flex;
                justify-content: center;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .carousel-banner-title {
            @include breakpoint('tablet-portrait-max') {
                padding: 0 0 0 24px;
                @include font-size(18px);
            }
        }
    }

    .account-dashboard {

        @include breakpoint('tablet-portrait-max') {
            overflow: hidden;
        }

        &__info {
            line-height: 1.64;
            
            @include breakpoint('tablet-portrait-max') {
                padding: 0 24px;
            }
        }

        &__ctas {
            display: flex;
            margin: 20px -12px;
            flex-flow: wrap;

            @include breakpoint('tablet-portrait-max') {
                margin: 12px -8px;
                padding: 0 24px;
            }
        }

        &__cta {
            width: calc(50% - 24px);
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            box-shadow: 0 5px 94px 0 rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            padding: 27px 40px;
            margin: 12px;
            position: relative;
            > h2 {
                font-size: inherit;
            }

            @include breakpoint('tablet-portrait-max') {
                width: calc(50% - 16px);
                margin: 8px;
            }

            @include breakpoint('phone-max') {
                padding: 27px 0;
                text-align: center;
            }
        }

        &__banner,
        &__contacts {
            padding-bottom: 30px;
        }

        &__contacts {
            .support__contacts {
                width: 100%;
            }

            .contact-image img {
                width: 100%;
                border-radius: 8px;
            }
        }
    }

    .account-online-orders {
        
        display: flex;
        flex-direction: column;
        h2, h3 {
            font-size: inherit;
        }

        @include breakpoint('tablet-portrait-max') {
            padding: 0 16px 16px;
        }

        .account__title {
            @include breakpoint('tablet-portrait-max') {
                padding-left: 0;
            }
        }

        &__no_result {
            > h2 {
                font-size: inherit;
            }
        }

        &__paragraph {
            line-height: normal;
            
            .content {
                margin-bottom: 15px;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-bottom: 25px;

            @include breakpoint('tablet-portrait-max') {
                margin-left: 0;
            }

            label {
                margin-right: 10px;
                @include font-size(14px);
            }
    
            .customselect {
                width: 240px;
    
                @include breakpoint('tablet-portrait-max') {
                    width: 100%;
                }
            }
        }

        &__form {
            display: flex;
            align-items: center;

            @include breakpoint('tablet-portrait-max') {
                margin: 0;
            }
            @include breakpoint('phone-max') {
                flex-direction: column;
                align-items: flex-start;
            }

            .wrapper {
                display: flex;
                max-width: 400px;

                @include breakpoint('phone-max') {
                    margin: 0 -10px;
                    flex-flow: wrap;
                }
            }

            .form-group {
                margin: 0 10px;
                width: 50%;

                @include breakpoint('phone-max') {
                    margin: 20px 10px;
                }

                input {
                    min-width: 165px; //ios

                    @include breakpoint('phone-max') {
                        min-width: 185px; //ios
                    }
                }
            }
        }

        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                margin: 10px;
            }

            .btn .arrow:hover {
                border-color: $green;
            }
        }

        .frequently-asked-questions {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .account-profile {
        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px 20px;
            @include font-size(18px);

            @include breakpoint('tablet-portrait-min') {
                padding-left: 0;
            }
        }

        &__c-info {
            display: flex;
            margin: 10px -20px 0;
            flex-flow: wrap;
            
            > h2 {
                font-size: inherit;
            }

            @include breakpoint('tablet-portrait-max') {
                margin: 0 24px;
                flex-direction: column;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            flex: 1 1 0%;
            padding: 0 20px 20px;
            min-width: 450px;

            @include breakpoint('tablet-portrait-max') {
                min-width: unset;
                padding: 0 0 5px;
            }
        }

        &__info-title {

            > h2 {
                font-size: inherit;
            }
            
            @include breakpoint('tablet-portrait-max') {
                border-bottom: 2px solid $gray-ultralight-rgb-8;
                padding-bottom: 10px;
                @include font-size(16px);
            }
        }

        &__container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        &__row {
            display: flex;
            margin-bottom: 25px;
            flex: 1 1 auto;

            @include breakpoint('tablet-portrait-max') {
                margin-bottom: 22px;
            }
        
            span,b {
                flex: 1 1 0%;
                @include font-size(14px);
            }

            span {
                padding-right: 5px;
            }
        }
    }
}