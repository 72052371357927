.awards-catalog {

    @include breakpoint('tablet-portrait-min') {
        display: flex;
        flex-flow: column;
        margin: 0 15.84% 80px 11.6%;

        &__header{
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding-right: 54px;
        }
    }

    &__header,
    &__text,
    &__download {
        padding-left: 16px;
        padding-right: 16px;

        @include breakpoint('tablet-portrait-min') {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__title {
        padding-bottom: 12px;
        @include font-size(24px);

        @include breakpoint('tablet-portrait-min') {
            @include font-size(30px);
        }
    }

    &__validity {
        @include font-size(16px);
    }

    &__text {
        line-height: normal;
        padding-top: 18px;
        @include font-size(16px);
    }

    &__content {
        padding-top: 24px;

        @include breakpoint('tablet-portrait-min') {
            display: flex;
            flex-flow: row;
        }
    }

    &__download {
        display: flex;
        width: 84%;

        @include breakpoint('tablet-portrait-max') {
            margin: 0 auto;
        }

        >div {
            width: 100%;
        }

        @include breakpoint('tablet-portrait-min') {
            width: 346px;
            padding: 0 0 16px 0;
        }
    }

    &__c-carousel {

        @include breakpoint('tablet-portrait-min') {
            width: calc(100% - 426px);
            margin-left: 80px;
        }

        .c-carousel-title {
            line-height: 1.67;
            padding: 16px 16px 0;
            color: $gray-dark;

            @include font-size(16px);
            @include breakpoint('tablet-portrait-min') {
                padding: 0;
                @include font-size(18px);
            }
        }
    }

    .glide__slide {
        display: flex;
        height: auto;
    }

    .award-card {
        border-radius: 4px;
        display: flex;
        width: 100%;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
        margin-bottom: 24px;
        margin-top: 16px;

        @include breakpoint('tablet-portrait-min') {
            margin: 16px 0;
            padding: 0;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            overflow: hidden;
        }

        &__c-img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 170px;
            background-color: $white;
            width: 100%;
        }

        &__img {
            border-radius: 4px 4px 0 0 ;
            height: 170px;
            object-fit: cover;
        }

        &__info {
            border-radius: 0 0 4px 4px;
            display: flex;
            flex: 1;
            background-color: $white;
            flex-direction: column;
            padding: 16px;
            width: 100%; //flex: 0 0 auto;

            @include breakpoint('tablet-portrait-min') {
                padding: 16px 16px 20px;
            }
        }

        &__title {
            height: 46px;
            line-height: normal;
            overflow: hidden;
            @include font-size(18px);
        }

        &__subtitle {
            line-height: 1.5;
            padding-top: 8px;
            @include font-size(14px);
        }
    }

    .glide__arrow {

        &--left {
           left: -54px;
        }

        &--right {
           right: -54px;
        }
    }
}