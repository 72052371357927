.unavailable-product {
    .checkout-item__cta,
    .checkout-item__promo {
        @include hidden();
    }
}

.unavailable-product,
.promo-expired-popup {
    .iconshippinghome {
        color: transparent;
    }

    .icontrash {
        color: $gray;
    }

    .btn {
        padding: 0 12px;
    }

    .popup__content,
    .popup__footer {
        padding: 16px 24px;

        @include breakpoint('tablet-portrait-max') {
            flex-flow: column;
        }

        >span:first-child {
            width: 60%;
            line-height: 1.5;
            @include font-size(13px);

            @include breakpoint('tablet-portrait-max') {
                margin-bottom: 16px;
                width: 100%;
                text-align: center;
            }
        }
    }

    .popup__content {
        max-height: 60vh;
        overflow-y: auto;

        @include breakpoint('tablet-portrait-max') {
            padding-bottom: 140px;
            max-height: none;
        }
    }

    &__info {
        padding-bottom: 16px;
        line-height: 1.5;
    }

    &__change-timeslot {
        padding-top: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span:first-child {
            width: 60%;
            line-height: 1.5;
            @include font-size(14px);
        }
    }
}

.min-threshold-checkout {
    .header-panel__close {
        @include hidden();
    }
}