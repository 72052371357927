.services-details,
.services-landing {
    padding: 0 0 24px;

    @include breakpoint("tablet-portrait-min") {
        padding-bottom: 60px;
    }

    &__hero {
        padding: 0 64px 16px;

        @include breakpoint("tablet-portrait-max") {
            padding: 24px 0 16px;
        }
    }

    &__wrapper {
        padding: 0 16px;

        @include breakpoint("tablet-portrait-min") {
            padding: 0 64px;
        }
    }

    .single-banner {
        padding: 12px 0;

        @include breakpoint("tablet-portrait-min") {
            padding: 16px 0;
        }
    }

    .bennetcard-carousel {
        padding: 16px 0;

        @include breakpoint("tablet-portrait-min") {
            padding: 16px 64px 0;
        }
    }
}

.services-landing {

    &__title {
        padding: 24px 16px 0;
        @include font-size(18px);

        @include breakpoint('tablet-portrait-min') {
            padding: 0 64px 24px;
            @include font-size(24px);
        }
    }

    &__items-title {
        @include font-size(16px);

        @include breakpoint("tablet-portrait-min") {
            padding-top: 16px;
            @include font-size(18px);
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 32px;
        margin: 16px 0 0;

        @include breakpoint("phone-min") {
            margin-left: -16px;
            margin-right: -16px;
        }

        @include breakpoint("tablet-portrait-min") {
            margin-bottom: 20px;
        }

        @include breakpoint("phone-max") {
            grid-template-columns: 1fr !important;
        }

        @include breakpoint("tablet-portrait-max") {
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
        }

        .bennet-card {
            margin: 12px 16px;
            width: 100%;
            height: auto;
            align-items: center;

            &__info {
                width: 100%;
            }

            @include breakpoint("phone-min") {
                // width: calc(50% - 32px);
                // margin: 12px 16px;
            }

            @include breakpoint("tablet-portrait-max") {
                margin: 0;
            }

        }
    }

    &__c-view-all {
        display: flex;
    }

    &__view-all {
        width: 345px;
        margin: 20px auto 12px;
        @include font-size(13px);
    }
}

.services-details {

    .multiple-component {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        
    
        @include breakpoint('tablet-portrait-min') {
            flex-flow: wrap;
            flex-direction: initial;
        }
    
        &__header {
            display: flex;
            flex-direction: column;
            padding-top: 22px;
    
            @include breakpoint('tablet-portrait-min') {
                flex-direction: initial;
                padding-top: 8px;
            }
        }
    
        &__title {
            @include font-size(24px);
        }
    
        &__info {
            display: flex;
            align-items: center;
            padding-top: 10px;
    
            @include breakpoint('tablet-portrait-min') {
                margin-left: auto;
                padding: 0 25px 0 0;
            }
    
            svg {
                width: 25px;
            }
        }

        &__info-text {
            padding-left: 15px;
            @include font-size(16px);
        }
    
        &__paragraph {
            line-height: 1.6;
            padding-top: 20px;
            @include font-size(16px);
        }
    
        &__img {
            order: 1;
    
            @include breakpoint('tablet-portrait-min') {
                width: 335px;
            }
        }
    
        &__content {
            order: 2;
            
            @include breakpoint('tablet-portrait-min') {
                width: calc(100% - 335px);
                padding-left: 32px;
            }
        }

        &__selectPos {
            display: flex;
            padding: 0 0 12px 4px;
        }
    
        &__c-cta {
            order: 3;
            padding-top: 24px;
    
            @include breakpoint('tablet-portrait-min') {
                margin-left: auto;
                padding-top: 30px;
            }

            &.libri {
                @include breakpoint('tablet-portrait-min') {
                    width: 600px;
                    min-height: 70px;
                }

                form {
                    @include breakpoint('tablet-portrait-max') {
                        height: 150px;
                    }
                }

                .customselect {
                    position: absolute;
                    width: calc(100% - 32px);
                    @include z-index(backToTop);

                    @include breakpoint('tablet-portrait-min') {
                        width: 250px;
                    }
                }
            }
        }

        &__cta {
            width: 100%;
            @include font-size(16px);

            @include breakpoint('tablet-portrait-min') {
                min-width: 210px;
            }

            &.libri {
                @include breakpoint('tablet-portrait-max') {
                    position: relative;
                    top: 45%;    
                }

                @include breakpoint('tablet-portrait-min') {
                    float: right;
                    width: auto;
                }
            }

            a {
                display: flex;
                width: 100%;
                justify-content: center;
                height: 100%;
                align-items: center;
                color: inherit;
                padding: 0 16px;
            }
        }

        .error-message{
            left: 4px;
        }
    }

    .store-component {
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        margin: 16px 0;

        @include breakpoint("tablet-portrait-min") {
            margin: 16px 64px;
            display: flex;
        }

        &__content {
            padding: 24px 16px;

            @include breakpoint("tablet-portrait-min") {
                width: 50%;
                padding: 32px 50px;
            }
        }

        &__text {
            padding-bottom: 16px;
            @include font-size(16px);
        }

        &__cta {
            width: 100%;
            @include font-size(16px);

            @include breakpoint("tablet-portrait-min") {
                width: 220px;
            }
        }

        &__c-img {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}