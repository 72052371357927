.supportForm{

    .go-back{
        padding: 8px 0 8px 16px;
        background-color: #f2f2f2;

        .arrow{
            vertical-align: middle;
            padding: 2px;
            border-color: $gray;
        }

        &__home{
            font-size: 13px;
            line-height: normal;
            color: $gray;
        }
    }

    .breadcrumbs{
        padding: 31px 0 8px 65px;

        li{
            margin: 0;

            &:last-child{
                span{
                    font-weight: bold;
                }
            }
        }
    }

    &__title{
        padding: 32px 0 24px 63px;
        font-size: 20px;
        font-weight: bold;
        line-height: normal;

        @include breakpoint('tablet-portrait-max'){
            color: #e30613;
            line-height: 1.5;
            font-weight: normal;
            font-size: 18px;
            padding: 24px 0 24px 16px;
        }
    }

    &__input{
        background-color: $gray-ultralight;
        padding: 32px 0 32px 0;

        @include breakpoint('tablet-portrait-max'){
            padding: 16px 0;

            .support__login{
                width: 0;
                height: 80px;

                .login-container{
                    margin: 0 16px;
                }
            }
        }

        form{
            max-width: 100%;
            width: calc(100% - 128px);
            margin: auto;

            @include breakpoint('tablet-portrait-max'){
                width: calc(100% - 16px);
                margin: 0 0 0 auto;
            }

            label{
                display: none;
            }

            .form-group{
                margin: 0 16px 28px 0;

                @include breakpoint('tablet-portrait-max'){
                    margin: 0 0 31px 0;
                }
            }

             .data-section-title{
                color: #000000;
                font-size: 24px;
                font-weight: bold;
                flex-basis: calc(100% - 36px);
                margin-bottom: 16px;
                line-height: normal;

                @include breakpoint('tablet-portrait-max'){
                    margin-bottom: 24px;
                    font-size: 20px;
                    line-height: 1.5;
                }
             }

            .user-data{
                background-color: #ffffff;
                padding: 32px 16px 24px 32px;
                display: flex;
                flex-flow: row wrap;

                @include breakpoint('tablet-portrait-max'){
                    padding: 16px 16px 47px 16px;
                }

                &__name, &__surname{
                    flex-basis: calc(50% - 16px);

                    @include breakpoint('tablet-portrait-max'){
                        margin: 0 0 30px 0;
                        flex-basis: 100%;
                    }
                }

                &__email, &__phone, &__cardNumber{
                    flex-basis: calc(33.3% - 16px);

                    @include breakpoint('tablet-portrait-max'){
                        margin: 0 0 30px 0;
                        flex-basis: 100%;
                    }
                }

                .btn{
                    margin: 0 16px 0 auto;
                    width: 218px;

                    @include breakpoint('tablet-portrait-max'){
                        margin: 0 auto;
                    }
                }
            }

            .user-data-saved{
                background-color: #ffffff;
                padding: 24px 16px 8px 32px;
                display: flex;
                flex-flow: row wrap;

                @include breakpoint('tablet-portrait-max'){
                    padding: 16px 16px 8px 16px;
                }

                .icon{
                    margin-right: 16px;
                    .iconpencil{
                        width: 20px;
                        height: 20px;
                    }
                }

                .column-1{
                    display: flex;
                    flex-direction: column;
                    margin-right: 140px;
                    line-height: normal;

                    span{
                        margin-bottom: 16px;

                        @include breakpoint('tablet-portrait-max'){
                            margin-bottom: 8px;
                        }
                    }

                    .semicolumn-1{
                        span{
                            display: inline-block;
                            text-transform: lowercase;

                            &:first-letter{
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .column-2{
                    display: flex;
                    flex-direction: column;
                    line-height: normal;

                    span{
                        margin-bottom: 16px;

                        @include breakpoint('tablet-portrait-max'){
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            .reporting{
                margin-top: 24px;
                background-color: #ffffff;
                padding: 24px 32px;
                display: flex;
                flex-flow: column wrap;

                @include breakpoint('tablet-portrait-max'){
                    padding: 16px 16px 24px 16px;
                }

                .form-group{
                    margin-bottom: 24px;

                    &:nth-child(3){
                        margin-bottom: 16px;
                    }

                    @include breakpoint('tablet-portrait-max'){

                        &:first-child{
                            margin: 0 -16px 16px;
                            padding-bottom: 16px;
                            border-bottom: 1px solid #f2f2f2;
                            .customselect{
                                width: calc(100% - 32px);
                                margin: 0 16px 0 16px;
                            }
                        }

                        &:nth-child(3){
                            margin-bottom: 40px;
                        }
                    }
                }

                &__description{

                    textarea{
                        height: 176px;

                       @include breakpoint('tablet-portrait-max'){
                            height: 237px;
                       }
                    }
                }

                .privacy-section{
                    border-radius: 5px;
                    height: 184px;
                    padding: 17px 28px 16px 16px;
                    line-height: 1.71;
                    overflow-y: scroll;
                    font-size: 14px;
                    color: $gray-light;

                    @include breakpoint('tablet-portrait-max'){
                        padding: 16px;
                        height: 248px;
                    }

                    &__title{
                        font-weight: bold;
                    }
                }

                .privacy-accept{

                    &__title{
                        font-size: 13px;
                        margin-top: 16px;
                        color: $gray;
                        margin-bottom: 16px;
                        line-height: normal;
                    }

                    label{
                        display: block;
                        padding-left: 34px;
                        color: #333333;
                        font-weight: 600;
                        width: fit-content;
                    }
                }

                .btn{
                    width: 218px;
                    margin: 0 16px 0 auto;

                    @include breakpoint('tablet-portrait-max'){
                        width: 100%;
                        margin: 32px auto 0;
                    }
                }
            }
        }
    }

    #channels{
        margin-top: 32px;

        @include breakpoint('tablet-portrait-max'){
            margin-top: 0;
        }
    }
}