input, 
textarea {
    &::-webkit-input-placeholder {
        color: $gray-dark; /* Edge */
        @include font-size(15px);
    }
    
    &:-ms-input-placeholder {
        color: $gray-dark; /* Internet Explorer 10-11 */
        @include font-size(15px);
    }
    
    &::placeholder {
        color: $gray-dark;
        @include font-size(15px);
    }
}

input[type="radio"] + label,
input[type="checkbox"] + label {
    padding-left: 35px;
    position: relative;
    cursor: pointer;
    min-height: 20px;
    display: inline-flex;
    align-items: center;
    line-height: 1.5;
    transition: color .15s ease;

    &:hover {
        color: $green;
    }

    &:after {
        content: '';
        height: 18px;
        left: 0;
        top: 0;
        width: 18px;
        background-color: #fff;
        box-shadow: inset 0 0 0 1px $gray-dark;
        border-radius: 2px;
        position: absolute;
    }

    &:hover:after {
        box-shadow: inset 0 0 0 1px $green;
    }
}

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
    &:before{
        background-color: $green;
        color: $white;
        border-radius: 2px;
        content: '\2714';
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        @include z-index(one);
    }
}

input[type="radio"] + label {
    &:after{
        border-radius: 100%;
    }
}

input[type="radio"]:checked + label {
    &:before {
        border-radius: 100%;
        border: 3px solid $white;
        content: '';
        top: 2px;
        left: 2px;
        width: 14px;
        height: 14px;
    }
}

input[type='date'] {
    -webkit-appearance: none;
}

input[type="file"] {
    width: 100%;

    &::-webkit-file-upload-button {
        outline: none;
        color: $gray-dark;
        border: 1px solid $gray;
        background-color: $white;
        border-radius: 5px;
        transition: color .15s ease-in-out,border-color .15s ease-in-out;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 30px;
        margin-bottom: 40px;
        width: 125px;

        @include breakpoint('phone-min') {
            width: 285px;
        }
    
        @include breakpoint('tablet-portrait-min') {
            width: 210px;
            margin-bottom: 0;
            @include font-size(13px);
        }
    
        &:hover {
            color: $green;
            border-color: $green;
            text-decoration: none;
        }
    }
}

form {
    max-width: 700px;
    margin: 0 auto;
}

.form-group {
    position: relative;
    margin-bottom: 30px;

    //IE11
    input:not([type=radio]), textarea {
        & + label {
            line-height: 1.5;
            position: absolute;
            top: 20px;
            left: 16px;
            transition: visibility .3s ease, top .3s ease;
            color: $gray;
            visibility: hidden;
            @include font-size(12px);
            @include z-index(zero);
        }
    }

    input {
        height: 45px;
        padding-top: 10px;
    }

    textarea {
        padding-top: 18px;
        resize: none;
    }

    input, textarea {
        border-radius: 5px;
        padding-left: 16px;
        width: 100%;
        position: relative;
        background-color: $gray-ultralight;
        line-height: 1.7;
        border: 1px solid $gray-ultralight;
        @include z-index(two);

        &:hover {
            border: 1px solid $gray-light;
            transition: 0.3s all ease;
        }

        &:focus {
            border: 1px solid transparent;
            border-bottom: 3px solid $gray;
            transition: 0.3s all ease;
            //border: none;
            

            &::-webkit-input-placeholder { /* Edge */
                visibility: hidden;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                visibility: hidden;
            }
            
            &::placeholder {
                visibility: hidden;
            }
        }

        &:focus + label {
            top: 2px;
            visibility: visible;
            transition: visibility .3s ease, top .3s ease;
        }

        &:not(:placeholder-shown):not([type=radio]) + label {
            top: 2px;
            visibility: visible;
            transition: visibility .3s ease, top .3s ease;
        }
        &.error-field {
            border-bottom: 3px solid $red;
        }

        &.disabled,
        &[disabled],
        fieldset[disabled] & {
            background-color: $gray-light;
            pointer-events: none;
            color: $gray-dark;
            opacity: 0.8;

            &:hover,
            &:focus,
            &.focus {
                background-color: $gray-light;
            }
        }

        &[readonly],
        fieldset[readonly] & {
            background-color: $gray-light;
            pointer-events: none;
            color: $gray-dark;
            opacity: 0.8;

            &:hover,
            &:focus,
            &.focus {
                background-color: $gray-light;
            }
        }
    }

    .customselect {
        width: 100%;
    }

    .error-message {
        color: $red;
        position: absolute;
        bottom: -30px;
        height: 28px;
        @include font-size(12px);
    }
}

.customselect, .customdropdown {
    $this: &;
    font-family: $lato-font;
	position: relative;
    display: inline-block;
    height: 45px;
    width: 150px;
    background-color: $white;
    border-radius: 5px;

    // &--white {
    //     #{$this}__button {}
    // }
       
	&__button {
        display: flex;
        align-items: center;
        border-radius: 5px;
		cursor: pointer;
        width: 100%;
        padding: 2px 15px;
        height: 100%;
        font-size: inherit;
        justify-content: space-between;
        background-color: $gray-ultralight;
        color: $gray-dark;
        border: 1px solid transparent;

        &.disabled,
        &[disabled],
        fieldset[disabled] & {
            background-color: $green-rgb-3;
            pointer-events: none;
            color: $gray-dark;
            opacity: 0.8;

            &:hover,
            &:focus,
            &.focus {
                background-color: $green-rgb-3;
            }

            .customselect__text {
                opacity: 0.8;
            }
        }

        &:hover {
            border: 1px solid $gray-light;
            transition: all ease-in-out .15s;
        }
        
        .arrow {
            border: solid $gray;
            border-width: 0 2px 2px 0;
        }

        &.active {
            border-radius: 5px 5px 0 0 ;
            border-top: solid 1px $gray-light;
            border-left: solid 1px $gray-light;
            border-right: solid 1px $gray-light;
            background-color: $gray-ultralight;

            &:hover {
                border-bottom:1px solid transparent;
            }
            
            > input ~ span,i {
                display: none;
            }

            > input {
                display: block;
            }
        }

        > input {
            display: none;
        }
	}

	&__list {
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		list-style-type: none;
		opacity: 0;
		pointer-events: none;
		transform-origin: top left;
		transform: scaleY(0);
        transition: all ease-in-out .15s;
        border: none;
        border-top: 0px solid transparent;
        background-color: $white;
        max-height: 250px;
        overflow: auto;
        border-radius: 0 0 5px 5px;
        @include z-index(three);
        @include overflow();

		&.active {
			opacity: 1;
			pointer-events: auto;
			transform: scaleY(1);
            perspective: 1000px;
            border-bottom: solid 1px $gray-light;
            border-left: solid 1px $gray-light;
            border-right: solid 1px $gray-light;
		}
    }
    
	&__list-item {
        padding: 2px 15px;
        display: flex;
        align-items: center;
		list-style-type: none;
		line-height: 1.5;
		cursor: pointer;
		color: $gray;
        transition: all ease-in-out .15s;
        min-height: 30px;
        background-color: $gray-ultralight;
        border-top: 1px solid $gray-ultralight;
        @include font-size(14px);

		&:hover {
            transition: all ease-in-out .15s;       
			background-color: $white;
        }
        
        &:active {
            font-weight: bold;
            transition: all ease-in-out .15s;        
            background-color: $white;
            color: $gray-dark;
        }

        label {
            width: 100%;
        }
	}

    &__list-search {
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        position: absolute;
        left: 0;
        padding: 2px 15px;

        &::placeholder {
            font-style: italic;
        }
    }
}